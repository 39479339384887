import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useStateContext } from 'store';
import { useCallback } from 'react';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { MOCK_FILE_RELATIONS_DATA } from 'containers/Files/Mocks/MockFileRelationsData';

import styles from './ModalFilesDelete.module.css';

// todo: GET /api/v3/files/{fileId}/relations
export const ModalFilesDelete = ({ file }) => {
  const { closePopup, removeFile } = useStateContext();
  // todo: fix
  const relationsData = MOCK_FILE_RELATIONS_DATA;

  const handleDeleteFile = useCallback(() => {
    removeFile(file.id, closePopup);
  }, []);

  return (
    <div className={styles.delete_wrapper}>
      <div className={styles.delete_wrapper_body}>
        <div className={styles.delete_wrapper_title}>{`Вы уверены что хотите удалить ${file.name}?`}</div>
        <div className={styles.delete_wrapper_text}>
          Данный файл используется:
          {relationsData.playlists.length &&
            relationsData.playlists.map(data =>
              data.screens.map(screen => (
                <NavLink key={screen.id} className={styles.delete_wrapper_link} to={'/'}>
                  {screen.name}
                </NavLink>
              )),
            )}
          {relationsData.presentations.length &&
            relationsData.presentations.map(data =>
              data.screens.map(screen => (
                <NavLink key={screen.id} className={styles.delete_wrapper_link} to={'/'}>
                  {screen.name}
                </NavLink>
              )),
            )}
        </div>

        <div className={styles.delete_wrapper_actions}>
          <Button onClick={handleDeleteFile}>Удалить</Button>
          <Button variant={ButtonVariantEnum.SOLID_SECONDARY} onClick={closePopup}>
            Отмена
          </Button>
        </div>
      </div>
    </div>
  );
};

ModalFilesDelete.propTypes = { file: PropTypes.object };
