const FileTypes = {
  Image: 'image',
  Video: 'video',
  Audio: 'audio',
  Presentation: 'presentation',
  PPTX: 'pptx',
};

const PPTX_EXT = 'vnd.openxmlformats-officedocument.presentationml.presentation';
const FileExtensions = {
  [FileTypes.Image]: ['jpg', 'jpeg', 'webp', 'png', 'gif'],
  [FileTypes.Video]: ['mp4', 'webm'],
  [FileTypes.Audio]: ['mpeg', 'wav', 'wave', 'flac', 'ogg', 'mp3'],
  [FileTypes.Presentation]: ['pdf'],
  [FileTypes.PPTX]: [PPTX_EXT],
};

const DefaultFileTypes = [FileTypes.Image, FileTypes.Video, FileTypes.Audio, FileTypes.Presentation, FileTypes.PPTX];

const AllFileTypes = [FileTypes.Image, FileTypes.Video, FileTypes.Audio, FileTypes.Presentation, FileTypes.PPTX];

export { FileTypes, FileExtensions, DefaultFileTypes, AllFileTypes, PPTX_EXT };
