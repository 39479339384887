import { useStateContext } from 'store';
import { useCallback, useEffect } from 'react';
import { useGroups } from 'containers/Groups/hooks/useGroups';
import { organisationsService } from 'services/organisations.service';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import { ModalOrganisationsAdd, ModalOrganisationsEdit } from 'containers/UI/Modal/Components/Organisations';
import { ModalGroupsManagement } from 'containers/UI/Modal/Components/Groups';

import { ReactComponent as Download } from 'icons/fileDownload.svg';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';

export const useOrganisations = () => {
  const {
    organisations,
    isOrganisationsLoading,
    totalOrganisationsCount,
    openPopup,
    downloadFile,
    closePopup,
    removeOrganisation,
    organisationsFilterType,
    setOrganisationsFilterType,
  } = useStateContext();

  const {
    groups,
    groupOptions,

    loadGroups,
    handleCreateGroup,
    handleUpdateGroup,
    handleDeleteGroup,
  } = useGroups(organisationsService);

  useEffect(() => {
    loadGroups();
  }, []);

  const handleCreate = () => {
    openPopup(
      () => (
        <ModalOrganisationsAdd
          organisationsGroupOptions={groupOptions.filter(groupOption => groupOption.value !== 'all')}
          handleCreateOrganisationsGroup={handleCreateGroup}
        />
      ),
      {
        title: 'Создание организации',
      },
    );
  };

  const handleFilterGroup = filterValue => {
    setOrganisationsFilterType(filterValue);
  };

  const handleFileDownload = organisationId => {
    downloadFile(organisationId, closePopup);
  };

  const handleEditOrganisation = organisation => {
    openPopup(
      () => (
        <ModalOrganisationsEdit
          organisation={organisation}
          organisationsGroupOptions={groupOptions.filter(groupOption => groupOption.value !== 'all')}
          handleCreateOrganisationsGroup={handleCreateGroup}
        />
      ),
      {
        title: 'Изменить данные огранизации',
      },
    );
  };

  const handleOrganisationDelete = useCallback(organisationId => {
    openPopup(
      () => (
        <ModalDelete
          contentId={organisationId}
          title={'Вы уверены что хотите удалить организацию?'}
          description={'После удаления все данные по этой организации станут недоступны, удалить?'}
          onDelete={removeOrganisation}
        />
      ),
      {
        title: 'Удаление организации',
      },
    );
  }, []);

  const header = [
    { key: 'name', title: 'Название' },
    { key: 'licenseNumber', title: 'Номер лицензии' },
    { key: 'comment', title: 'Комментарий' },
    { key: 'groupName', title: 'Группа' },
    {
      key: 'actions',
      title: (
        <div key="organisations-head-button" className={tableStyles.rowButtons}>
          <Button key="organisation-create" className={tableStyles.headerButton} onClick={handleCreate}>
            Создать организацию
          </Button>
        </div>
      ),
    },
  ];

  const handleOpenGroupSettings = useCallback(() => {
    openPopup(
      () => (
        <ModalGroupsManagement
          groups={groups}
          handleCreate={handleCreateGroup}
          handleEdit={handleUpdateGroup}
          handleDelete={handleDeleteGroup}
        />
      ),
      {
        title: 'Управление группами',
      },
    );
  }, [groups]);

  const body = organisations?.map(organisation => {
    const { name, licenseNumber, comment, fileName, group } = organisation || {};
    const groupName = group?.name;

    return {
      name,
      licenseNumber,
      comment,
      groupName,
      actions: (
        <div key="organisation-item-buttons" className={tableStyles.rowButtons}>
          {fileName && (
            <Button
              variant={ButtonVariantEnum.ICON}
              iconData={{ icon: <Download /> }}
              title={'Скачать файл'}
              onClick={() => handleFileDownload(organisation.id)}
            />
          )}

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Изменить'}
            onClick={() => handleEditOrganisation(organisation)}
          />

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить'}
            onClick={() => handleOrganisationDelete(organisation.id)}
          />
        </div>
      ),
    };
  });

  return {
    totalOrganisationsCount,
    isOrganisationsLoading,
    organisationsFilterType,
    groupOptions,
    handleFilterGroup,
    handleOpenGroupSettings,

    header,
    body,
  };
};
