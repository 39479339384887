const WidgetTypes = {
  Text: 'Text',
  Fuel: 'Fuel',
  Html: 'HTML',
  Image: 'Image',
  Clock: 'Time',
  Playlist: 'Playlist',
  RoadSign: 'RoadSign',
  Timer: 'Timer',
  VideoStream: 'VideoStream',
  Integration: 'Integration',
  YouTube: 'YouTube',
  Telegram: 'Telegram',
  AudioPlaylist: 'AudioPlaylist',
  SiteCapture: 'Website',
  Presentation: 'PDF',
  RunningText: 'RunningText',
  Rss: 'RSS',
  PPTX: 'PPTX',
  SportScore: 'SportScore',
  SportPeriod: 'SportPeriod',
};
const WidgetTypesC = {
  Text: 'Text',
  RoadSign: 'RoadSign',
  Clock: 'Time',
  Image: 'Image',
  Fuel: 'Fuel',
  Timer: 'Timer',
  VideoStream: 'VideoStream',
  Integration: 'Integration',
  YouTube: 'YouTube',
  SiteCapture: 'Website',
  RunningText: 'RunningText',
  Presentation: 'PDF',
  Html: 'HTML',
  Rss: 'RSS',
  AudioPlaylist: 'AudioPlaylist',
  Telegram: 'Telegram',
  Playlist: 'Playlist',
  PPTX: 'PPTX',
  SportScore: 'SportScore',
  SportPeriod: 'SportPeriod',
};

const WidgetTitle = {
  [WidgetTypes.Text]: 'Текст',
  [WidgetTypes.Fuel]: 'Цена на топливо',
  [WidgetTypes.Html]: 'HTML',
  [WidgetTypes.Image]: 'Изображение',
  [WidgetTypes.Clock]: 'Часы',
  [WidgetTypes.Playlist]: 'Плейлист',
  [WidgetTypes.RoadSign]: 'Дорожный знак',
  [WidgetTypes.Timer]: 'Таймер',
  [WidgetTypes.VideoStream]: 'Видеопоток',
  [WidgetTypes.Integration]: 'Интеграция',
  [WidgetTypes.YouTube]: 'YouTube',
  [WidgetTypes.Telegram]: 'Telegram',
  [WidgetTypes.AudioPlaylist]: 'Аудио плейлист',
  [WidgetTypes.SiteCapture]: 'Захват сайта',
  [WidgetTypes.Presentation]: 'Презентация',
  [WidgetTypes.RunningText]: 'Бегущая строка',
  [WidgetTypes.Rss]: 'Лента новостей',
  [WidgetTypes.PPTX]: 'PPTX',
  [WidgetTypes.SportScore]: 'SportScore',
  [WidgetTypes.SportPeriod]: 'SportPeriod',
};

const ALL_WIDGETS = [
  WidgetTypes.Text,
  WidgetTypes.Fuel,
  WidgetTypes.Html,
  WidgetTypes.Image,
  WidgetTypes.Clock,
  WidgetTypes.Playlist,
  WidgetTypes.RoadSign,
  WidgetTypes.Timer,
  WidgetTypes.VideoStream,
  WidgetTypes.Integration,
  WidgetTypes.YouTube,
  WidgetTypes.Telegram,
  WidgetTypes.AudioPlaylist,
  WidgetTypes.SiteCapture,
  WidgetTypes.Presentation,
  WidgetTypes.RunningText,
  WidgetTypes.Rss,
  WidgetTypes.PPTX,
  WidgetTypes.SportScore,
  WidgetTypes.SportPeriod,
];

const WidgetTypesOldFormat = {
  Text: 'text',
  Fuel: 'fuel_price_list',
  Html: 'html',
  Image: 'image',
  Clock: 'clock',
  Playlist: 'playlist',
  RoadSign: 'road_sign',
  Timer: 'timer',
  VideoStream: 'video_stream',
  Integration: 'integration',
  YouTube: 'youtube',
  Telegram: 'telegram_chat',
  AudioPlaylist: 'audio_playlist',
  SiteCapture: 'site_capture',
  Presentation: 'presentation',
  RunningText: 'running_text',
  Rss: 'rss',
};

export { ALL_WIDGETS, WidgetTypes, WidgetTypesC, WidgetTitle, WidgetTypesOldFormat };
