import { getServiceMethods } from './helpers/getServiceMethods';
import { configRequest, createBaseApi } from './baseApi';

const baseApi = createBaseApi();

const getTerminalReleases = () => {
  return baseApi.get('/releases', configRequest);
};

const activateTerminalUpdate = releaseScheduleId => {
  return baseApi.post(`/releases/schedules/${releaseScheduleId}/activate`, configRequest);
};

const deactivateTerminalUpdate = releaseScheduleId => {
  return baseApi.post(`/releases/schedules/${releaseScheduleId}/deactivate`, configRequest);
};

/**
 *
 * @type {{getAll: (function(*): *), patchById: (function(*, *): *), getById: (function(*): *), deactivateTerminalUpdate: (function(*): Promise<axios.AxiosResponse<any>>), deleteById: (function(*): *), activateTerminalUpdate: (function(*): Promise<axios.AxiosResponse<any>>), put: (function(*, *): *), createNew: (function(*): *), getTerminalReleases: (function(): Promise<axios.AxiosResponse<any>>)}}
 */
export const terminalUpdatesService = {
  ...getServiceMethods('releases/schedules'),
  getTerminalReleases,
  activateTerminalUpdate,
  deactivateTerminalUpdate,
};
