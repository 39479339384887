import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useStateContext } from 'store';
import { ReactComponent as Close } from 'icons/close.svg';
import { useKeydown } from 'containers/Hooks';
import { Button } from 'containers/UI/Button';
import { ModalVariantEnum } from './Constants/ModalVariantEnum';

import styles from './Modal.module.css';

const Modal = ({ variant = ModalVariantEnum.DARK }) => {
  const { pathname } = useLocation();
  const { popupData, closePopup } = useStateContext();
  const isOpenModal = typeof popupData.render === 'function';

  useKeydown({
    key: {
      escape: closePopup,
      esc: closePopup,
    },
  });

  useEffect(() => {
    if (isOpenModal) {
      closePopup();
    }
  }, [pathname]);

  if (!isOpenModal) {
    return null;
  }

  const { title, className, showCloseBtn = false } = popupData.options;

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalBG} onClick={closePopup} />
      <div
        className={classnames(styles.modalBody, {
          [styles[`modalBody_${variant}`]]: true,
          [className]: !!className,
        })}
      >
        {title && <div className={styles.modalTitle}>{title}</div>}
        {showCloseBtn && (
          <Button
            className={styles.modalCloseBtn}
            iconData={{
              icon: <Close />,
              inlineStyles: { width: '10px', height: '10px' },
            }}
            onClick={closePopup}
          />
        )}
        <div className={styles.modalContent}>{popupData.render()}</div>
      </div>
    </div>
  );
};

Modal.propTypes = { variant: PropTypes.oneOf([ModalVariantEnum.DARK, ModalVariantEnum.LIGHT]) };

export default Modal;
