import { useApi } from 'services/hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import { organisationsService } from 'services/organisations.service';
import { responseHandler } from '../helpers/responseHandler';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { URLs } from 'helpers/constants/URLs';

export const useOrganisationsState = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [
    organisations,
    setOrganisations,
    isOrganisationsLoading,
    organisationsError,
    setOrganisationsError,
    totalOrganisationsCount,
    setTotalOrganisationsCount,
  ] = useApi(() => organisationsService.getAll(getQueryParams(+page - 1) || {}));

  const [allOrganisationsNames] = useApi(organisationsService.getAllOrganisationsNames);
  const [organisationWasEditedToggler, setOrganisationWasEditedToggler] = useState(false);
  const [organisationsFilterType, setOrganisationsFilterType] = useState('all');

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.organisations) {
        return (() => {
          organisationsService
            .getAll(getQueryParams(+page - 1, organisationsFilterType) || {})
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response.data?.items) &&
                  setOrganisations(
                    response.data.items.map(organisation =>
                      organisation.group ? organisation : { ...organisation, group: null },
                    ),
                  );

                setTotalOrganisationsCount(response?.data?.count);
              }
            })
            .catch(setOrganisationsError);
        })();
      }
    }, [pathname, page, totalOrganisationsCount, organisationWasEditedToggler, organisationsFilterType]);
  };
  paginate();

  const addOrganisation = useCallback((organisation, fileData, onModalClose) => {
    organisationsService
      .createNew(organisation)
      .then(response => {
        if (response.status === 200) {
          setTotalOrganisationsCount(prevState => prevState + 1);
          responseHandler(true, 'Организация была успешно создана', onModalClose);

          if (fileData) {
            uploadFile(response.data.id, fileData, onModalClose);
          }
        }
      })
      .catch(e => {
        setOrganisationsError(e);
        responseHandler(false, 'Организация не была создана', onModalClose);
      });
  }, []);

  const uploadFile = (organisationId, fileData, onModalClose) => {
    organisationsService
      .uploadFile(organisationId, fileData)
      .then(response => {
        if (response.status === 200) {
          responseHandler(true, 'Файл был успешно загружен', onModalClose, null);
        }
      })
      .catch(e => {
        setOrganisationsError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  };

  const changeOrganisation = useCallback((organisationNewData, fileData, onModalClose) => {
    organisationsService
      .put(organisationNewData.id, organisationNewData)
      .then(response => {
        if (response.status === 204) {
          setOrganisationWasEditedToggler(prevState => !prevState);
          responseHandler(true, 'Данные организации были успешно изменены', onModalClose);

          if (fileData) {
            uploadFile(organisationNewData, fileData, onModalClose);
          }
        }
      })
      .catch(e => {
        setOrganisationsError(e);
        responseHandler(false, 'Данные организации не были изменены', onModalClose);
      });
  }, []);

  const removeOrganisation = useCallback((organisationId, onModalClose) => {
    organisationsService
      .deleteById(organisationId)
      .then(response => {
        if (response.status === 204) {
          setTotalOrganisationsCount(prevState => prevState - 1);
          responseHandler(true, 'Организация была успешно удалена', onModalClose, null);
        }
      })
      .catch(e => {
        setOrganisationsError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  }, []);

  const downloadFile = (organisationId, onModalClose) => {
    organisationsService
      .downloadFile(organisationId)
      .then(response => {
        if (response.status === 200) {
          // TODO Возможно дописать функционал по загрузке файлов?
          responseHandler(true, 'Файл был успешно загружен', onModalClose, null);
        }
      })
      .catch(e => {
        setOrganisationsError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  };

  return {
    organisations,
    totalOrganisationsCount,
    allOrganisationsNames,

    changeOrganisation,
    removeOrganisation,
    addOrganisation,
    isOrganisationsLoading,
    organisationsError,
    downloadFile,
    uploadFile,
    setOrganisationsFilterType,
    organisationsFilterType,
  };
};
