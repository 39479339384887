export const isEmpty = arrayOrObject => {
  if (!arrayOrObject) {
    return true;
  }

  if (Array.isArray(arrayOrObject)) {
    return arrayOrObject.length === 0;
  }

  return Object.keys(arrayOrObject).length === 0;
};

export const lastFrom = array => {
  return array[array.length - 1];
};
