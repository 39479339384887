import { useEffect, useMemo, useState } from 'react';
import { playlistsService } from 'services';

export function usePlaylistsLoad() {
  const [playlistsForDropDown, setPlaylistsForDropDown] = useState([]);
  const [playlistsCount, setPlaylistsCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const playlistOptions = useMemo(() => {
    return [
      { name: 'Плейлист не выбран', value: null },
      ...playlistsForDropDown.map(playlist => ({
        name: playlist.name,
        value: playlist.id,
      })),
    ];
  }, [playlistsForDropDown]);

  useEffect(() => {
    setLoading(true);

    playlistsService
      .getPlaylists({ skip: 0, take: 10 })
      .then(response => {
        if (response.status === 200) {
          Array.isArray(response.data?.items) && setPlaylistsForDropDown(response.data.items);
          setPlaylistsCount(response.data.count);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handlePlaylistsLoad = () => {
    if (playlistsForDropDown.length < playlistsCount) {
      playlistsService
        .getPlaylists({ skip: playlistsForDropDown.length, take: 10 })
        .then(response => {
          if (response.status === 200) {
            if (Array.isArray(response.data?.items)) {
              setPlaylistsForDropDown(prev => [...prev, ...response.data.items]);
              setPlaylistsCount(response.data.count);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return {
    isLoading,
    setLoading,
    playlistsForDropDown,
    playlistsCount,
    playlistOptions,
    handlePlaylistsLoad,
  };
}
