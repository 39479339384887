import { getServiceMethods } from './helpers/getServiceMethods';
import { configRequest, createBaseApi } from './baseApi';

const baseApi = createBaseApi();

/**
 *
 * @param administratorId
 * @param isBlocked
 * @returns {*}
 */
const blockUnblock = (administratorId, isBlocked) => {
  return baseApi.post(`/administrators/${administratorId}/${isBlocked ? 'unblock' : 'block'}`, configRequest);
};

const changePassword = (administratorId, password) => {
  return baseApi.post(`/administrators/${administratorId}/password`, { password }, configRequest);
};

/**
 *
 * @type {{blockUnblock: (function(*, *): *), getAll: (function(*): *), patchById: (function(*, *): *), getById: (function(*): *), deleteById: (function(*): *), put: (function(*, *): *), createNew: (function(*): *)}}
 */
export const administratorsService = {
  ...getServiceMethods('administrators'),
  blockUnblock,
  changePassword,
};
