import { getServiceMethods } from './helpers/getServiceMethods';
import { configRequest, createBaseApi } from './baseApi';

const baseApi = createBaseApi();

const DEFAULT_PARAMS = {
  skip: 0,
  take: 10,
};

const getDevicesLogs = (deviceId, params = {}) => {
  return baseApi.get(`devices/${deviceId}/logs`, {
    ...configRequest,
    params: { ...DEFAULT_PARAMS, ...params },
  });
};

const getDevicesCameraPhoto = deviceId => {
  return baseApi.get(`devices/${deviceId}/camera/photo`, { ...configRequest, responseType: 'blob' });
};

const getDevicesCameraScreenshot = deviceId => {
  return baseApi.get(`devices/${deviceId}/screenshot`, { ...configRequest, responseType: 'blob' });
};

const getSensors = deviceId => {
  return baseApi.get(`devices/${deviceId}/sensors`, configRequest);
};

/**
 *
 * @param deviceId
 * @returns {*}
 */

const getSensorsList = deviceId => {
  return baseApi.get(`devices/${deviceId}/sensors`, configRequest);
};

const getSensorValues = (sensorID, params) => {
  return baseApi.get(`sensors/${sensorID}/values`, { params }, configRequest);
};

/**
 *
 * @type {{getDevicesLogs: (function(*, *=): *), getAll: (function(*): *), patchById: (function(*, *): *), getSensors: (function(*): *), getById: (function(*): *), deleteById: (function(*): *), getSensorValues: (function(*): *), getDevicesCameraScreenshot: (function(*): *), put: (function(*, *): *), createNew: (function(*): *), getSensorsList: (function(*): *), getDevicesCameraPhoto: (function(*): *)}}
 */
export const devicesService = {
  ...getServiceMethods('devices'),
  getDevicesLogs,
  getDevicesCameraPhoto,
  getDevicesCameraScreenshot,
  getSensors,
  getSensorsList,
  getSensorValues,
};
