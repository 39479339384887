import { useStateContext } from 'store';
import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { UserRoleEnum } from 'helpers/constants';
import { setLocalStorage } from 'helpers/localStorage';
import { RoleNames } from 'helpers/constants/logsConstants';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import { ScreenTemplatesAdd, ScreenTemplatesEdit } from 'containers/UI/Modal/Components/ScreenTemplates';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Editing } from 'icons/editing.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';

export const useScreenTemplates = () => {
  const {
    user,
    openPopup,
    screenTemplates,
    isScreenTemplatesLoading,
    totalScreenTemplatesCount,
    removeScreenTemplate,
  } = useStateContext();

  const isMaster = user.role === UserRoleEnum.MASTER;

  const handleCreate = useCallback(() => {
    openPopup(() => <ScreenTemplatesAdd isMaster={isMaster} />, {
      title: 'Создание шаблона рабочих столов',
    });
  }, []);

  const handleEditScreenTemplate = screenTemplate => {
    openPopup(() => <ScreenTemplatesEdit screenTemplate={screenTemplate} isMaster={isMaster} />, {
      title: 'Редактирование шаблона рабочих столов',
    });
  };

  const handleDeleteScreenTemplate = useCallback(screenTemplate => {
    openPopup(
      () => (
        <ModalDelete
          contentId={screenTemplate.id}
          title={`Вы уверены что хотите удалить шаблон ${screenTemplate.name}?`}
          isVisibleIcon={false}
          onDelete={removeScreenTemplate}
        />
      ),
      {
        title: 'Удаление шаблона рабочих столов',
      },
    );
  }, []);

  const handleClick = (id, timestamp) => {
    setLocalStorage('screenTemplates_timestamp', {
      [id]: timestamp,
    });
  };

  const header = [
    { key: 'name', title: 'Название' },
    { key: 'resolution', title: 'Разрешение' },
    { key: 'email', title: 'Email' },
    { key: 'role', title: 'Роль' },
    { key: 'nameOrganization', title: 'Название организации' },
    {
      key: 'actions',
      title: (
        <div key="screen-templates-head-button" className={tableStyles.rowButtons}>
          <Button key="screen-templates-create" className={tableStyles.headerButton} onClick={handleCreate}>
            Создать шаблон
          </Button>
        </div>
      ),
    },
  ];

  const body = screenTemplates?.map(screenTemplate => {
    const { id, name, width, height, owner, clientsGroups = [], timestamp } = screenTemplate || {};

    const nameOrganization =
      clientsGroups.length === 1 ? clientsGroups.map(el => el.name)[0] : clientsGroups.map(el => el.name).join(', \n');

    return {
      name,
      resolution: `${width}x${height}`,
      email: isMaster ? owner.email : null,
      role: isMaster ? RoleNames[owner.role] : null,
      nameOrganization: isMaster && clientsGroups.length > 0 ? nameOrganization : null,
      actions: (
        <div key="screen-templates-item-buttons" className={tableStyles.rowButtons}>
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Изменить'}
            onClick={() => handleEditScreenTemplate(screenTemplate)}
          />

          <NavLink to={'/screenTemplates/' + id} onClick={() => handleClick(id, timestamp)}>
            <Button variant={ButtonVariantEnum.ICON} iconData={{ icon: <Editing /> }} title={'Редактировать'} />
          </NavLink>

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить'}
            onClick={() => handleDeleteScreenTemplate(screenTemplate)}
          />
        </div>
      ),
    };
  });

  return {
    isScreenTemplatesLoading,
    totalScreenTemplatesCount,

    header,
    body,
  };
};
