import { configRequest, createBaseApi } from '../baseApi';
const baseApi = createBaseApi();

const prepareQuery = params => {
  const query = [];
  Object.keys(params).forEach(key => {
    if (params[key] === undefined) return;

    const value = Array.isArray(params[key]) ? JSON.stringify(params[key]) : params[key];
    query.push([key, encodeURIComponent(value)].join('='));
  });
  return query.join('&');
};

/**
 * getServiceMethods creates and returns all types of methods for interaction with api for each service.
 * For example for admins or operators service
 * @param restUrl is an actual rest url for chosen service. Value can be for example 'operators'
 * @param restUrl is an actual rest url for chosen service. Value can be for example 'operators'
 * @returns {{put: (function(*, *): *), getAll: (function(*): *), createNew: (function(*): *), patchById: (function(*, *): *), getById: (function(*): *), deleteById: (function(*): *)}}
 */
export const getServiceMethods = restUrl => {
  const getAll = (params = {}) => {
    return baseApi.get(`/${restUrl}?${prepareQuery(params)}`, configRequest);
  };

  const getById = id => {
    return baseApi.get(`/${restUrl}/${id}`, configRequest);
  };
  const createNew = data => {
    return baseApi.post(`/${restUrl}`, data, configRequest);
  };

  const deleteById = id => {
    return baseApi.delete(`/${restUrl}/${id}`, configRequest);
  };

  const put = (id, data) => {
    return baseApi.put(`/${restUrl}/${id}`, data, configRequest);
  };

  const patchById = (id, data) => {
    return baseApi.patch(`/${restUrl}/${id}`, data, configRequest);
  };

  return {
    getAll,
    getById,
    createNew,
    deleteById,
    put,
    patchById,
  };
};
