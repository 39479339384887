import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ButtonGroup, ButtonGroupVariantEnum } from 'containers/UI/ButtonGroup';
import { ReactComponent as Arrow } from 'icons/arrows/arrow_left_small.svg';

import { ScheduleMain } from './Components';
import { TIME_INTERVALS } from './Constants/extra';
import { ScheduleContext } from './Context/ScheduleContext';
import { useSchedule } from './Hooks/useSchedule';
import styles from './Schedule.module.css';

const Schedule = () => {
  const { schedulesId } = useParams();
  const navigate = useNavigate();

  const { saveSchedulePlaylists, handleTimeChange, ...contextData } = useSchedule(schedulesId);

  return (
    <ScheduleContext.Provider value={contextData}>
      <form className={styles.wrapper} onSubmit={saveSchedulePlaylists}>
        <header className={styles.header}>
          <Button variant={ButtonVariantEnum.TEXT} onClick={() => navigate(-1)}>
            <Arrow />
            <span className={styles.headerBackButton_text}>Назад</span>
          </Button>
          <div className={styles.headerActionWrapper}>
            <Button type="submit" className={styles.headerAction}>
              Сохранить
            </Button>
            <Button
              className={styles.headerAction}
              variant={ButtonVariantEnum.SOLID_ACCENT}
              onClick={() => navigate('/schedules')}
            >
              Отмена
            </Button>
          </div>
        </header>

        <div className={styles.scheduleWrapper}>
          <ButtonGroup
            className={styles.scheduleTimeSettings}
            itemClassName={styles.scheduleTimeSettingsItem}
            variant={ButtonGroupVariantEnum.CONTAINED}
            selected={contextData.scale.toString()}
            items={TIME_INTERVALS}
            onChange={handleTimeChange}
          />
          <ScheduleMain />
        </div>
      </form>
    </ScheduleContext.Provider>
  );
};

export default Schedule;
