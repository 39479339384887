import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ReactComponent as Close } from 'icons/close.svg';

import { DAYS_WEEK } from '../../Constants/DaysWeek';
import { useScheduleTable } from './useScheduleTable';
import { ScheduleTimeBar } from '../ScheduleTimeBar/ScheduleTimeBar';
import { ScheduleTablePlaylist } from '../ScheduleTablePlaylist/ScheduleTablePlaylist';
import { useStateContext } from 'store';

import styles from './ScheduleTable.module.css';

export const ScheduleTable = () => {
  const {
    tableRef,
    resize,
    setResize,
    tempResize,
    stopResize,
    handlePlaylistAdd,
    mouseMoveHandler,
    mouseLeaveHandler,
  } = useScheduleTable();

  const { schedulePlaylists } = useStateContext();

  return (
    <div
      ref={tableRef}
      className={styles.scheduleTable}
      style={{ cursor: resize.enabled ? 'w-resize' : '' }}
      onMouseUp={stopResize}
      onMouseMove={mouseMoveHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <ScheduleTimeBar />
      <div className={styles.scheduleTableCol}>
        {DAYS_WEEK.map(day => (
          <div key={day} className={styles.scheduleTableCell} data-day={day}>
            {schedulePlaylists
              .filter(el => el.weekday === day)
              .sort((a, b) => a.end - b.end)
              .map((playlist, i, row) => (
                <ScheduleTablePlaylist
                  key={playlist.id}
                  playlist={playlist}
                  weekday={day}
                  isLast={i + 1 === row.length}
                  resizeInfo={tempResize}
                  setResize={setResize}
                  stopResize={stopResize}
                />
              ))}
            <Button
              className={styles.scheduleTableCell_Button}
              variant={ButtonVariantEnum.ICON}
              iconData={{
                icon: <Close />,
                inlineStyles: { width: '12px', height: '12px', transform: 'rotate(45deg)' },
              }}
              title={'Добавить плейлист'}
              onClick={() => handlePlaylistAdd(day)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
