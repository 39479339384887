import { useCallback, useEffect, useState } from 'react';
import { useApi } from 'services/hooks/useApi';
import { devicesService } from 'services/devices.service';
import { responseHandler } from '../helpers/responseHandler';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { URLs } from 'helpers/constants/URLs';
import { organisationsService } from 'services/organisations.service';
import { isEmpty } from 'helpers/lodash';
import { getPreparedDataForGraphic } from '../helpers/datesTimesConverter';

const MAX_ORGANISATIONS_COUNT_PER_REQUEST = 100;
const MAX_DEVICES_COUNT_PER_REQUEST = 100;

export function useDevicesState() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [devices, setDevices, isDevicesLoading, error, setError, totalDevicesCount, setTotalDevicesCount] = useApi(() =>
    devicesService.getAll(getQueryParams(+page - 1) || {}),
  );

  const [isDevicesAlreadyLoaded, setIsDevicesAlreadyLoaded] = useState(false);
  const [allOrganisationsListInDevices, setAllOrganisationsListInDevices] = useState([]);
  const [allOrganisationsCount, setAllOrganisationsCount] = useState(0);
  const [dynamicSkipValueForOrganisations, setDynamicSkipValueForOrganisations] = useState(0);
  const [dynamicTakeValueForOrganisations, setDynamicTakeValueForOrganisations] = useState(
    MAX_ORGANISATIONS_COUNT_PER_REQUEST,
  );
  const [dynamicSkipValueForDevices, setDynamicSkipValueForDevices] = useState(0);
  const [dynamicTakeValueForDevices, setDynamicTakeValueForDevices] = useState(MAX_DEVICES_COUNT_PER_REQUEST);
  const [organisationFilterTypeForDevices, setOrganisationFilterTypeForDevices] = useState(null);
  const [sensorsList, setSensorsList] = useState([]);
  const [sensorValues, setSensorValues] = useState([]);
  const [allDevicesForMap, setAllDevicesForMap] = useState([]);

  const [isMapOpened, setIsMapOpened] = useState(false);

  const isMinimumOneMarkerWithPosition = () => {
    let result = false;

    devices.forEach(marker => {
      if (!isEmpty(marker.position)) {
        result = true;
      }
    });

    return result;
  };

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.devices) {
        return (() => {
          devicesService
            .getAll({
              ...getQueryParams(+page - 1 || {}),
              ...(organisationFilterTypeForDevices ? { clientId: organisationFilterTypeForDevices } : {}),
            })
            .then(response => {
              if (response.status === 200) {
                setTotalDevicesCount(response?.data?.count);
                Array.isArray(response?.data?.items) && setDevices(response.data.items);

                setIsDevicesAlreadyLoaded(true);
              }
            })
            .catch(setError);
        })();
      }
    }, [pathname, page, totalDevicesCount, organisationFilterTypeForDevices]);
  };
  paginate();

  const addDevice = useCallback((deviceData, onModalClose) => {
    return (() => {
      devicesService
        .createNew(deviceData)
        .then(response => {
          if (response?.status === 200) {
            setTotalDevicesCount(prev => prev + 1);
            responseHandler(true, 'Устройство было успешно добавлено', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Устройство не было добавлено', onModalClose);
        });
    })();
  }, []);

  const updateDevice = useCallback((deviceData, onModalClose) => {
    return (() => {
      devicesService
        .put(deviceData.id, deviceData)
        .then(response => {
          if (response?.status === 204) {
            setDevices(prev => [
              ...prev.map(el => {
                if (el.id === deviceData.id) return { ...el, ...deviceData };
                return el;
              }),
            ]);
            responseHandler(true, 'Устройство было успешно изменено', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Устройство не было изменено', onModalClose);
        });
    })();
  }, []);

  const setDeviceOnline = useCallback(
    ({ id, value }) =>
      setDevices(prev => [
        ...prev.map(el => {
          if (el.id === id) return { ...el, state: value };
          return el;
        }),
      ]),
    [],
  );

  const setCurrentPlaylist = useCallback(
    ({ id, value }) =>
      setDevices(prev => [
        ...prev.map(el => {
          if (el.id === id) return { ...el, content: value };
          return el;
        }),
      ]),
    [],
  );

  const removeDevice = useCallback((id, onModalClose) => {
    return (() => {
      devicesService
        .deleteById(id)
        .then(response => {
          if (response?.status === 204) {
            setTotalDevicesCount(prev => prev - 1);
            setDevices(prev => prev.filter(el => el.id !== id));
            responseHandler(true, 'Устройство было успешно удалено', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Устройство не было удалено', onModalClose);
        });
    })();
  }, []);

  const getAllOrganisations = function () {
    useEffect(() => {
      if (
        !isDevicesAlreadyLoaded ||
        (allOrganisationsListInDevices.length > 0 && allOrganisationsListInDevices.length === allOrganisationsCount)
      ) {
        return;
      }

      const queryParams = {
        skip: dynamicSkipValueForOrganisations,
        take: dynamicTakeValueForOrganisations,
      };

      organisationsService.getAll(queryParams).then(response => {
        if (response.status === 200) {
          setAllOrganisationsCount(response?.data?.count);
          setDynamicSkipValueForOrganisations(prevState => prevState + MAX_ORGANISATIONS_COUNT_PER_REQUEST);
          setDynamicTakeValueForOrganisations(allOrganisationsCount - dynamicSkipValueForOrganisations);
          Array.isArray(response?.data.items) &&
            response.data.items.length > 0 &&
            setAllOrganisationsListInDevices(prevState => [...prevState, ...response.data.items]);
        }
      });
    }, [isDevicesAlreadyLoaded, allOrganisationsListInDevices]);
  };
  getAllOrganisations();

  const getSensorsList = useCallback(deviceId => {
    devicesService
      .getSensorsList(deviceId)
      .then(response => {
        if (response.status === 200) {
          Array.isArray(response?.data?.items) && setSensorsList(response.data?.items);
        }
      })
      .catch(setError);
  }, []);

  const getSensorValues = useCallback((sensorID, datesParams) => {
    devicesService
      .getSensorValues(sensorID, datesParams)
      .then(response => {
        if (response.status === 200) {
          if (Array.isArray(response?.data?.items)) {
            setSensorValues(getPreparedDataForGraphic(response.data.items));
          }
        }
      })
      .catch(e => responseHandler(false, '', () => {}, e.response.data.code));
  }, []);

  const getAllDevicesForMap = () => {
    useEffect(() => {
      if (!isMapOpened || (!isEmpty(allDevicesForMap) && allDevicesForMap.length === totalDevicesCount)) {
        return;
      }

      const queryParams = {
        skip: dynamicSkipValueForDevices,
        take: dynamicTakeValueForDevices,
      };

      devicesService.getAll(queryParams).then(response => {
        if (response.status === 200) {
          setTotalDevicesCount(response?.data?.count);
          setDynamicSkipValueForDevices(prevState => prevState + MAX_DEVICES_COUNT_PER_REQUEST);
          setDynamicTakeValueForDevices(totalDevicesCount - dynamicSkipValueForDevices);
          Array.isArray(response?.data?.items) &&
            !isEmpty(response.data.items) &&
            setAllDevicesForMap(prevState => [...prevState, ...response.data.items]);
        }
      });
    }, [isMapOpened, allDevicesForMap, totalDevicesCount]);
  };
  getAllDevicesForMap();

  return {
    devices,
    setDevices,
    isDevicesLoading,
    totalDevicesCount,
    error,

    addDevice,
    updateDevice,
    removeDevice,
    setDeviceOnline,
    setCurrentPlaylist,
    setIsDevicesAlreadyLoaded,
    allOrganisationsListInDevices,
    setOrganisationFilterTypeForDevices,
    organisationFilterTypeForDevices,

    getSensorsList,
    sensorsList,
    getSensorValues,
    sensorValues,
    isMinimumOneMarkerWithPosition,
    allDevicesForMap,
    setIsMapOpened,
  };
}
