import { TOTAL_SECONDS } from '../Constants/extra';

const getFreeSpace = playlists => {
  return playlists.reduce((acc, curr) => acc - (curr.end - curr.start), TOTAL_SECONDS);
};

const getFreeSpaceInRange = (start, playlists) => {
  const rangeHeight = TOTAL_SECONDS - start;
  const playlistsInRange = getPlaylistsInRange(start, TOTAL_SECONDS, playlists);

  return playlistsInRange.reduce((acc, playlist) => {
    if (playlist.start <= start && playlist.end >= TOTAL_SECONDS) {
      return acc - (playlist.end - playlist.start);
    }

    if (playlist.start < start) {
      return acc - (start - playlist.start);
    }

    return acc - (TOTAL_SECONDS - playlist.start);
  }, rangeHeight);
};

const findPlaylistBetweenStart = (start, playlists, id = null) => {
  if (id) {
    return playlists.find(plst => {
      if (id === plst.id) return;

      return plst.start <= start && plst.end >= start;
    });
  }

  return playlists.find(plst => plst.start <= start && plst.end >= start);
};

const getPlaylistsUnder = (start, playlists, id = null) => {
  if (id) {
    const playlistsUnder = playlists.filter(playlist => {
      if (playlist.id === id) return;
      return playlist.start >= start;
    });

    return playlistsUnder.sort((a, b) => a.start - b.start);
  }
  const playlistsUnder = playlists.filter(playlist => playlist.start >= start);

  return playlistsUnder.sort((a, b) => a.start - b.start);
};

const getPlaylistsAbove = (start, playlists, id = null) => {
  if (id) {
    const playlistsAbove = playlists.filter(playlist => {
      if (playlist.id === id) return;
      return playlist.start <= start;
    });

    return playlistsAbove.sort((a, b) => b.start - a.start);
  }
  const playlistsAbove = playlists.filter(playlist => playlist.start <= start);

  return playlistsAbove.sort((a, b) => b.start - a.start);
};

const moveUnderPlaylists = (movingPlaylists, playlists) => {
  [...movingPlaylists].reverse().forEach(playlist => {
    const height = playlist.end - playlist.start;
    const playlistsUnder = getPlaylistsUnder(playlist.start + 1, playlists, playlist.id);
    playlist.end = playlistsUnder.length ? playlistsUnder[0].start - 1 : TOTAL_SECONDS;
    playlist.start = playlist.end - height;
  });
};

const getPlaylistsInRange = (start, end, playlists, id) => {
  const playlistsInRange = playlists.filter(playlist => {
    if (id === playlist.id) return;

    return start <= playlist.end && end >= playlist.start;
  });

  return playlistsInRange.sort((a, b) => b.start - a.start);
};

const convertFromStringIntoSeconds = stringDate => {
  const splittedDate = stringDate.split(':');
  return +splittedDate[0] * 60 * 60 + +splittedDate[1] * 60 + +splittedDate[2];
};

export {
  getFreeSpace,
  getFreeSpaceInRange,
  getPlaylistsAbove,
  getPlaylistsUnder,
  getPlaylistsInRange,
  moveUnderPlaylists,
  findPlaylistBetweenStart,
  convertFromStringIntoSeconds,
};
