import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useApi } from 'services/hooks/useApi';
import { arbitersService } from 'services/arbiters.service';
import { responseHandler } from '../helpers/responseHandler';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { URLs } from 'helpers/constants/URLs';

export function useArbitersState() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [arbiters, setArbiters, isArbitersLoading, error, setError, totalArbitersCount, setTotalArbitersCount] = useApi(
    () => arbitersService.getAll(getQueryParams(+page - 1) || {}),
  );

  const [fullArbiterData, setFullArbiterData] = useState({});

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.arbiters) {
        return (() => {
          arbitersService
            .getAll(getQueryParams(+page - 1) || {})
            .then(({ status, data }) => status === 200 && Array.isArray(data?.items) && setArbiters(data.items))
            .catch(setError);
        })();
      }
    }, [pathname, page, totalArbitersCount]);
  };
  paginate();

  const addArbiter = useCallback((newArbiter, onModalClose) => {
    return (() => {
      arbitersService
        .createNew(newArbiter)
        .then(({ status }) => {
          if (status === 200) {
            setTotalArbitersCount(prevState => prevState + 1);
            responseHandler(true, 'Судья был успешно создан', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Судья не был создан', onModalClose);
        });
    })();
  }, []);

  const removeArbiter = useCallback((arbiterId, onModalClose) => {
    return (() => {
      arbitersService
        .deleteById(arbiterId)
        .then(({ status }) => {
          if (status === 204) {
            setArbiters(prev => prev.filter(el => el.id !== arbiterId));
            setTotalArbitersCount(prevState => prevState - 1);
            responseHandler(true, 'Судья был успешно удалён', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Судья не был удалён', onModalClose);
        });
    })();
  }, []);

  const updateArbiter = useCallback((arbiterNewData, onModalClose) => {
    return (() => {
      arbitersService
        .put(arbiterNewData.id, arbiterNewData)
        .then(({ status }) => {
          if (status === 204) {
            setArbiters(prev => [
              ...prev.map(el => {
                if (el.id === arbiterNewData.id) return { ...el, ...arbiterNewData };
                return el;
              }),
            ]);
            responseHandler(true, 'Судья был успешно изменён', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Судья не был изменён', onModalClose);
        });
    })();
  }, []);

  const changeArbiterPassword = useCallback((arbiterNewData, onModalClose) => {
    return (() => {
      arbitersService
        .changePassword(arbiterNewData.id, arbiterNewData.password)
        .then(({ status }) => {
          if (status === 204) {
            setArbiters(prev => [
              ...prev.map(el => {
                if (el.id === arbiterNewData.id) return { ...el, ...arbiterNewData };
                return el;
              }),
            ]);
            responseHandler(true, 'Пароль был успешно изменён', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Пароль не был изменён', onModalClose);
        });
    })();
  }, []);

  const getFullArbiterDataById = useCallback(arbiterId => {
    return (() => {
      arbitersService
        .getById(arbiterId)
        .then(({ status, data }) => status === 200 && setFullArbiterData(data))
        .catch(setError);
    })();
  }, []);

  return {
    arbiters,
    setArbiters,

    addArbiter,
    updateArbiter,
    removeArbiter,
    totalArbitersCount,
    changeArbiterPassword,

    isArbitersLoading,
    error,
    fullArbiterData,
    setFullArbiterData,
    getFullArbiterDataById,
  };
}
