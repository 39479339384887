import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { InputParseErrors } from 'containers/UI/Input';
import { Select, SelectVariantEnum } from 'containers/UI/Select';

import styles from '../Desktops.module.css';

export const DesktopFormFile = ({ fieldKey, label, fileType, options }) => {
  const { indexSelect, selectWidgetIndex, control, files } = useFormContext();
  const [list, setList] = useState([]);

  useEffect(() => {
    const filterFiles = files.filter(f => f.type === fileType) || [];
    setList(filterFiles.map(f => ({ ...f, value: f.id })));
  }, []);

  return (
    <Controller
      control={control}
      name={`screens.${indexSelect}.widgets.${selectWidgetIndex}.${fieldKey}`}
      rules={options}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Select
          wrapperClassName={styles['params-field']}
          variant={SelectVariantEnum.FLAT}
          label={label}
          options={list}
          selected={value}
          onChange={onChange}
          error={InputParseErrors(error)}
        />
      )}
    />
  );
};

DesktopFormFile.defaultProps = {
  fieldKey: '',
  label: '',
  fileType: '',
  options: {},
};

DesktopFormFile.propTypes = {
  fieldKey: PropTypes.string,
  label: PropTypes.string,
  fileType: PropTypes.string,
  options: PropTypes.object,
};
