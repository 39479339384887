import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { authService } from 'services';
import { Input, InputActionTypeEnum, InputParseErrors, InputTypeEnum } from 'containers/UI/Input';
import { Button } from 'containers/UI/Button';
import { responseHandler } from 'store/helpers/responseHandler';
import { ReactComponent as Logo } from 'icons/logo.svg';

import styles from './Login.module.css';
import { UserRoleEnum } from 'helpers/constants';

const Login = () => {
  const navigate = useNavigate();
  const { changeUser, closePopup } = useStateContext();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  // TODO: добавить данные по id для получения sportEventsAvailability
  const signIn = form => {
    authService
      .signIn(form)
      .then(({ status, data = {} }) => {
        if (status === 200) {
          const { accessToken, refreshToken } = data;
          const { /*id*/ role } = jwt_decode(accessToken);
          if (role === UserRoleEnum.ARBITER) {
            setError('username', { type: 'pattern', message: 'Нет доступа' });
            return;
          }
          const user = { username: form.username, accessToken, refreshToken, role /*id*/ };

          sessionStorage.setItem('user', JSON.stringify(user));
          changeUser(user);
          navigate('/');
          responseHandler(true, 'Вход был успешно выполнен', closePopup, null);
        }
      })
      .catch(e => responseHandler(false, '', closePopup, e.response.data.code));
  };

  const goToRegisterPage = () => {
    navigate('/register');
  };

  return (
    <div className={styles['wrapper']}>
      <form className={styles['form']} onSubmit={handleSubmit(signIn)}>
        <header>
          <Logo />
        </header>
        <span>Авторизация</span>

        <div className={styles['form-fields']}>
          <Input
            type={InputTypeEnum.EMAIL}
            label={'Email'}
            placeholder={'Email'}
            error={InputParseErrors(errors.username)}
            {...register('username', { required: true })}
          />

          <Input
            type={InputTypeEnum.PASSWORD}
            label={'Пароль'}
            placeholder={'Пароль'}
            actions={[InputActionTypeEnum.EYE]}
            error={InputParseErrors(errors.password)}
            {...register('password', { required: true })}
          />
        </div>

        <div className={styles.buttons}>
          <Button type="submit" disabled={!isValid}>
            Войти
          </Button>

          <Button className={styles.register_button} onClick={goToRegisterPage}>
            Зарегистрироваться
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
