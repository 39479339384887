import { getServiceMethods } from './helpers/getServiceMethods';
import { configRequest as config, createBaseApi } from './baseApi';

const baseApi = createBaseApi();

const uploadFile = file => {
  const data = {
    resource: file.resource,
  };

  const configRequest = {
    ...config,
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return baseApi.post(`/files/${file.id}/resources`, data, configRequest);
};

const downloadFileData = resourceId => {
  return baseApi.get(`/files/resources/${resourceId}`, { responseType: 'blob' });
};

/**
 *
 * @type {{getAll: (function(*): *), patchById: (function(*, *): *), getById: (function(*): *), deleteById: (function(*): *), put: (function(*, *): *), createNew: (function(*): *)}}
 */
export const filesService = { ...getServiceMethods('files'), uploadFile, downloadFileData };
