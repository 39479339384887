import PropTypes from 'prop-types';
import { Loader } from 'containers/UI/Loader';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ReactComponent as RefreshIcon } from 'icons/refresh.svg';

import styles from './DevicesSensorCard.module.css';
import classnames from 'classnames';

export const DevicesSensorCard = props => {
  const { sensors, isLoading, onRefresh } = props;

  const isEmpty = sensors.length === 0;

  return (
    <div className={classnames(styles.wrapper)}>
      {isLoading && (
        <div className={styles.loader_wrapper}>
          <Loader size={50} />
        </div>
      )}

      <div className={styles.params}>
        {isEmpty && 'Нет датчиков'}
        {!isEmpty &&
          sensors.map(({ sensor, id }) => (
            <div key={id} className={styles.params_item}>
              <label>{sensor}:</label>
              <span>{id}</span>
            </div>
          ))}
      </div>

      <Button
        variant={ButtonVariantEnum.SOLID_SECONDARY}
        disabled={isLoading}
        iconData={{ icon: <RefreshIcon /> }}
        onClick={onRefresh}
      >
        Обновить
      </Button>
    </div>
  );
};

DevicesSensorCard.propTypes = {
  sensors: PropTypes.array,
  isLoading: PropTypes.bool,
  onRefresh: PropTypes.func,
};
