import { devicesService } from 'services';
import { LogsEvents, RoleNames } from 'helpers/constants/logsConstants';
import { useEffect, useMemo, useState } from 'react';

import styles from '../ModalDevicesLogs.module.css';

export const useModalDevicesLogs = deviceId => {
  const [logs, setLogs] = useState([]);
  const [logsCount, setLogsCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    devicesService.getDevicesLogs(deviceId).then(response => {
      if (response.status === 200) {
        setLogsCount(response.data.count);
        const responseLogs = response.data.items.map(x => {
          x.logMessage = LogsEvents[x.type];
          return x;
        });
        setLogs(responseLogs);
        setLoading(false);
      }
    });
  }, []);

  const heading = [
    { key: 'createdAt', title: 'Дата создания' },
    { key: 'receivedAt', title: 'Дата получения' },
    { key: 'type', title: 'Тип события' },
    { key: 'content', title: 'Смена контента' },
    { key: 'externalEquipment', title: 'Внешнее оборудование' },
    { key: 'file ', title: 'Файл' },
  ];

  const tableBodyLogsList = useMemo(
    () =>
      logs?.map(
        ({ createdAt, receivedAt, type, content = null, user = null, externalEquipment = null, file = null }) => {
          return {
            createdAt: new Date(receivedAt).toLocaleString(),
            receivedAt: new Date(createdAt).toLocaleString(),
            type: LogsEvents[type],
            content: content && (
              <>
                Пользователь, <br />
                сменивший контент:
                <div className={styles.logsItemMainInfo}>
                  <div className={styles.logItemEllipsis}>{RoleNames[user.role]}</div>
                  <div className={styles.logItemEllipsis}>{user.email}</div>
                  <div className={styles.logItemEllipsis} title={content.id}>
                    {content.name}
                  </div>
                </div>
              </>
            ),
            externalEquipment: externalEquipment && (
              <>
                <div className={styles.logItemEllipsis}>{externalEquipment.name}</div>
                {externalEquipment.text && <div className={styles.logItemEllipsis}>{externalEquipment.text}</div>}
              </>
            ),
            file: file && (
              <>
                <div className={styles.logItemEllipsis}>Id: {file.id}</div>
                <div className={styles.logItemEllipsis}>Name: {file.name}</div>
              </>
            ),
          };
        },
      ),
    [logs],
  );

  return {
    heading,
    tableBodyLogsList,
    loading,
    logsCount,
  };
};
