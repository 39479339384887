import { useScheduleTimeBar } from './useScheduleTimeBar';
import styles from './ScheduleTimeBar.module.css';

export const ScheduleTimeBar = () => {
  const { timeBarRef, tableWidth, timesArray, translateToValidFormat } = useScheduleTimeBar();

  return (
    <div ref={timeBarRef} className={styles.scheduleTimeBar} style={{ width: tableWidth ? `${tableWidth}px` : '100%' }}>
      <ul className={styles.scheduleTimeBarTimes}>
        {timesArray.map(time => (
          <li key={time} className={styles.scheduleTimeBarTime}>
            <span className={styles.scheduleTimeBarValue}>{translateToValidFormat(time)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
