import Header from 'containers/App/Header/Header';
import Navigate from 'containers/App/Navigate/Navigate';
import Body from 'containers/App/Body/Body';

import styles from './MainLayout.module.css';

const MainLayout = () => {
  return (
    <div className={styles['app']}>
      <Header />
      <Navigate />
      <Body />
      <div className={styles['footer']} />
    </div>
  );
};

export default MainLayout;
