import { NavLink } from 'react-router-dom';
import { AccessLevels, NAV_MENU } from 'helpers/constants';
import { LoginStatusHelper } from 'services/hooks/loginStatusHelper';

import styles from './Navigate.module.css';

const Navigate = () => {
  const getNavigateLinksForRoles = () => {
    const { getParsedUser } = LoginStatusHelper.getInstance();
    const keysForNavMenu = AccessLevels[getParsedUser().role];
    const navigationLinksForRole = NAV_MENU.filter(link => keysForNavMenu?.includes(link.key));

    return navigationLinksForRole.map(link => {
      return (
        <NavLink
          key={link.key}
          to={'/' + link.key}
          className={({ isActive }) => (isActive ? styles['item__active'] : styles['item'])}
        >
          {link.title}
        </NavLink>
      );
    });
  };

  return <nav className={styles['wrapper']}>{getNavigateLinksForRoles()}</nav>;
};

export default Navigate;
