import { useEffect, useMemo, useState } from 'react';
import { devicesService } from 'services';

export function useDevicesLoad() {
  const [devices, setDevices] = useState([]);
  const [devicesCount, setDevicesCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const devicesOptions = useMemo(() => devices.map(device => ({ name: device.name, value: device.id })), [devices]);

  useEffect(() => {
    setLoading(true);

    devicesService
      .getAll({ skip: 0, take: 10 })
      .then(response => {
        if (response.status === 200) {
          Array.isArray(response.data?.items) && setDevices(response.data.items);
          setDevicesCount(response.data.count);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleDevicesLoad = () => {
    if (devices.length < devicesCount) {
      devicesService
        .getAll({ skip: devices.length, take: 10 })
        .then(response => {
          if (response.status === 200) {
            if (Array.isArray(response.data?.items)) {
              setDevices(prev => [...prev, ...response.data.items]);
              setDevicesCount(response.data.count);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return {
    isLoading,
    setLoading,
    devices,
    devicesCount,
    devicesOptions,
    handleDevicesLoad,
  };
}
