import Hex from 'crypto-js/enc-hex';
import md5 from 'crypto-js/md5';
import { Buffer } from 'buffer';

export const getFileChecksum = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = e => {
      const buffer = Buffer.from(e.target.result, 'binary');
      const checksum = md5(buffer).toString(Hex);

      resolve(checksum);
    };

    reader.onerror = reject;
  });
