export const getShortDayName = day => {
  switch (day) {
    case 'monday':
      return 'Пн';
    case 'tuesday':
      return 'Вт';
    case 'wednesday':
      return 'Ср';
    case 'thursday':
      return 'Чт';
    case 'friday':
      return 'Пт';
    case 'saturday':
      return 'Сб';
    case 'sunday':
      return 'Вс';
  }
};
