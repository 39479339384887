import PropTypes from 'prop-types';
import { Table } from 'containers/UI/Table';
import { ButtonGroup } from 'containers/UI/ButtonGroup';
import { useDevices } from './Hooks/useDevices';
import { Select, SelectVariantEnum } from '../UI/Select';
import { ReactComponent as FilterIcon } from 'icons/filter.svg';
import { ReactComponent as ArrowIcon } from 'icons/arrows/arrow_down.svg';
import { LoginStatusHelper } from 'services/hooks/loginStatusHelper';

import styles from './Devices.module.css';

const Devices = () => {
  const {
    heading,
    body,
    isDevicesLoading,
    totalDevicesCount,
    organisationFilterTypeForDevices,
    handleFilterGroup,
    organisationsOptions,
  } = useDevices();
  const { getParsedUser } = LoginStatusHelper.getInstance();

  return (
    <>
      {getParsedUser().role === 'master' && (
        <div className={styles.filterWrapper}>
          <Select
            key="file-filter"
            wrapperClassName={styles.headerFilterWrapper}
            className={styles.headerFilter}
            variant={SelectVariantEnum.NOT_BORDERED}
            icon={<FilterIcon />}
            customArrowIcon={<ArrowIcon />}
            options={organisationsOptions()}
            selected={organisationFilterTypeForDevices}
            onChange={handleFilterGroup}
          />
        </div>
      )}

      <ButtonGroup
        className={styles['devicesWrapperTabs']}
        isNavigate
        items={[
          { url: '/devices', text: 'Список' },
          { url: '/devices/map', text: 'Карта' },
        ]}
      />

      <Table
        heading={heading}
        body={body}
        isLoading={isDevicesLoading}
        totalEntitiesCount={totalDevicesCount}
        idKey="id"
        emptyText={'Вы еще не добавили ни одного устройства'}
      />
    </>
  );
};

Devices.displayName = 'Devices';
Devices.defaultProps = { devices: [] };
Devices.propTypes = { devices: PropTypes.array, isLoading: PropTypes.bool };

export default Devices;
