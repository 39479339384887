import { NavLink } from 'react-router-dom';
import { useStateContext } from 'store';
import { ReactComponent as Exit } from 'icons/exit.svg';
import { ReactComponent as Logo } from 'icons/logo.svg';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import styles from './Header.module.css';
import { LoginStatusHelper } from 'services/hooks/loginStatusHelper';

const Header = () => {
  const { user, changeUser } = useStateContext();
  const { changeUser: changeLoggedUser } = LoginStatusHelper.getInstance();

  const signOut = () => {
    sessionStorage.removeItem('user');
    changeUser({ username: '', accessToken: '', refreshToken: '' });
    changeLoggedUser(null);
  };

  return (
    <header className={styles['wrapper']}>
      <NavLink to="/" className={styles['logo']}>
        <Logo />
      </NavLink>
      <div className={styles['profile']}>
        <span className={styles['profile-link']}>{user.username}</span>
        <Button
          className={styles['profile-exit']}
          variant={ButtonVariantEnum.TEXT}
          iconData={{ icon: <Exit /> }}
          onClick={signOut}
        />
      </div>
    </header>
  );
};

export default Header;
