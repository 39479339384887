import { Button } from 'containers/UI/Button';
import classnames from 'classnames';
import Desktops from '../../Desktops';
import PropTypes from 'prop-types';

import styles from '../styles/DesktopsModals.module.css';

export const TemplateItem = ({
  id,
  name,
  width,
  height,
  chosenTemplateId,
  setChosenTemplateId,
  previewTemplateMode,
}) => {
  const onTemplateChoose = () => {
    if (id === chosenTemplateId) {
      setChosenTemplateId(null);
      return;
    }

    setChosenTemplateId(id);
  };

  return (
    <div key={id} className={styles[`preview-template-${previewTemplateMode}-mode-wrapper`]}>
      <div className={styles[`empty-blocker-${previewTemplateMode}-mode`]}>
        <Button
          className={classnames({
            [styles['chosen-template']]: id === chosenTemplateId,
            [styles['not-chosen-template']]: id !== chosenTemplateId,
          })}
          onClick={onTemplateChoose}
        >
          {id === chosenTemplateId ? 'Отменить' : 'Выбрать'}
        </Button>
      </div>

      <Desktops previewTemplateId={id} previewTemplateMode={previewTemplateMode}></Desktops>

      <div className={styles['template-name-and-resolution']}>
        <div className={styles['template-name']}>{name}</div>
        <div className={styles['template-resolution']}>{`${width}x${height}`}</div>
      </div>
    </div>
  );
};

TemplateItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  chosenTemplateId: PropTypes.number,
  setChosenTemplateId: PropTypes.func,
  previewTemplateMode: PropTypes.string,
};
