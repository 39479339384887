import { useContext, useEffect } from 'react';
import { showErrorNotify } from 'helpers/showNotify';
import { ScheduleContext } from '../../Context/ScheduleContext';

export const useScheduleMain = () => {
  const { loading, timeWidth, scale, start, undo, setLoading, updateStartValue, saveSchedulePlaylists } =
    useContext(ScheduleContext);

  const handleSave = async () => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      await saveSchedulePlaylists();
    } catch (error) {
      showErrorNotify('Ошибка при сохранении расписания');
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = e => {
    const { deltaY } = e;
    const deltaStart = (deltaY * scale) / timeWidth;
    updateStartValue(start - deltaStart);
  };

  const keyUpHandler = e => {
    const { keyCode, ctrlKey } = e;
    if (ctrlKey && keyCode === 83) {
      e.preventDefault();
      e.stopPropagation();
      return handleSave();
    }

    if (ctrlKey && keyCode === 90) {
      e.preventDefault();
      e.stopPropagation();
      return undo();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyUpHandler);
    return () => {
      window.removeEventListener('keydown', keyUpHandler);
    };
  }, []);

  return {
    handleScroll,
  };
};
