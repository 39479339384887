import PropTypes from 'prop-types';
import { useStateContext } from 'store';
import { Table } from 'containers/UI/Table';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from './ModalDevicesLogs.module.css';
import { useModalDevicesLogs } from './Hooks/useModalDevicesLogs';

export const ModalDevicesLogs = ({ deviceId }) => {
  const { closePopup } = useStateContext();

  const { heading, tableBodyLogsList, loading, logsCount } = useModalDevicesLogs(deviceId);

  return (
    <div className={styles.wrapper}>
      <Table
        className={styles.modalDevicesLogs_table}
        heading={heading}
        body={tableBodyLogsList}
        isLoading={loading}
        totalEntitiesCount={logsCount}
        idKey="createdAt"
        emptyText={'Нет логов'}
      />

      <div className={styles.wrapper_actions}>
        <Button variant={ButtonVariantEnum.SOLID_SECONDARY} disabled={loading} onClick={closePopup}>
          Закрыть
        </Button>
      </div>
    </div>
  );
};

ModalDevicesLogs.propTypes = { deviceId: PropTypes.string };
