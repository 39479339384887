import PropTypes from 'prop-types';
import classnames from 'classnames';
import { devicesService } from 'services';
import { useStateContext } from 'store';
import { useCallback, useEffect, useState } from 'react';
import { DevicesMonitorCard } from 'containers/Devices/Components';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from './ModalDevicesSnapshot.module.css';

export const ModalDevicesSnapshot = ({ deviceData }) => {
  const { id, config } = deviceData;
  const { closePopup } = useStateContext();

  const [loading, setLoading] = useState(true);
  const [screenshot, setScreenshot] = useState({});
  const [cameraShot, setCameraShot] = useState({});

  const fetchScreenshot = useCallback(async () => {
    setScreenshot({ loading: true });

    devicesService
      .getDevicesCameraScreenshot(id)
      .then(response => {
        if (response.status === 200) {
          setScreenshot(prev => ({ ...prev, image: URL.createObjectURL(response.data) }));
        }
      })
      .catch(error => {
        setScreenshot(prev => ({ ...prev, error: error?.response?.data?.message ?? error.message }));
      })
      .finally(() => {
        setScreenshot(prev => ({ ...prev, loading: false, created: new Date() }));
        setLoading(false);
      });
  }, [id]);

  const fetchCameraShot = useCallback(async () => {
    setCameraShot({ loading: true });

    devicesService
      .getDevicesCameraPhoto(id)
      .then(response => {
        if (response.status === 200) {
          setCameraShot(prev => ({ ...prev, image: URL.createObjectURL(response.data) }));
        }
      })
      .catch(error => {
        setCameraShot(prev => ({ ...prev, error: error?.response?.data?.message ?? error.message }));
      })
      .finally(() => {
        setCameraShot(prev => ({ ...prev, loading: false, created: new Date() }));
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    setLoading(true);

    fetchScreenshot();

    if (config.hasCamera) {
      fetchCameraShot();
    }
  }, [fetchCameraShot, fetchScreenshot]);

  return (
    <div className={styles.wrapper}>
      <div className={classnames(styles.wrapper_content, { [styles.__hasCamera]: config.hasCamera })}>
        <DevicesMonitorCard
          name={'Скриншот'}
          onRefresh={fetchScreenshot}
          {...screenshot}
          loading={loading || screenshot.loading}
        />
        {config.hasCamera && (
          <DevicesMonitorCard
            name={'Камера'}
            onRefresh={fetchCameraShot}
            {...cameraShot}
            loading={loading || cameraShot.loading}
          />
        )}
      </div>

      <div className={styles.wrapper_actions}>
        <Button variant={ButtonVariantEnum.SOLID_SECONDARY} disabled={loading} onClick={closePopup}>
          Закрыть
        </Button>
      </div>
    </div>
  );
};

ModalDevicesSnapshot.propTypes = {
  deviceData: PropTypes.object,
};
