import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { Checkbox } from 'containers/UI/Checkbox';
import Map from 'containers/Map/Map';
import { Input, InputTypeEnum, InputParseErrors } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ReactComponent as PinIcon } from 'icons/map/pin.svg';
import { createPortal } from 'react-dom';
import { useState } from 'react';

import styles from './ModalDevicesEdit.module.css';

export const ModalDevicesEdit = ({ deviceData, isMaster }) => {
  const {
    id,
    administrator,
    name,
    position: { latitude = 0, longitude = 0 },
    screenWidth,
    screenHeight,
    sensorsPollingFrequency,
    comment,
    config,
  } = deviceData;

  const { closePopup, updateDevice } = useStateContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowChangePosition, setIsShowChangePosition] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name,
      comment,
      screenWidth,
      screenHeight,
      sensorsPollingFrequency,
      location: `${latitude}, ${longitude}`,
      hasCamera: config.hasCamera,
      externalEquipment: !!config.externalEquipment,
    },
  });

  const save = async form => {
    const [lat, lng] = form.location.split(',');

    const formData = {
      id,
      administratorId: administrator.id,
      name: form.name,
      comment: form.comment,
      position: {
        latitude: +lat,
        longitude: +lng,
      },
      config: {
        hasCamera: !!form.hasCamera,
        externalEquipment: !!form.externalEquipment,
        hasExternalSensors: false,
      },
      sensorsPollingFrequency: isMaster ? +form.sensorsPollingFrequency : sensorsPollingFrequency,
      screenWidth: isMaster ? +form.screenWidth : screenWidth,
      screenHeight: isMaster ? +form.screenHeight : screenHeight,
    };

    setIsLoading(true);
    await updateDevice(formData, closePopup);
    setIsLoading(false);
  };

  const handleOpenChangeLocation = () => {
    setIsShowChangePosition(true);
  };

  const handleSaveMap = value => {
    setValue('location', value);
    setIsShowChangePosition(false);
  };

  const handleCloseMap = () => setIsShowChangePosition(false);

  return (
    <>
      <div className={styles.wrapper}>
        <form className={styles.form} onSubmit={handleSubmit(save)}>
          <div className={styles.form_fields}>
            <Input
              label={'Название устройства'}
              placeholder={'Название'}
              error={InputParseErrors(errors.name, { minLength: 5, maxLength: 30 })}
              {...register('name', { required: true, minLength: 5, maxLength: 30, setValueAs: v => v.trim() })}
            />

            <Input
              label={'Комментарий'}
              placeholder={'Комментарий'}
              error={InputParseErrors(errors.comment, { minLength: 5, maxLength: 300 })}
              {...register('comment', { minLength: 5, maxLength: 300, setValueAs: v => v.trim() })}
            />

            {isMaster && (
              <div className={styles.form_sizes}>
                <Input
                  label={'Ширина'}
                  type={InputTypeEnum.NUMBER}
                  placeholder={'Ширина'}
                  error={InputParseErrors(errors.screenWidth, { min: 100, max: 10000 })}
                  {...register('screenWidth', { required: true, min: 100, max: 10000 })}
                />

                <Input
                  label={'Высота'}
                  type={InputTypeEnum.NUMBER}
                  placeholder={'Высота'}
                  error={InputParseErrors(errors.screenHeight, { min: 100, max: 10000 })}
                  {...register('screenHeight', { required: true, min: 100, max: 10000 })}
                />

                <Input
                  label={'Частота опроса датчиков'}
                  type={InputTypeEnum.NUMBER}
                  placeholder={'Частота опроса датчиков'}
                  error={InputParseErrors(errors.sensorsPollingFrequency, { min: 5, max: 60 })}
                  {...register('sensorsPollingFrequency', { required: true, min: 5, max: 60 })}
                />
              </div>
            )}

            <Input
              label={'Местоположение'}
              placeholder={'Местоположение'}
              error={InputParseErrors(errors.location)}
              {...register('location', { required: true })}
            >
              <PinIcon
                className={styles.inputIconLocation}
                title="Указать на карте"
                onClick={handleOpenChangeLocation}
              />
            </Input>

            <Checkbox control={control} name="hasCamera" label={'Наличие камеры'} disabled={isLoading} />
            <Checkbox control={control} name="externalEquipment" label={'Внешние датчики (TCP)'} disabled={isLoading} />
          </div>

          <div className={styles.form_actions}>
            <Button className={styles.form_button} type="submit" disabled={!isValid}>
              Сохранить
            </Button>
            <Button
              className={styles.form_button}
              variant={ButtonVariantEnum.SOLID_ACCENT}
              disabled={isLoading}
              onClick={closePopup}
            >
              Закрыть
            </Button>
          </div>
        </form>
      </div>

      {isShowChangePosition &&
        createPortal(
          <Map
            location={getValues().location}
            isOnline={deviceData.state === 'online'}
            onSave={handleSaveMap}
            onClose={handleCloseMap}
          />,
          document.getElementById('map-root'),
        )}
    </>
  );
};

ModalDevicesEdit.defaultProps = {
  deviceData: {},
  isMaster: false,
};

ModalDevicesEdit.propTypes = {
  deviceData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    screenWidth: PropTypes.number,
    screenHeight: PropTypes.number,
    comment: PropTypes.string,
    state: PropTypes.oneOf(['online', 'offline']),
    position: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      // address: PropTypes.string,
    }),
    config: PropTypes.shape({
      hasCamera: PropTypes.bool,
      externalEquipment: PropTypes.bool,
      hasExternalSensors: PropTypes.bool,
    }),
    administrator: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
    }),
    content: PropTypes.shape({
      type: PropTypes.string,
      playlistId: PropTypes.string,
    }),
    sensorsPollingFrequency: PropTypes.number,
  }),
  isMaster: PropTypes.bool,
};
