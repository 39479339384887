import { useEffect } from 'react';

export function useKeydown(params = {}) {
  const handleKeyDown = e => {
    if (params.disabled) {
      return;
    }

    if (typeof params.onKeydown === 'function') {
      params.onKeydown(e);
    }

    const keyAlias = e?.key?.toLowerCase();
    if (keyAlias && typeof params.key?.[keyAlias] === 'function') {
      return params.key[keyAlias](e);
    }

    return true;
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
