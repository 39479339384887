import { useContext, useRef, useState } from 'react';
import { ModalSchedulePlaylistAdd } from 'containers/UI/Modal/Components/SchedulePlaylist';
import { useStateContext } from 'store';
import { ScheduleContext } from 'containers/Schedule/Context/ScheduleContext';

const defaultResize = {
  enabled: false,
  left: null,
  top: null,
  direction: null,
  playlist: null,
  weekday: null,
};

export const useScheduleTable = () => {
  const { openPopup, schedulePlaylists } = useStateContext();
  const { start, createPlaylist, tableWidth, secondsOnScreen, resizePlaylist } = useContext(ScheduleContext);

  const tableRef = useRef(null);
  const interval = useRef(0);

  const [resize, setResize] = useState(defaultResize);
  const [tempResize, setTempResize] = useState(null);

  const stopResize = () => setResize(defaultResize);

  const handlePlaylistAdd = weekday => {
    openPopup(
      () => (
        <ModalSchedulePlaylistAdd
          weekday={weekday}
          handleCreate={createPlaylist}
          existingPlaylists={schedulePlaylists.filter(playlist => playlist.weekday === weekday)}
        />
      ),
      {
        title: 'Добавление плейлиста в расписание',
      },
    );
  };

  const setPosition = clientX => {
    let _start = resize.playlist.start;
    let end = resize.playlist.end;

    if (tableRef.current) {
      const { x: offsetLeft } = tableRef.current.getBoundingClientRect();
      const leftBefore = start + Math.round(((resize.left - offsetLeft) * secondsOnScreen) / tableWidth);

      if (resize.direction === 'left') {
        const deltaX = resize.left - clientX;
        const seconds = Math.round((deltaX * secondsOnScreen) / tableWidth);
        _start = leftBefore - seconds;

        setTempResize(deltaX);
      }

      if (resize.direction === 'right') {
        const deltaX = resize.left - clientX;
        const seconds = Math.round((deltaX * secondsOnScreen) / tableWidth);
        end = leftBefore - seconds;

        setTempResize(deltaX);
      }

      resizePlaylist({
        weekday: resize.weekday,
        playlistId: resize.playlist.id,
        start: _start,
        end,
      });
    }
  };

  const mouseMoveHandler = e => {
    if (!resize.enabled) {
      return;
    }
    const { clientX } = e;
    setPosition(clientX);
  };

  const mouseLeaveHandler = () => {
    clearInterval(interval.current);
    stopResize();
  };

  return {
    tableRef,
    resize,
    tempResize,
    setResize,
    schedulePlaylists,
    mouseMoveHandler,
    mouseLeaveHandler,
    setPosition,
    stopResize,
    handlePlaylistAdd,
  };
};
