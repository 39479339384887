import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AddressSuggestions } from 'react-dadata';
import { useState } from 'react';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from './DevicesMapSearch.module.css';

// todo: move .env
const API_KEY = '701542ca70804310675cf36399982621f6de623b';

export const DevicesMapSearch = props => {
  const { mapRef, className, withControls, onClose, onSave } = props;

  const [suggestion, setSuggestion] = useState();

  const handleChange = value => {
    const { geo_lat, geo_lon } = value.data;

    setSuggestion(value);

    if (geo_lat && geo_lon && mapRef) {
      mapRef.current.panTo([geo_lat, geo_lon]);
    }
  };

  return (
    <div className={classnames(styles.deviceMapSearchBar, { [className]: !!className })}>
      <div className={styles.deviceMapSearchBar_wrapper}>
        <AddressSuggestions
          token={API_KEY}
          value={suggestion}
          containerClassName={styles.deviceMapSearchInputWrapper}
          inputProps={{
            className: styles.deviceMapSearchInput,
            placeholder: 'Поиск по карте',
          }}
          suggestionClassName={styles.deviceMapSearchSuggestionItem}
          suggestionsClassName={styles.deviceMapSearchSuggestions}
          onChange={handleChange}
        />
        {withControls && (
          <div className={styles.deviceMapSearchButtons}>
            <Button variant={ButtonVariantEnum.SOLID_SECONDARY} onClick={onClose}>
              Отменить
            </Button>
            <Button onClick={onSave}>Сохранить</Button>
          </div>
        )}
      </div>
    </div>
  );
};

DevicesMapSearch.defaultProps = {
  className: '',
  mapRef: null,
  withControls: false,
};

DevicesMapSearch.propTypes = {
  className: PropTypes.string,
  withControls: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  mapRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};
