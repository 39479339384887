import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useStateContext } from 'store';
import { SelectForm } from 'containers/UI/Select';
import { Input, InputParseErrors } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import { ReactComponent as PlusIcon } from 'icons/plus.svg';

import styles from '../ModalPresentations.module.css';
import { useEffect } from 'react';

export const ModalPresentationsCopy = ({ presentation, handleCreateGroup, groupsOptions }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', defaultValues: { ...presentation, groupId: presentation?.group?.id } });

  const { closePopup, copyPresentationWithScreens, getScreens, setPresentationsScreens } = useStateContext();

  const componentDidMount = function () {
    useEffect(() => {
      getScreens(presentation.id);
    }, []);
  };
  componentDidMount();

  const save = form => {
    copyPresentationWithScreens(form, closePopup);
  };

  const componentWillMount = function () {
    useEffect(() => {
      return () => {
        setPresentationsScreens([]);
      };
    }, []);
  };
  componentWillMount();

  return (
    <form className={styles.form} onSubmit={handleSubmit(save)}>
      <Input
        label="Название"
        placeholder="Название"
        className={styles.input}
        error={InputParseErrors(errors.name, { minLength: 4, maxLength: 30 })}
        {...register('name', { required: true, minLength: 4, maxLength: 30, setValueAs: v => v.trim() })}
      />

      <Input
        label="Комментарий"
        placeholder="Комментарий"
        className={styles.input}
        error={InputParseErrors(errors.comment, { minLength: 4, maxLength: 100 })}
        {...register('comment', { minLength: 4, maxLength: 100, setValueAs: v => v.trim() })}
      />

      <div className={styles.form_select_action}>
        <SelectForm
          name="groupId"
          control={control}
          placeholder={'Без группы'}
          label={'Группа'}
          options={groupsOptions}
          rules={{ required: false }}
        />

        <Button
          className={styles.form_create_group_btn}
          variant={ButtonVariantEnum.ICON}
          iconData={{ icon: <PlusIcon /> }}
          title={'Создание группы'}
          onClick={handleCreateGroup}
        />
      </div>

      <div className={styles.actions}>
        <Button type="submit" disabled={!isValid}>
          Создать
        </Button>
        <Button variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
          Закрыть
        </Button>
      </div>
    </form>
  );
};

ModalPresentationsCopy.defaultProps = {
  presentation: null,
  groupsOptions: [],
};

ModalPresentationsCopy.propTypes = {
  presentation: PropTypes.object,
  groupsOptions: PropTypes.array,
  handleCreateGroup: PropTypes.func,
};
