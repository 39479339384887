import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Input, InputParseErrors, InputTypeEnum } from 'containers/UI/Input';
import { useStateContext } from 'store';
import { SelectForm } from 'containers/UI/Select';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from '../ScreenTemplates.module.css';

export const ScreenTemplatesEdit = ({ screenTemplate, isMaster }) => {
  const {
    closePopup,
    screenTemplateData,
    isModalInAdminsOpen,
    setIsModalInAdminsOpen,
    changeScreenTemplate,
    getScreenTemplateById,
    setScreenTemplateData,
    getAllClientsGroups,
    allClientsGroups,
  } = useStateContext();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues:
      Object.keys(screenTemplateData).length > 0
        ? {
            name: screenTemplateData.name,
            width: screenTemplateData.width,
            height: screenTemplateData.height,
            clientsGroups: screenTemplateData.clientsGroups
              ? screenTemplateData.clientsGroups.map(group => group.id)
              : [],
          }
        : {
            name: screenTemplate.name,
            width: screenTemplate.width,
            height: screenTemplate.height,
            clientsGroups: screenTemplate?.clientsGroups ? screenTemplate.clientsGroups.map(group => group.id) : [],
          },
  });

  useEffect(() => {
    getAllClientsGroups();
    getScreenTemplateById(screenTemplate.id);

    // triggerOrganisationsLoading
    if (!isModalInAdminsOpen && isMaster) {
      setIsModalInAdminsOpen(true);
    }

    return () => {
      setScreenTemplateData({});
    };
  }, []);

  const save = form => {
    changeScreenTemplate({ id: screenTemplate.id, ...form }, closePopup);
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_fields}>
          <Input
            label="Название"
            placeholder="Название"
            error={InputParseErrors(errors.name, { minLength: 4, maxLength: 30 })}
            {...register('name', { required: true, minLength: 4, maxLength: 30, setValueAs: v => v.trim() })}
          />

          <div className={styles.form_row_fields}>
            <Input
              label={'Ширина'}
              type={InputTypeEnum.NUMBER}
              placeholder={'Ширина'}
              error={InputParseErrors(errors.screenWidth, { min: 100, max: 10000 })}
              {...register('width', { required: true, min: 100, max: 10000, valueAsNumber: true })}
            />

            <Input
              label={'Высота'}
              type={InputTypeEnum.NUMBER}
              placeholder={'Высота'}
              error={InputParseErrors(errors.screenHeight, { min: 100, max: 10000 })}
              {...register('height', { required: true, min: 100, max: 10000, valueAsNumber: true })}
            />
          </div>

          {isMaster && (
            <SelectForm
              name="clientsGroups"
              control={control}
              placeholder={'Группы организаций'}
              label={'Группы организаций, имеющие доступ к шаблону'}
              options={allClientsGroups}
              searchable
              isMulti
            />
          )}

          <div className={styles.form_actions}>
            <Button className={styles.form_button} type="submit" disabled={!isValid}>
              Создать
            </Button>
            <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
              Отмена
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

ScreenTemplatesEdit.defaultProps = { screenTemplate: null, isMaster: false };
ScreenTemplatesEdit.propTypes = { screenTemplate: PropTypes.object, isMaster: PropTypes.bool };
