import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import classnames from 'classnames';
import styles from './Checkbox.module.css';

export const Checkbox = props => {
  const { control, name, label, disabled = false, className = '' } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <div className={classnames(styles.checkbox, className, { [styles.checkbox__disabled]: disabled })}>
            <input
              id={name}
              ref={ref}
              value={value}
              checked={value}
              type="checkbox"
              className={classnames(styles.checkbox_checkmark)}
              onChange={onChange}
              onBlur={onBlur}
            />

            <label htmlFor={name} className={styles.checkbox_label}>
              {label}
            </label>
          </div>
        );
      }}
    />
  );
};

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
