import { useApi } from 'services/hooks/useApi';
import { schedulesService } from 'services';
import { useCallback, useEffect, useState } from 'react';
import { responseHandler } from '../helpers/responseHandler';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { useLocation, useSearchParams } from 'react-router-dom';
import { URLs } from 'helpers/constants/URLs';

export const useSchedulesState = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [
    schedules,
    setSchedules,
    isSchedulesLoading,
    schedulesError,
    setSchedulesError,
    totalSchedulesCount,
    setTotalSchedulesCount,
  ] = useApi(() => schedulesService.getSchedules(getQueryParams(+page - 1) || {}));
  const [filterType, setFilterType] = useState(null);

  const [schedulePlaylists, setSchedulePlaylists] = useState([]);
  const [copiedSchedulePlaylists, setCopiedSchedulePlaylists] = useState([]);

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.schedules) {
        return (() => {
          schedulesService
            .getSchedules(getQueryParams(+page - 1) || {})
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response.data?.items) && setSchedules(response.data.items);
              }
            })
            .catch(setSchedulesError);
        })();
      }
    }, [pathname, page, totalSchedulesCount]);
  };
  paginate();

  const addSchedule = useCallback((newSchedule, onModalClose) => {
    return (() => {
      schedulesService
        .addSchedule(newSchedule)
        .then(result => {
          if (result.status === 200) {
            setTotalSchedulesCount(prevState => prevState + 1);
            responseHandler(true, 'Расписание было успешно создано', onModalClose);
          }
        })
        .catch(e => {
          setSchedulesError(e);
          responseHandler(false, '', onModalClose, e.response.data.code);
        });
    })();
  }, []);

  const copyScheduleWithPlaylists = (newSchedule, onModalClose) => {
    return (() => {
      schedulesService
        .addSchedule(newSchedule)
        .then(response => {
          if (response.status === 200) {
            const newScheduleId = response?.data?.id;
            schedulesService
              .updateSchedulePlaylists(newScheduleId, copiedSchedulePlaylists)
              .then(response => {
                if (response.status === 204) {
                  setTotalSchedulesCount(prevState => prevState + 1);
                  responseHandler(true, 'Расписание было успешно скопировано', onModalClose);
                }
              })
              .catch(error => {
                setSchedulesError(error);
                responseHandler(false, '', onModalClose, error.response.data.code);
              });
          }
        })
        .catch(e => {
          setSchedulesError(e);
          responseHandler(false, '', onModalClose, e.response.data.code);
        });
    })();
  };

  const getCopiedSchedulePlaylists = useCallback(scheduleId => {
    schedulesService
      .getSchedulePlaylists(scheduleId)
      .then(response => {
        if (response.status === 200) {
          const playlistsDataForPayload = response?.data.map(playlist => {
            return {
              playlistId: playlist.id,
              weekday: playlist.weekday,
              timeFrom: playlist.timeFrom,
              timeTo: playlist.timeTo,
            };
          });
          Array.isArray(response?.data) && setCopiedSchedulePlaylists(playlistsDataForPayload);
        }
      })
      .catch(setSchedulesError);
  }, []);

  const editSchedule = useCallback((scheduleId, newScheduleData, onModalClose) => {
    return (() => {
      schedulesService
        .updateSchedule(scheduleId, newScheduleData)
        .then(result => {
          if (result.status === 204) {
            setSchedules(prev => [
              ...prev.map(el => {
                if (el.id === newScheduleData.id) return { ...el, ...newScheduleData };
                return el;
              }),
            ]);
            responseHandler(true, 'Расписание было успешно изменено', onModalClose);
          }
        })
        .catch(e => {
          setSchedulesError(e);
          responseHandler(false, 'Расписание не было изменено', onModalClose);
        });
    })();
  }, []);

  const removeSchedule = useCallback((scheduleId, onModalClose) => {
    return (() => {
      schedulesService
        .deleteSchedule(scheduleId)
        .then(result => {
          if (result.status === 204) {
            setSchedules(prev => prev.filter(el => el.id !== scheduleId));
            responseHandler(true, 'Расписание было успешно удалено', onModalClose);
          }
        })
        .catch(e => {
          setSchedulesError(e);
          responseHandler(false, 'Расписание не было удалено', onModalClose);
        });
    })();
  }, []);

  return {
    schedules,
    setSchedules,
    isSchedulesLoading,
    filterType,
    setFilterType,
    addSchedule,
    schedulesError,
    editSchedule,
    removeSchedule,
    totalSchedulesCount,
    schedulePlaylists,
    setSchedulePlaylists,
    getCopiedSchedulePlaylists,
    setCopiedSchedulePlaylists,
    copyScheduleWithPlaylists,
  };
};
