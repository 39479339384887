import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { SelectForm } from 'containers/UI/Select';
import { useEffect, useState } from 'react';
import { Checkbox } from 'containers/UI/Checkbox';
import { TimePickerForm } from 'containers/UI/TimePicker';
import { hmsToSeconds } from 'helpers/time/hmsToSeconds';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { useDevicesLoad } from 'containers/Hooks/devices/useDevicesLoad';
import { isEmpty } from 'helpers/lodash';

import styles from './ModalTerminalUpdates.module.css';

export const ModalTerminalUpdate = ({ terminalUpdate = {} }) => {
  const [isValidTime, setIsValidTime] = useState(true);
  const {
    closePopup,
    addTerminalUpdate,
    terminalReleasesForTerminalUpdates,
    getTerminalReleasesForTerminalUpdates,
    updateTerminalUpdateStatus,
  } = useStateContext();
  const addMode = isEmpty(terminalUpdate);
  const editMode = !addMode;

  const { handleSubmit, control, getValues, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues: terminalUpdate,
  });

  const componentDidMount = function () {
    useEffect(() => {
      getTerminalReleasesForTerminalUpdates();
    }, []);
  };
  componentDidMount();

  const updateFormValues = function () {
    useEffect(() => {
      reset({ status: terminalUpdate.state === 'active', start: '', end: '' });
    }, [terminalReleasesForTerminalUpdates]);
  };
  updateFormValues();

  const { devicesOptions, handleDevicesLoad } = useDevicesLoad();

  const validateTimeFields = function () {
    useEffect(() => {
      if (watch('end')) {
        if (watch('start')) {
          const { start, end } = getValues();
          const startSec = hmsToSeconds(start);
          const endSec = hmsToSeconds(end);

          setIsValidTime(startSec < endSec && startSec !== endSec);
        }
      }
    }, [watch('start'), watch('end')]);
  };
  validateTimeFields();

  const isFormValid = () => {
    if (addMode) {
      return isValidTime && watch('releaseId') && watch('devices') && watch('end');
    }

    if (editMode) {
      return true;
    }
  };

  const save = form => {
    if (addMode) {
      const newTerminalUpdate = {
        releaseId: form.releaseId[0],
        timeFrom: form.start,
        timeTo: form.end,
        devices: form.devices,
        state: form.status ? 'active' : 'inactive',
      };

      addTerminalUpdate(newTerminalUpdate, closePopup);
    }

    if (editMode) {
      updateTerminalUpdateStatus(terminalUpdate.id, form.status, closePopup);
    }
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_fields}>
          {addMode && (
            <>
              <SelectForm
                name="releaseId"
                control={control}
                placeholder={'Выберите релиз'}
                label={'Доступные релизы'}
                options={terminalReleasesForTerminalUpdates}
                searchable
                isMulti
                required
              />

              <TimePickerForm
                name="start"
                control={control}
                valid={isValidTime}
                label={'Время начала обновления'}
                required
              />

              <TimePickerForm
                name="end"
                control={control}
                valid={isValidTime}
                label={'Время завершения обновления'}
                required
              />

              <SelectForm
                name="devices"
                control={control}
                placeholder={'Выберите устройство'}
                label={'Доступные устройства'}
                options={devicesOptions}
                searchable
                isMulti
                required
                isInfinityLoad
                onLoad={handleDevicesLoad}
              />
            </>
          )}

          <Checkbox key="status" label="Статус (active/inactive)" name="status" control={control} />
        </div>

        <div className={styles.form_actions}>
          <Button className={styles.form_button} type="submit" disabled={!isFormValid()}>
            {addMode ? 'Создать' : 'Изменить'}
          </Button>
          <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
            Закрыть
          </Button>
        </div>
      </form>
    </div>
  );
};

ModalTerminalUpdate.propTypes = {
  terminalUpdate: PropTypes.object,
};
