import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { useRef, useState } from 'react';
import { Input, InputTypeEnum, InputParseErrors, InputActionTypeEnum } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { getFileChecksum } from 'helpers/fileHelper';
import { PPTX_EXT } from 'helpers/constants/fileTypes';
import { DefaultFileTypes, FileTypes } from 'helpers/constants/fileTypes';

import styles from './ModalFiles.module.css';

export const ModalFiles = ({ file }) => {
  const loading = false; // todo fix

  const { closePopup, files, addFile, updateFile, fileLoadingMessage } = useStateContext();

  const typeFileRef = useRef(file ? file.type : null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', defaultValues: file });

  const [fileData, setFileData] = useState(file || null);
  const [isFileExist, setIsFileExist] = useState(false);

  const checkFileExists = checksum => {
    setIsFileExist(!!files.find(file => file.checksum === checksum));
  };

  const save = async form => {
    let formData = {
      name: form.name,
      comment: form.comment,
      type: typeFileRef.current,
    };

    if (fileData.size) {
      formData = {
        ...formData,
        resource: fileData,
      };
    }

    file ? updateFile({ id: file.id, ...formData }, closePopup) : addFile(formData, closePopup);
  };

  const setFile = async uploadFile => {
    if (!(uploadFile instanceof File)) return;

    const checksum = await getFileChecksum(uploadFile);
    checkFileExists(checksum);

    if (uploadFile.type.includes('image')) {
      typeFileRef.current = FileTypes.Image;
    } else if (uploadFile.type.includes('mpeg') || uploadFile.type.includes('audio')) {
      typeFileRef.current = FileTypes.Audio;
    } else if (uploadFile.type.includes('video') && !uploadFile.type.includes('mpeg')) {
      typeFileRef.current = FileTypes.Video;
    } else if (uploadFile.type.includes('pdf')) {
      typeFileRef.current = FileTypes.Presentation;
    } else if (uploadFile.type.includes(PPTX_EXT)) {
      typeFileRef.current = FileTypes.PPTX;
    }

    if (typeFileRef.current) {
      setFileData(uploadFile);
      const currentName = getValues().name;
      setValue('name', currentName ? currentName : uploadFile.name);
    }
  };

  // TODO условия дизейбла: нет имени или файл не выбран. есть имя, файл выбран, коммент не пустой и его длина не в диапазоне 4-100
  const disabledAddButton = file
    ? file.name === watch('name') && file.comment === watch('comment') && file.size === fileData.size
    : !isValid || isFileExist || !fileData;

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_fields}>
          {fileLoadingMessage && <div className={styles.file_loading_message}>{fileLoadingMessage}</div>}

          <Input
            label={'Название'}
            placeholder={'Название'}
            error={InputParseErrors(errors.name, { minLength: 4, maxLength: 30 })}
            {...register('name', { minLength: 4, maxLength: 30, setValueAs: v => v.trim() })}
          />

          <Input
            label="Комментарий"
            placeholder="Комментарий"
            error={InputParseErrors(errors.comment, { minLength: 4, maxLength: 100 })}
            {...register('comment', { minLength: 4, maxLength: 100, setValueAs: v => v.trim() })}
          />

          <Input
            id={'fileInput'}
            label="Файл"
            placeholder="Файл"
            hidden
            type={InputTypeEnum.FILE}
            error={isFileExist && InputParseErrors({ type: 'fileExist' })}
            actions={[InputActionTypeEnum.LOAD_FILE]}
            fileTypes={file ? [file.type] : DefaultFileTypes}
            onChange={setFile}
          />
        </div>

        <div className={styles.form_actions}>
          <Button className={styles.form_button} type="submit" disabled={disabledAddButton}>
            {!file ? 'Создать' : 'Сохранить'}
          </Button>
          <Button
            className={styles.form_button}
            variant={ButtonVariantEnum.SOLID_ACCENT}
            disabled={loading}
            onClick={closePopup}
          >
            Отмена
          </Button>
        </div>
      </form>
    </div>
  );
};

ModalFiles.defaultProps = { file: null };
ModalFiles.propTypes = { file: PropTypes.object };
