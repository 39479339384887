import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ButtonSizeEnum } from './Constants/ButtonSizeEnum';
import { ButtonVariantEnum } from './Constants/ButtonVariantEnum';

import styles from './Button.module.css';

export const Button = props => {
  const {
    size = ButtonSizeEnum.MD,
    iconData = {},
    isActive = false,
    loading,
    disabled,
    variant = ButtonVariantEnum.PRIMARY,
    children,
    onClick,
    type = 'button',
    className = '',
    ...attrs
  } = props;

  return (
    <button
      className={classnames(styles.button, {
        [styles[`button__${variant}`]]: true,
        [styles[`button__${size}`]]: true,
        [styles.__active]: isActive,
        [className]: !!className,
      })}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      {...attrs}
    >
      {iconData.icon && (
        <span className={styles.icon} style={iconData.inlineStyles ? { ...iconData.inlineStyles } : {}}>
          {iconData.icon}
        </span>
      )}
      {children}
    </button>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  size: PropTypes.oneOf([ButtonSizeEnum.SM, ButtonSizeEnum.MD, ButtonSizeEnum.XL]),
  variant: PropTypes.oneOf([
    ButtonVariantEnum.ICON,
    ButtonVariantEnum.TEXT,
    ButtonVariantEnum.FLAT,
    ButtonVariantEnum.PRIMARY,
    ButtonVariantEnum.SECONDARY,
    ButtonVariantEnum.SOLID_ACCENT,
    ButtonVariantEnum.SOLID_BOTTOM,
    ButtonVariantEnum.SOLID_SECONDARY,
  ]),
  iconData: PropTypes.shape({
    icon: PropTypes.element,
    inlineStyles: PropTypes.object,
  }),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  type: PropTypes.string,
  className: PropTypes.string,
};
