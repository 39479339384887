import { useForm } from 'react-hook-form';
import { Input, InputActionTypeEnum, InputParseErrors, InputTypeEnum } from 'containers/UI/Input';
import { useStateContext } from 'store';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { DefaultFileTypes } from 'helpers/constants/fileTypes';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DatePicker } from 'containers/UI/DatePicker';
import { SelectForm } from 'containers/UI/Select';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';

import styles from '../ModalOrganisations.module.css';

export const ModalOrganisationsEdit = ({ organisation, organisationsGroupOptions, handleCreateOrganisationsGroup }) => {
  const { closePopup, changeOrganisation } = useStateContext();
  const [file, setFile] = useState(null);
  const [licenseExpirationDate, setLicenseExpirationDate] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
  } = useForm({ mode: 'onChange', defaultValues: organisation });

  useEffect(() => {
    setLicenseExpirationDate(new Date(organisation.licenseExpirationDate));
    setValue('groupId', organisation?.group?.id);
  }, []);

  const save = form => {
    const formData = {
      name: form.name,
      licenseNumber: form.licenseNumber,
      comment: form.comment,
      maxTotalFilesSize: form.maxTotalFilesSize,
      licenseExpirationDate: licenseExpirationDate.toISOString(),
      id: organisation.id,
      groupId: form.groupId,
    };

    changeOrganisation(formData, file, closePopup);
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_fields}>
          <Input
            label={'Имя'}
            placeholder={'Имя'}
            type={InputTypeEnum.TEXT}
            error={InputParseErrors(errors.name)}
            {...register('name', { required: true, minLength: 5, maxLength: 100 })}
          />

          <Input
            label={'Номер лицензии'}
            placeholder={'Номер лицензии'}
            type={InputTypeEnum.TEXT}
            error={InputParseErrors(errors.licenseNumber)}
            {...register('licenseNumber', { required: true })}
          />

          <Input
            label={'Комментарий'}
            placeholder={'Комментарий'}
            type={InputTypeEnum.TEXT}
            error={InputParseErrors(errors.comment, { minLength: 5, maxLength: 300 })}
            {...register('comment')}
          />

          <Input
            label={'Максимальный объем файлов'}
            placeholder={'Максимальный объем файлов'}
            type={InputTypeEnum.NUMBER}
            {...register('maxTotalFilesSize', { valueAsNumber: true })}
          />

          <DatePicker
            label={'Дата истечения лицензи'}
            selected={licenseExpirationDate}
            labelClassName={styles['date-label']}
            onChange={date => setLicenseExpirationDate(date)}
            dateFormat="yyyy-mm-dd hh:mm:ss"
            classNameWrapper={styles['datePicker']}
          />

          <Input
            id={'fileInput'}
            label="Файл"
            placeholder="Файл"
            hidden
            type={InputTypeEnum.FILE}
            actions={[InputActionTypeEnum.LOAD_FILE]}
            fileTypes={DefaultFileTypes}
            onChange={setFile}
          />

          <div className={styles['form-select-action']}>
            <SelectForm
              name="groupId"
              control={control}
              placeholder={'Без группы'}
              label={'Группа'}
              options={organisationsGroupOptions}
              rules={{ required: false }}
            />

            <Button
              className={styles['form-create-group-btn']}
              variant={ButtonVariantEnum.ICON}
              iconData={{ icon: <PlusIcon /> }}
              title={'Создание группы'}
              onClick={handleCreateOrganisationsGroup}
            />
          </div>

          <div className={styles.form_actions}>
            <Button className={styles.form_button} type="submit" disabled={!isValid}>
              Создать
            </Button>
            <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
              Отмена
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

ModalOrganisationsEdit.defaultProps = { file: null, organisation: null };
ModalOrganisationsEdit.propTypes = {
  organisation: PropTypes.object,
  organisationsGroupOptions: PropTypes.array,
  handleCreateOrganisationsGroup: PropTypes.func,
};
