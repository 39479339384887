import { getServiceMethods } from './helpers/getServiceMethods';
import { configRequest, createBaseApi } from './baseApi';

const baseApi = createBaseApi();

const changePassword = (arbiterId, password) => {
  return baseApi.post(`/arbiters/${arbiterId}/password`, { password }, configRequest);
};

export const arbitersService = { ...getServiceMethods('arbiters'), changePassword };
