import { Table } from 'containers/UI/Table';
import { useTableauTemplates } from './hooks/useTableauTemplates';
import { Select, SelectVariantEnum } from 'containers/UI/Select';

import { ReactComponent as FilterIcon } from 'icons/filter.svg';
import { ReactComponent as ArrowIcon } from 'icons/arrows/arrow_down.svg';

import styles from './TableauTemplates.module.css';

export const TableauTemplates = () => {
  const {
    header,
    body,
    isTableauTemplatesLoading,
    totalTableauTemplatesCount,
    handleFilterGroup,
    tableauTemplatesFilterType,
  } = useTableauTemplates();

  return (
    <>
      <div className={styles['filter-wrapper']}>
        <Select
          key="TableauTemplates-filter"
          wrapperClassName={styles['header-filterWrapper']}
          className={styles['header-filter']}
          variant={SelectVariantEnum.NOT_BORDERED}
          icon={<FilterIcon />}
          customArrowIcon={<ArrowIcon />}
          options={[
            { name: 'Все', value: null },
            { name: 'Хоккей', value: 'hockey' },
            { name: 'Generic', value: 'generic' },
          ]}
          selected={tableauTemplatesFilterType}
          onChange={handleFilterGroup}
        />
      </div>

      <Table
        heading={header}
        body={body}
        isLoading={isTableauTemplatesLoading}
        totalEntitiesCount={totalTableauTemplatesCount}
        idKey="name"
        emptyText={'Вы еще не добавили ни одного шаблона'}
      />
    </>
  );
};
