import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { registerLocale } from 'react-datepicker';

registerLocale('ru', ru);

import styles from './DatePicker.module.css';

export const DatePicker = props => {
  const {
    selected = '',
    className = '',
    classNameWrapper = '',
    error,
    labelClassName = '',
    label = '',
    placeholder = 'dd/mm/yy',
    onChange = () => {},
    minDate = false,
    dateFormat = 'dd/mm/yy',
    disabled,
  } = props;

  return (
    <div className={classnames(styles.datePickerWrapper, classNameWrapper)}>
      {label && <div className={classnames(styles.datePickerLabel, labelClassName)}>{label}</div>}
      <ReactDatePicker
        className={classnames({ [styles.datePicker]: true, [styles['datePicker-error']]: error }, className)}
        locale="ru"
        placeholderText={placeholder}
        dateFormat={dateFormat}
        selected={selected}
        showMonthDropdown
        showYearDropdown
        peekNextMonth
        showTimeInput
        minDate={minDate}
        onChange={date => onChange(date)}
        disabled={disabled}
      />

      {error && <div className={classnames(styles.error)}>{error}</div>}
    </div>
  );
};

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  selected: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  classNameWrapper: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  showTimeInput: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.bool]),
};
