import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { SelectForm } from 'containers/UI/Select';
import { Input, InputActionTypeEnum, InputParseErrors, InputTypeEnum } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { useAdminsLoad } from 'containers/Hooks/admins/useAdminsLoad';
import { DefaultFileTypes } from 'helpers/constants/fileTypes';
import { useState } from 'react';

import styles from './ModalTerminalReleasesEdit.module.css';

export const ModalTerminalReleasesEdit = ({ terminalRelease = {} }) => {
  const {
    closePopup,
    editTerminalRelease,
    getTerminalReleaseById,
    fullTerminalReleaseData,
    allAdminsList,
    isModalOpen,
    setIsModalOpen,
    setFullTerminalReleaseData,
  } = useStateContext();

  const [fileData, setFileData] = useState(null);

  const getFullTerminalReleaseData = function () {
    useEffect(() => {
      if (Object.keys(fullTerminalReleaseData).length === 0) {
        getTerminalReleaseById(terminalRelease.id);
      }

      reset({ administrators: fullTerminalReleaseData?.administrators });
    }, [fullTerminalReleaseData]);
  };
  getFullTerminalReleaseData();

  const triggerAllAdminsLoading = function () {
    useEffect(() => {
      if (!isModalOpen) {
        setIsModalOpen(true);
      }
    }, []);
  };
  triggerAllAdminsLoading();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({ mode: 'all', defaultValues: terminalRelease });

  const { isLoading, setIsLoading, handleAdminsLoad } = useAdminsLoad();

  const save = async form => {
    const formData = {
      id: terminalRelease.id,
      name: form.name,
      comment: form.comment,
      fileName: fileData?.name || terminalRelease.fileName,
      administrators: form.administrators,
    };

    // TODO Зачем тут setIsLoading? Он просто туда сюда переключается и всё. Он же не ждет пока выполнится editTerminalRelease(formData, closePopup)
    setIsLoading(true);
    editTerminalRelease(formData, fileData, closePopup);
    setIsLoading(false);
  };

  const adminsOptions = useMemo(() => {
    return allAdminsList.map(user => ({
      name: user.email,
      value: user.id,
    }));
  }, [allAdminsList]);

  const setFile = async uploadFile => {
    if (!(uploadFile instanceof File)) return;

    setFileData(uploadFile);
    const { name } = getValues();
    setValue('name', name ? name : uploadFile.name);
  };

  const componentWillUnmount = function () {
    useEffect(() => {
      return () => {
        setFullTerminalReleaseData({});
      };
    }, []);
  };
  componentWillUnmount();

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <fieldset className={styles.form_fieldset}>
          <div className={styles.form_fields}>
            <Input
              label={'Название (версия)'}
              placeholder={'Название'}
              error={InputParseErrors(errors.name, { minLength: 5, maxLength: 100 })}
              {...register('name', { required: true, minLength: 5, maxLength: 100, setValueAs: v => v.trim() })}
            />

            <Input
              label={'Комментарий (релиз ноутс)'}
              placeholder={'Комментарий'}
              error={InputParseErrors(errors.comment, { minLength: 5, maxLength: 500 })}
              {...register('comment', { minLength: 5, maxLength: 500, setValueAs: v => v.trim() })}
            />

            <SelectForm
              name="administrators"
              control={control}
              placeholder={'Администраторы'}
              label={'Администраторы, имеющие доступ к релизу'}
              options={adminsOptions}
              searchable
              isMulti
              isInfinityLoad
              onLoad={handleAdminsLoad}
            />

            <Input
              id={'fileInput'}
              label={'Файл (образ релиза)'}
              placeholder={terminalRelease.fileName}
              hidden
              type={InputTypeEnum.FILE}
              fileTypes={DefaultFileTypes}
              actions={[InputActionTypeEnum.LOAD_FILE]}
              onChange={setFile}
              allFileTypesAvailable
            />
          </div>

          <div className={styles.form_actions}>
            <Button className={styles.form_button} type="submit" disabled={isLoading || !isValid}>
              Сохранить
            </Button>
            <Button
              className={styles.form_button}
              disabled={isLoading}
              variant={ButtonVariantEnum.SOLID_ACCENT}
              onClick={closePopup}
            >
              Отмена
            </Button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

ModalTerminalReleasesEdit.propTypes = {
  terminalRelease: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    comment: PropTypes.string,
    fileName: PropTypes.string,
    administrators: PropTypes.array,
  }),
};
