import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { passwordSchema } from 'helpers/validation';
import { Input, InputTypeEnum, InputParseErrors, InputActionTypeEnum } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from './ModalAdminsChangePassword.module.css';

export const ModalAdminsChangePassword = ({ adminData }) => {
  const { id, email } = adminData;
  const loading = false; // todo fix

  const { closePopup, changeAdminPassword } = useStateContext();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const save = form => {
    changeAdminPassword({ id, password: form.password }, closePopup);
  };

  const getPasswordSchema = fieldName => ({
    ...passwordSchema,
    validate: {
      ...passwordSchema.validate,
      retryPassword: val => {
        if (watch(fieldName) != val) return 'Ваши пароли не совпадают';
      },
    },
  });

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_email_label}>{email}</div>
        <div className={styles.form_fields}>
          <Input
            type={InputTypeEnum.PASSWORD}
            label={'Новый пароль'}
            placeholder={'Пароль'}
            actions={[InputActionTypeEnum.EYE, InputActionTypeEnum.DICE, InputActionTypeEnum.COPY]}
            error={!isValid && InputParseErrors(errors.password, passwordSchema)}
            {...register('password', passwordSchema)}
          />

          <Input
            type={InputTypeEnum.PASSWORD}
            label={'Новый пароль повторно'}
            placeholder={'Пароль'}
            actions={[InputActionTypeEnum.EYE]}
            error={!isValid && InputParseErrors(errors.confirm_password, getPasswordSchema('password'))}
            {...register('confirm_password', getPasswordSchema('password'))}
          />
        </div>

        <div className={styles.form_actions}>
          <Button className={styles.form_button} type="submit" disabled={!isValid}>
            Сохранить
          </Button>
          <Button
            className={styles.form_button}
            variant={ButtonVariantEnum.SOLID_ACCENT}
            disabled={loading}
            onClick={closePopup}
          >
            Отмена
          </Button>
        </div>
      </form>
    </div>
  );
};

ModalAdminsChangePassword.propTypes = { adminData: PropTypes.object };
