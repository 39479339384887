const LogsEvents = {
  terminalStart: 'Запуск терминала',
  terminalTurnOff: 'Выключение терминала',
  terminalOnline: 'Переход терминала в онлайн',
  terminalOffline: 'Переход терминала в оффлайн',
  syncSuccess: 'Синхронизация завершена успешно',
  syncFail: 'Синхронизация завершена неуспешно',
  contentChange: 'Смена контента',
  codecsUndefined: 'Невозможно воспроизвести контент - нет кодеков',
  fileFormatIncorrect: 'Некорректный формат файла',
  cameraUnavailable: 'Ошибка камеры. API {url} недоступно',
  cameraNegativeResponse: 'Ошибка камеры. API {url} вернул 400 или 500',
  cameraTimeout: 'Ошибка камеры. API {url} не ответил за 60 секунд',
  externalSensorPortUndefined: 'Номер TCP порта не задан в локальном конфиге',
  externalSensorNoData: 'Не удалось получить данные',
  externalSensorSerializationError: 'Не удалось сериализовать данные',
  externalEquipment: 'Внешнее оборудование',
  error: 'Ошибка воспроизведения контента',
  undefined: 'Неизвестная ошибка',
};

const RoleNames = {
  admin: 'Администратор',
  basic: 'Оператор',
  master: 'Мастер',
};

const LogsEventsOptions = Object.keys(LogsEvents).map(type => ({
  name: LogsEvents[type],
  value: type,
}));

export { LogsEvents, LogsEventsOptions, RoleNames };
