import { useContext, useEffect, useRef } from 'react';
import parseSeconds from 'helpers/date/parseSeconds';

import { ScheduleContext } from '../../Context/ScheduleContext';

export const useScheduleTimeBar = () => {
  const { scale, start, end, tableWidth, setTableWidth } = useContext(ScheduleContext);

  const timeBarRef = useRef(null);

  const timesArray = () => {
    const array = [];

    for (let i = Math.round(start); i <= end; i = i + scale) {
      array.push(i);
    }

    return array;
  };

  const translateToValidFormat = seconds => {
    const { hours, minutes } = parseSeconds(seconds, true);
    return `${hours}:${minutes}`;
  };

  const resizeHandler = async () => {
    setTableWidth(null);
    setTimeout(() => {
      setTableWidth(timeBarRef.current?.clientWidth);
    }, 1);
  };

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return {
    tableWidth,
    timesArray: timesArray(),
    timeBarRef,
    translateToValidFormat,
  };
};
