import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './ResizeHandler.module.css';

export const ResizeHandler = forwardRef((props, ref) => {
  const { handleAxis = 'se', setDisabledDrag = () => {}, active = false, ...restProps } = props;
  return (
    <div
      ref={ref}
      className={classnames(styles['resize-handle'], styles[`resize-handle-${handleAxis}`], {
        [styles['resize-handle-active']]: active,
      })}
      onMouseEnter={() => setDisabledDrag(true)}
      onMouseLeave={() => setDisabledDrag(false)}
      {...restProps}
    ></div>
  );
});
ResizeHandler.displayName = 'ResizeHandler';

ResizeHandler.propTypes = {
  setDisabledDrag: PropTypes.func,
  active: PropTypes.bool,
  handleAxis: PropTypes.string,
};
