import { useTerminalUpdates } from './useTerminalUpdates';
import { Table } from 'containers/UI/Table';

export const TerminalUpdates = () => {
  const { header, body, isTerminalUpdatesLoading, totalTerminalUpdatesCount } = useTerminalUpdates();

  return (
    <Table
      heading={header}
      body={body}
      isLoading={isTerminalUpdatesLoading}
      totalEntitiesCount={totalTerminalUpdatesCount}
      idKey="terminalUpdates"
      emptyText={'Вы еще не добавили ни одного обновления'}
    />
  );
};
