export const htmlContent = {
  __html: `
<!DOCTYPE html>
<html>
  <head>
    <title>Test</title>
  </head>
  <body>
    <object data="https://unitai.online/" style="width:100%;height:100vh;">
      <embed src="https://unitai.online/" style="width:100%;height:100vh;"> </embed>
      Error: Embedded data could not be displayed.
    </object>
  </body>
</html>
`,
};
