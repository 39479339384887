import { LoginStatusHelper } from 'services/hooks/loginStatusHelper';
import { Navigate } from 'react-router-dom';

/**
 *
 * @returns {JSX.Element}
 */
export const getDefaultRoute = () => {
  const { getParsedUser } = LoginStatusHelper.getInstance();
  const parsedUser = getParsedUser();
  const linkTo = parsedUser.role === 'master' || parsedUser.role === 'admin' ? '/devices' : '/files';

  return <Navigate to={linkTo} />;
};
