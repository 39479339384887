import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forwardRef, useState } from 'react';
import { generatePassword } from 'helpers/generatePassword';

import { ReactComponent as Dice } from 'icons/dice.svg';
import { ReactComponent as Copy } from 'icons/copy.svg';
import { ReactComponent as ContextMenu } from 'icons/contextMenu.svg';
import { ReactComponent as OpenedEye } from 'icons/openedEye.svg';
import { ReactComponent as ClosedEye } from 'icons/eye.svg';

import { InputActionTypeEnum } from '../Constants/InputActionTypeEnum';
import { InputTooltipOptions } from '../Constants/InputTooltipOptions';
import { InputTypeEnum } from '../Constants/InputTypeEnum';

import styles from '../Input.module.css';

export const InputActions = forwardRef(({ actions, inputType, onChangeInputType }, ref) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleVisiblePassword = () => {
    onChangeInputType(inputType === InputTypeEnum.PASSWORD ? InputTypeEnum.TEXT : InputTypeEnum.PASSWORD);
  };

  const handleGeneratePassword = () => {
    ref.current.value = generatePassword();
    setIsCopied(false);

    if (inputType === InputTypeEnum.PASSWORD) {
      onChangeInputType(InputTypeEnum.PASSWORD);
    }

    ref.current.focus();
    setTimeout(() => ref.current.blur(), 1);
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(ref?.current.value);
    setIsCopied(true);
  };

  const handleLoadFile = async () => {
    ref?.current?.click();
  };

  if (actions.length === 0) return null;

  return actions.map(action => {
    switch (action) {
      case InputActionTypeEnum.EYE: {
        const Icon = inputType === InputTypeEnum.TEXT ? OpenedEye : ClosedEye;
        return <Icon key={action} className={styles.input__icon} onClick={handleVisiblePassword} />;
      }
      case InputActionTypeEnum.DICE:
        return (
          <Dice
            key={action}
            className={styles.input__icon}
            {...InputTooltipOptions}
            data-tooltip-content={'Сгенерировать пароль'}
            onClick={handleGeneratePassword}
          />
        );
      case InputActionTypeEnum.COPY:
        return (
          <Copy
            key={action}
            className={styles.input__icon}
            {...InputTooltipOptions}
            data-tooltip-content={isCopied ? 'Скопировано' : 'Скопировать'}
            onClick={copyToClipboard}
          />
        );
      case InputActionTypeEnum.LOAD_FILE:
        return (
          <div
            key={action}
            className={styles.input__icon_file_wrapper}
            {...InputTooltipOptions}
            data-tooltip-content={'Выберите файл'}
            onClick={handleLoadFile}
          >
            <ContextMenu className={classnames(styles.input__icon, styles.input__icon_file)} />
          </div>
        );
    }
  });
});

InputActions.displayName = 'InputActions';

InputActions.defaultProps = {
  actions: [],
  inputType: InputTypeEnum.TEXT,
  onChangeInputType: () => InputTypeEnum,
};

InputActions.propTypes = {
  actions: PropTypes.array,
  inputType: PropTypes.oneOf([
    InputTypeEnum.TEXT,
    InputTypeEnum.EMAIL,
    InputTypeEnum.NUMBER,
    InputTypeEnum.PASSWORD,
    InputTypeEnum.TELEPHONE,
    InputTypeEnum.FILE,
  ]),
  onChangeInputType: PropTypes.func,
};
