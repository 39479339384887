import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { useApi } from 'services/hooks/useApi';
import { useLocation, useSearchParams } from 'react-router-dom';
import { tableauTemplatesService } from 'services/tableauTemplates.service';
import { useState, useEffect } from 'react';
import { URLs } from 'helpers/constants/URLs';
import { responseHandler } from 'store/helpers/responseHandler';

export const useTableauTemplatesState = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [
    tableauTemplates,
    setTableauTemplates,
    isTableauTemplatesLoading,
    tableauTemplatesError,
    setTableauTemplatesError,
    totalTableauTemplatesCount,
    setTotalTableauTemplatesCount,
  ] = useApi(() => tableauTemplatesService.getAll(getQueryParams(+page - 1) || {}));

  const [tableauTemplateWasEditedToggler, setTableauTemplateWasEditedToggler] = useState(false);
  const [tableauTemplatesFilterType, setTableauTemplatesFilterType] = useState(null);

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.scoreboardTemplates) {
        return (() => {
          tableauTemplatesService
            .getAll({
              ...(getQueryParams(+page - 1) || {}),
              ...(tableauTemplatesFilterType ? { sport: tableauTemplatesFilterType } : {}),
            })
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response?.data?.items) && setTableauTemplates(response.data.items);
              }
            })
            .catch(setTableauTemplatesError);
        })();
      }
    }, [pathname, page, totalTableauTemplatesCount, tableauTemplateWasEditedToggler, tableauTemplatesFilterType]);
  };
  paginate();

  const createTableauTemplate = (newTableauTemplateData, onModalClose) => {
    tableauTemplatesService
      .createNew(newTableauTemplateData)
      .then(response => {
        if (response.status === 200) {
          setTotalTableauTemplatesCount(prevState => prevState + 1);
          responseHandler(true, 'Шаблон был успешно создан', onModalClose, null);
        }
      })
      .catch(e => {
        setTableauTemplatesError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  };

  const editTableauTemplate = (tableauTemplateId, newTableauTemplateData, onModalClose) => {
    tableauTemplatesService
      .put(tableauTemplateId, newTableauTemplateData)
      .then(response => {
        if (response.status === 204) {
          setTableauTemplateWasEditedToggler(prevState => !prevState);
          responseHandler(true, 'Шаблон был успешно изменен', onModalClose, null);
        }
      })
      .catch(e => {
        setTableauTemplatesError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  };

  const removeTableauTemplate = (templateId, onModalClose) => {
    tableauTemplatesService
      .deleteById(templateId)
      .then(response => {
        if (response.status === 204) {
          setTotalTableauTemplatesCount(prevState => prevState - 1);
          responseHandler(true, 'Шаблон был успешно удален', onModalClose, null);
        }
      })
      .catch(e => {
        setTableauTemplatesError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  };

  return {
    tableauTemplates,
    isTableauTemplatesLoading,
    totalTableauTemplatesCount,
    tableauTemplatesError,

    createTableauTemplate,
    editTableauTemplate,
    removeTableauTemplate,

    tableauTemplatesFilterType,
    setTableauTemplatesFilterType,
  };
};
