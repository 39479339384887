import { Table } from 'containers/UI/Table';
import { useSportEvents } from './hooks/useSportEvents';

export const SportEvents = () => {
  const { header, body, isSportEventsLoading, totalSportEventsCount } = useSportEvents();

  return (
    <Table
      heading={header}
      body={body}
      isLoading={isSportEventsLoading}
      totalEntitiesCount={totalSportEventsCount}
      idKey="name"
      emptyText={'Вы еще не добавили ни одного мероприятия'}
    />
  );
};
