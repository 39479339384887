export const InputTypeEnum = {
  TEXT: 'text',
  EMAIL: 'email',
  NUMBER: 'number',
  PASSWORD: 'password',
  TELEPHONE: 'tel',
  FILE: 'file',
  Date: 'date',
  Radio: 'radio',
};
