import { useForm } from 'react-hook-form';

import { useStateContext } from 'store';
import { emailSchema, passwordSchema } from 'helpers/validation';
import { PermissionTitle } from 'helpers/constants/permissionsConstants';
import { Input, InputTypeEnum, InputParseErrors, InputActionTypeEnum } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { Checkbox } from 'containers/UI/Checkbox';

import styles from './ModalArbitersAdd.module.css';

export const ModalArbitersAdd = () => {
  const { closePopup, addArbiter } = useStateContext();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' });

  const save = form => {
    const formData = {
      email: form.email,
      password: form.password,
      permissions: { filesAvailability: !!form.filesAvailability },
    };
    addArbiter(formData, closePopup);
  };

  const getPasswordSchema = fieldName => ({
    ...passwordSchema,
    validate: {
      ...passwordSchema.validate,
      retryPassword: val => {
        if (watch(fieldName) != val) return 'Ваши пароли не совпадают';
      },
    },
  });

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_fields}>
          <Input
            label={'Email'}
            placeholder={'Email'}
            type={InputTypeEnum.EMAIL}
            error={InputParseErrors(errors.email, { minLength: 5, maxLength: 30 })}
            {...register('email', emailSchema)}
          />

          <Input
            type={InputTypeEnum.PASSWORD}
            label={'Пароль'}
            placeholder={'Пароль'}
            actions={[InputActionTypeEnum.EYE, InputActionTypeEnum.DICE, InputActionTypeEnum.COPY]}
            error={InputParseErrors(errors.password, passwordSchema)}
            {...register('password', passwordSchema)}
          />

          <Input
            type={InputTypeEnum.PASSWORD}
            label={'Пароль повторно'}
            placeholder={'Пароль повторно'}
            actions={[InputActionTypeEnum.EYE]}
            error={InputParseErrors(errors.confirm_password, getPasswordSchema('password'))}
            {...register('confirm_password', getPasswordSchema('password'))}
          />

          <div className={styles.form_checkboxes}>
            <Checkbox control={control} name="filesAvailability" label={PermissionTitle.filesAvailability} />
          </div>
        </div>

        <div className={styles.form_actions}>
          <Button className={styles.form_button} type="submit" disabled={!isValid}>
            Создать
          </Button>
          <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
            Закрыть
          </Button>
        </div>
      </form>
    </div>
  );
};
