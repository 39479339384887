import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Select } from 'containers/UI/Select';

export const SelectForm = props => {
  const { control, name, rules = { required: true }, ...attrs } = props;

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => <Select {...field} {...attrs} selected={field.value} />}
    />
  );
};

SelectForm.propTypes = {
  // Controller options
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  // Select options
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  searchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isInfinityLoad: PropTypes.bool,
  onLoad: PropTypes.func,
  options: PropTypes.array,
};
