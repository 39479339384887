import { useStateContext } from 'store';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import { ModalTableauTemplates } from 'containers/UI/Modal/Components/TableauTemplates/ModalTableauTemplates';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as TemplatesList } from 'icons/list.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';

export const useTableauTemplates = () => {
  const {
    tableauTemplates,
    isTableauTemplatesLoading,
    totalTableauTemplatesCount,
    openPopup,
    createTableauTemplate,
    editTableauTemplate,
    removeTableauTemplate,
    tableauTemplatesFilterType,
    setTableauTemplatesFilterType,
  } = useStateContext();

  const handleFilterGroup = filterValue => {
    setTableauTemplatesFilterType(filterValue);
  };

  const handleCreateTableauTemplate = () => {
    openPopup(() => <ModalTableauTemplates createTableauTemplate={createTableauTemplate} />, {
      title: 'Создание шаблона спортивного табло',
    });
  };

  const handleEditTableauTemplate = tableauTemplate => {
    openPopup(
      () => <ModalTableauTemplates tableauTemplate={tableauTemplate} editTableauTemplate={editTableauTemplate} />,
      { title: 'Редактирование шаблона спортивного табло' },
    );
  };

  const handleDeleteTableauTemplate = (tableauTemplateId, name) => {
    openPopup(
      () => (
        <ModalDelete
          contentId={tableauTemplateId}
          title={`Вы уверены, что хотите удалить шаблон ${name}?`}
          onDelete={removeTableauTemplate}
        />
      ),
      {
        title: 'Удаление шаблона спортивного табло',
      },
    );
  };

  const header = [
    { key: 'name', title: 'Название' },
    { key: 'sport', title: 'Вид спорта' },
    { key: 'screenCount', title: 'Количество рабочих столов' },
    { key: 'comment', title: 'Комментарий' },
    {
      key: 'actions',
      title: (
        <div key="tableauTemplates-head-button" className={tableStyles.rowButtons}>
          <Button
            key="tableauTemplate-create"
            className={tableStyles.headerButton}
            onClick={handleCreateTableauTemplate}
          >
            Создать шаблон
          </Button>
        </div>
      ),
    },
  ];

  const body = tableauTemplates?.map(tableauTemplate => {
    const { name, comment, screenCount, id, sport } = tableauTemplate || {};

    return {
      name,
      comment,
      screenCount,
      sport,
      actions: (
        <div key="tableauTemplates-item-buttons" className={tableStyles.rowButtons}>
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Изменить'}
            onClick={() => handleEditTableauTemplate(tableauTemplate)}
          />

          <NavLink to={'/tableauTemplates/' + id}>
            <Button variant={ButtonVariantEnum.ICON} iconData={{ icon: <TemplatesList /> }} title={'Рабочие столы'} />
          </NavLink>

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить'}
            onClick={() => handleDeleteTableauTemplate(id, name)}
          />
        </div>
      ),
    };
  });

  return {
    header,
    body,
    totalTableauTemplatesCount,
    isTableauTemplatesLoading,
    handleFilterGroup,
    tableauTemplatesFilterType,
  };
};
