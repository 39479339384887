import { createBaseApi, configRequest } from './baseApi';

const baseApi = createBaseApi();

const DEFAULT_PARAMS = {
  skip: 0,
  take: 10,
};

/**
 * schedules
 */
function getSchedules(params = {}) {
  return baseApi.get('/schedulings', {
    ...configRequest,
    params: { ...DEFAULT_PARAMS, ...params },
  });
}

function addSchedule(data) {
  return baseApi.post('/schedulings', data, configRequest);
}

function updateSchedule(scheduleId, data) {
  return baseApi.put(`/schedulings/${scheduleId}`, data, configRequest);
}

function deleteSchedule(scheduleId) {
  return baseApi.delete(`/schedulings/${scheduleId}`, configRequest);
}

function sendSchedule(scheduleId, deviceId) {
  return baseApi.post(`/schedulings/${scheduleId}/devices/${deviceId}`, configRequest);
}

/** groups */
function getGroups() {
  return baseApi.get('/schedulings/groups', configRequest);
}

function addGroup(data) {
  return baseApi.post('/schedulings/groups', data, configRequest);
}

function updateGroup(groupId, data) {
  return baseApi.put(`/schedulings/groups/${groupId}`, data, configRequest);
}

function deleteGroup(groupId) {
  return baseApi.delete(`/schedulings/groups/${groupId}`, configRequest);
}

/**
 * schedule playlists
 */
function getSchedulePlaylists(scheduleId) {
  return baseApi.get(`/schedulings/${scheduleId}/playlists`, configRequest);
}

function updateSchedulePlaylists(scheduleId, data) {
  return baseApi.post(`/schedulings/${scheduleId}/playlists`, data, configRequest);
}

export const schedulesService = {
  getSchedules,
  addSchedule,
  updateSchedule,
  deleteSchedule,
  sendSchedule,

  getSchedulePlaylists,
  updateSchedulePlaylists,

  getGroups,
  addGroup,
  updateGroup,
  deleteGroup,
};
