import { pageSize as defaultPageSize } from '../constants/queryParams';

export const getQueryParams = (skip, groupFilter, pageSize) => {
  if (skip < 0 || isNaN(skip)) {
    skip = 0;
  }

  const queryParams = {
    skip: skip * (pageSize || defaultPageSize),
    take: pageSize || defaultPageSize,
  };

  if (groupFilter !== 'all' && groupFilter !== '') {
    queryParams['groupId'] = groupFilter;
  }

  return queryParams;
};
