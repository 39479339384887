import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useStateContext } from 'store';
import { UserRoleEnum } from 'helpers/constants';
import { ModalDevicesEdit, ModalDevicesLogs, ModalDevicesSnapshot } from 'containers/UI/Modal/Components/Devices';

export const useDevicesMapMarker = (markerRef, deviceData) => {
  const { id, position } = deviceData;
  const { latitude, longitude } = position || { latitude: 0, longitude: 0 };
  const loading = false; /** todo fix */

  const { user, openPopup } = useStateContext();
  const isMaster = user.role === UserRoleEnum.MASTER;

  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [sensors, setSensors] = useState([]);
  const [isHasSensorError, setIsHasSensorError] = useState(false);

  const getSnapshot = useCallback(() => {
    openPopup(() => <ModalDevicesSnapshot deviceData={deviceData} />, {
      title: 'Снимок',
    });
  }, []);

  const openLogs = useCallback(() => {
    openPopup(() => <ModalDevicesLogs deviceId={id} />, {
      title: 'Логи',
    });
  }, []);

  const handleShowPopup = useCallback(() => {
    openPopup(() => <ModalDevicesEdit deviceData={deviceData} isMaster={isMaster} />, {
      title: 'Редактирование устройства',
    });
  }, [deviceData]);

  const getSensors = useCallback(async () => {
    try {
      const response = await axios.get(`/devices/${id}/sensors`);
      if (response.data) {
        setSensors(response.data);
      }
    } catch (error) {
      unstable_batchedUpdates(() => {
        setIsHasSensorError(true);
        setSensors([
          {
            sensor: 'Ошибка',
            value: error?.response?.data?.message ?? error.message,
          },
        ]);
      });
    }
  }, [id]);

  const isVisibleMarker = useCallback(
    ({ _southWest, _northEast }) => {
      const isCorrectLat = _southWest.lat < latitude && latitude < _northEast.lat;
      const isCorrectLng = _southWest.lng < longitude && longitude < _northEast.lng;

      return isCorrectLng && isCorrectLat;
    },
    [latitude, longitude],
  );

  const openDetailPopup = useCallback(
    ({ detail }) => {
      if (isVisibleMarker(detail) && markerRef.current) {
        markerRef.current.openPopup();
      }
    },
    [markerRef.current],
  );

  const closeDetailPopup = useCallback(
    ({ detail }) => {
      if (isVisibleMarker(detail) && markerRef.current) {
        markerRef.current.closePopup();
        setIsHasSensorError(false);
      }
    },
    [markerRef.current],
  );

  useEffect(() => {
    window.addEventListener('map:openPopupAll', openDetailPopup);
    window.addEventListener('map:closePopupAll', closeDetailPopup);

    return () => {
      window.removeEventListener('map:openAll', openDetailPopup);
      window.removeEventListener('map:closePopupAll', closeDetailPopup);
    };
  }, [openDetailPopup, closeDetailPopup]);

  return {
    loading,
    sensors,
    isMaster,
    isHasSensorError,
    selectedPlaylist,
    setSelectedPlaylist,

    getSensors,
    openLogs,
    getSnapshot,
    handleShowPopup,
  };
};
