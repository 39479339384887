import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';

import { DesktopItem } from './DesktopItem/DesktopItem';
import { Loader } from 'containers/UI/Loader';

import styles from '../Desktops.module.css';

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: { active: { opacity: '0.4', background: 'var(--accent-color)' } },
  }),
};

export const DesktopList = ({ loading = false }) => {
  const { watch, screensMove } = useFormContext();
  const screens = watch('screens');

  //DND Controls
  const [active, setActive] = useState(null);
  const activeItem = useMemo(() => screens.find(item => item.id === active?.id), [active, screens]);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(KeyboardSensor));

  const replace = ({ active, over }) => {
    const from = active?.data?.current?.sortable?.index;
    const to = over?.data?.current?.sortable?.index;
    if (isNaN(from) || isNaN(to)) return;
    screensMove(from, to);
  };

  return (
    <div className={styles['scroll-list']}>
      <DndContext
        autoScroll={false}
        sensors={sensors}
        onDragEnd={replace}
        onDragStart={({ active }) => setActive(active)}
        onDragCancel={() => setActive(null)}
        modifiers={[restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={screens.map(s => s.ourId)}>
          {loading && <Loader />}
          {!loading && screens.map((s, index) => <DesktopItem key={s.ourId} index={index} screen={s} />)}
        </SortableContext>

        <DragOverlay dropAnimation={dropAnimationConfig}>
          {!!activeItem && <DesktopItem key={activeItem.ourId} screen={activeItem} />}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

DesktopList.propTypes = {
  onSelect: PropTypes.func,
  loading: PropTypes.bool,
};
