import { useStateContext } from 'store';
import parseSeconds from 'helpers/date/parseSeconds';
import { correctFormatTime } from 'helpers/time/correctFormatTime';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import {
  ModalSchedulePlaylistAdd,
  ModalSchedulePlaylistInfo,
  ModalSchedulePlaylistEdit,
} from 'containers/UI/Modal/Components/SchedulePlaylist';
import { useCallback, useContext, useMemo, useState } from 'react';

import { ScheduleContext } from '../../Context/ScheduleContext';

export const useScheduleTablePlaylist = playlist => {
  const { openPopup, schedulePlaylists } = useStateContext();

  const {
    start,
    tableWidth,
    secondsOnScreen,
    deletePlaylist,
    createPlaylist,
    handleLocalUpdate,
    deleteWeekdayPlaylist,
  } = useContext(ScheduleContext);

  const [isDragging, setIsDragging] = useState(false);

  const getStyles = () => {
    const width = ((playlist.end - playlist.start) * tableWidth) / secondsOnScreen;
    const startValue = ((playlist.start - start) * tableWidth) / secondsOnScreen;

    return {
      widthValue: width,
      width: `${width}px`,
      transform: `translateX(${startValue}px)`,
    };
  };

  const toValidFormat = seconds => {
    const { hours, minutes } = parseSeconds(seconds);
    return `${correctFormatTime(hours)}:${correctFormatTime(minutes)}`;
  };

  const timeFormat = useMemo(
    () => `${toValidFormat(playlist.start)} - ${toValidFormat(playlist.end)}`,
    [playlist.start, playlist.end],
  );

  const handleDeletePlaylist = (contentId, closePopup) => {
    deletePlaylist(playlist.id);
    closePopup();
  };

  const handleAdd = useCallback(
    weekday => {
      openPopup(
        () => (
          <ModalSchedulePlaylistAdd
            weekday={weekday}
            handleCreate={createPlaylist}
            existingPlaylists={schedulePlaylists.filter(playlist => playlist.weekday === weekday)}
          />
        ),
        {
          title: 'Добавление плейлиста в расписание',
        },
      );
    },
    [playlist],
  );

  const handleEdit = useCallback(
    weekday => {
      openPopup(
        () => (
          <ModalSchedulePlaylistEdit
            schedulePlaylist={playlist}
            handleUpdate={handleLocalUpdate}
            existingPlaylists={schedulePlaylists.filter(playlist => playlist.weekday === weekday)}
          />
        ),
        {
          title: 'Редактирование плейлиста в расписании',
        },
      );
    },
    [schedulePlaylists, playlist],
  );

  const handleInfo = useCallback(() => {
    openPopup(() => <ModalSchedulePlaylistInfo playlist={playlist} />, {
      title: playlist.name,
      showCloseBtn: true,
    });
  }, [playlist]);

  const handleDelete = useCallback(() => {
    openPopup(
      () => (
        <ModalDelete
          contentId={playlist.id}
          title={`Вы уверены что хотите удалить ${playlist.name} из расписания?`}
          isVisibleIcon={false}
          onDelete={handleDeletePlaylist}
        />
      ),
      {
        title: 'Удаление плейлиста из расписания',
      },
    );
  }, [playlist]);

  const { width, transform, widthValue } = getStyles();

  return {
    timeFormat,
    width: widthValue,
    styles: { width, transform },
    isDragging,
    setIsDragging,
    handleAdd,
    handleInfo,
    handleEdit,
    handleDelete,
    createPlaylist,
    deleteWeekdayPlaylist,
  };
};
