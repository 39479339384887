import PropTypes from 'prop-types';
import { useLocation, NavLink } from 'react-router-dom';
import { EMPTY } from 'helpers/constants';

export const TableRow = ({ row = {}, heading = [], link = '', idKey = '' }) => {
  const { pathname } = useLocation();

  const renderCell = ({ key }) => {
    const title = row[key] || EMPTY;
    return (
      <td key={key}>{key === link ? <NavLink to={[pathname, '/', row[idKey]].join('')}>{title}</NavLink> : title}</td>
    );
  };

  return <tr>{heading.map(renderCell)}</tr>;
};

TableRow.propTypes = {
  row: PropTypes.object,
  heading: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  link: PropTypes.string,
  idKey: PropTypes.string,
};
