import PropTypes from 'prop-types';
import classnames from 'classnames';
import { correctFormatTime } from 'helpers/time/correctFormatTime';
import { useOnClickOutside } from 'containers/Hooks';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import styles from './TimePicker.module.css';

export const TimePicker = forwardRef((props, ref) => {
  const {
    className = '',
    label = '',
    labelClassName = '',
    value = '',
    readOnly = false,
    valid = true,
    disabled = false,
    onChange = () => {},
  } = props;
  const [_hours, _minutes, _seconds] = value.split(':');

  const [isOpen, setIsOpen] = useState(false);
  const [hours, setHours] = useState(_hours || '00');
  const [minutes, setMinutes] = useState(_minutes || '00');
  const [seconds, setSeconds] = useState(_seconds || '00');

  const timePickerRef = useRef(null);
  useImperativeHandle(ref, () => timePickerRef.current);

  useOnClickOutside(timePickerRef, () => setIsOpen(false));

  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const secondsRef = useRef(null);

  useEffect(() => {
    if (value) {
      const [_hours, _minutes, _seconds] = value.split(':');
      setHours(_hours);
      setMinutes(_minutes);
      setSeconds(_seconds);
    }
  }, [value]);

  const onClick = () => {
    if (!isOpen) {
      setIsOpen(true);
      setTimeout(() => {
        showTimes();
      }, 1);
    }
  };

  const showTimes = () => {
    const hoursEl = hoursRef.current;
    const minutesEl = minutesRef.current;
    const secondsEl = secondsRef.current;

    if (hoursEl && minutesEl && secondsEl) {
      const activeHour = hoursEl?.querySelector('[data-active="true"]');
      const activeMinute = minutesEl?.querySelector('[data-active="true"]');
      const activeSecond = secondsEl?.querySelector('[data-active="true"]');
      const listHeight = hoursEl?.clientHeight;

      hoursEl?.scrollTo({
        top: activeHour?.offsetTop - listHeight / 2,
      });
      minutesEl?.scrollTo({
        top: activeMinute?.offsetTop - listHeight / 2,
      });
      secondsEl?.scrollTo({
        top: activeSecond?.offsetTop - listHeight / 2,
      });
    }
  };

  const handleChangeHours = value => {
    const updateValue = correctFormatTime(value);
    setHours(updateValue);
    onChange(`${updateValue}:${minutes}:${seconds}`);
  };

  const handleChangeMinutes = value => {
    const updateValue = correctFormatTime(value);
    setMinutes(updateValue);
    onChange(`${hours}:${updateValue}:${seconds}`);
  };

  const handleChangeSeconds = value => {
    const updateValue = correctFormatTime(value);
    setSeconds(updateValue);
    onChange(`${hours}:${minutes}:${updateValue}`);
  };

  return (
    <div className={classnames(styles.timePickerWrapper, className)}>
      {label && <label className={classnames(styles.timePickerLabel, labelClassName)}>{label}</label>}

      <div
        ref={timePickerRef}
        className={classnames(styles.timePicker, {
          [styles.timePicker__disabled]: disabled,
          [styles.timePicker__readOnly]: readOnly,
          [styles.timePicker__invalid]: !valid,
        })}
        onClick={onClick}
      >
        <div className={styles.timePickerField}>
          <input value={hours} className={styles.timePickerInput} type="text" />
          {':'}
        </div>
        <div className={styles.timePickerField}>
          <input value={minutes} className={styles.timePickerInput} type="text" />
          {':'}
        </div>
        <div className={styles.timePickerField}>
          <input value={seconds} className={styles.timePickerInput} type="text" />
        </div>
        <div className={styles.timePickerValues} style={{ display: isOpen ? 'flex' : 'none' }}>
          <ul ref={hoursRef} className={styles.timePickerOptions}>
            <li className={classnames(styles.timePickerOption, styles.timePickerOption__disabled)}>HH</li>
            {Array.from({ length: 25 }, (_, i) => (
              <li
                key={i}
                className={classnames(styles.timePickerOption, {
                  [styles.timePickerOption__active]: correctFormatTime(i) === hours,
                })}
                data-active={correctFormatTime(i) === hours ? true : null}
                onClick={() => handleChangeHours(i)}
              >
                {correctFormatTime(i)}
              </li>
            ))}
          </ul>
          <ul ref={minutesRef} className={styles.timePickerOptions}>
            <li className={classnames(styles.timePickerOption, styles.timePickerOption__disabled)}>mm</li>
            {Array.from({ length: 60 }, (_, i) => (
              <li
                key={i}
                className={classnames(styles.timePickerOption, {
                  [styles.timePickerOption__active]: correctFormatTime(i) === minutes,
                })}
                data-active={correctFormatTime(i) === minutes ? true : null}
                onClick={() => handleChangeMinutes(i)}
              >
                {correctFormatTime(i)}
              </li>
            ))}
          </ul>
          <ul ref={secondsRef} className={styles.timePickerOptions}>
            <li className={classnames(styles.timePickerOption, styles.timePickerOption__disabled)}>ss</li>
            {Array.from({ length: 60 }, (_, i) => (
              <li
                key={i}
                className={classnames(styles.timePickerOption, {
                  [styles.timePickerOption__active]: correctFormatTime(i) === seconds,
                })}
                data-active={correctFormatTime(i) === seconds ? true : null}
                onClick={() => handleChangeSeconds(i)}
              >
                {correctFormatTime(i)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
});

TimePicker.displayName = 'TimePicker';

TimePicker.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  onChange: PropTypes.func,
};
