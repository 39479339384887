import PropTypes from 'prop-types';
import { useStateContext } from 'store';
import classnames from 'classnames';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { DATETIME_FORMATS } from 'containers/Desktops/helpers';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from '../ModalSelectLastSave.module.css';

export const ModalSelectLastSave = ({ timestamp, lastSaveTimestamp, onComplete, onClose }) => {
  const { closePopup } = useStateContext();

  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const onSubmit = form => {
    onComplete(form.timestamp || timestamp);
    closePopup();
  };

  const handleClose = () => {
    closePopup();
    onClose();
  };

  const getFormatDate = date => {
    const currentDate = new Date(date);
    console.log(currentDate);
    return format(currentDate, DATETIME_FORMATS['DD/MM/YYYY hh:mm:ss']);
  };

  return (
    <div
      className={classnames(styles.delete_wrapper, {
        [styles.delete_wrapper__full]: true,
      })}
    >
      <div className={styles.delete_wrapper_body}>
        <span className={styles.delete_wrapper_text}>
          Есть несохраненная локальная версия, открыть ее для редактирования?
        </span>

        <form className={styles.form_wrapper} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="timestamp"
            render={({ field: { onChange, ref, ...props } }) => {
              return (
                <div className={styles.wrapper_fields}>
                  <div className={classnames(styles.checkbox)}>
                    <input
                      id={'timestamp'}
                      ref={ref}
                      type="radio"
                      className={classnames(styles.checkbox_checkmark)}
                      onChange={() => onChange(timestamp)}
                      defaultChecked
                      {...props}
                    />

                    <label htmlFor={'timestamp'} className={styles.checkbox_label}>
                      {`Да, возобновить редактирование ${getFormatDate(timestamp)}`}
                    </label>
                  </div>

                  {lastSaveTimestamp > 0 && (
                    <div className={classnames(styles.checkbox)}>
                      <input
                        id={'lastSaveTimestamp'}
                        ref={ref}
                        type="radio"
                        className={classnames(styles.checkbox_checkmark)}
                        onChange={() => onChange(lastSaveTimestamp)}
                        {...props}
                      />

                      <label htmlFor={'lastSaveTimestamp'} className={styles.checkbox_label}>
                        {`Нет, открыть сохраненную версию ${getFormatDate(lastSaveTimestamp)}`}
                      </label>
                    </div>
                  )}
                </div>
              );
            }}
          />

          <div className={styles.delete_wrapper_actions}>
            <Button type="submit">Продолжить</Button>
            <Button variant={ButtonVariantEnum.SOLID_ACCENT} onClick={handleClose}>
              Отмена
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

ModalSelectLastSave.defaultProps = {
  contentId: null,
  timestamp: 0,
  lastSaveTimestamp: 0,
  onClose: () => {},
};

ModalSelectLastSave.propTypes = {
  contentId: PropTypes.string,
  timestamp: PropTypes.number,
  lastSaveTimestamp: PropTypes.number,
  onComplete: PropTypes.func,
  onClose: PropTypes.func,
};
