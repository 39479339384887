import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { useMemo, useState } from 'react';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ButtonGroupVariantEnum } from './Constants/ButtonGroupVariantEnum';
import { ButtonGroupOrientationEnum } from './Constants/ButtonGroupOrientationEnum';

import styles from './ButtonGroup.module.css';

export const ButtonGroup = props => {
  const {
    variant = ButtonGroupVariantEnum.TEXT,
    orientation = ButtonGroupOrientationEnum.HORIZONTAL,
    className = '',
    itemClassName = '',
    selected = '',
    isNavigate = false,
    items = [],
    onChange = () => {},
  } = props;
  const defaultSelected = selected ? items.find(el => el.type === selected)?.type : '';

  const [keyActive, setKeyActive] = useState(defaultSelected);

  const getVariantButton = useMemo(() => {
    switch (variant) {
      case ButtonGroupVariantEnum.TEXT:
        return ButtonVariantEnum.SOLID_BOTTOM;
      case ButtonGroupVariantEnum.CONTAINED:
        return ButtonVariantEnum.SECONDARY;
      case ButtonGroupVariantEnum.OUTLINE:
        return ButtonVariantEnum.SOLID_ACCENT;
    }
  }, [variant]);

  const handleClick = type => {
    setKeyActive(type);
    onChange(type);
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <div
      className={classnames(styles.buttonGroupWrapper, styles[`buttonGroupWrapper__${variant}`], {
        [styles.buttonGroupWrapper__vertical]: orientation === ButtonGroupOrientationEnum.VERTICAL,
        [className]: !!className,
      })}
    >
      {isNavigate &&
        items.map(link => (
          <NavLink
            key={link.url}
            to={link.url}
            className={({ isActive }) =>
              classnames(styles.buttonGroupItem, {
                [styles.buttonGroupItem_active]: isActive,
              })
            }
            end
          >
            {link.text}
          </NavLink>
        ))}

      {!isNavigate &&
        items.map(item => (
          <Button
            key={item.type}
            className={itemClassName}
            isActive={item.type === keyActive}
            variant={getVariantButton}
            onClick={() => handleClick(item.type)}
          >
            {item.text}
          </Button>
        ))}
    </div>
  );
};

ButtonGroup.propTypes = {
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  selected: PropTypes.string,
  orientation: PropTypes.oneOf([ButtonGroupOrientationEnum.HORIZONTAL, ButtonGroupOrientationEnum.VERTICAL]),
  variant: PropTypes.oneOf([
    ButtonGroupVariantEnum.TEXT,
    ButtonGroupVariantEnum.OUTLINE,
    ButtonGroupVariantEnum.CONTAINED,
  ]),
  isNavigate: PropTypes.bool,
  items: PropTypes.array,
  onChange: PropTypes.func,
};
