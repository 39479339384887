import { groupBy } from 'helpers/groupBy';
import { getShortDayName } from 'helpers/date/getShortDayName';
import { DAYS_WEEK } from '../../Constants/DaysWeek';
import { useStateContext } from 'store';

import styles from './ScheduleDays.module.css';

export const ScheduleDays = () => {
  const { schedulePlaylists } = useStateContext();
  const scheduleWeekDays = groupBy(schedulePlaylists, 'weekday') || {};

  return (
    <div className={styles.scheduleDaysWrapper}>
      {DAYS_WEEK.map(day => (
        <div key={day} className={styles.scheduleDay}>
          {getShortDayName(day)}({scheduleWeekDays[day] ? scheduleWeekDays[day].length : 0})
        </div>
      ))}
    </div>
  );
};
