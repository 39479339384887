import PropTypes from 'prop-types';
import styles from './Error.module.css';

const Error = ({ code, title }) => {
  return (
    <article className={styles['wrapper']}>
      <div className={styles['code']}>{code}</div>
      <h2>{title}</h2>
    </article>
  );
};

Error.defaultProps = { code: 404, title: 'Страница не найдена' };
Error.propTypes = { code: PropTypes.number, title: PropTypes.string };

export default Error;
