import { Outlet, useLocation } from 'react-router-dom';
import { ROLES } from 'helpers/constants';
import { useStateContext } from 'store';
import Error from 'containers/Error/Error';

const AbilityWrapper = () => {
  const { pathname } = useLocation();
  const { user } = useStateContext();
  const role = ROLES[user.role];

  if (role && role?.ability(pathname.replace('/', ''))) {
    return <Outlet />;
  }

  return <Error code={403} title="Страница недоступна" />;
};

export default AbilityWrapper;
