import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useEffect, useState } from 'react';
import { useStateContext } from 'store';
import PropTypes from 'prop-types';
import { isEmpty } from '../../helpers/lodash';
import { useForm } from 'react-hook-form';
import { NavLink, useParams } from 'react-router-dom';
import { DatePicker } from 'containers/UI/DatePicker';
import { SelectForm } from 'containers/UI/Select';
import { Button } from 'containers/UI/Button';
import { ReactComponent as BackArrow } from 'icons/backArrow.svg';

import styles from './DeviceChart.module.css';

const StyledTooltip = ({ payload, label }) => {
  if (!isEmpty(payload)) {
    return (
      <div className={styles['tooltip-wrapper']}>
        <div>Дата/Время</div>
        <div>{label}</div>
        <div>Показания</div>
        <div>{payload[0]?.value}</div>
      </div>
    );
  }
};

const StyledXAxis = ({ x, y, payload }) => {
  const renderSpans = () => {
    const dateTime = payload.value.split('\n');
    const isDateAndTime = dateTime.length === 2;

    if (isDateAndTime) {
      const date = dateTime[0];
      const time = dateTime[1];

      return (
        <>
          <tspan x={x} y={y - 15}>
            {date}
          </tspan>

          <tspan x={x} y={y + 44}>
            {time}
          </tspan>
        </>
      );
    }

    if (!isDateAndTime) {
      return (
        <tspan x={x} y={y}>
          {payload.value}
        </tspan>
      );
    }
  };

  return (
    <text dy={44} textAnchor="middle" fill="#fff">
      {renderSpans()}
    </text>
  );
};

export const DevicesChart = () => {
  const { deviceId } = useParams();
  const { getSensorsList, sensorsList, getSensorValues, sensorValues } = useStateContext();

  const { control, handleSubmit, watch } = useForm({ mode: 'all' });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    getSensorsList(deviceId);
  }, []);

  useEffect(() => {
    validate90DaysPeriod();
  }, [fromDate, toDate]);

  const validate90DaysPeriod = () => {
    const unixFromDate = (fromDate?.getTime() / 1000).toFixed(0);
    const unixToDate = (toDate?.getTime() / 1000).toFixed(0);
    const unix90Days = 7889229;

    if (unixToDate - unixFromDate > unix90Days) {
      const correctToDateAsDate = new Date((+unixFromDate + +unix90Days) * 1000);
      setToDate(correctToDateAsDate);
    }
  };

  const handleFilterApply = () => {
    const sensorID = watch('sensorID');
    const datesParams = { dateFrom: fromDate?.toISOString(), dateTo: toDate?.toISOString() };

    getSensorValues(sensorID, datesParams);
  };

  const getSensorsOptions = () => {
    return sensorsList.map(sensor => {
      return { name: sensor.sensor, value: sensor.id };
    });
  };

  const renderBackArrow = () => {
    return (
      <NavLink className={styles['back-arrow-wrapper']} to={'/devices'}>
        <BackArrow /> Назад
      </NavLink>
    );
  };

  const renderFilterPanel = () => {
    return (
      <form onSubmit={handleSubmit(handleFilterApply)}>
        <div className={styles['filter-panel-wrapper']}>
          <SelectForm
            name={'sensorID'}
            control={control}
            placeholder={'Название датчика'}
            options={getSensorsOptions()}
          />

          <DatePicker dateFormat={'dd.MM.yyyy'} label={'С'} selected={fromDate} onChange={date => setFromDate(date)} />
          <DatePicker dateFormat={'dd.MM.yyyy'} label={'По'} selected={toDate} onChange={date => setToDate(date)} />

          <Button className={styles['apply-button']} type="submit">
            Применить
          </Button>
        </div>
      </form>
    );
  };

  const renderChart = () => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={sensorValues} margin={{ top: 69 }}>
          <Brush dataKey="createdAt" stroke="#8884d8" y={0}>
            <AreaChart data={sensorValues}>
              <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
          </Brush>

          <XAxis dataKey="createdAt" height={50} width={50} tick={<StyledXAxis />} />
          <YAxis dataKey="value" style={{ fill: '#fff' }} />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" />
          <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" />
          <Tooltip content={<StyledTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <>
      {renderBackArrow()}
      {renderFilterPanel()}
      {renderChart()}
    </>
  );
};

StyledTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
};

StyledXAxis.propTypes = {
  x: PropTypes.string,
  y: PropTypes.string,
  payload: PropTypes.array,
};
