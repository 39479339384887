import PropTypes from 'prop-types';
import { useStateContext } from 'store';
import { useCallback, useEffect, useState } from 'react';
import { Loader } from 'containers/UI/Loader';
import { devicesService } from 'services';
import { DevicesSensorCard } from 'containers/Devices/Components';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from './ModalDevicesSensors.module.css';

export const ModalDevicesSensors = ({ deviceId }) => {
  const { closePopup } = useStateContext();
  const [sensors, setSensors] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSensors = useCallback(() => {
    setLoading(true);

    devicesService
      .getSensors(deviceId)
      .then(response => {
        if (response.status === 200) {
          setSensors(response.data.items);
        }
      })
      .finally(() => setLoading(false));
  }, [deviceId]);

  useEffect(() => {
    fetchSensors();
  }, [fetchSensors]);

  return (
    <div className={styles.wrapper}>
      {loading && <Loader size={50} />}

      {!loading && (
        <div className={styles.wrapper_body}>
          <DevicesSensorCard sensors={sensors} isLoading={loading} onRefresh={fetchSensors} />
        </div>
      )}

      <div className={styles.wrapper_actions}>
        <Button variant={ButtonVariantEnum.SOLID_SECONDARY} disabled={loading} onClick={closePopup}>
          Закрыть
        </Button>
      </div>
    </div>
  );
};

ModalDevicesSensors.propTypes = {
  deviceId: PropTypes.string,
  isMaster: PropTypes.bool,
};
