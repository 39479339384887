import { useForm } from 'react-hook-form';
import { Input, InputActionTypeEnum, InputParseErrors, InputTypeEnum } from '../UI/Input';
import { Button } from 'containers/UI/Button';
import { useStateContext } from 'store';
import { ReactComponent as Logo } from 'icons/logo.svg';
import { responseHandler } from 'store/helpers/responseHandler';
import { emailSchema, passwordSchema } from 'helpers/validation';
import { NavLink, useNavigate } from 'react-router-dom';
import { authService } from 'services';

import styles from './Register.module.css';

export const Register = () => {
  const { closePopup } = useStateContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const onRegister = formData => {
    authService
      .register(formData)
      .then(({ status }) => {
        if (status === 204) {
          responseHandler(true, 'Регистрация прошла успешно', closePopup, null);
          navigate('/login');
        }
      })
      .catch(e => responseHandler(false, null, closePopup, e.response.data.code));
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(onRegister)}>
        <header>
          <Logo />
        </header>
        <span>Регистрация</span>

        <div className={styles.form_fields}>
          <Input
            type={InputTypeEnum.EMAIL}
            label={'Email'}
            placeholder={'Email'}
            error={InputParseErrors(errors.email, { minLength: 5, maxLength: 30 })}
            {...register('email', emailSchema)}
          />

          <Input
            type={InputTypeEnum.PASSWORD}
            label={'Пароль'}
            placeholder={'Пароль'}
            actions={[InputActionTypeEnum.EYE]}
            error={InputParseErrors(errors.password, passwordSchema)}
            {...register('password', passwordSchema)}
          />

          <Input
            type={InputTypeEnum.NUMBER}
            label={'Номер лицензии'}
            placeholder={'Номер лицензии'}
            {...register('licenseNumber', { required: true })}
          />
        </div>

        <Button disabled={!isValid} type="submit">
          Зарегистрироваться
        </Button>

        <NavLink to={'/'} className={styles['login-page-link']}>
          Авторизоваться
        </NavLink>
      </form>
    </div>
  );
};
