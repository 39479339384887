import { useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useStateContext } from 'store';
import { presentationsService } from 'services';
import { showSuccessNotify } from 'helpers/showNotify';
import { useGroups } from 'containers/Groups/hooks/useGroups';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalGroupsManagement } from 'containers/UI/Modal/Components/Groups';
import { ModalSend } from 'containers/UI/Modal/Components/Common/ModalSend';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import {
  ModalPresentationsAdd,
  ModalPresentationsEdit,
  ModalPresentationsCopy,
} from 'containers/UI/Modal/Components/Presentations';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as List } from 'icons/list.svg';
import { ReactComponent as Upload } from 'icons/upload.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Copy } from 'icons/copy.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';
import styles from '../Presentations.module.css';

export const usePresentations = () => {
  const {
    openPopup,
    setPresentations,
    isPresentationsLoading,
    presentations,
    filterType,
    setFilterType,
    totalPresentationsCount,
    deletePresentation,
  } = useStateContext();

  const {
    groups,
    groupOptions,

    loadGroups,
    handleCreateGroup,
    handleUpdateGroup,
    handleDeleteGroup,
  } = useGroups(presentationsService);

  useEffect(() => {
    loadGroups();
  }, []);

  useEffect(() => {
    setFilterType(null);
  }, [groups]);

  const loadPresentations = params => {
    presentationsService.getPresentations(params).then(result => {
      if (result.status === 200) {
        setPresentations(result.data.items);
      }
    });
  };

  const handleFilterGroup = filterValue => {
    if (filterValue === 'all') {
      loadPresentations();
    } else {
      loadPresentations({ groupId: String(filterValue) });
    }
    setFilterType(filterValue);
  };

  const handleSendPresentations = ({ deviceId, contentId }) => {
    if (Array.isArray(deviceId)) {
      deviceId.forEach(id => {
        presentationsService.sendPresentation(contentId, id).then(result => {
          if (result.status === 204) {
            showSuccessNotify('Презентация была успешна отправлена');
          }
        });
      });
    } else {
      presentationsService.sendPresentation(contentId, deviceId).then(result => {
        if (result.status === 204) {
          showSuccessNotify('Презентация была успешна отправлена');
        }
      });
    }
  };

  const handleOpenGroupSettings = useCallback(() => {
    openPopup(
      () => (
        <ModalGroupsManagement
          groups={groups}
          handleCreate={handleCreateGroup}
          handleEdit={handleUpdateGroup}
          handleDelete={handleDeleteGroup}
        />
      ),
      {
        title: 'Управление группами',
      },
    );
  }, [groups]);

  const handlePresentationCreate = useCallback(() => {
    openPopup(
      () => (
        <ModalPresentationsAdd
          groupsOptions={groupOptions.filter(option => option.value !== 'all')}
          handleCreateGroup={handleCreateGroup}
        />
      ),
      {
        title: 'Создание презентации',
      },
    );
  }, [groupOptions]);

  const handlePresentationCopy = useCallback(
    presentation => {
      openPopup(
        () => (
          <ModalPresentationsCopy
            presentation={presentation}
            groupsOptions={groupOptions.filter(option => option.value !== 'all')}
            handleCreateGroup={handleCreateGroup}
          />
        ),
        { title: 'Копирование презентации' },
      );
    },
    [groupOptions],
  );

  const handlePresentationEdit = useCallback(
    presentation => {
      openPopup(
        () => (
          <ModalPresentationsEdit
            presentation={presentation}
            groupsOptions={groupOptions.filter(option => option.value !== 'all')}
            handleCreateGroup={handleCreateGroup}
          />
        ),
        {
          title: 'Редактирование презентации',
        },
      );
    },
    [groupOptions],
  );

  const handlePresentationDelete = useCallback(presentation => {
    openPopup(
      () => (
        <ModalDelete
          contentId={presentation.id}
          title={'Вы уверены что хотите удалить презентацию?'}
          description={'После удаления все данные по этой презентации станут недоступны, удалить?'}
          onDelete={deletePresentation}
        />
      ),
      {
        title: 'Удаление презентации',
      },
    );
  }, []);

  const handlePresentationSend = useCallback(presentation => {
    openPopup(() => <ModalSend contentId={presentation.id} onSend={handleSendPresentations} />, {
      title: 'Отправка на устройство',
    });
  }, []);

  const header = [
    { key: 'name', title: 'Название' },
    { key: 'screenCount', title: 'Количество рабочих столов' },
    { key: 'comment', title: 'Комментарий' },
    { key: 'group', title: 'Группа' },
    {
      key: 'actions',
      title: (
        <div key="presentations-head-button" className={tableStyles.rowButtons}>
          <Button
            key="presentation-create"
            className={tableStyles.headerButton}
            onClick={() => handlePresentationCreate()}
          >
            Создать презентацию
          </Button>
        </div>
      ),
    },
  ];

  const body = presentations?.map(presentation => {
    const { id, name, screenCount, comment, group = {} } = presentation || {};
    return {
      id,
      name,
      screenCount,
      comment,
      group: group?.name || null,
      actions: (
        <div key="presentation-item-buttons" className={tableStyles.rowButtons}>
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Copy /> }}
            className={styles.copyButton}
            title={'Скопировать'}
            onClick={() => handlePresentationCopy(presentation)}
          />

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Изменить'}
            onClick={() => handlePresentationEdit(presentation)}
          />

          <NavLink to={'/presentations/' + id}>
            <Button variant={ButtonVariantEnum.ICON} iconData={{ icon: <List /> }} title={'Список'} />
          </NavLink>

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Upload /> }}
            title={'Отправка на устройство'}
            className={styles.sendButton}
            onClick={() => handlePresentationSend(presentation)}
          />

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить'}
            onClick={() => handlePresentationDelete(presentation)}
          />
        </div>
      ),
    };
  });

  return {
    header,
    body,

    isPresentationsLoading,
    filterType,
    groupOptions,
    handleFilterGroup,
    handleOpenGroupSettings,
    totalPresentationsCount,
  };
};
