import { createBaseApi, configRequest } from './baseApi';

const baseApi = createBaseApi();

export const authService = { signIn, register };

function signIn(authData) {
  return baseApi.post('/auth/login', authData, configRequest);
}

function register(authData) {
  return baseApi.post('/registration', authData, configRequest);
}
