export const NAV_MENU = [
  { key: 'devices', title: 'Устройства' },
  { key: 'administrators', title: 'Администраторы' },
  {
    key: 'terminalReleases',
    title: 'Релизы терминала',
  },
  { key: 'operators', title: 'Операторы' },
  { key: 'arbiters', title: 'Судьи' },
  { key: 'files', title: 'Файлы' },
  { key: 'playlists', title: 'Плейлисты' },
  { key: 'presentations', title: 'Презентации' },
  { key: 'schedules', title: 'Расписания' },
  {
    key: 'terminalUpdates',
    title: 'Обновления терминалов',
  },
  { key: 'organisations', title: 'Организации' },
  { key: 'screenTemplates', title: 'Шаблоны рабочих столов' },
  { key: 'ledWarningAi1', title: 'Led Warning AI - 1' },
  { key: 'ledWarningAi2', title: 'Led Warning AI - 2' },
  { key: 'tableauTemplates', title: 'Шаблоны спортивного табло' },
  { key: 'sportEvents', title: 'Спортивные мероприятия' },
];

export const AccessLevels = {
  master: [
    'devices',
    'administrators',
    'terminalReleases',
    'organisations',
    'screenTemplates',
    'ledWarningAi1',
    'ledWarningAi2',
    'tableauTemplates',
  ],
  admin: [
    'devices',
    'operators',
    'arbiters',
    'files',
    'playlists',
    'presentations',
    'schedules',
    'terminalUpdates',
    'screenTemplates',
    'sportEvents',
  ],
  basic: ['files', 'playlists', 'presentations', 'schedules', 'screenTemplates'],
};

export const ROLES = {
  master: {
    ability: page => page.split('/').filter(path => AccessLevels.master.includes(path)).length,
  },
  // TODO: добавить данные для sportEventsAvailability
  admin: {
    ability: page => page.split('/').filter(path => AccessLevels.admin.includes(path)).length,
  },
  basic: {
    ability: page => page.split('/').filter(path => AccessLevels.basic.includes(path)).length,
  },
};

export const UserRoleEnum = {
  ADMIN: 'admin',
  MASTER: 'master',
  OPERATOR: 'basic',
  ARBITER: 'arbiter',
};

export const EMPTY = '-';
