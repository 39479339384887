import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { useCallback, useEffect, useState } from 'react';
import parseSeconds from 'helpers/date/parseSeconds';
import { hmsToSeconds } from 'helpers/time/hmsToSeconds';
import { TimePickerForm } from 'containers/UI/TimePicker';
import { SelectForm } from 'containers/UI/Select';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { usePlaylistsLoad } from 'containers/Hooks/playlists/usePlaylistsLoad';
import { isEmpty, lastFrom } from 'helpers/lodash';

import styles from './ModalSchedulePlaylist.module.css';

const DEFAULT_VALUES = {
  start: '00:00:00',
  end: '23:59:59',
  duration: '23:59:59',
};

export const ModalSchedulePlaylistAdd = props => {
  const { weekday, handleCreate, existingPlaylists } = props;
  const [isValidTime, setIsValidTime] = useState(true);
  const [durationState, setDurationState] = useState('00:00:00');
  const [timeAlreadyTakenMessage, setTimeAlreadyTakenMessage] = useState('');

  const { closePopup } = useStateContext();
  const { playlistsForDropDown, playlistOptions, handlePlaylistsLoad } = usePlaylistsLoad();

  const getTimeBetweenPlaylists = () => {
    const [possiblePlace] = existingPlaylists.map((playlist, index) => {
      if (existingPlaylists[index + 1]?.start - playlist.end > 1) {
        return {
          start: playlist.end + 1,
          end: existingPlaylists[index + 1].start - 1,
        };
      }
    });

    const {
      hours: startHours,
      minutes: startMinutes,
      seconds: startSeconds,
    } = parseSeconds(possiblePlace?.start, true);
    const { hours: endHours, minutes: endMinutes, seconds: endSeconds } = parseSeconds(possiblePlace?.end, true);

    return {
      start: `${startHours}:${startMinutes}:${startSeconds}`,
      end: `${endHours}:${endMinutes}:${endSeconds}`,
    };
  };

  let defaultValues;
  const initFormValues = function () {
    if (isEmpty(existingPlaylists)) {
      defaultValues = DEFAULT_VALUES;
    }

    if (!isEmpty(existingPlaylists)) {
      if (lastFrom(existingPlaylists).timeTo === DEFAULT_VALUES.end && existingPlaylists.length >= 2) {
        defaultValues = getTimeBetweenPlaylists();

        return;
      }

      const prevPlaylistEndTimeInSeconds = hmsToSeconds(lastFrom(existingPlaylists).timeTo);
      const { hours, minutes, seconds } = parseSeconds(prevPlaylistEndTimeInSeconds + 1, true);

      defaultValues = {
        start: `${hours}:${minutes}:${seconds}`,
        end: '23:59:59',
      };
    }
  };
  initFormValues();

  const { watch, control, handleSubmit, getValues, setValue } = useForm({ mode: 'onChange', defaultValues });
  const { start, end, duration } = getValues();

  const save = async form => {
    handleCreate({
      weekday,
      name: playlistsForDropDown.find(el => el.id === form.playlistId)?.name,
      start: hmsToSeconds(start),
      end: hmsToSeconds(end),
      playlistId: form.playlistId,
      timeFrom: start,
      timeTo: end,
    });
    closePopup();
  };

  const durationSetter = function () {
    useEffect(() => {
      const startSec = hmsToSeconds(start);
      const endSec = hmsToSeconds(end);
      const { hours, minutes, seconds } = parseSeconds(endSec - startSec, true);
      const duration = `${hours}:${minutes}:${seconds}`;
      const isTimeTaken = isTimeAlreadyTaken(startSec, endSec);

      setValue('duration', !duration.includes('-') ? duration : '00:00:00');
      setDurationState(`${hours}:${minutes}:${seconds}`);

      if (isTimeTaken) {
        setIsValidTime(false);
        setTimeAlreadyTakenMessage('Данное время занято другим плейлистом. Пожалуйста выберите другое');
      } else {
        setIsValidTime(true);
      }
    }, [watch('start'), watch('end')]);
  };
  durationSetter();

  const endTimeByDurationChangeSetter = function () {
    useEffect(() => {
      if (durationState !== duration && duration) {
        const startTimeInSeconds = hmsToSeconds(start);
        const durationInSeconds = hmsToSeconds(duration);
        const endTimeInSeconds = startTimeInSeconds + durationInSeconds;
        const { hours, minutes, seconds } = parseSeconds(endTimeInSeconds, true);

        setValue('end', `${hours}:${minutes}:${seconds}`);
      }
    }, [watch('duration')]);
  };
  endTimeByDurationChangeSetter();

  const isTimeAlreadyTaken = useCallback(
    (start, end) => {
      if (!isEmpty(existingPlaylists)) {
        for (const existingPlaylist of existingPlaylists) {
          if (start < existingPlaylist.start && end < existingPlaylist.end && end > existingPlaylist.start) {
            return true;
          }

          if (start < existingPlaylist.end && end > existingPlaylist.end) {
            return true;
          }
        }
      }
      setTimeAlreadyTakenMessage('');

      return false;
    },
    [existingPlaylists],
  );

  const isButtonDisabled = () => {
    return !(isValidTime && !!watch('playlistId'));
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(save)}>
      <SelectForm
        name="playlistId"
        control={control}
        placeholder={'Плейлист'}
        label={'Плейлист'}
        options={playlistOptions}
        searchable
        isInfinityLoad
        onLoad={handlePlaylistsLoad}
      />

      <TimePickerForm
        name="start"
        control={control}
        valid={isValidTime}
        label={'Время начала воспроизведения плейлиста'}
      />

      <TimePickerForm
        name="end"
        control={control}
        valid={isValidTime}
        label={'Время завершения воспроизведения плейлиста'}
      />

      <TimePickerForm name="duration" control={control} label={'Длительность воспроизведения плейлиста'} />

      {timeAlreadyTakenMessage && <div className={styles.timeIsTaken}>{timeAlreadyTakenMessage}</div>}

      <div className={styles.actions}>
        <Button type="submit" disabled={isButtonDisabled()}>
          Создать
        </Button>
        <Button variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
          Отмена
        </Button>
      </div>
    </form>
  );
};

ModalSchedulePlaylistAdd.defaultProps = {
  weekday: '',
};

ModalSchedulePlaylistAdd.propTypes = {
  weekday: PropTypes.string,
  handleCreate: PropTypes.func,
  existingPlaylists: PropTypes.array,
};
