import { useCallback, useMemo, useState } from 'react';
import { useStateContext } from 'store';

import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { Select, SelectVariantEnum } from 'containers/UI/Select';
import { ModalFiles, ModalFilesDelete, ModalFilesPreview } from 'containers/UI/Modal/Components/Files';
import { AllFileTypes } from 'helpers/constants/fileTypes';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Open } from 'icons/search.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as FilterIcon } from 'icons/filter.svg';
import { ReactComponent as ArrowIcon } from 'icons/arrows/arrow_down.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';
import styles from './Files.module.css';

export const useFiles = () => {
  const { openPopup, files, isFilesLoading, totalFilesCount } = useStateContext();
  const [fileType, setFileType] = useState(null);

  const fileTypesOptions = useMemo(() => {
    return [
      { name: 'Все файлы', value: null },
      ...AllFileTypes.map(fileType => ({
        name: fileType,
        value: fileType,
      })),
    ];
  }, []);

  const handleFilterChoose = filterValue => {
    setFileType(filterValue);
  };

  const handleFileAdd = useCallback(() => {
    openPopup(() => <ModalFiles />, {
      title: 'Создание файла',
    });
  }, []);

  const handleFileOpen = useCallback(file => {
    openPopup(() => <ModalFilesPreview file={file} />, {
      title: 'Просмотр файла',
      className: styles.modal_preview_wrapper,
      showCloseBtn: true,
    });
  }, []);

  const handleFileEdit = useCallback(file => {
    openPopup(() => <ModalFiles file={file} />, {
      title: 'Редактирование файла',
    });
  }, []);

  const handleFileDelete = useCallback(file => {
    openPopup(() => <ModalFilesDelete file={file} />, {
      title: 'Удаление файла',
    });
  }, []);

  const header = [
    { key: 'name', title: 'Название' },
    { key: 'type', title: 'Тип' },
    { key: 'comment', title: 'Комментарий' },
    {
      key: 'actions',
      title: (
        <div key="file-head-buttons" className={tableStyles.headerRowButtons}>
          <Select
            key="file-filter"
            wrapperClassName={styles.headerFilterWrapper}
            className={styles.headerFilter}
            variant={SelectVariantEnum.NOT_BORDERED}
            icon={<FilterIcon />}
            customArrowIcon={<ArrowIcon />}
            options={fileTypesOptions}
            selected={fileType}
            onChange={handleFilterChoose}
          />
          <Button key="file-add" className={tableStyles.headerButton} onClick={handleFileAdd}>
            Создать файл
          </Button>
        </div>
      ),
    },
  ];

  const getFilteredFiles = () => {
    return fileType ? files.filter(file => file.type === fileType) : files;
  };

  const body = getFilteredFiles()?.map(file => {
    const { id, name, type, comment } = file || {};
    return {
      id,
      name,
      type,
      comment,
      actions: (
        <div key="file-item-buttons" className={tableStyles.rowButtons}>
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Open /> }}
            title={'Просмотр'}
            onClick={() => handleFileOpen(file)}
          />
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Редактировать'}
            onClick={() => handleFileEdit(file)}
          />
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить'}
            onClick={() => handleFileDelete(file)}
          />
        </div>
      ),
    };
  });

  return {
    header,
    body,
    isFilesLoading,
    totalFilesCount,
  };
};
