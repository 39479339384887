import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { useEffect, useMemo } from 'react';
import { SelectForm } from 'containers/UI/Select';
import { Input, InputActionTypeEnum, InputParseErrors, InputTypeEnum } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { useState } from 'react';
import { isEmpty } from 'helpers/lodash';

import styles from './ModalTerminalReleasesAdd.module.css';

export const ModalTerminalReleasesAdd = () => {
  const { allAdminsList, closePopup, addTerminalRelease, setIsModalOpen, isModalOpen } = useStateContext();

  const triggerAllAdminsLoading = function () {
    useEffect(() => {
      if (!isModalOpen) {
        setIsModalOpen(true);
      }
    }, []);
  };
  triggerAllAdminsLoading();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const adminsOptions = useMemo(() => {
    return allAdminsList.map(user => ({
      name: user.email,
      value: user.id,
    }));
  }, [allAdminsList]);

  const save = async form => {
    const formData = {
      name: form.name,
      comment: form.comment,
      administrators: form.administrators,
      fileName: fileData?.name,
    };

    addTerminalRelease(formData, fileData, closePopup);
  };

  const [fileData, setFileData] = useState(null);

  // TODO formState: { isValid } не работает при использовании автокомплита и даже иногда и без автокомплита, несмотря на то, что объект formState: { errors } пустой.
  const isFormValid = () => {
    const administrators = watch('administrators');

    return (
      isEmpty(errors) &&
      !!administrators &&
      !isEmpty(administrators) &&
      fileData &&
      fileData?.name?.length > 1 &&
      fileData?.name?.length < 200
    );
  };

  const setFile = uploadFile => {
    if (!(uploadFile instanceof File)) return;

    setFileData(uploadFile);
    const { name } = getValues();
    setValue('name', name ? name : uploadFile.name);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <form className={styles.form} onSubmit={handleSubmit(save)}>
          <div className={styles.form_fields}>
            <Input
              label={'Название (версия)'}
              placeholder={'Название'}
              error={InputParseErrors(errors.name, { minLength: 5, maxLength: 100 })}
              {...register('name', { required: true, minLength: 5, maxLength: 100, setValueAs: v => v.trim() })}
            />

            <Input
              label={'Комментарий (релиз ноутс)'}
              placeholder={'Комментарий'}
              error={InputParseErrors(errors.comment, { minLength: 5, maxLength: 500 })}
              {...register('comment', { minLength: 5, maxLength: 500, setValueAs: v => v.trim() })}
            />

            <SelectForm
              name="administrators"
              control={control}
              placeholder={'Администраторы'}
              label={'Администраторы, имеющие доступ к релизу'}
              options={adminsOptions}
              searchable
              isMulti
              isInfinityLoad
            />

            <Input
              id={'fileInput'}
              name="fileInput"
              label={'Файл (образ релиза)'}
              placeholder={'Файл'}
              hidden
              type={InputTypeEnum.FILE}
              actions={[InputActionTypeEnum.LOAD_FILE]}
              onChange={setFile}
              allFileTypesAvailable
            />
          </div>

          <div className={styles.form_actions}>
            <Button className={styles.form_button} type="submit" disabled={!isFormValid()}>
              Создать
            </Button>
            <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
              Отмена
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
