import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Input, InputParseErrors } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from './ModalGroups.module.css';

export const ModalGroups = ({ group, handleCreate, handleUpdate, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', defaultValues: group });

  const save = form => {
    if (group) {
      handleUpdate(group.id, form);
    } else {
      handleCreate(form);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(save)}>
      <Input
        label="Название"
        placeholder="Название"
        error={InputParseErrors(errors.name, { minLength: 4, maxLength: 30 })}
        {...register('name', { required: true, minLength: 4, maxLength: 30, setValueAs: v => v.trim() })}
      />

      <div className={styles.actions}>
        <Button type="submit" disabled={!isValid}>
          {group ? 'Сохранить' : 'Создать'}
        </Button>
        <Button variant={ButtonVariantEnum.SOLID_ACCENT} onClick={handleClose}>
          Отмена
        </Button>
      </div>
    </form>
  );
};

ModalGroups.defaultProps = { group: null };

ModalGroups.propTypes = {
  group: PropTypes.object,
  handleCreate: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleClose: PropTypes.func,
};
