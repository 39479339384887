import { createBaseApi, configRequest } from './baseApi';

const baseApi = createBaseApi();

const DEFAULT_PARAMS = {
  skip: 0,
  take: 10,
};

/**
 * presentations
 */
function getPresentations(params = {}) {
  return baseApi.get('/presentations', {
    ...configRequest,
    params: { ...DEFAULT_PARAMS, ...params },
  });
}

function getPresentation(presentationId) {
  return baseApi.get(`/presentations/${presentationId}`, configRequest);
}

function addPresentation(data) {
  return baseApi.post('/presentations', data, configRequest);
}

function updatePresentation(presentationId, data) {
  return baseApi.put(`/presentations/${presentationId}`, data, configRequest);
}

function deletePresentation(presentationId) {
  return baseApi.delete(`/presentations/${presentationId}`, configRequest);
}

function sendPresentation(presentationId, deviceId) {
  return baseApi.post(`/presentations/${presentationId}/devices/${deviceId}`, configRequest);
}

/** groups */
function getGroups() {
  return baseApi.get('/presentations/groups', configRequest);
}

function addGroup(data) {
  return baseApi.post('/presentations/groups', data, configRequest);
}

function updateGroup(groupId, data) {
  return baseApi.put(`/presentations/groups/${groupId}`, data, configRequest);
}

function deleteGroup(groupId) {
  return baseApi.delete(`/presentations/groups/${groupId}`, configRequest);
}

/** screens */
function getScreens(presentationsId) {
  return baseApi.get(`/presentations/${presentationsId}/screens`, configRequest);
}

function addScreens(data, presentationsId) {
  return baseApi.post(`/presentations/${presentationsId}/screens`, data, configRequest);
}

export const presentationsService = {
  getPresentation,
  getPresentations,
  addPresentation,
  updatePresentation,
  deletePresentation,
  sendPresentation,

  getScreens,
  addScreens,

  getGroups,
  addGroup,
  updateGroup,
  deleteGroup,
};
