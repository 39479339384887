import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TableRow, TableEmptyContent } from './Components';
import { Loader } from '../Loader';
import { Paginator } from '../Paginator/Paginator';

import styles from './Table.module.css';

export const Table = ({
  className = '',
  heading = [],
  body = [],
  link = '',
  idKey = '',
  emptyText,
  isLoading = false,
  totalEntitiesCount = 0,
}) => {
  return (
    <div className={styles.wrapper}>
      <table className={classnames(styles.table, { [className]: !!className })}>
        <thead>
          <tr>
            {heading.map(({ key, title }) => (
              <th key={'th-' + key}>{title}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {body.map(row => (
            <TableRow key={'tr-' + row[idKey]} heading={heading} row={row} link={link} idKey={idKey} />
          ))}
        </tbody>
      </table>

      {isLoading && <Loader />}

      {body.length === 0 && !isLoading && <TableEmptyContent title={emptyText} />}

      {totalEntitiesCount !== 0 && <Paginator totalEntitiesCount={totalEntitiesCount} />}
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.array,
  body: PropTypes.array,
  link: PropTypes.string,
  idKey: PropTypes.string,
  emptyText: PropTypes.string,
  isLoading: PropTypes.bool,
  totalEntitiesCount: PropTypes.number,
};
