import { useEffect, useMemo, useState } from 'react';
import { administratorsService } from 'services';

export function useAdminsLoad() {
  const [admins, setAdmins] = useState([]);
  const [adminsCount, setAdminsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const adminsOptions = useMemo(() => {
    return admins.map(user => ({
      name: user.email,
      value: user.id,
    }));
  }, [admins]);

  useEffect(() => {
    setIsLoading(true);

    administratorsService
      .getAll({ skip: 0, take: 10 })
      .then(response => {
        if (response.status === 200) {
          Array.isArray(response.data?.items) && setAdmins(response.data.items);
          setAdminsCount(response.data.count);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleAdminsLoad = () => {
    if (admins.length < adminsCount) {
      administratorsService
        .getAll({ skip: admins.length, take: 10 })
        .then(response => {
          if (response.status === 200) {
            if (Array.isArray(response.data?.items)) {
              setAdmins(prev => [...prev, ...response.data.items]);
              setAdminsCount(response.data.count);
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return {
    isLoading,
    setIsLoading,
    admins,
    adminsCount,
    adminsOptions,
    handleAdminsLoad,
  };
}
