import { configRequest, createBaseApi } from './baseApi';
import { getServiceMethods } from './helpers/getServiceMethods';

const baseApi = createBaseApi();

const getScreens = templateId => {
  return baseApi.get(`/scoreboardTemplates/${templateId}/screens`, configRequest);
};

const addScreens = (data, templateId) => {
  return baseApi.post(`/scoreboardTemplates/${templateId}/screens`, data, configRequest);
};

export const tableauTemplatesService = {
  ...getServiceMethods('scoreboardTemplates'),
  getScreens,
  addScreens,
};
