import { Table } from 'containers/UI/Table';
import { useScreenTemplates } from './useScreenTemplates';

const ScreenTemplates = () => {
  const { header, body, isScreenTemplatesLoading, totalScreenTemplatesCount } = useScreenTemplates();

  return (
    <Table
      heading={header}
      body={body}
      isLoading={isScreenTemplatesLoading}
      totalEntitiesCount={totalScreenTemplatesCount}
      idKey="screenTemplate"
      emptyText={'Вы еще не добавили ни одного шаблона'}
    />
  );
};

export default ScreenTemplates;
