const getConvertedDatesTimesValues = unconvertedData => {
  return unconvertedData.map(dateTimeValue => {
    const date = new Date(dateTimeValue.createdAt).toLocaleDateString();

    const convertedTime = new Date(dateTimeValue.createdAt).toLocaleTimeString();
    const convertedDate = date.split('/').join('.');

    return { date: convertedDate, time: convertedTime, value: dateTimeValue.value };
  });
};

export const getPreparedDataForGraphic = unconvertedData => {
  const convertedDatesTimesValues = getConvertedDatesTimesValues(unconvertedData);

  return convertedDatesTimesValues.reduce((accumulator, { date, time, value }) => {
    let dateTime = `${date}\n${time}`;

    for (let item of accumulator) {
      if (item.createdAt.includes(date)) {
        dateTime = time;
      }
    }
    accumulator.push({ createdAt: dateTime, value });

    return accumulator;
  }, []);
};
