const TOTAL_SECONDS = 86400;
const LOCAL_CHANGES_LENGTH = 11;

const TIME_INTERVALS = [
  { type: '60', text: '1 минута' },
  { type: '300', text: '5 минут' },
  { type: '900', text: '15 минут' },
  { type: '1800', text: '30 минут' },
  { type: '3600', text: '1 час' },
];

export { TOTAL_SECONDS, LOCAL_CHANGES_LENGTH, TIME_INTERVALS };
