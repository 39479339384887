import { Routes, Route } from 'react-router-dom';
import AbilityWrapper from 'helpers/abilityRoute';
import Devices from 'containers/Devices/Devices';
import DevicesLogs from 'containers/DevicesLogs/DevicesLogs';
import Administrators from 'containers/Administrators/Administrators';
import Error from 'containers/Error/Error';
import DevicesMap from 'containers/DevicesMap/DevicesMap';
import Playlists from 'containers/Playlists/Playlists';
import Files from 'containers/Files/Files';
import Operators from 'containers/Operators/Operators';
import Presentations from 'containers/Presentations/Presentations';
import TerminalReleases from 'containers/TerminalReleases/TerminalReleases';
import Desktops from 'containers/Desktops/Desktops';
import Schedules from 'containers/Schedules/Schedules';
import Schedule from 'containers/Schedule/Schedule';
import Organisations from 'containers/Organisations/Organisations';
import ScreenTemplates from 'containers/ScreenTemplates/ScreenTemplates';
import { TerminalUpdates } from 'containers/TerminalUpdates/TerminalUpdates';
import { getDefaultRoute } from './helpers/getDefaultRoute';
import { DevicesChart } from '../../DevicesChart/DevicesChart';
import { LedWarningAi1 } from '../../LedWarnings/LedWarningAI _1/LedWarningAi1';
import { LedWarningAi2 } from '../../LedWarnings/LedWarningAI_2/LedWarningAi2';
import { TableauTemplates } from 'containers/TableauTemplates/TableauTemplates';
import { SportEvents } from 'containers/SportEvents/SportEvents';
import Arbiters from 'containers/Arbiters/Arbiters';

import styles from './Body.module.css';

const Body = () => {
  return (
    <div className={styles['wrapper']}>
      <Routes>
        <Route path="/" element={getDefaultRoute()} />
        <Route element={<AbilityWrapper />}>
          <Route path="/devices" element={<Devices />} />
          <Route path="/devices/map" element={<DevicesMap />} />
          <Route path="/devices/:deviceId/logs" element={<DevicesLogs />} />
          <Route path="/devices/:deviceId/chart" element={<DevicesChart />} />
          <Route path="/administrators" element={<Administrators />} />
          <Route path="/playlists" element={<Playlists />} />
          <Route path="/playlists/:itemId" element={<Desktops />} />
          <Route path="/files" element={<Files />} />
          <Route path="/operators" element={<Operators />} />
          <Route path="/presentations" element={<Presentations />} />
          <Route path="/presentations/:itemId" element={<Desktops />} />
          <Route path="/terminalReleases" element={<TerminalReleases />} />
          <Route path="/schedules" element={<Schedules />} />
          <Route path="/schedules/:schedulesId" element={<Schedule />} />
          <Route path="/organisations" element={<Organisations />} />
          <Route path="/terminalUpdates" element={<TerminalUpdates />} />
          <Route path="/screenTemplates" element={<ScreenTemplates />} />
          <Route path="/screenTemplates/:itemId" element={<Desktops />} />
          <Route path="/ledWarningAi1" element={<LedWarningAi1 />} />
          <Route path="/ledWarningAi2" element={<LedWarningAi2 />} />
          <Route path="/tableauTemplates" element={<TableauTemplates />} />
          <Route path="/tableauTemplates/:itemId" element={<Desktops />} />
          <Route path="/sportEvents" element={<SportEvents />} />
          <Route path="/sportEvents/:itemId" element={<Desktops />} />
          <Route path="/arbiters" element={<Arbiters />} />
        </Route>

        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
};

export default Body;
