import { Table } from 'containers/UI/Table';
import HeaderFilterGroups from 'containers/App/HeaderFilterGroups/HeaderFilterGroups';
import { usePresentations } from './Hooks/usePresentations';

const Presentations = () => {
  const {
    header,
    body,
    isPresentationsLoading,
    filterType,
    groupOptions,
    handleFilterGroup,
    handleOpenGroupSettings,
    totalPresentationsCount,
  } = usePresentations();

  return (
    <>
      <HeaderFilterGroups
        filterType={filterType}
        groupOptions={groupOptions}
        handleFilterGroup={handleFilterGroup}
        handleOpenGroupSettings={handleOpenGroupSettings}
      />

      <Table
        isLoading={isPresentationsLoading}
        heading={header}
        body={body}
        totalEntitiesCount={totalPresentationsCount}
        link="id"
        idKey="id"
        emptyText={'Вы еще не добавили ни одной презентации'}
      />
    </>
  );
};

export default Presentations;
