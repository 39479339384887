import { Table } from 'containers/UI/Table';
import { useTerminalReleases } from './Hooks/useTerminalReleases';

const TerminalReleases = () => {
  const { header, body, isTerminalReleasesLoading, totalTerminalReleasesCount } = useTerminalReleases();

  return (
    <Table
      heading={header}
      body={body}
      idKey="terminalReleaseId"
      isLoading={isTerminalReleasesLoading}
      totalEntitiesCount={totalTerminalReleasesCount}
      emptyText={'Вы еще не добавили ни одного релиза'}
    />
  );
};

TerminalReleases.displayName = 'TerminalReleases';

export default TerminalReleases;
