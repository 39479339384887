import { getServiceMethods } from './helpers/getServiceMethods';
import { configRequest, createBaseApi } from './baseApi';

const baseApi = createBaseApi();

const getScreens = screenTemplateId => {
  return baseApi.get(`/screenTemplates/${screenTemplateId}/widgets`, configRequest);
};
const getScreenData = screenTemplateId => {
  return baseApi.get(`/screenTemplates/${screenTemplateId}`, configRequest);
};

const addWidgets = (screenTemplateId, updateData) => {
  return baseApi.post(`/screenTemplates/${screenTemplateId}/widgets`, updateData, configRequest);
};

const getAllowedScreenTemplatesWidgets = screenTemplateId => {
  return baseApi.get(`/screenTemplates/${screenTemplateId}/widgets`, configRequest);
};

const updateAllowedScreenTemplatesWidgets = (screenTemplateId, updateData) => {
  return baseApi.post(`/screenTemplates/${screenTemplateId}/widgets`, updateData, configRequest);
};

/**
 *
 * @type {{updateAllowedScreenTemplatesWidgets: (function(*, *): *), getAll: (function(*): *), patchById: (function(*, *): *), getById: (function(*): *), getAllowedScreenTemplatesWidgets: (function(*): *), deleteById: (function(*): *), put: (function(*, *): *), createNew: (function(*): *), getScreens: (function(*): *), addWidgets: (function(*, *): *), getScreenData: (function(screenTemplateId):Promise {
  
 })}}
 */
export const screenTemplatesService = {
  ...getServiceMethods('screenTemplates'),
  getScreens,
  addWidgets,
  getAllowedScreenTemplatesWidgets,
  updateAllowedScreenTemplatesWidgets,
  getScreenData,
};
