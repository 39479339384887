import { getServiceMethods } from './helpers/getServiceMethods';
import { configRequest, createBaseApi } from './baseApi';

const baseApi = createBaseApi();

const changePassword = (operatorId, password) => {
  return baseApi.post(`/operators/${operatorId}/password`, { password }, configRequest);
};
/**
 * @type {{getAll: (function(*): *), patchById: (function(*, *): *), getById: (function(*): *), deleteById: (function(*): *), put: (function(*, *): *), createNew: (function(*): *), changePassword: (function(*, *): *)}}
 */
export const operatorsService = { ...getServiceMethods('operators'), changePassword };
