import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useStateContext } from 'store';

import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import styles from './ModalGroupsManagement.module.css';

export const ModalGroupsManagement = ({ groups, handleCreate, handleEdit, handleDelete }) => {
  const { closePopup } = useStateContext();

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_header}>
        <Button className={styles.headerButton} onClick={handleCreate}>
          Добавить новую группу
        </Button>
      </div>
      <div className={styles.wrapper_list_title}>Группы</div>
      <div className={styles.wrapper_list}>
        {groups.length === 0 && (
          <div className={classnames(styles.wrapper_list_item, styles.wrapper_list_item_empty)}>
            Вы еще не добавили ни одной группы
          </div>
        )}
        {groups.map(group => (
          <div key={group.id} className={styles.wrapper_list_item}>
            <div>{group.name}</div>
            <div className={styles.wrapper_list_item_actions}>
              <Button
                variant={ButtonVariantEnum.ICON}
                iconData={{ icon: <Edit /> }}
                title={'Изменить'}
                onClick={() => handleEdit(group)}
              />
              <Button
                variant={ButtonVariantEnum.ICON}
                iconData={{ icon: <Delete /> }}
                title={'Удалить'}
                onClick={() => handleDelete(group)}
              />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.actions}>
        <Button variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
          Закрыть
        </Button>
      </div>
    </div>
  );
};

ModalGroupsManagement.defaultProps = { groups: [] };

ModalGroupsManagement.propTypes = {
  groups: PropTypes.array,
  handleCreate: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};
