import { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { Routes, Route } from 'react-router-dom';
import MainLayout from 'containers/App/MainLayout/MainLayout';
import Login from 'containers/Login/Login';
import Modal from 'containers/UI/Modal/Modal';
import { StateProvider } from 'store';
import PrivateWrapper from 'helpers/privateRoute';
import { Register } from 'containers/Register/Register';

class App extends Component {
  render() {
    return (
      <StateProvider>
        <Routes>
          <Route element={<PrivateWrapper />}>
            <Route path="*" element={<MainLayout />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
        <Modal />
        <ToastContainer />
        <div id="map-root" />
      </StateProvider>
    );
  }
}

export default App;
