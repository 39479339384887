//TODO: delete
export const MOCK_FILE_RELATIONS_DATA = {
  playlists: [
    {
      id: '123123',
      name: 'Плейлист 1',
      screens: [
        {
          id: '844fb4b0c17aa5321bf84b3c',
          name: 'Screen 1',
        },
      ],
    },
  ],
  presentations: [
    {
      id: '644fb4b0c17aa5321bf84b3c',
      name: 'Презентация 1',
      screens: [
        {
          id: '744fb4b0c17aa5321bf84b3c',
          name: 'Screen 2',
        },
      ],
    },
  ],
};
