import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from '../../../helpers/lodash';

const ZOOM = 13;

export const useDevicesMap = mapRef => {
  const { latitude, longitude } = { latitude: 0, longitude: 0 };
  const center = [latitude, longitude];

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [fullscreenTextButton, setFullscreenTextButton] = useState('На весь экран');

  const fullscreenChange = () => {
    setIsFullscreen(prev => !prev);
    setFullscreenTextButton(!isFullscreen ? 'Свернуть' : 'На весь экран');
  };

  const onCenter = useCallback(() => {
    mapRef.current.locate();
  }, [mapRef]);

  const onCenterMarkers = useCallback(
    markers => {
      const _markers = markers
        .filter(x => !isEmpty(x.position) && x.position)
        .map(device => {
          const { latitude, longitude } = device.position;
          return { lat: latitude, lng: longitude, device };
        });

      mapRef.current.fitBounds(_markers);
    },
    [mapRef],
  );

  const toggleFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }, []);

  const handleOpenAll = () => {
    window.dispatchEvent(new CustomEvent('map:openPopupAll', { detail: mapRef.current.getBounds() }));
  };

  const handleCloseAll = () => {
    window.dispatchEvent(new CustomEvent('map:closePopupAll', { detail: mapRef.current.getBounds() }));
  };

  useEffect(() => {
    window.addEventListener('fullscreenchange', fullscreenChange);
    return () => {
      window.removeEventListener('fullscreenchange', fullscreenChange);
    };
  });

  return {
    zoom: ZOOM,
    center,
    isFullscreen,
    fullscreenTextButton,

    onCenter,
    onCenterMarkers,
    toggleFullscreen,
    handleOpenAll,
    handleCloseAll,
  };
};
