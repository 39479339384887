import PropTypes from 'prop-types';

import styles from './TextArea.module.css';

export const TextArea = ({
  className = '',
  label = '',
  placeholder = '',
  error = false,
  onChange = () => {},
  disabled = false,
  rows = 3,
  register = {},
}) => {
  const classes = [styles['wrapper']];
  if (error) classes.push(styles['error']);
  if (className) classes.push(className);

  return (
    <div className={classes.join(' ')}>
      <label>{label}</label>
      <textarea
        className={styles['input']}
        placeholder={placeholder || label}
        onChange={onChange}
        disabled={disabled}
        rows={rows}
        {...register}
      />
      {!!error.message && <span>{error.message}</span>}
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  rows: PropTypes.number,
  register: PropTypes.object,
};
