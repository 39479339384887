import { useFormContext } from 'react-hook-form';
import { useDraggable } from '@dnd-kit/core';
import { ResizableBox } from 'react-resizable';
import PropTypes from 'prop-types';
import { getWidgetPositionParamsAfterMouseResolve, getGroupSides, isIntersection } from '../../helpers';
import { ResizeHandler } from '../ResizeHandler/ResizeHandler';
import { useEffect, useState } from 'react';
import { RESIZE_HANDLES, getWidgetNewPositionsAndSizes } from '../helpers';

import styles from './Group.module.css';

export const Group = ({ creatingNewGroupMode = false, widgetGroup }) => {
  const {
    indexSelect,
    watch,
    selectedWidgets,
    sizeCoeff,
    setCurrentChosenGroup,
    currentChosenGroup,
    screensUpdate,
    currentGroupWithIntersection,
    setCurrentGroupWithIntersection,
  } = useFormContext();
  const screens = watch('screens');
  const widgets = screens[indexSelect]?.widgets || [];
  const [disabledDrag, setDisabledDrag] = useState(false);

  const getWidgets = () => {
    if (creatingNewGroupMode) {
      return widgets.filter((item, index) => selectedWidgets[index]) || [];
    }

    if (!creatingNewGroupMode) {
      return widgetGroup.widgets;
    }
  };

  if (creatingNewGroupMode && selectedWidgets.length >= 2) {
    setCurrentChosenGroup(null);
  }

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: widgetGroup?.canvasGroupId || 'group',
    disabled: disabledDrag,
  });
  const { top, left, right, bottom } = creatingNewGroupMode ? getGroupSides(getWidgets()) : widgetGroup.coordinates;

  const getWidgetsInitialSizes = () => {
    return widgets.reduce((accumulator, widget) => {
      accumulator[widget.id] = { width: widget.width, height: widget.height, top: widget.top, left: widget.left };
      return accumulator;
    }, {});
  };
  const [widgetsInitialSizes, setWidgetsInitialSizes] = useState(getWidgetsInitialSizes());
  const [groupInitialSize, setGroupInitialSize] = useState({ width: right - left, height: bottom - top });
  const { clientWidth, clientHeight } = document.getElementById('editor-droppable') || {};

  const onResize = (event, { size: currentGroupSize, handle } = {}) => {
    if (!currentGroupSize) {
      return;
    }

    const isSavedGroup = !!widgetGroup;
    const screen = screens[indexSelect];

    screensUpdate(indexSelect, {
      ...screen,
      widgets: screen.widgets.map((widget, index) => {
        if (isSavedGroup) {
          if (widget.canvasGroupId === widgetGroup.canvasGroupId) {
            return getWidgetNewPositionsAndSizes({
              handle,
              widget,
              widgetsInitialSizes,
              groupInitialSize,
              currentGroupSize,
              clientWidth,
              clientHeight,
            });
          }
          return widget;
        }

        if (creatingNewGroupMode) {
          if (selectedWidgets[index]) {
            return getWidgetNewPositionsAndSizes({
              handle,
              widget,
              widgetsInitialSizes,
              groupInitialSize,
              currentGroupSize,
              clientWidth,
              clientHeight,
            });
          }

          return widget;
        }
      }),
    });
  };

  const onResizeStop = () => {
    setWidgetsInitialSizes(getWidgetsInitialSizes());
    setGroupInitialSize({ width: right - left, height: bottom - top });
  };

  const getBorderStyle = () => {
    if (!creatingNewGroupMode && currentGroupWithIntersection === widgetGroup?.canvasGroupId) {
      return '1px solid red';
    }

    return `1px solid ${
      widgetGroup?.canvasGroupId && widgetGroup?.canvasGroupId === currentChosenGroup ? 'green' : '#1ba1e2'
    }`;
  };

  const style = {
    ...getWidgetPositionParamsAfterMouseResolve(top, left, sizeCoeff),
    width: `${right - left}px`,
    height: `${bottom - top}px`,
    border: getBorderStyle(),
  };

  useEffect(() => {
    if (!creatingNewGroupMode) {
      const isGroupWithIntersection = isIntersection({ top, left, right, bottom }, widgets, currentChosenGroup);

      if (!isGroupWithIntersection) {
        setCurrentGroupWithIntersection(null);
        return;
      }

      if (isGroupWithIntersection) {
        setCurrentGroupWithIntersection(widgetGroup?.canvasGroupId);
      }
    }
  });

  return (
    <div ref={setNodeRef} style={{ ...style }} className={styles['wrapper']} {...listeners} {...attributes}>
      {(widgetGroup || creatingNewGroupMode) && (
        <ResizableBox
          width={right - left}
          height={bottom - top}
          handleSize={[10, 10]}
          handle={
            <ResizeHandler
              active={creatingNewGroupMode || widgetGroup.canvasGroupId === currentChosenGroup}
              setDisabledDrag={setDisabledDrag}
              disabled={!widgetGroup && !creatingNewGroupMode}
            />
          }
          resizeHandles={RESIZE_HANDLES}
          onResize={onResize}
          onResizeStop={onResizeStop}
          onMouseUp={onResize}
          disabled={!widgetGroup && !creatingNewGroupMode}
        >
          <div style={{ width: right - left, height: bottom - top }} />
        </ResizableBox>
      )}
    </div>
  );
};

Group.defaultProps = {
  top: 0,
  left: 0,
  creatingNewGroupMode: false,
};
Group.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  creatingNewGroupMode: PropTypes.bool,
  widgetGroup: PropTypes.object,
};
