import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import Map from 'containers/Map/Map';
import { Checkbox } from 'containers/UI/Checkbox';
import { SelectForm } from 'containers/UI/Select';
import { Input, InputTypeEnum, InputParseErrors } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { useAdminsLoad } from 'containers/Hooks/admins/useAdminsLoad';
import { ReactComponent as PinIcon } from 'icons/map/pin.svg';

import styles from './ModalDevicesAdd.module.css';

export const ModalDevicesAdd = () => {
  const [isShowChangePosition, setIsShowChangePosition] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const { closePopup, addDevice } = useStateContext();

  const { isLoading, setIsLoading, adminsOptions, handleAdminsLoad } = useAdminsLoad();

  const save = async form => {
    const [lat, lng] = form.location.split(',');

    const formData = {
      name: form.name,
      comment: form.comment,
      screenWidth: +form.screenWidth,
      screenHeight: +form.screenHeight,
      position: {
        latitude: +lat,
        longitude: +lng,
        address: '',
      },
      config: {
        hasCamera: !!form.hasCamera,
        externalEquipment: !!form.externalEquipment,
        hasExternalSensors: false,
      },
      administratorId: form.administratorId,
      sensorsPollingFrequency: +form.sensorsPollingFrequency,
    };

    setIsLoading(true);
    await addDevice(formData, closePopup);
    setIsLoading(false);
  };

  const handleOpenChangeLocation = () => {
    setIsShowChangePosition(true);
  };

  const handleSaveMap = value => {
    setValue('location', value);
    setIsShowChangePosition(false);
  };

  const handleCloseMap = () => setIsShowChangePosition(false);

  return (
    <>
      <div className={styles.wrapper}>
        <form className={styles.form} onSubmit={handleSubmit(save)}>
          <div className={styles.form_fields}>
            <SelectForm
              name="administratorId"
              control={control}
              placeholder={'Администратор'}
              label={'Администратор'}
              options={adminsOptions}
              searchable
              isInfinityLoad
              onLoad={handleAdminsLoad}
            />

            <Input
              label={'Название устройства'}
              placeholder={'Название'}
              error={InputParseErrors(errors.name, { minLength: 5, maxLength: 30 })}
              {...register('name', { required: true, minLength: 5, maxLength: 30, setValueAs: v => v.trim() })}
            />

            <Input
              label={'Комментарий'}
              placeholder={'Комментарий'}
              error={InputParseErrors(errors.comment)}
              {...register('comment', { minLength: 5, maxLength: 300, setValueAs: v => v.trim() })}
            />

            <div className={styles.form_sizes}>
              <Input
                label={'Ширина'}
                type={InputTypeEnum.NUMBER}
                placeholder={'Ширина'}
                error={InputParseErrors(errors.screenWidth, { min: 100, max: 10000 })}
                {...register('screenWidth', { required: true, min: 100, max: 10000 })}
              />

              <Input
                label={'Высота'}
                type={InputTypeEnum.NUMBER}
                placeholder={'Высота'}
                error={InputParseErrors(errors.screenHeight, { min: 100, max: 10000 })}
                {...register('screenHeight', { required: true, min: 100, max: 10000 })}
              />

              <Input
                label={'Частота опроса датчиков'}
                type={InputTypeEnum.NUMBER}
                defaultValue={5}
                placeholder={'Частота опроса датчиков'}
                error={InputParseErrors(errors.sensorsPollingFrequency, { min: 5, max: 60 })}
                {...register('sensorsPollingFrequency', { required: true, min: 5, max: 60 })}
              />
            </div>

            <Input
              label={'Местоположение'}
              placeholder={'Местоположение'}
              error={InputParseErrors(errors.location)}
              {...register('location', { required: true })}
            >
              <PinIcon className={styles.inputIconLocation} onClick={handleOpenChangeLocation} />
            </Input>

            <Checkbox key="hasCamera" label={'Наличие камеры'} name="hasCamera" control={control} />

            <Checkbox
              key="externalEquipment"
              name="externalEquipment"
              label={'Внешние датчики (TCP)'}
              disabled={isLoading}
              control={control}
            />
          </div>

          <div className={styles.form_actions}>
            <Button className={styles.form_button} type="submit" disabled={!isValid}>
              Создать
            </Button>
            <Button
              className={styles.form_button}
              variant={ButtonVariantEnum.SOLID_ACCENT}
              disabled={isLoading}
              onClick={closePopup}
            >
              Закрыть
            </Button>
          </div>
        </form>
      </div>

      {isShowChangePosition &&
        createPortal(
          <Map location={getValues().location} isOnline onSave={handleSaveMap} onClose={handleCloseMap} />,
          document.getElementById('map-root'),
        )}
    </>
  );
};
