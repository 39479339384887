import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStateContext } from 'store';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import { ModalHtmlCode } from 'containers/UI/Modal/Components/Desktops/ModalHtmlCode/ModalHtmlCode';

export const useDesktopEditor = () => {
  const { openPopup } = useStateContext();
  const { watch, indexSelect, screensMove } = useFormContext();
  const screens = watch('screens');

  const handleScreensDelete = useCallback((screen, method) => {
    openPopup(
      () => (
        <ModalDelete
          contentId={screen.id}
          title={`Вы уверены что хотите удалить рабочий стол ${screen.name}?`}
          isVisibleIcon={false}
          onDelete={(id, closePopup) => {
            closePopup();
            method();
          }}
        />
      ),
      {
        title: 'Удаление рабочего стола',
      },
    );
  }, []);

  const handleAudioFileDelete = useCallback(removeAudioFileMethod => {
    openPopup(
      () => (
        <ModalDelete
          contentId={screen.id}
          title={`Вы уверены что хотите удалить данный файл?`}
          isVisibleIcon={false}
          onDelete={(id, closePopup) => {
            closePopup();
            removeAudioFileMethod();
          }}
        />
      ),
      {
        title: 'Удаление аудио файла',
      },
    );
  }, []);

  const handleHtmlCode = useCallback(({ name, options, register }) => {
    openPopup(() => <ModalHtmlCode name={name} options={options} register={register} />, {
      title: 'HTML код',
    });
  }, []);

  const replace = direction => {
    const to = indexSelect + direction;
    if (to === -1) {
      //Перемещение в конец
      screensMove(indexSelect, screens?.length - 1);
    } else if (to === screens?.length) {
      //Перемещение в начало
      screensMove(indexSelect, 0);
    } else {
      screensMove(indexSelect, to);
    }
  };

  return {
    replace,
    handleScreensDelete,
    handleAudioFileDelete,
    handleHtmlCode,
  };
};
