import { useCallback, useEffect, useState } from 'react';
import { useApi } from 'services/hooks/useApi';
import { administratorsService } from 'services';
import { organisationsService } from 'services/organisations.service';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { responseHandler } from '../helpers/responseHandler';
import { URLs } from 'helpers/constants/URLs';
import { WidgetTypes, WidgetTypesOldFormat } from 'helpers/constants/widgetsConstants';

const MAX_ORGANISATIONS_COUNT_PER_REQUEST = 100;

export function useAdminsState() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [admins, setAdmins, isAdminsLoading, adminsError, setAdminsError, totalAdminsCount, setTotalAdminsCount] =
    useApi(() => administratorsService.getAll(getQueryParams(+page - 1) || {}));
  const [fullAdminData, setFullAdminData] = useState({});

  const [allOrganisationsListInAdmins, setAllOrganisationsListInAdmins] = useState([]);
  const [isModalInAdminsOpen, setIsModalInAdminsOpen] = useState(false);
  const [dynamicSkipValue, setDynamicSkipValue] = useState(0);
  const [dynamicTakeValue, setDynamicTakeValue] = useState(MAX_ORGANISATIONS_COUNT_PER_REQUEST);
  const [allOrganisationsCount, setAllOrganisationsCount] = useState(0);

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.administrators) {
        return (() => {
          administratorsService
            .getAll(getQueryParams(+page - 1 || {}))
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response.data?.items) && setAdmins(response.data.items);
              }
            })
            .catch(setAdminsError);
        })();
      }
    }, [pathname, page, totalAdminsCount]);
  };
  paginate();

  const getAllOrganisations = function () {
    useEffect(() => {
      if (
        !isModalInAdminsOpen ||
        (allOrganisationsListInAdmins.length > 0 && allOrganisationsListInAdmins.length === allOrganisationsCount)
      ) {
        return;
      }

      const queryParams = {
        skip: dynamicSkipValue,
        take: dynamicTakeValue,
      };

      organisationsService.getAll(queryParams).then(response => {
        if (response.status === 200) {
          setAllOrganisationsCount(response?.data?.count);
          setDynamicSkipValue(prevState => prevState + MAX_ORGANISATIONS_COUNT_PER_REQUEST);
          setDynamicTakeValue(allOrganisationsCount - dynamicSkipValue);
          Array.isArray(response?.data?.items) &&
            response.data.items.length > 0 &&
            setAllOrganisationsListInAdmins(prevState => [...prevState, ...response.data.items]);
        }
      });
    }, [isModalInAdminsOpen, allOrganisationsListInAdmins]);
  };
  getAllOrganisations();

  const getAdminById = useCallback(adminId => {
    administratorsService
      .getById(adminId)
      .then(response => {
        if (response.status === 200) {
          const widgets = response.data.widgets.map(widget => {
            // need convert old widgets format
            switch (widget) {
              case WidgetTypesOldFormat.Text:
                return WidgetTypes.Text;
              case WidgetTypesOldFormat.Clock:
                return WidgetTypes.Clock;
              case WidgetTypesOldFormat.Fuel:
                return WidgetTypes.Fuel;
              case WidgetTypesOldFormat.Html:
                return WidgetTypes.Html;
              case WidgetTypesOldFormat.Image:
                return WidgetTypes.Image;
              case WidgetTypesOldFormat.Playlist:
                return WidgetTypes.Playlist;
              case WidgetTypesOldFormat.RoadSign:
                return WidgetTypes.RoadSign;
              case WidgetTypesOldFormat.Timer:
                return WidgetTypes.Timer;
              case WidgetTypesOldFormat.VideoStream:
                return WidgetTypes.VideoStream;
              case WidgetTypesOldFormat.Integration:
                return WidgetTypes.Integration;
              case WidgetTypesOldFormat.YouTube:
                return WidgetTypes.YouTube;
              case WidgetTypesOldFormat.Telegram:
                return WidgetTypes.Telegram;
              case WidgetTypesOldFormat.AudioPlaylist:
                return WidgetTypes.AudioPlaylist;
              case WidgetTypesOldFormat.SiteCapture:
                return WidgetTypes.SiteCapture;
              case WidgetTypesOldFormat.Presentation:
                return WidgetTypes.Presentation;
              case WidgetTypesOldFormat.RunningText:
                return WidgetTypes.RunningText;
              case WidgetTypesOldFormat.Rss:
                return WidgetTypes.Rss;
              default:
                return widget;
            }
          });

          setFullAdminData({
            ...response?.data,
            widgets,
          });
        }
      })
      .catch(setAdminsError);
  }, []);

  const addAdmin = useCallback((admin, onModalClose) => {
    return (() => {
      administratorsService
        .createNew(admin)
        .then(response => {
          if (response.status === 200) {
            setTotalAdminsCount(prevState => prevState + 1);
            responseHandler(true, 'Администратор был успешно создан', onModalClose);
          }
        })
        .catch(e => {
          setAdminsError(e);
          responseHandler(false, 'Администратор не был создан', onModalClose);
        });
    })();
  }, []);

  const updateAdmin = useCallback((updateData, onModalClose) => {
    return (() => {
      administratorsService
        .put(updateData.id, updateData)
        .then(response => {
          if (response.status === 204) {
            setAdmins(prev => [
              ...prev.map(el => {
                if (el.id === updateData.id) return { ...el, ...updateData };
                return el;
              }),
            ]);
            responseHandler(true, 'Администратор был успешно изменён', onModalClose);
          }
        })
        .catch(error => {
          setAdminsError(error);
          responseHandler(false, 'Администратор не был изменён', onModalClose);
        });
    })();
  }, []);

  const changeAdminPassword = useCallback((updateData, onModalClose) => {
    return (() => {
      administratorsService
        .changePassword(updateData.id, updateData.password)
        .then(response => {
          if (response.status === 204) {
            setAdmins(prev => [
              ...prev.map(el => {
                if (el.id === updateData.id) return { ...el, ...updateData };
                return el;
              }),
            ]);
            responseHandler(true, 'Пароль был успешно изменен', onModalClose);
          }
        })
        .catch(error => {
          setAdminsError(error);
          responseHandler(false, 'Пароль не был изменен', onModalClose);
        });
    })();
  }, []);

  // TODO: Is this method useless?
  //  There is no remove button in the app and no routes in API
  const removeAdmin = useCallback((id, onModalClose) => {
    return (() => {
      administratorsService
        .deleteById(id)
        .then(response => {
          if (response.status === 204) {
            setAdmins(prev => prev.filter(admin => admin.id !== id));
            setTotalAdminsCount(prevState => prevState - 1);
            responseHandler(true, 'Администратор был успешно удалён', onModalClose);
          }
        })
        .catch(error => {
          setAdminsError(error);
          responseHandler(false, 'Администратор не был удалён', onModalClose, error.response.data.code);
        });
    })();
  }, []);

  const blockUnblockAdmin = useCallback((adminData, onModalClose) => {
    administratorsService
      .blockUnblock(adminData.id, !adminData.isBlocked)
      .then(response => {
        if (response.status === 204) {
          setAdmins(prevState => {
            let blockedOrUnblockedAdminOrderPosition = 0;

            const [adminToUpdate] = prevState.filter((admin, index) => {
              if (admin.id === adminData.id) {
                blockedOrUnblockedAdminOrderPosition = index;

                return true;
              }

              return false;
            });
            const adminsWithoutUpdatedAdmin = prevState.filter(admin => admin.id !== adminData.id);
            const updatedAdmin = { ...adminToUpdate, ...adminData };

            adminsWithoutUpdatedAdmin.splice(blockedOrUnblockedAdminOrderPosition, 0, updatedAdmin);

            return adminsWithoutUpdatedAdmin;
          });
          responseHandler(
            true,
            `Администратор был успешно ${!adminData.isBlocked ? 'разблокирован' : 'заблокирован'}`,
            onModalClose,
            null,
          );
        }
      })
      .catch(error => {
        setAdminsError(error);
        responseHandler(false, null, onModalClose, error.response.data.code);
      });
  }, []);

  return {
    admins,
    totalAdminsCount,

    addAdmin,
    updateAdmin,
    removeAdmin,
    blockUnblockAdmin,

    isAdminsLoading,
    adminsError,

    changeAdminPassword,
    getAdminById,
    fullAdminData,
    setFullAdminData,
    allOrganisationsListInAdmins,
    setIsModalInAdminsOpen,
    isModalInAdminsOpen,
  };
}
