import Leaflet from 'leaflet';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { useEffect, useRef, useState } from 'react';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { iconOnline, iconOffline, DevicesMapSearch } from '../DevicesMap/Components';

import 'leaflet/dist/leaflet.css';
import 'css/leaflet.css';
import styles from './Map.module.css';

Leaflet.Icon.Default.mergeOptions({ shadowUrl: markerShadow });

const Map = ({ location, isOnline, onClose, onSave }) => {
  const mapRef = useRef();

  const [lat, lng] = location ? location.split(', ') : [0, 0];

  const center = [lat, lng];

  const zoom = location ? 18 : 13;

  const [position, setPosition] = useState({ lat, lng });

  const handleSave = () => {
    const { lat, lng } = position;
    onSave(`${lat}, ${lng}`);
  };

  function DeviceMarker() {
    const map = useMapEvents({
      click: e => {
        if (e.latlng) {
          setPosition(e.latlng);
        }
      },
      locationfound(e) {
        map.panTo(e.latlng, { zoom: 13 });
      },
    });

    mapRef.current = map;

    useEffect(() => {
      if (!location && !position.lat) {
        mapRef.current.locate();
      }
    }, []);

    if (!location && !position) return null;

    return <Marker position={position} icon={isOnline ? iconOnline : iconOffline} />;
  }

  return (
    <div className={styles.deviceMapContainer}>
      <DevicesMapSearch
        className={styles.deviceMapSearchWrapper}
        mapRef={mapRef}
        withControls
        onSave={handleSave}
        onClose={onClose}
      />

      <MapContainer className={styles.deviceMapWrapper} center={center} zoom={zoom} minZoom={3} maxZoom={18}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <DeviceMarker />
      </MapContainer>
    </div>
  );
};

Map.propTypes = {
  location: PropTypes.string,
  isOnline: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default Map;
