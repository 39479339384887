import PropTypes from 'prop-types';
import { SliderPicker } from 'react-color';

import styles from './ColorPicker.module.css';

export const ColorPicker = ({ label = '', value = '#FFFFFF', onChange = () => {}, className = '' }) => {
  return (
    <div className={[styles['wrapper'], className].join(' ')}>
      <label>{label}</label>
      <SliderPicker color={value} onChangeComplete={color => onChange(color.hex)} />
    </div>
  );
};

ColorPicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
