import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useMemo, useState } from 'react';
import {
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import { AudioFileItem as FileItem } from './AudioFileItem';
import { useStateContext } from 'store';
import { Select, SelectVariantEnum } from 'containers/UI/Select';
import { ReactComponent as Add } from 'icons/add.svg';
import { generateId } from 'helpers/generateId';

import styles from '../Desktops.module.css';

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: { active: { opacity: '0.4', background: 'var(--accent-color)' } },
  }),
};

export const DesktopFormFiles = ({ screenIndex, widgetIndex }) => {
  const { files: imagesAndVideoFiles } = useStateContext();
  const userAllFiles = imagesAndVideoFiles.filter(file => file.type !== 'audio');
  const userAllAudioFilesWithUniqueId = userAllFiles.map(file => {
    return {
      ...file,
      uniqueId: generateId(),
    };
  });

  const { watch, control } = useFormContext();
  const filesStateAddress = `screens[${screenIndex}].widgets[${widgetIndex}].videoFiles`;

  const { move, remove, append } = useFieldArray({ control, name: filesStateAddress });
  const screens = watch('screens');
  const { videoFiles } = screens[screenIndex].widgets[widgetIndex];

  //DND Controls
  const [active, setActive] = useState(null);
  const activeItem = useMemo(
    () => videoFiles.find(videoFile => videoFile?.uniqueId === active?.id),
    [active, videoFiles],
  );
  const sensors = useSensors(useSensor(MouseSensor), useSensor(KeyboardSensor));

  const replace = ({ active, over }) => {
    const from = active?.data?.current?.sortable?.index;
    const to = over?.data?.current?.sortable?.index;

    if (isNaN(from) || isNaN(to)) return;

    move(from, to);
  };

  const onAudioFileAdd = fileId => {
    const [file] = userAllAudioFilesWithUniqueId.filter(file => file.id === fileId);
    const newFile = {
      id: file.id,
      resourceId: file.resourceId,
      name: file.name,
      uniqueId: file.uniqueId,
      duration: 5,
    };

    append(newFile);
  };

  const renderAddFileSelector = () => {
    return (
      <>
        <div className={styles['files-add-title']}>Добавить файл</div>

        <div className={styles['add-audio-file-selector']}>
          <div className={styles['fuel-field']}>
            <Select
              variant={SelectVariantEnum.NOT_BORDERED}
              placeholder={<Add />}
              options={userAllAudioFilesWithUniqueId.map(file => ({ name: file.name, value: file.id }))}
              onChange={onAudioFileAdd}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles['audio-files-title']}>Список файлов</div>

      <div className={styles['audio-files-wrapper']}>
        <div className={styles['audio-files-scroll-List']}>
          <DndContext
            autoScroll={false}
            sensors={sensors}
            onDragEnd={replace}
            onDragStart={({ active }) => setActive(active)}
            onDragCancel={() => setActive(null)}
            modifiers={[restrictToFirstScrollableAncestor]}
          >
            <SortableContext items={videoFiles.map(file => file?.uniqueId)}>
              {videoFiles.map((audioFile, index) => (
                <FileItem
                  key={audioFile?.uniqueId}
                  index={index}
                  name={audioFile?.name}
                  id={audioFile?.uniqueId}
                  removeAudioFileMethod={() => remove(index)}
                  filesStateAddress={filesStateAddress}
                  type="video"
                />
              ))}
            </SortableContext>

            <DragOverlay dropAnimation={dropAnimationConfig}>
              {!!activeItem && (
                <FileItem key={activeItem.uniqueId} name={activeItem.name} id={activeItem.uniqueId} type="video" />
              )}
            </DragOverlay>
          </DndContext>
        </div>
      </div>

      {renderAddFileSelector()}
    </>
  );
};

DesktopFormFiles.propTypes = {
  screenIndex: PropTypes.number,
  widgetIndex: PropTypes.number,
};
