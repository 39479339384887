import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TimePicker } from 'containers/UI/TimePicker';

export const TimePickerForm = props => {
  const { control, name, rules = { required: true }, ...attrs } = props;

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => <TimePicker {...field} {...attrs} />}
    />
  );
};

TimePickerForm.propTypes = {
  // Controller options
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  // TimePicker options
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
};
