import { useEffect, useState } from 'react';
import { LoginStatusHelper } from './loginStatusHelper';

/**
 * useApi is used for fetching data by the component's loading and returns data as state, and method for it's updating.
 * Also has an error if something goes wrong and loading indicator, which are also returned
 * @param serviceToCall is a service for interaction with API
 * For example administrators.service.js
 * @returns {[*[],((value: (((prevState: *[]) => *[]) | *[])) => void),boolean,unknown]}
 */
export const useApi = serviceToCall => {
  const [items, setItems] = useState([]);
  const [totalItemsCountInDataBase, setTotalItemsCountInDataBase] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { getUser } = LoginStatusHelper.getInstance();

  useEffect(() => {
    if (!getUser()) {
      return;
    }

    (() => {
      setIsLoading(true);
      serviceToCall()
        .then(response => {
          if (response.status === 200) {
            setTotalItemsCountInDataBase(response.data.count);
            Array.isArray(response.data?.items) && setItems(response.data.items);
          }
        })
        .catch(error => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    })();
  }, [getUser()]);

  return [items, setItems, isLoading, error, setError, totalItemsCountInDataBase, setTotalItemsCountInDataBase];
};
