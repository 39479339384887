import { Table } from 'containers/UI/Table';
import { useParams } from 'react-router-dom';
import { Select } from 'containers/UI/Select';
import { DatePicker } from 'containers/UI/DatePicker';

import styles from './DevicesLogs.module.css';
import { useDevicesLogs } from './Hooks/useDevicesLogs';

const DevicesLogs = () => {
  const { deviceId } = useParams();

  const {
    heading,
    tableBodyLogsList,
    loading,
    logsCount,
    typeOptions,
    filterTypes,
    handleFilterType,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
  } = useDevicesLogs(deviceId);

  return (
    <>
      <div className={styles.headerButtons}>
        <Select
          wrapperClassName={styles.headerFilterWrapper}
          className={styles.headerFilter}
          options={typeOptions}
          selected={filterTypes}
          placeholder={'Тип события'}
          onChange={handleFilterType}
          isMulti
          searchable
        />
        <DatePicker label={'От'} selected={fromDate} onChange={date => setFromDate(date)} />
        <DatePicker label={'До'} selected={toDate} onChange={date => setToDate(date)} />
      </div>

      <Table
        className={styles.devicesLogsTable}
        heading={heading}
        body={tableBodyLogsList}
        isLoading={loading}
        totalEntitiesCount={logsCount}
        idKey="createdAt"
        emptyText={'Нет логов'}
      />
    </>
  );
};

DevicesLogs.displayName = 'DevicesLogs';

export default DevicesLogs;
