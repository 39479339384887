import { correctFormatTime } from '../time/correctFormatTime';

/**
 * @param seconds
 * @param withNull
 * @returns {{hours: number, seconds: number, minutes: number}|{hours: string, seconds: string, minutes: string}}
 */
export default function parseSeconds(seconds, withNull) {
  if (typeof seconds !== 'number') {
    // TODO For what?
    throw new TypeError('Expected a number');
  }

  if (withNull) {
    return {
      hours: correctFormatTime(Math.trunc(seconds / 3600) % 24),
      minutes: correctFormatTime(Math.trunc(seconds / 60) % 60),
      seconds: correctFormatTime(Math.trunc(seconds) % 60),
    };
  }

  return {
    hours: Math.trunc(seconds / 3600) % 24,
    minutes: Math.trunc(seconds / 60) % 60,
    seconds: Math.trunc(seconds) % 60,
  };
}
