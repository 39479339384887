import { getServiceMethods } from './helpers/getServiceMethods';
import { configRequest as config, createBaseApi } from './baseApi';

const baseApi = createBaseApi();

const uploadFile = (file, releaseId) => {
  const data = {
    resource: file,
  };

  const configRequest = {
    ...config,
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return baseApi.post(`/releases/${releaseId}/resources`, data, configRequest);
};

/**
 *
 * @type {{uploadFile: (function(*, *): *), getAll: (function(*): *), patchById: (function(*, *): *), getById: (function(*): *), deleteById: (function(*): *), put: (function(*, *): *), createNew: (function(*): *)}}
 */
export const terminalReleasesService = { ...getServiceMethods('releases'), uploadFile };
