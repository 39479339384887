import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useStateContext } from 'store';
import { isEmpty } from 'helpers/lodash';
import { Checkbox } from 'containers/UI/Checkbox';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { PermissionTitle } from 'helpers/constants/permissionsConstants';

import styles from './ModalArbitersEdit.module.css';

export const ModalArbitersEdit = ({ arbiterData }) => {
  const { closePopup, updateArbiter, fullArbiterData, getFullArbiterDataById } = useStateContext();

  const { id, email } = arbiterData;

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({ mode: 'onChange', defaultValues: arbiterData });

  useEffect(() => {
    if (isEmpty(fullArbiterData)) {
      getFullArbiterDataById(arbiterData.id);
    }
    reset({ filesAvailability: arbiterData.permissions.filesAvailability });
  }, [fullArbiterData]);

  const save = form => {
    const formData = {
      permissions: { filesAvailability: !!form.filesAvailability },
    };
    updateArbiter({ id, ...formData }, closePopup);
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_email_label}>{email}</div>
        <div className={styles.form_fields}>
          <div className={styles.form_checkboxes}>
            <Checkbox name="filesAvailability" label={PermissionTitle.filesAvailability} control={control} />
          </div>
        </div>

        <div className={styles.form_actions}>
          <Button className={styles.form_button} disabled={!isValid} type="submit">
            Сохранить
          </Button>
          <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
            Отмена
          </Button>
        </div>
      </form>
    </div>
  );
};

ModalArbitersEdit.defaultProps = { arbiterData: {} };

ModalArbitersEdit.propTypes = {
  arbiterData: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    permissions: PropTypes.shape({ filesAvailability: PropTypes.bool }),
  }),
};
