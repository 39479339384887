import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useApi } from 'services/hooks/useApi';
import { filesService } from 'services/files.service';
import { RESPONSE_CODE_FILE_CANNOT_BE_DELETED } from 'helpers/constants/responseCodes';
import { responseHandler } from '../helpers/responseHandler';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { URLs } from 'helpers/constants/URLs';
import { getDecodedData } from '../helpers/decoder';

const MAX_FILES_COUNT_PER_REQUEST = 100;

export function useFilesState() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [files, setFiles, isFilesLoading, filesError, setFilesError, totalFilesCount, setTotalFilesCount] = useApi(() =>
    filesService.getAll(getQueryParams(+page - 1) || {}),
  );

  const [fileLoadingMessage, setFileLoadingMessage] = useState('');
  const [fileData, setFileData] = useState(null);

  const [allFilesForDesktops, setAllFilesForDesktops] = useState([]);
  const [dynamicSkipValue, setDynamicSkipValue] = useState(0);
  const [dynamicTakeValue, setDynamicTakeValue] = useState(MAX_FILES_COUNT_PER_REQUEST);

  useEffect(() => {
    if (pathname === URLs.files) {
      return (() => {
        filesService
          .getAll(getQueryParams(+page - 1) || {})
          .then(response => {
            if (response.status === 200) {
              Array.isArray(response.data?.items) && setFiles(response.data.items);
            }
          })
          .catch(setFilesError);
      })();
    }
  }, [pathname, page, totalFilesCount]);

  const addFile = useCallback((newFile, onModalClose) => {
    const newFileMetadata = {
      name: newFile.name,
      type: newFile.type,
      comment: newFile.comment,
    };

    return (() => {
      filesService
        .createNew(newFileMetadata)
        .then(response => {
          if (response.status === 200) {
            const newFileWithId = { ...newFile, id: response.data.id };
            uploadFile(newFileWithId, onModalClose);
          }
        })
        .catch(error => {
          setFilesError(error);
          responseHandler(false, '', onModalClose, error.response.data.code);
        });
    })();
  }, []);

  const uploadFile = (file, onModalClose) => {
    setFileLoadingMessage('Происходит загрузка файла на сервер. Пожалуйста подождите...');

    filesService
      .uploadFile(file)
      .then(response => {
        if (response.status === 204) {
          setTotalFilesCount(prevState => prevState + 1);
          responseHandler(true, 'Файл успешно загружен', onModalClose);
        }
      })
      .catch(e => {
        setFilesError(e);
        responseHandler(false, '', onModalClose, e.response.data.code);
      })
      .finally(() => {
        setFileLoadingMessage('');
      });
  };

  const removeFile = useCallback((id, onModalClose) => {
    return (() => {
      filesService
        .deleteById(id)
        .then(response => {
          if (response.code === RESPONSE_CODE_FILE_CANNOT_BE_DELETED) {
            // TODO send user a message about it
          }

          if (response.status === 204) {
            setTotalFilesCount(prevState => prevState - 1);
            setFiles(prev => prev.filter(el => el.id !== id));
            responseHandler(true, 'Файл успешно удалён', onModalClose);
          }
        })
        .catch(error => {
          setFilesError(error);
          responseHandler(false, 'Файл не был удалён', onModalClose);
        });
    })();
  }, []);

  const updateFile = useCallback((fileUpdatedData, onModalClose) => {
    return (() => {
      filesService
        .put(fileUpdatedData.id, fileUpdatedData)
        .then(response => {
          if (response.status === 204) {
            setFiles(prev => [
              ...prev.map(el => {
                if (el.id === fileUpdatedData.id) {
                  if (fileUpdatedData.resource && Object.keys(fileUpdatedData.resource).length === 0) {
                    uploadFile(fileUpdatedData, onModalClose);
                  }

                  return {
                    ...el,
                    ...fileUpdatedData,
                  };
                }

                return el;
              }),
            ]);
            responseHandler(true, 'Файл успешно изменен', onModalClose);
          }
        })
        .catch(({ response }) => {
          setFilesError(response.data);
          responseHandler(false, 'Файл не был изменен', onModalClose, response.data.code);
        });
    })();
  }, []);

  const downloadFileData = useCallback((resourceId, onModalClose, saveLocal) => {
    const saveMethod = saveLocal ? saveLocal : setFileData;
    filesService
      .downloadFileData(resourceId)
      .then(response => {
        if (response.status === 200) {
          saveMethod(getDecodedData(response));
        }
      })
      .catch(e => {
        saveMethod(null);
        setFilesError(e);
        responseHandler(false, '', onModalClose, e.response.data.code);
      });
  }, []);

  const getAllFiles = () => {
    if (allFilesForDesktops.length === 0) {
      setTimeout(() => {}, 1000);
    }

    const queryParams = {
      skip: dynamicSkipValue,
      take: dynamicTakeValue,
    };

    filesService.getAll(queryParams).then(response => {
      if (response.status === 200) {
        setTotalFilesCount(response?.data?.count);

        if (response?.data?.count > 100) {
          setDynamicSkipValue(prevState => prevState + MAX_FILES_COUNT_PER_REQUEST);
          setDynamicTakeValue(totalFilesCount - dynamicSkipValue);
        }

        if (Array.isArray(response?.data?.items)) {
          setAllFilesForDesktops(prevState => {
            const prevAllFilesState = JSON.stringify(prevState);
            const newFiles = JSON.stringify(response.data.items);

            if (!prevAllFilesState.includes(newFiles)) {
              return [...prevState, ...response.data.items];
            }

            return prevState;
          });
        }
      }
    });
  };

  useLayoutEffect(() => {
    getAllFiles();
  }, [totalFilesCount]);

  return {
    files,
    setFiles,

    isFilesLoading,
    filesError,

    addFile,
    updateFile,
    removeFile,
    totalFilesCount,
    allFilesForDesktops,
    fileLoadingMessage,
    downloadFileData,
    fileData,
    setFileData,
  };
}
