import { useStateContext } from 'store';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { NavLink } from 'react-router-dom';
import { ModalSportEvents } from 'containers/UI/Modal/Components/SportEvents/ModalSportEvents';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as TemplatesList } from 'icons/list.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';

export const useSportEvents = () => {
  const {
    sportEvents,
    isSportEventsLoading,
    totalSportEventsCount,
    createSportEvent,
    editSportEvent,
    removeSportEvent,
    openPopup,
  } = useStateContext();

  const handleCreateSportEvent = () => {
    openPopup(() => <ModalSportEvents createSportEvent={createSportEvent} />, { title: 'Создание мероприятия' });
  };

  const handleEditSportEvent = sportEvent => {
    openPopup(() => <ModalSportEvents sportEvent={sportEvent} editSportEvent={editSportEvent} />, {
      title: 'Редактирование спортивного мероприятия',
    });
  };

  const handleDeleteSportEvent = (id, name) => {
    openPopup(
      () => (
        <ModalDelete
          contentId={id}
          title={`Вы уверены, что хотите удалить спортивное мероприятие ${name}`}
          onDelete={removeSportEvent}
        />
      ),
      { title: 'Удаление спортивного мероприятия' },
    );
  };

  const header = [
    { key: 'name', title: 'Название' },
    { key: 'sport', title: 'Вид спорта' },
    { key: 'startDate', title: 'Дата/время' },
    { key: 'team1Name', title: 'Первая команда' },
    { key: 'team2Name', title: 'Вторая команда' },
    { key: 'period', title: 'Период' },
    { key: 'score', title: 'Счет' },
    { key: 'state', title: 'Статус' },
    { key: 'comment', title: 'Комментарий' },
    {
      key: 'actions',
      title: (
        <div key="sportEvents-head-button" className={tableStyles.rowButtons}>
          <Button
            key="sportEvent-create"
            className={tableStyles.headerButton}
            onClick={handleCreateSportEvent}
            style={{ width: '140px' }}
          >
            Создать мероприятие
          </Button>
        </div>
      ),
    },
  ];

  const body = sportEvents?.map(sportEvent => {
    const { id, name, startDate, team1Name, team2Name, period, score, state, comment, sport } = sportEvent || {};

    return {
      name,
      startDate,
      team1Name,
      team2Name,
      period,
      score,
      state,
      comment,
      sport,
      actions: (
        <div key="sportEvents-item-buttons" className={tableStyles.rowButtons}>
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Изменить'}
            onClick={() => handleEditSportEvent(sportEvent)}
          />

          <NavLink to={'/sportEvents/' + id}>
            <Button variant={ButtonVariantEnum.ICON} iconData={{ icon: <TemplatesList /> }} title={'Рабочие столы'} />
          </NavLink>

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить'}
            onClick={() => handleDeleteSportEvent(id, name)}
          />
        </div>
      ),
    };
  });

  return {
    header,
    body,
    totalSportEventsCount,
    isSportEventsLoading,
  };
};
