import { useScheduleMain } from './useScheduleMain';
import styles from './ScheduleMain.module.css';
import { ScheduleDays } from '../ScheduleDays/ScheduleDays';
import { ScheduleTable } from '../ScheduleTable/ScheduleTable';

export const ScheduleMain = () => {
  const { handleScroll } = useScheduleMain();

  return (
    <div className={styles.scheduleMain} onWheel={handleScroll}>
      <ScheduleDays />
      <ScheduleTable />
    </div>
  );
};
