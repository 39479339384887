import { Controller, useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import get from 'lodash.get';

import {
  DESKTOP_FORM,
  DESKTOP_SPORT_EVENTS_FORM,
  DESKTOP_TABLEAU_TEMPLATE_FORM,
  WIDGETS_FORMS,
  WidgetParamTypeEnum,
} from '../helpers';
import { useDesktopEditor } from '../DesktopEditor/useDesktopEditor';
import { Input, InputParseErrors } from 'containers/UI/Input';
import { ColorPicker } from 'containers/UI/ColorPicker';
import { SelectForm, SelectVariantEnum } from 'containers/UI/Select';
import { Checkbox } from 'containers/UI/Checkbox';
import { DatePicker } from 'containers/UI/DatePicker';
import { Button } from 'containers/UI/Button';
import { Slider } from 'containers/UI/Slider/Slider';
import { DesktopFormFile } from './DesktopFormFile';
import { DesktopFormFuel } from './DesktopFormFuel';
import { DesktopFormAudioFiles } from './DesktopFormAudioFiles';
import { DesktopFormFiles } from './DesktopFormFiles';
import { useLocation } from 'react-router-dom';
import { TimePickerForm } from 'containers/UI/TimePicker';

import styles from '../Desktops.module.css';

export const DesktopForm = () => {
  const { pathname } = useLocation();
  const isTableauTemplatePageWasOpened = pathname.includes('tableauTemplates');
  const isPlaylistsOrPresentationsPageWasOpened = pathname.includes('playlists') || pathname.includes('presentations');
  const isSportEventsPageWasOpened = pathname.includes('sportEvents');

  const {
    data,
    watch,
    register,
    indexSelect,
    selectWidgetIndex,
    control,
    formState: { errors },
  } = useFormContext();

  const { handleHtmlCode } = useDesktopEditor();
  const screens = watch('screens');
  const screen = (screens && screens[indexSelect]) || {};
  const widget = selectWidgetIndex !== undefined && screen?.widgets && screen?.widgets[selectWidgetIndex];

  if (indexSelect === -1) return null;

  const { COLOR, SELECT, CHECK, FILE, DATE, FUEL, NUMBER, AUDIO_FILES, SLIDER, VIDEO_FILES, CODE, TIME } =
    WidgetParamTypeEnum;

  const isWidgetForm = !!widget?.type;
  const isScreenForm = !widget?.type && isPlaylistsOrPresentationsPageWasOpened;
  const isTableauTemplateForm = !widget?.type && isTableauTemplatePageWasOpened;
  const isSportEventsForm = !widget?.type && isSportEventsPageWasOpened;

  if (isWidgetForm) {
    if (widget?.blockedOnCanvas) {
      return;
    }

    return WIDGETS_FORMS({ data })[widget?.type]?.map(props => {
      const { key, label, type, options = {} } = props || {};
      const name = `screens.${indexSelect}.widgets.${selectWidgetIndex}.${key}`;

      const { disabled = () => false, ...other } = props || {};

      if (type === COLOR) {
        return (
          <Controller
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
              <ColorPicker className={styles['params-field']} label={label} value={value} onChange={onChange} />
            )}
          />
        );
      }
      if (type === SELECT) {
        const { list = [], options } = props || {};
        return (
          <SelectForm
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            name={name}
            control={control}
            wrapperClassName={styles['params-field']}
            variant={SelectVariantEnum.FLAT}
            label={label}
            placeholder={label}
            options={list}
            rules={options}
          />
        );
      }
      if (type === CHECK) {
        return (
          <Checkbox
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            control={control}
            name={name}
            className={styles['params-field']}
            label={label}
            disabled={disabled(widget)}
          />
        );
      }
      if (type === FILE) {
        const { fileType = '' } = props || {};
        return (
          <DesktopFormFile
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            fieldKey={key}
            label={label}
            fileType={fileType}
            options={options}
          />
        );
      }
      if (type === DATE) {
        const { minDate = () => new Date(), options } = props || {};
        return (
          <Controller
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            control={control}
            name={name}
            rules={options}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                classNameWrapper={classnames(styles['params-field'], styles['date-field'])}
                disabled={disabled(widget)}
                label={label}
                dateFormat="dd.MM.yyyy"
                selected={value}
                onChange={onChange}
                minDate={minDate()}
                error={InputParseErrors(
                  get(errors, `screens[${indexSelect}]widgets[${selectWidgetIndex}]${key}`),
                  options,
                )}
              />
            )}
          />
        );
      }
      if (type === FUEL) {
        return <DesktopFormFuel key={'widget' + screen.ourId + key + selectWidgetIndex} fieldKey={key} label={label} />;
      }

      if (type === CODE) {
        return (
          <Button
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            className={[styles['params-field'], styles['params-field-code']].join(' ')}
            onClick={() => handleHtmlCode({ name, options, register })}
          >
            Редактировать HTML
          </Button>
        );
      }

      if (type === NUMBER) {
        return (
          <Input
            type={NUMBER}
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            className={styles['params-field']}
            classNameError={styles['params-field-error']}
            label={label}
            placeholder={label}
            disabled={disabled(widget)}
            {...other}
            {...register(name, { valueAsNumber: true, ...options })}
            defaultValue={options.defaultValue}
            error={InputParseErrors(get(errors, `screens[${indexSelect}]widgets[${selectWidgetIndex}]${key}`), options)}
          />
        );
      }

      if (type === AUDIO_FILES) {
        return (
          <DesktopFormAudioFiles
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            screenIndex={indexSelect}
            widgetIndex={selectWidgetIndex}
          />
        );
      }

      if (type === VIDEO_FILES) {
        return (
          <DesktopFormFiles
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            screenIndex={indexSelect}
            widgetIndex={selectWidgetIndex}
          />
        );
      }

      if (type === SLIDER) {
        return (
          <Slider
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            label={label}
            min={options.min}
            max={options.max}
            screenIndex={indexSelect}
            selectWidgetIndex={selectWidgetIndex}
          />
        );
      }

      return (
        <Input
          type={type}
          key={'widget' + screen.ourId + key + selectWidgetIndex}
          className={styles['params-field']}
          classNameError={styles['params-field-error']}
          label={label}
          placeholder={label}
          disabled={disabled(widget)}
          {...other}
          {...register(name, options)}
          error={InputParseErrors(get(errors, `screens[${indexSelect}]widgets[${selectWidgetIndex}]${key}`), options)}
        />
      );
    });
  }

  if (isScreenForm || isTableauTemplateForm || isSportEventsForm) {
    const form =
      (isScreenForm && DESKTOP_FORM) ||
      (isTableauTemplateForm && DESKTOP_TABLEAU_TEMPLATE_FORM) ||
      (isSportEventsForm && DESKTOP_SPORT_EVENTS_FORM);

    return form.map(({ key, label, options, type, list, disabled = () => false }) => {
      if (type === SELECT) {
        return (
          <SelectForm
            key={'screen' + screen.ourId + key}
            control={control}
            name={`screens.${indexSelect}.${key}`}
            wrapperClassName={styles['params-field']}
            variant={SelectVariantEnum.FLAT}
            label={label}
            placeholder={label}
            options={list}
            defaultValue={options?.defaultValue}
            disabled={disabled(watch(`screens.${indexSelect}`))}
            rules={options}
          />
        );
      }

      if (type === CHECK) {
        return (
          <Checkbox
            key={'widget' + screen.ourId + key + selectWidgetIndex}
            control={control}
            name={`screens.${indexSelect}.${key}`}
            className={styles['params-field']}
            label={label}
          />
        );
      }

      if (type === TIME) {
        return (
          <TimePickerForm
            key={'screen' + screen.ourId + key}
            name={`screens.${indexSelect}.${key}`}
            control={control}
            label={label}
            labelClassName={styles['event-duration']}
            disabled={disabled(watch(`screens.${indexSelect}`))}
          />
        );
      }

      return (
        <Input
          key={'screen' + screen.ourId + key}
          className={styles['params-field']}
          classNameError={styles['params-field-error']}
          label={label}
          placeholder={label}
          {...register(`screens.${indexSelect}.${key}`, options || {})}
          error={InputParseErrors(get(errors, `screens[${indexSelect}]${key}`), options)}
          defaultValue={options?.defaultValue}
          disabled={disabled(watch(`screens.${indexSelect}`))}
        />
      );
    });
  }
};
