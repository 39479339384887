import { getServiceMethods } from './helpers/getServiceMethods';
import { configRequest as config, configRequest, createBaseApi } from './baseApi';

const baseApi = createBaseApi();

// TODO Maybe should be moved into getServiceMethods, but not critical
// TODO this endpoint doesn't exist yet
const getAllOrganisationsNames = () => {
  return baseApi.get('/allOrganisationsNames', configRequest);
};

const getAllOrganisationsLicenseNumbers = () => {
  return baseApi.get('/allOrganisationsLicenseNumbers', configRequest);
};

const downloadFile = organisationId => {
  return baseApi.get(`clients/${organisationId}/resources`, configRequest);
};

const uploadFile = (organisationId, fileData) => {
  const data = {
    resource: fileData,
  };

  const configRequest = {
    ...config,
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  return baseApi.post(`clients/${organisationId}/resources`, data, configRequest);
};

/** groups */
function getGroups() {
  return baseApi.get('/clients/groups', configRequest);
}

function addGroup(data) {
  return baseApi.post('/clients/groups', data, configRequest);
}

function updateGroup(groupId, data) {
  return baseApi.put(`/clients/groups/${groupId}`, data, configRequest);
}

function deleteGroup(groupId) {
  return baseApi.delete(`/clients/groups/${groupId}`, configRequest);
}

/**
 *
 * @type {{uploadFile: (function(*, *): *), getAll: (function(*): *), patchById: (function(*, *): *), getClientsGroups: (function(): *), updateOrganisationsGroup: (function(*, *): *), getAllOrganisationsLicenseNumbers: (function(): *), put: (function(*, *): *), createNew: (function(*): *), deleteOrganisationsGroup: (function(*): *), downloadFile: (function(*): *), getById: (function(*): *), deleteById: (function(*): *), getAllOrganisationsNames: (function(): *), addOrganisationsGroup: (function(*): *)}}
 */
export const organisationsService = {
  ...getServiceMethods('clients'),
  getAllOrganisationsNames,
  getAllOrganisationsLicenseNumbers,
  downloadFile,
  uploadFile,

  getGroups,
  addGroup,
  updateGroup,
  deleteGroup,
};
