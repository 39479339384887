import { Navigate, Outlet } from 'react-router-dom';
import { useStateContext } from 'store';

const PrivateWrapper = () => {
  const { user } = useStateContext();

  if (user.accessToken) {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
};

export default PrivateWrapper;
