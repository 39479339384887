import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { useEffect, useMemo } from 'react';
import { PermissionTitle } from 'helpers/constants/permissionsConstants';
import { emailSchema, passwordSchema } from 'helpers/validation';
import { Checkbox } from 'containers/UI/Checkbox';
import { SelectForm } from 'containers/UI/Select';
import { Input, InputTypeEnum, InputParseErrors, InputActionTypeEnum } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from './ModalAdminsAdd.module.css';

/** after api v3 */
// todo 2. ['auth/currentUser'].permissions
// todo 3. isEmailTaken logic
export const ModalAdminsAdd = () => {
  const {
    closePopup,
    addAdmin,
    setIsModalInAdminsOpen,
    isModalInAdminsOpen,
    allowedWidgets,
    getAllowedWidgets,
    setAllowedWidgets,
    allOrganisationsListInAdmins,
  } = useStateContext();

  useEffect(() => {
    if (!isModalInAdminsOpen) {
      setIsModalInAdminsOpen(true);
    }

    if (getAllowedWidgets.length === 0) {
      getAllowedWidgets(true);
    }

    return () => {
      setAllowedWidgets([]);
    };
  }, []);

  const organisationsOptions = useMemo(() => {
    const options = allOrganisationsListInAdmins.map(organisation => ({
      name: organisation.name,
      value: organisation.id,
    }));

    return [{ name: 'Без организации', value: 'null' }, ...options];
  }, [allOrganisationsListInAdmins]);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' });

  const save = form => {
    const formData = {
      email: form.email,
      password: form.password,
      widgets: form.widgets,
      permissions: {
        mobileAppActiveScreenAvailability: !!form.mobileAppActiveScreenAvailability,
        sportEventsAvailability: !!form.sportEventsAvailability,
      },
      clientId: form.clientId !== 'null' ? form.clientId : null,
    };

    addAdmin(formData, closePopup);
  };

  const getPasswordSchema = fieldName => ({
    ...passwordSchema,
    validate: {
      ...passwordSchema.validate,
      retryPassword: val => {
        if (watch(fieldName) != val) return 'Ваши пароли не совпадают';
      },
    },
  });

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_fields}>
          <Input
            label={'Email'}
            placeholder={'Email'}
            type={InputTypeEnum.EMAIL}
            error={InputParseErrors(errors.email, { minLength: 5, maxLength: 30 })}
            {...register('email', emailSchema)}
          />

          <SelectForm
            name="clientId"
            control={control}
            placeholder={'Выберите организацию из списка'}
            label={'Организация'}
            options={organisationsOptions}
            searchable
            rules={{ required: false }}
          />

          <Input
            type={InputTypeEnum.PASSWORD}
            label={'Пароль'}
            placeholder={'Пароль'}
            actions={[InputActionTypeEnum.EYE, InputActionTypeEnum.DICE, InputActionTypeEnum.COPY]}
            error={InputParseErrors(errors.password, passwordSchema)}
            {...register('password', passwordSchema)}
          />

          <Input
            type={InputTypeEnum.PASSWORD}
            label={'Пароль повторно'}
            placeholder={'Пароль повторно'}
            actions={[InputActionTypeEnum.EYE]}
            error={InputParseErrors(errors.confirm_password, getPasswordSchema('password'))}
            {...register('confirm_password', getPasswordSchema('password'))}
          />

          <SelectForm
            name="widgets"
            control={control}
            placeholder={'Выберите виджеты'}
            label={'Доступные выджеты'}
            options={allowedWidgets}
            searchable
            isMulti
          />

          <Checkbox
            key={PermissionTitle.mobileAppActiveScreenAvailability}
            control={control}
            label={PermissionTitle.mobileAppActiveScreenAvailability}
            name="mobileAppActiveScreenAvailability"
          />

          <Checkbox
            key={PermissionTitle.sportEventsAvailability}
            control={control}
            label={PermissionTitle.sportEventsAvailability}
            name="sportEventsAvailability"
          />
        </div>

        <div className={styles.form_actions}>
          <Button className={styles.form_button} type="submit" disabled={!isValid}>
            Создать
          </Button>
          <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
            Закрыть
          </Button>
        </div>
      </form>
    </div>
  );
};
