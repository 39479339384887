import { useCallback, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useStateContext } from 'store';
import { schedulesService } from 'services';
import { showSuccessNotify } from 'helpers/showNotify';
import { useGroups } from 'containers/Groups/hooks/useGroups';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalGroupsManagement } from 'containers/UI/Modal/Components/Groups';
import { ModalSend } from 'containers/UI/Modal/Components/Common/ModalSend';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import { ModalSchedulesAdd, ModalSchedulesEdit, ModalSchedulesCopy } from 'containers/UI/Modal/Components/Schedules';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Upload } from 'icons/upload.svg';
import { ReactComponent as Copy } from 'icons/copy.svg';
import { ReactComponent as PlaylistPlay } from 'icons/playlist_play.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';
import styles from '../Schedules.module.css';

export const useSchedules = () => {
  const {
    schedules,
    setSchedules,
    isSchedulesLoading,
    openPopup,
    filterType,
    setFilterType,
    totalSchedulesCount,
    removeSchedule,
  } = useStateContext();

  const {
    groups,
    groupOptions,

    loadGroups,
    handleCreateGroup,
    handleUpdateGroup,
    handleDeleteGroup,
  } = useGroups(schedulesService);

  useEffect(() => {
    loadGroups();
  }, []);

  useEffect(() => {
    setFilterType(null);
  }, [groups]);

  const loadSchedules = params => {
    schedulesService.getSchedules(params).then(result => {
      if (result.status === 200) {
        setSchedules(result.data.items);
      }
    });
  };

  const handleFilterGroup = filterValue => {
    if (filterValue === 'all') {
      loadSchedules();
    } else {
      loadSchedules({ groupId: String(filterValue) });
    }
    setFilterType(filterValue);
  };

  const handleSendSchedules = ({ deviceId, contentId }) => {
    if (Array.isArray(deviceId)) {
      deviceId.forEach(id => {
        schedulesService.sendSchedule(contentId, id).then(result => {
          if (result.status === 204) {
            showSuccessNotify('Расписание было успешно отправлено');
          }
        });
      });
    } else {
      schedulesService.sendSchedule(contentId, deviceId).then(result => {
        if (result.status === 204) {
          showSuccessNotify('Расписание было успешно отправлено');
        }
      });
    }
  };

  const handleOpenGroupSettings = useCallback(() => {
    openPopup(
      () => (
        <ModalGroupsManagement
          groups={groups}
          handleCreate={handleCreateGroup}
          handleEdit={handleUpdateGroup}
          handleDelete={handleDeleteGroup}
        />
      ),
      {
        title: 'Управление группами',
      },
    );
  }, [groups]);

  const handleScheduleCreate = useCallback(() => {
    openPopup(() => <ModalSchedulesAdd groupsOptions={groupOptions} handleCreateGroup={handleCreateGroup} />, {
      title: 'Создание расписания',
    });
  }, [groupOptions]);

  const handleScheduleCopy = useCallback(
    schedule => {
      openPopup(
        () => (
          <ModalSchedulesCopy
            schedule={schedule}
            groupsOptions={groupOptions.filter(option => option.value !== 'all')}
            handleCreateGroup={handleCreateGroup}
          />
        ),
        { title: 'Копирование расписания' },
      );
    },
    [groupOptions],
  );

  const handleScheduleEdit = useCallback(
    schedule => {
      openPopup(
        () => (
          <ModalSchedulesEdit schedule={schedule} groupsOptions={groupOptions} handleCreateGroup={handleCreateGroup} />
        ),
        {
          title: 'Редактирование расписания',
        },
      );
    },
    [groupOptions],
  );

  const handleScheduleSend = useCallback(schedule => {
    openPopup(() => <ModalSend contentId={schedule.id} onSend={handleSendSchedules} />, {
      title: 'Отправка на устройство',
    });
  }, []);

  const handleScheduleDelete = useCallback(schedule => {
    openPopup(
      () => (
        <ModalDelete
          contentId={schedule.id}
          title={`Вы уверены что хотите удалить расписание ${schedule.name}?`}
          isVisibleIcon={false}
          onDelete={removeSchedule}
        />
      ),
      {
        title: 'Удаление расписания',
      },
    );
  }, []);

  const header = [
    { key: 'name', title: 'Название' },
    { key: 'playlistCount', title: 'Количество плейлистов' },
    { key: 'comment', title: 'Комментарий' },
    { key: 'group', title: 'Группа' },
    {
      key: 'actions',
      title: (
        <div key="schedule-head-button" className={tableStyles.rowButtons}>
          <Button key="schedule-add" className={tableStyles.headerButton} onClick={() => handleScheduleCreate()}>
            Создать расписание
          </Button>
        </div>
      ),
    },
  ];

  const body = schedules?.map(schedule => {
    const { id, name, playlistCount, comment, group = {} } = schedule || {};
    return {
      id,
      name,
      playlistCount,
      comment,
      group: group?.name || null,
      actions: (
        <div key="schedule-item-buttons" className={tableStyles.rowButtons}>
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Copy /> }}
            className={styles.copyButton}
            title={'Скопировать'}
            onClick={() => handleScheduleCopy(schedule)}
          />

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Изменить'}
            onClick={() => handleScheduleEdit(schedule)}
          />

          <NavLink to={'/schedules/' + id}>
            <Button
              variant={ButtonVariantEnum.ICON}
              iconData={{ icon: <PlaylistPlay /> }}
              title={'Управление плейлистами'}
            />
          </NavLink>

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Upload /> }}
            title={'Отправка на устройство'}
            className={styles.sendButton}
            onClick={() => handleScheduleSend(schedule)}
          />
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить'}
            onClick={() => handleScheduleDelete(schedule)}
          />
        </div>
      ),
    };
  });

  return {
    header,
    body,
    isSchedulesLoading,
    filterType,
    groupOptions,

    loadSchedules,
    handleFilterGroup,
    handleOpenGroupSettings,
    totalSchedulesCount,
  };
};
