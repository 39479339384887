import { devicesService } from 'services';
import { LogsEvents, LogsEventsOptions, RoleNames } from 'helpers/constants/logsConstants';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';

import styles from '../DevicesLogs.module.css';

export const useDevicesLogs = deviceId => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const [logsCount, setLogsCount] = useState(0);
  const [filterTypes, setFilterTypes] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    loadLogs();
  }, [pathname, page, fromDate, toDate]);

  const loadLogs = (params = {}) => {
    setLogs([]);
    const { types = [] } = params;
    const { skip, take } = getQueryParams(+page - 1);
    const skipParams = typeof params.skip !== 'undefined' ? params.skip : skip;

    if (page > 1) {
      resetFilters();
    }

    setLoading(true);

    devicesService
      .getDevicesLogs(deviceId, { skip: skipParams, take, from: fromDate, to: toDate, types })
      .then(response => {
        if (response.status === 200) {
          const responseLogs = response.data.items.map(x => {
            x.logMessage = LogsEvents[x.type];

            if (x.text) {
              const jsonText = JSON.parse(x.text);
              if (jsonText) {
                x.text = jsonText.text || '';
              }
            }
            return x;
          });

          setLogsCount(response.data.count);
          setLogs(responseLogs);
          setLoading(false);
        }
      });
  };

  const handleFilterType = value => {
    loadLogs({ types: value, skip: 0 });

    setTimeout(() => {
      setFilterTypes(value);
    }, 1);
  };

  const resetFilters = () => {
    setFilterTypes([]);
    setFromDate(null);
    setToDate(null);
  };

  const heading = [
    { key: 'createdAt', title: 'Дата создания' },
    { key: 'receivedAt', title: 'Дата получения' },
    { key: 'type', title: 'Тип события' },
    { key: 'content', title: 'Смена контента' },
    { key: 'externalEquipment', title: 'Внешнее оборудование' },
    { key: 'file', title: 'Файл' },
    { key: 'text', title: 'Текст' },
  ];

  const tableBodyLogsList = useMemo(
    () =>
      logs?.map(({ createdAt, receivedAt, type, content, user, externalEquipment, text, file }) => {
        return {
          createdAt: new Date(createdAt).toLocaleString(),
          receivedAt: new Date(receivedAt).toLocaleString(),
          type: LogsEvents[type],
          content: content && (
            <>
              Пользователь, <br />
              сменивший контент:
              <div className={styles.logsItemMainInfo}>
                <div className={styles.logItemEllipsis}>
                  {RoleNames[user.role]}: {user.email}
                </div>
                <div className={styles.logItemEllipsis} title={content.id}>
                  {content.name}
                </div>
              </div>
            </>
          ),
          externalEquipment: externalEquipment && (
            <>
              <div className={styles.logItemEllipsis}>{externalEquipment.name}</div>
              {externalEquipment.text && <div className={styles.logItemEllipsis}>{externalEquipment.text}</div>}
            </>
          ),
          file: file && (
            <>
              <div className={styles.logItemEllipsis}>Id: {file.id}</div>
              <div className={styles.logItemEllipsis}>{file.name}</div>
            </>
          ),
          text: text && <div className={styles.logItemEllipsis}>{text}</div>,
        };
      }),
    [logs],
  );

  return {
    heading,
    tableBodyLogsList,
    loading,
    logsCount,
    typeOptions: LogsEventsOptions,
    filterTypes,
    handleFilterType,

    fromDate,
    toDate,
    setFromDate,
    setToDate,
  };
};
