import { useApi } from 'services/hooks/useApi';
import { terminalUpdatesService } from 'services/terminalUpdates.service';
import { useCallback, useEffect, useState } from 'react';
import { responseHandler } from '../helpers/responseHandler';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { URLs } from 'helpers/constants/URLs';

export const useTerminalUpdatesState = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [
    terminalUpdates,
    setTerminalUpdates,
    isTerminalUpdatesLoading,
    error,
    setError,
    totalTerminalUpdatesCount,
    setTotalTerminalUpdatesCount,
  ] = useApi(() => terminalUpdatesService.getAll(getQueryParams(+page - 1) || {}));

  const [terminalReleasesForTerminalUpdates, setTerminalReleasesForTerminalUpdates] = useState([]);
  const [activeStatus, setActiveStatus] = useState(null);

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.terminalUpdates) {
        return (() => {
          terminalUpdatesService
            .getAll(getQueryParams(+page - 1) || {})
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response.data?.items) && setTerminalUpdates(response.data.items);
              }
            })
            .catch(setError);
        })();
      }
    }, [pathname, page, totalTerminalUpdatesCount, activeStatus]);
  };
  paginate();

  const getTerminalReleasesForTerminalUpdates = useCallback(() => {
    return (() => {
      terminalUpdatesService
        .getTerminalReleases()
        .then(response => {
          if (response.status === 200) {
            Array.isArray(response?.data?.items) &&
              setTerminalReleasesForTerminalUpdates(
                response.data.items.map(release => ({ name: release.name, value: release.id })),
              );
          }
        })
        .catch(setError);
    })();
  }, []);

  const addTerminalUpdate = useCallback((newTerminalUpdate, onModalClose) => {
    return (() => {
      terminalUpdatesService
        .createNew(newTerminalUpdate)
        .then(response => {
          if (response.status === 200) {
            setTotalTerminalUpdatesCount(prevState => prevState + 1);
            responseHandler(true, 'Обновление терминала было успешно создано', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Обновление терминала не было создано', onModalClose, error.response.data.code);
        });
    })();
  }, []);

  const updateTerminalUpdateStatus = useCallback((releaseScheduleId, newStatus, onModalClose) => {
    const service = newStatus
      ? terminalUpdatesService.activateTerminalUpdate
      : terminalUpdatesService.deactivateTerminalUpdate;

    service(releaseScheduleId)
      .then(response => {
        if (response.status === 204) {
          setActiveStatus(newStatus);
          responseHandler(true, 'Статус задания был успешно изменён', onModalClose);
        }
      })
      .catch(error => {
        setError(error);
        responseHandler(false, 'Статус задания не был изменён', onModalClose, error.response.data.code);
      });
  }, []);

  const deleteTerminalUpdate = useCallback((terminalUpdateId, onModalClose) => {
    return (() => {
      terminalUpdatesService
        .deleteById(terminalUpdateId)
        .then(response => {
          if (response.status === 204) {
            setTerminalUpdates(prevState => prevState.filter(terminalUpdate => terminalUpdate.id !== terminalUpdateId));
            setTotalTerminalUpdatesCount(prevState => prevState - 1);
            responseHandler(true, 'Обновление терминала было успешно удалено', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Обновление терминала не было удалено', onModalClose, error.response.data.code);
        });
    })();
  }, []);

  return {
    terminalUpdates,
    setTerminalUpdates,
    isTerminalUpdatesLoading,
    error,
    setError,
    totalTerminalUpdatesCount,
    addTerminalUpdate,
    deleteTerminalUpdate,
    terminalReleasesForTerminalUpdates,
    getTerminalReleasesForTerminalUpdates,
    updateTerminalUpdateStatus,
  };
};
