import { Controller, useForm } from 'react-hook-form';
import { Input, InputParseErrors, InputTypeEnum } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import PropTypes from 'prop-types';
import { useStateContext } from 'store';

import styles from './ModalTableauTemplates.module.css';

export const ModalTableauTemplates = ({ createTableauTemplate, tableauTemplate, editTableauTemplate }) => {
  const { closePopup } = useStateContext();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: { name: tableauTemplate?.name, comment: tableauTemplate?.comment, sport: 'generic' },
  });

  const save = formData => {
    if (createTableauTemplate) {
      createTableauTemplate(formData, closePopup);
    }

    if (tableauTemplate) {
      editTableauTemplate(tableauTemplate.id, formData, closePopup);
    }
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_fields}>
          <Controller
            key={'ModalTableauTemplates-radioInput'}
            control={control}
            name={'sport'}
            render={({ field: { onChange } }) => (
              <Input
                label={'Вид спорта'}
                type={InputTypeEnum.Radio}
                handleInput={onChange}
                radioVariants={[
                  { name: 'Хоккей', value: 'hockey' },
                  { name: 'Generic', value: 'generic' },
                ]}
                defaultRadioVariant={'generic'}
              />
            )}
          />

          <Input
            label={'Название'}
            placeholder={'Название'}
            type={InputTypeEnum.TEXT}
            error={InputParseErrors(errors.name, { minLength: 4, maxLength: 30 })}
            {...register('name', { required: true, minLength: 4, maxLength: 30 })}
          />

          <Input
            label={'Комментарий'}
            placeholder={'Комментарий'}
            type={InputTypeEnum.TEXT}
            error={InputParseErrors(errors.comment, { minLength: 4, maxLength: 100 })}
            {...register('comment', { minLength: 4, maxLength: 100 })}
          />

          <div className={styles.form_actions}>
            <Button className={styles.form_button} type="submit" disabled={!isValid}>
              {createTableauTemplate ? 'Создать' : 'Сохранить'}
            </Button>
            <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
              Отмена
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

ModalTableauTemplates.propTypes = {
  createTableauTemplate: PropTypes.func,
  tableauTemplate: PropTypes.object,
  editTableauTemplate: PropTypes.func,
};
