import { useSortable } from '@dnd-kit/sortable';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash.get';
import {
  LOCATION_PLAYLISTS,
  LOCATION_PRESENTATIONS,
  LOCATION_SPORT_EVENTS,
  LOCATION_TABLEAU_TEMPLATES,
} from '../../helpers';

import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { useDesktopEditor } from 'containers/Desktops/DesktopEditor/useDesktopEditor';
import { ReactComponent as Drag } from 'icons/drag.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Error } from 'icons/attention.svg';
import { ReactComponent as OpenedLock } from 'icons/padlock.svg';
import { ReactComponent as ClosedLock } from 'icons/lock.svg';

import styles from '../../Desktops.module.css';

export const DesktopItem = ({ index = undefined, screen = {} }) => {
  const { ourId, id } = screen || {};

  const { listeners, attributes, setNodeRef, transform, transition } = useSortable({ id });
  const {
    watch,
    indexSelect,
    setSelectScreen,
    selectWidgetIndex,
    setSelectWidgetIndex,
    screenRemove,
    location,
    previewTemplateMode,
    setSelectedWidgets,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { handleScreensDelete } = useDesktopEditor();
  const screens = watch('screens');
  const widgets = watch(`screens[${indexSelect}].widgets`) || [];
  const name = screens?.find(s => s.ourId === ourId)?.name;
  const isOpen = screens[indexSelect]?.ourId === ourId;
  const screenErrors = get(errors, `screens[${index}]`);

  const lockUnlockWidget = (event, widgetIndex) => {
    event.stopPropagation();
    const isBlocked = watch(`screens[${indexSelect}].widgets[${widgetIndex}].blockedOnCanvas`);

    setValue(`screens[${indexSelect}].widgets[${widgetIndex}].blockedOnCanvas`, !isBlocked);
  };

  const selectWidgetHandler = (i, blockedOnCanvas) => {
    if (blockedOnCanvas) return;
    setSelectWidgetIndex(selectWidgetIndex === i ? undefined : i);
  };

  return (
    <div ref={setNodeRef} style={{ transform: `translateY(${transform?.y})`, transition }}>
      {((location === LOCATION_PRESENTATIONS && !previewTemplateMode) ||
        (location === LOCATION_PLAYLISTS && !previewTemplateMode) ||
        (location === LOCATION_TABLEAU_TEMPLATES && !previewTemplateMode) ||
        (location === LOCATION_SPORT_EVENTS && !previewTemplateMode)) && (
        <div
          className={classnames(styles['desktops-item'], {
            [styles['select-item']]: isOpen,
            [styles['error-item']]: !!screenErrors,
          })}
          onClick={() => {
            setSelectScreen(isOpen ? false : ourId);
            setSelectWidgetIndex(undefined);
            setSelectedWidgets({});
          }}
        >
          {name}
          {!!screenErrors && <Error className={styles['error-icon']} />}
          <Button
            variant={ButtonVariantEnum.TEXT}
            className={styles['desktops-item-delete']}
            onClick={() => handleScreensDelete(screen, () => screenRemove(index))}
          >
            <Delete />
          </Button>
          <Button
            variant={ButtonVariantEnum.TEXT}
            className={styles['desktops-item-drag']}
            {...listeners}
            {...attributes}
          >
            <Drag />
          </Button>
        </div>
      )}

      {isOpen && (
        <div className={styles['desktops-item-widgets']}>
          {widgets.map(({ name, blockedOnCanvas }, index) => (
            <div
              key={index}
              className={classnames(styles['desktops-item-widget'], {
                [styles['select-item']]: selectWidgetIndex === index,
                [styles['error-item']]: !!screenErrors?.widgets?.[index],
              })}
              onClick={() => selectWidgetHandler(index, blockedOnCanvas)}
            >
              {name}

              <Button
                variant={ButtonVariantEnum.ICON}
                className={styles['lock']}
                iconData={{
                  icon: !blockedOnCanvas ? <OpenedLock /> : <ClosedLock />,
                  inlineStyles: { width: '14px', height: '14px' },
                }}
                title={!blockedOnCanvas ? 'Заблокировать виджет' : 'Разблокировать виджет'}
                onClick={event => lockUnlockWidget(event, index)}
              ></Button>
              {!!screenErrors?.widgets?.[index] && <Error className={styles['error-icon']} />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DesktopItem.propTypes = {
  index: PropTypes.oneOfType([PropTypes.bool, PropTypes.undefined]),
  screen: PropTypes.object,
};
