export const LoginStatusHelper = (function () {
  let user = sessionStorage.getItem('user');
  let instance;

  const getUser = function () {
    return user;
  };

  const getParsedUser = function () {
    return typeof user === 'string' ? JSON.parse(user) : user;
  };

  const changeUser = function (newUserData) {
    user = newUserData;
  };

  function createInstance() {
    return {
      getUser,
      changeUser,
      getParsedUser,
    };
  }

  return {
    getInstance: function () {
      return instance || (instance = createInstance());
    },
  };
})();
