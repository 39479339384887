import { useState, useEffect, useCallback, useRef } from 'react';
import { useStateContext } from 'store';
import { ModalGroups, ModalGroupsManagement } from 'containers/UI/Modal/Components/Groups';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import { responseHandler } from 'store/helpers/responseHandler';

const DEFAULT_GROUP_OPTIONS = [
  { name: 'Без группы', value: null },
  { name: 'Все', value: 'all' },
];

export const useGroups = service => {
  const [groups, setGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState(DEFAULT_GROUP_OPTIONS);

  const { openPopup, setGroupNameWasChangedInPlaylists } = useStateContext();

  const groupsRef = useRef(groups);

  useEffect(() => {
    setGroupOptions([
      ...DEFAULT_GROUP_OPTIONS,
      ...groups.map(group => ({
        name: group.name,
        value: group.id,
      })),
    ]);
  }, [groups]);

  const loadGroups = async () => {
    await service.getGroups().then(result => {
      if (result.status === 200) {
        setGroups(result.data);
        groupsRef.current = result.data;
      }
    });
  };

  const openModalGroupsManagement = async () => {
    await loadGroups();
    openPopup(
      () => (
        <ModalGroupsManagement
          groups={groupsRef.current}
          handleCreate={handleCreateGroup}
          handleEdit={handleUpdateGroup}
          handleDelete={handleDeleteGroup}
        />
      ),
      {
        title: 'Управление группами',
      },
    );
  };

  const addGroup = newGroup => {
    service
      .addGroup(newGroup)
      .then(result => {
        if (result.status === 200) {
          responseHandler(true, 'Группа была успешна создана', openModalGroupsManagement, null);
        }
      })
      .catch(e => responseHandler(false, null, openModalGroupsManagement, e.response.data.code));
  };

  const updateGroup = (groupId, updateData) => {
    service
      .updateGroup(groupId, updateData)
      .then(result => {
        if (result.status === 204) {
          if (service?.name === 'playlists') {
            setGroupNameWasChangedInPlaylists(prevState => !prevState);
          }
          responseHandler(true, 'Группа была успешна изменена', openModalGroupsManagement, null);
        }
      })
      .catch(e => responseHandler(false, null, openModalGroupsManagement, e.response.data.code));
  };

  const handleCreateGroup = useCallback(() => {
    openPopup(() => <ModalGroups handleCreate={addGroup} handleClose={openModalGroupsManagement} />, {
      title: 'Создание группы',
    });
  }, []);

  const handleUpdateGroup = useCallback(group => {
    openPopup(() => <ModalGroups group={group} handleUpdate={updateGroup} handleClose={openModalGroupsManagement} />, {
      title: 'Редактирование группы',
    });
  }, []);

  const deleteGroup = groupId => {
    service
      .deleteGroup(groupId)
      .then(response => {
        if (response.status === 204) {
          responseHandler(true, 'Группа была успешна удалена', openModalGroupsManagement, null);
        }
      })
      .catch(error => responseHandler(false, null, openModalGroupsManagement, error.response.data.code));
  };

  const handleDeleteGroup = useCallback(group => {
    openPopup(
      () => (
        <ModalDelete
          contentId={group.id}
          title={`Вы уверены что хотите удалить группу ${group.name}?`}
          isVisibleIcon={false}
          onDelete={deleteGroup}
          onClose={openModalGroupsManagement}
        />
      ),
      {
        title: 'Удаление группы',
      },
    );
  }, []);

  return {
    groups,
    groupOptions,

    loadGroups,
    handleCreateGroup,
    handleUpdateGroup,
    handleDeleteGroup,
  };
};
