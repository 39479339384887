import PropTypes from 'prop-types';

import styles from './TableEmptyContent.module.css';

export const TableEmptyContent = ({ title = 'Контента нет' }) => {
  return (
    <div className={styles['emptyContent']}>
      <span className={styles['emptyContent_Title']}>{title}</span>
    </div>
  );
};

TableEmptyContent.propTypes = { title: PropTypes.string };
