import axios from 'axios';
import { isEmpty } from '../helpers/lodash';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(promise => {
    if (error) promise.reject(error);
    else promise.resolve(token);
  });

  failedQueue = [];
};

export function createBaseApi(apiVersion = 'v3', timeout = 0) {
  const baseApi = axios.create({ baseURL: `/api/${apiVersion}` });

  baseApi.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const { config: originalRequest, response } = error;
      const status = response?.status;
      if (status && status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers['Authorization'] = ['Bearer', token].join(' ');
              return axios(originalRequest);
            })
            .catch(err => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const { refreshToken } = JSON.parse(sessionStorage.getItem('user')) || {};
        if (refreshToken) {
          return new Promise(function (resolve, reject) {
            return axios
              .post(`/api/${apiVersion}/auth/token-refresh`, { refreshToken }, configRequest)
              .then(response => {
                if (response.status !== 200) {
                  sessionStorage.removeItem('user');
                  window.dispatchEvent(new Event('storage'));
                }

                const { role, username } = JSON.parse(sessionStorage.getItem('user'));
                const { accessToken, refreshToken } = response.data;
                sessionStorage.setItem('user', JSON.stringify({ accessToken, refreshToken, role, username }));
                window.dispatchEvent(new Event('storage'));
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
                originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;

                resolve(axios(originalRequest));
                processQueue(null, accessToken);
                resolve(error.config);
              })
              .catch(e => {
                reject(e);
                processQueue(e, null);
                sessionStorage.removeItem('user');
                window.dispatchEvent(new Event('storage'));
              })
              .finally(() => (isRefreshing = false));
          });
        }
      }

      return Promise.reject(error);
    },
  );

  baseApi.interceptors.request.use(
    config => {
      const { accessToken } = JSON.parse(sessionStorage.getItem('user')) || {};

      if (!isEmpty(accessToken)) {
        config.headers = {
          ...config.headers,
          Authorization: ['Bearer', accessToken].join(' '),
        };
      }

      config.timeout = timeout;

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  return baseApi;
}

export const configRequest = createConfig();

function createConfig() {
  return {
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
  };
}
