import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { sportEventsService } from 'services/sportEvents.service';
import { useApi } from 'services/hooks/useApi';
import { URLs } from 'helpers/constants/URLs';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { responseHandler } from 'store/helpers/responseHandler';

export const useSportEventsState = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [
    sportEvents,
    setSportEvents,
    isSportEventsLoading,
    sportEventsError,
    setSportEventsError,
    totalSportEventsCount,
    setTotalSportEventsCount,
  ] = useApi(() => sportEventsService.getAll(getQueryParams(+page - 1) || {}));

  const [sportEventWasEditedToggler, setSportEventWasEditedToggler] = useState(false);

  const [chosenSportType, setChosenSportType] = useState(null);

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.sportEvents) {
        return (() => {
          sportEventsService
            .getAll(getQueryParams(+page - 1) || {})
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response.data?.items) && setSportEvents(response.data.items);
              }
            })
            .catch(setSportEventsError);
        })();
      }
    }, [pathname, page, totalSportEventsCount, sportEventWasEditedToggler]);
  };
  paginate();

  const getDataForExistingSportEvent = (sportEventId, reset, onModalClose) => {
    sportEventsService
      .getById(sportEventId)
      .then(response => {
        if (response.status === 200) {
          const { devices, arbiters } = response?.data || {};
          reset({
            devices: Array.isArray(devices) ? devices.map(d => d.id) : [],
            arbiters: Array.isArray(arbiters) ? arbiters.map(a => a.id) : [],
          });
        }
      })
      .catch(e => {
        setSportEventsError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  };

  const createSportEvent = (newSportEvent, onModalClose) => {
    sportEventsService
      .createNew(newSportEvent)
      .then(response => {
        if (response.status === 200) {
          setTotalSportEventsCount(prevState => prevState + 1);
          responseHandler(true, 'Спортивное мероприятие было успешно создано', onModalClose, null);
        }
      })
      .catch(e => {
        setSportEventsError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  };

  const editSportEvent = (sportEventId, newSportEventData, onModalClose) => {
    sportEventsService
      .put(sportEventId, newSportEventData)
      .then(response => {
        if (response.status === 204) {
          setSportEventWasEditedToggler(prevState => !prevState);
          responseHandler(true, 'Спортивное мероприятие было успешно изменено', onModalClose, null);
        }
      })
      .catch(e => {
        setSportEventsError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  };

  const removeSportEvent = (sportEventId, onModalClose) => {
    sportEventsService
      .deleteById(sportEventId)
      .then(response => {
        if (response.status === 204) {
          setTotalSportEventsCount(prevState => prevState - 1);
          responseHandler(true, 'Спортивное мероприятие было успешно удалено', onModalClose, null);
        }
      })
      .catch(e => {
        setSportEventsError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  };

  return {
    sportEvents,
    isSportEventsLoading,
    totalSportEventsCount,
    sportEventsError,

    createSportEvent,
    editSportEvent,
    removeSportEvent,
    getDataForExistingSportEvent,
    setChosenSportType,
    chosenSportType,
  };
};
