import { useCallback } from 'react';
import { useStateContext } from 'store';

import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import {
  ModalArbitersAdd,
  ModalArbitersEdit,
  ModalArbitersChangePassword,
} from 'containers/UI/Modal/Components/Arbiters';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as PasswordChange } from 'icons/passwordChange.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';

export const useArbiters = () => {
  const { openPopup, arbiters, isArbitersLoading, totalArbitersCount, removeArbiter } = useStateContext();

  const handleArbiterCreate = useCallback(() => {
    openPopup(() => <ModalArbitersAdd />, {
      title: 'Создание судьи',
    });
  }, []);

  const handleChangePassword = useCallback(arbiter => {
    openPopup(() => <ModalArbitersChangePassword arbiter={arbiter} />, {
      title: 'Смена пароля',
    });
  }, []);

  const handleEdit = useCallback(arbiter => {
    openPopup(() => <ModalArbitersEdit arbiterData={arbiter} />, {
      title: 'Редактирование судьи',
    });
  }, []);

  const handleDelete = useCallback(arbiterId => {
    openPopup(
      () => (
        <ModalDelete
          contentId={arbiterId}
          title="Вы уверены что хотите удалить судью?"
          description="После удаления все данные по этому судьи станут недоступны, удалить?"
          onDelete={removeArbiter}
        />
      ),
      {
        title: 'Удаление судьи',
      },
    );
  }, []);

  const header = [
    { key: 'email', title: 'Email' },
    { key: 'filesAvailability', title: 'Доступ к файлам' },
    {
      key: 'actions',
      title: (
        <div key="arbiter-head-button" className={tableStyles.rowButtons}>
          <Button key="arbiter-create" className={tableStyles.headerButton} onClick={handleArbiterCreate}>
            Создать судью
          </Button>
        </div>
      ),
    },
  ];

  const body = arbiters?.map(arbiter => {
    const { id, email, permissions } = arbiter || {};
    const { filesAvailability } = permissions;

    return {
      email,
      filesAvailability: filesAvailability ? 'Да' : 'Нет',
      actions: (
        <div key="arbiter-item-buttons" className={tableStyles.rowButtons}>
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Изменить'}
            onClick={() => handleEdit(arbiter)}
          />
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <PasswordChange /> }}
            title={'Изменить пароль'}
            onClick={() => handleChangePassword(arbiter)}
          />
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить'}
            onClick={() => handleDelete(id)}
          />
        </div>
      ),
    };
  });

  return {
    header,
    body,
    isArbitersLoading,
    totalArbitersCount,
  };
};
