import { useApi } from 'services/hooks/useApi';
import { screenTemplatesService } from 'services';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { URLs } from 'helpers/constants/URLs';
import { setLocalStorage } from 'helpers/localStorage';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { responseHandler } from '../helpers/responseHandler';
import { organisationsService } from '../../services/organisations.service';

export const useScreenTemplatesState = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [screenTemplateData, setScreenTemplateData] = useState({});

  const [
    screenTemplates,
    setScreenTemplates,
    isScreenTemplatesLoading,
    screenTemplatesError,
    setScreenTemplatesError,
    totalScreenTemplatesCount,
    setTotalScreenTemplatesCount,
  ] = useApi(() => screenTemplatesService.getAll(getQueryParams(+page - 1) || {}));

  const [screenTemplatesWasEditedToggler, setScreenTemplatesWasEditedToggler] = useState(false);
  const [allClientsGroups, setAllClientsGroups] = useState([]);

  const updateScreenTemplatesWidgets = useCallback((screenTemplateId, updatedWidgets, onModalClose) => {
    screenTemplatesService
      .updateAllowedScreenTemplatesWidgets(screenTemplateId, updatedWidgets)
      .then(response => {
        if ([200, 204].includes(response.status)) {
          responseHandler(true, 'Виджеты были успешно обновлены', onModalClose, null);

          setLocalStorage('screenTemplates', {
            [screenTemplateId]: {
              timestamp: response.data.timestamp,
              ...updatedWidgets,
            },
          });

          setLocalStorage('screenTemplates_timestamp', {
            [screenTemplateId]: response.data.timestamp,
          });
        }
      })
      .catch(e => {
        setScreenTemplatesError(e.response.data.code);
        responseHandler(false, 'Виджеты не были обновлены', onModalClose, e.response.data.code);
      });
  }, []);

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.screenTemplates) {
        return (() => {
          screenTemplatesService
            .getAll(getQueryParams(+page - 1) || {})
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response.data?.items) && setScreenTemplates(response.data.items);
              }
            })
            .catch(screenTemplatesError);
        })();
      }
    }, [pathname, page, totalScreenTemplatesCount, screenTemplatesWasEditedToggler]);
  };
  paginate();

  const addScreenTemplate = useCallback((screenTemplate, onModalClose) => {
    screenTemplatesService
      .createNew(screenTemplate)
      .then(response => {
        if (response.status === 200) {
          setTotalScreenTemplatesCount(prevState => prevState + 1);
          responseHandler(true, 'Шаблон рабочего стола был успешно создан', onModalClose);
        }
      })
      .catch(e => {
        setScreenTemplatesError(e);
        responseHandler(false, 'Шаблон рабочего стола не был создан', onModalClose, e.response.data.code);
      });
  }, []);

  const changeScreenTemplate = useCallback((screenTemplateNewData, onModalClose) => {
    screenTemplatesService
      .put(screenTemplateNewData.id, screenTemplateNewData)
      .then(response => {
        if (response.status === 204) {
          setScreenTemplatesWasEditedToggler(prevState => !prevState);
          responseHandler(true, 'Данные шаблона были успешно изменены', onModalClose);
        }
      })
      .catch(e => {
        setScreenTemplatesError(e);
        responseHandler(false, 'Данные шаблона не были изменены', onModalClose, e.response.data.code);
      });
  }, []);

  const removeScreenTemplate = useCallback((screenTemplateId, onModalClose) => {
    screenTemplatesService
      .deleteById(screenTemplateId)
      .then(response => {
        if (response.status === 204) {
          setTotalScreenTemplatesCount(prevState => prevState - 1);
          responseHandler(true, 'Шаблон был успешно удален', onModalClose, null);
        }
      })
      .catch(e => {
        setScreenTemplatesError(e);
        responseHandler(false, null, onModalClose, e.response.data.code);
      });
  }, []);

  const getScreenTemplateById = useCallback(screenTemplateId => {
    screenTemplatesService
      .getById(screenTemplateId)
      .then(response => {
        if (response.status === 200) {
          setScreenTemplateData(response?.data);
        }
      })
      .catch(setScreenTemplatesError);
  }, []);

  const getAllClientsGroups = () => {
    organisationsService.getGroups().then(response => {
      if (response.status === 200) {
        if (Array.isArray(response?.data)) {
          const clientsGroupsForDropdown = response.data.map(clientGroup => ({
            name: clientGroup.name,
            value: clientGroup.id,
          }));
          setAllClientsGroups(clientsGroupsForDropdown);
        }
      }
    });
  };

  return {
    screenTemplates,
    screenTemplateData,
    setScreenTemplateData,
    totalScreenTemplatesCount,
    isScreenTemplatesLoading,
    screenTemplatesError,

    addScreenTemplate,
    changeScreenTemplate,
    removeScreenTemplate,
    getScreenTemplateById,
    updateScreenTemplatesWidgets,
    getAllClientsGroups,
    allClientsGroups,
  };
};
