import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { WIDGETS_COMPONENTS } from 'containers/Desktops/helpers';

import styles from './GuideLines.module.css';

export const GuideLines = ({ x = 0, y = 0, resize = false }) => {
  const { indexSelect, watch, selectWidgetIndex } = useFormContext();
  const list = watch(`screens[${indexSelect}].widgets`) || [];
  const widgets = list.filter(widget => !!WIDGETS_COMPONENTS[widget?.type]);
  const widget = widgets[selectWidgetIndex] || {};

  const [xAxis, setXAxis] = useState(false);
  const [yAxis, setYAxis] = useState(false);

  const calculateXAxis = ({
    top,
    topTarget,
    bottom,
    bottomTarget,
    left: leftCurrent,
    leftTarget,
    right: rightCurrent,
    righTarget,
  }) => {
    const left = leftCurrent < leftTarget ? leftCurrent : leftTarget;
    const right = rightCurrent > righTarget ? rightCurrent : righTarget;
    const width = right - left;

    // console.log(bottomTarget, ' - ', bottom);
    if (topTarget === top || topTarget === bottom) {
      return { top: topTarget, left, width };
    } else if (bottomTarget === top || bottomTarget === bottom) {
      return { top: bottomTarget, left, width };
    } else {
      return false;
    }
  };
  const calculateYAxis = ({
    left,
    leftTarget,
    right,
    righTarget,
    top: topCurrent,
    topTarget,
    bottom: bottomCurrent,
    bottomTarget,
  }) => {
    const top = topCurrent < topTarget ? topCurrent : topTarget;
    const bottom = bottomCurrent > bottomTarget ? bottomCurrent : bottomTarget;
    const height = bottom - top;

    if (leftTarget === left || leftTarget === right) {
      return { left: leftTarget, top, height };
    } else if (righTarget === left || righTarget === right) {
      return { left: righTarget, top, height };
    } else {
      return false;
    }
  };
  useEffect(() => {
    widgets.forEach(({ top, left, width, height, position, ...other }, i) => {
      if (i === selectWidgetIndex) return;

      // top and bottom current checked widget
      const bottom = Math.round(top + (height || position?.height));
      console.log(other);
      // left and right current checked widget
      const right = Math.round(left + (width || position?.width));
      // topTarget and bottomTarget - top and buttom moving widget
      const topTarget = Math.round(resize ? y : widget?.top + y);
      const bottomTarget = Math.round(topTarget + widget?.height);
      // leftTarget and righTarget - top and buttom moving widget
      const leftTarget = Math.round(resize ? x : widget?.left + x);
      const righTarget = Math.round(leftTarget + widget?.width);

      const data = {
        top: Math.round(top),
        topTarget,
        bottom,
        bottomTarget,
        left: Math.round(left),
        leftTarget,
        right,
        righTarget,
      };
      setXAxis(calculateXAxis(data));
      setYAxis(calculateYAxis(data));
    });
  }, [x, y]);

  return (
    <>
      {xAxis && <div style={xAxis} className={styles['x-axis']} />}
      {yAxis && <div style={yAxis} className={styles['y-axis']} />}
    </>
  );
};

GuideLines.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  resize: PropTypes.bool,
};
