import { createContext } from 'react';

export const ScheduleContext = createContext({
  playlists: [],
  schedulePlaylists: [],
  loading: false,
  timeWidth: 0,
  tableWidth: 0,
  scale: 0,
  start: 0,
  secondsOnScreen: 0,
  totalSeconds: 0,

  undo: () => {},
  setLoading: () => {},
  setTableWidth: () => {},
  setTimeWidth: () => {},
  pushLocalChange: () => {},
  updateStartValue: () => {},
  saveSchedulePlaylists: () => {},
});
