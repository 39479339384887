import { Table } from 'containers/UI/Table';
import { useAdmins } from './useAdmins';

const Administrators = () => {
  const { header, body, isAdminsLoading, totalAdminsCount } = useAdmins();

  return (
    <Table
      heading={header}
      body={body}
      isLoading={isAdminsLoading}
      totalEntitiesCount={totalAdminsCount}
      idKey="email"
      emptyText={'Вы еще не добавили ни одного администратора'}
    />
  );
};

export default Administrators;
