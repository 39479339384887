import { useState, useCallback } from 'react';

const DEFAULT_OPTIONS = {
  render: null,
  options: {},
};

export function usePopupDataState() {
  const [popupData, setPopupData] = useState(DEFAULT_OPTIONS);

  const openPopup = useCallback((render, options = {}) => setPopupData(prev => ({ ...prev, render, options })), []);

  const closePopup = useCallback(() => setPopupData(DEFAULT_OPTIONS), []);

  return {
    popupData,
    openPopup,
    closePopup,
  };
}
