import PropTypes from 'prop-types';

import { getSelectedRangeStyle } from 'containers/Desktops/helpers';

import styles from './SelectedRange.module.css';

export const SelectedRange = ({ coords = {}, sizeCoeff }) => {
  return <div style={getSelectedRangeStyle(coords, sizeCoeff)} className={styles['wrapper']} />;
};

SelectedRange.propTypes = {
  coords: PropTypes.object,
  sizeCoeff: PropTypes.number,
};
