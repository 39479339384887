import PropTypes from 'prop-types';
import { useStateContext } from 'store';
import { useCallback } from 'react';
import classnames from 'classnames';
import { ReactComponent as QuestionIcon } from 'icons/question.svg';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from '../ModalDelete.module.css';

export const ModalDelete = props => {
  const { contentId, title, description, isVisibleIcon, onDelete, onClose } = props;
  const { closePopup } = useStateContext();

  const isCompact = !!description;

  const handleDelete = useCallback(() => {
    onDelete(contentId, closePopup);
  }, [onDelete]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      closePopup();
    }
  };

  return (
    <div
      className={classnames(styles.delete_wrapper, {
        [styles.delete_wrapper__compact]: isCompact,
        [styles.delete_wrapper__full]: !isCompact,
      })}
    >
      {isVisibleIcon && <QuestionIcon className={styles.delete_wrapper_icon} />}
      <div className={styles.delete_wrapper_body}>
        <div className={styles.delete_wrapper_title}>{title}</div>
        {description && <span className={styles.delete_wrapper_text}>{description}</span>}

        <div className={styles.delete_wrapper_actions}>
          <Button onClick={handleDelete}>Удалить</Button>
          <Button variant={ButtonVariantEnum.SOLID_SECONDARY} onClick={handleClose}>
            Отмена
          </Button>
        </div>
      </div>
    </div>
  );
};

ModalDelete.defaultProps = {
  contentId: null,
  title: '',
  description: '',
  isVisibleIcon: true,
  onDelete: () => {},
};

ModalDelete.propTypes = {
  contentId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  isVisibleIcon: PropTypes.bool,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
};
