import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SelectVariantEnum } from './Constants/SelectVariantEnum';
import { ReactComponent as Close } from 'icons/close.svg';
import { ReactComponent as Arrow } from 'icons/triangle.svg';
import { useOnClickOutside } from 'containers/Hooks';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SelectOption } from './Components/SelectOption/SelectOption';

import styles from './Select.module.css';

export const Select = forwardRef((props, ref) => {
  const {
    className = '',
    wrapperClassName = '',
    selected = [],
    options = [],
    variant = SelectVariantEnum.FLAT,
    isMulti,
    searchable = false,
    label = '',
    icon = null,
    customArrowIcon = null,
    disabled = false,
    placeholder = '',
    isInfinityLoad = false,
    onLoad = () => {},
    onChange = () => {},
    error,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filterOptions, setFilterOptions] = useState(options);

  const selectRef = useRef(null);
  useImperativeHandle(ref, () => selectRef.current);

  useOnClickOutside(selectRef, () => setIsOpen(false));

  useEffect(() => {
    setFilterOptions(options);
  }, [options]);

  const onClick = () => setIsOpen(prev => !prev);

  const handleChangeSearch = e => {
    const value = e.target.value;
    const _filterOptions = options.filter(el => el.name.trim().toLowerCase().includes(value.trim().toLowerCase()));

    setSearchValue(value);
    setIsOpen(true);
    setFilterOptions(_filterOptions.length ? _filterOptions : [{ name: 'Результатов не найдено', value: null }]);
  };

  const onDeleteOption = value => {
    onChange(selected.filter(x => x !== value));
  };

  const onClickOption = selectValue => {
    if (isMulti) {
      onChange([...selected, selectValue]);
    } else {
      setIsOpen(false);
      onChange(selectValue);
    }
    setSearchValue('');
    setFilterOptions(options);
  };

  const onDeleteAll = e => {
    e.stopPropagation();
    setIsOpen(false);
    setSearchValue('');
    setFilterOptions(options);
    onChange(isMulti ? [] : null);
  };

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom && isInfinityLoad) {
      onLoad();
    }
  };

  const stopPropagation = e => e.stopPropagation();

  const renderValues = () => {
    if (isMulti) {
      return options
        .filter(x => selected.includes(x.value))
        .map(({ value, name }) => (
          <span
            key={value}
            className={classnames(styles.select_multiple, styles.select_value)}
            onClick={stopPropagation}
          >
            <span className={styles.select_value_text}>{name}</span>
            <span className={styles.select_delete} onClick={() => onDeleteOption(value)}>
              <Close />
            </span>
          </span>
        ));
    }

    const selectedOption = options.filter(x => selected === x.value)[0];
    if (selectedOption) {
      return <div className={styles.select_value}>{selectedOption.name}</div>;
    }
    return <></>;
  };

  const isEmpty = !selected || selected.length === 0;

  const selectedOption = options.filter(x => selected === x.value)[0];

  return (
    <div className={classnames(styles.select_wrapper, { [wrapperClassName]: !!wrapperClassName })}>
      {label && <label className={styles.select_label}>{label}</label>}
      <div
        ref={selectRef}
        className={classnames(styles.select, styles[`select__${variant}`], {
          [styles.select_opened]: isOpen,
          [styles.select_disabled]: disabled,
          [styles.select_searchable]: searchable,
          [styles.select__with_error]: error,
          [className]: !!className,
        })}
        tabIndex="0"
      >
        <div className={styles.select_selection} onClick={onClick}>
          {icon && <div className={styles.select_icon}>{icon}</div>}
          {searchable && !isMulti && (
            <div className={styles.select_search_wrapper}>
              <input type="text" className={styles.select_search} value={searchValue} onInput={handleChangeSearch} />
            </div>
          )}
          <div className={classnames(styles.select_values, { [styles.select_values_multi]: isMulti })}>
            {((isEmpty && isMulti) || (searchable && !isMulti) || (!searchable && isEmpty)) && (
              <div
                className={classnames(styles.select_placeholder, {
                  [styles.select_placeholder_hidden]: searchValue,
                  [styles.select_placeholder_active]: !isEmpty,
                })}
              >
                {selectedOption && !isMulti ? selectedOption.name : placeholder || label || options[0]?.name}
              </div>
            )}
            {(isMulti ? !isEmpty : !searchable && !isEmpty) && renderValues()}
            {searchable && isMulti && (
              <div className={styles.select_search_wrapper}>
                <input type="text" className={styles.select_search} value={searchValue} onInput={handleChangeSearch} />
              </div>
            )}
          </div>
          <div className={styles.select_actions}>
            {isMulti && !isEmpty && (
              <span className={styles.select_delete_all} onClick={onDeleteAll}>
                <Close />
              </span>
            )}
            <span className={styles.select_arrow}>
              {customArrowIcon}
              {!customArrowIcon && <Arrow />}
            </span>
          </div>
        </div>
        <div className={styles.select_options} onScroll={handleScroll}>
          {filterOptions.map(({ name, value }) => (
            <SelectOption
              key={value}
              value={value}
              name={name}
              isMulti={isMulti}
              selected={(isMulti && selected.includes(value)) || selected === value}
              onClickOption={onClickOption}
            />
          ))}
        </div>
      </div>

      {error && <div className={styles.select__error}>{error}</div>}
    </div>
  );
});

Select.displayName = 'Select';

Select.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object,
  name: PropTypes.string,
  wrapperClassName: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  isMulti: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  customArrowIcon: PropTypes.element,
  variant: PropTypes.oneOf([SelectVariantEnum.FLAT, SelectVariantEnum.BORDERED, SelectVariantEnum.NOT_BORDERED]),
  label: PropTypes.string,
  isInfinityLoad: PropTypes.bool,
  onLoad: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
