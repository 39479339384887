import { useEffect, useMemo, useState } from 'react';
import { arbitersService } from 'services';

export function useArbitersLoad() {
  const [arbiters, setArbiters] = useState([]);
  const [arbitersCount, setArbitersCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const arbitersOptions = useMemo(
    () => arbiters.map(arbiter => ({ name: arbiter.email, value: arbiter.id })),
    [arbiters],
  );

  useEffect(() => {
    setLoading(true);

    arbitersService
      .getAll({ skip: 0, take: 10 })
      .then(response => {
        if (response.status === 200) {
          Array.isArray(response.data?.items) && setArbiters(response.data.items);
          setArbitersCount(response.data.count);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleArbitersLoad = () => {
    if (arbiters.length < arbitersCount) {
      arbitersService
        .getAll({ skip: arbiters.length, take: 10 })
        .then(response => {
          if (response.status === 200) {
            if (Array.isArray(response.data?.items)) {
              setArbiters(prev => [...prev, ...response.data.items]);
              setArbitersCount(response.data.count);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return {
    isLoading,
    setLoading,
    arbiters,
    arbitersCount,
    arbitersOptions,
    handleArbitersLoad,
  };
}
