export const RESIZE_HANDLES = ['se', 'ne', 'sw', 'nw', 's', 'w', 'e', 'n'];
export const widgetResize = ({ handle = 'se', prev, newSize, clientWidth, clientHeight, isFullScreen }) => {
  if (isFullScreen) {
    return prev;
  }
  const newTop = prev?.top + (prev?.height - newSize?.height);
  const newLeft = prev?.left + (prev?.width - newSize?.width);

  const newWidth = clientWidth - (prev?.left + newSize?.width) > 0 ? newSize?.width : clientWidth - prev?.left;
  const newHeight = clientHeight - (prev?.top + newSize?.height) > 0 ? newSize?.height : clientHeight - prev?.top;
  if (handle === 'se') {
    return {
      ...prev,
      width: newSize?.width < prev?.width ? newSize?.width : newWidth,
      height: newSize?.height < prev?.height ? newSize?.height : newHeight,
    };
  }
  if (handle === 'nw') {
    return {
      top: newTop > 0 ? newTop : 0,
      left: newLeft > 0 ? newLeft : 0,
      height: newTop > 0 ? newSize?.height : prev?.height,
      width: newLeft > 0 ? newSize?.width : prev?.width,
    };
  }
  if (handle === 'sw') {
    return {
      ...prev,
      left: newLeft > 0 ? newLeft : 0,
      height: newSize?.height < prev?.height ? newSize?.height : newHeight,
      width: newLeft > 0 ? newSize?.width : prev?.width,
    };
  }
  if (handle === 'ne') {
    return {
      ...prev,
      top: newTop > 0 ? newTop : 0,
      height: newTop > 0 ? newSize?.height : prev?.height,
      width: newSize?.width < prev?.width ? newSize?.width : newWidth,
    };
  }
  if (handle === 's') {
    return {
      ...prev,
      height: newSize?.height < prev?.height ? newSize?.height : newHeight,
    };
  }
  if (handle === 'w') {
    return {
      ...prev,
      left: newLeft > 0 ? newLeft : 0,
      width: newLeft > 0 ? newSize?.width : prev?.width,
    };
  }
  if (handle === 'e') {
    return {
      ...prev,
      width: newSize?.width < prev?.width ? newSize?.width : newWidth,
    };
  }
  if (handle === 'n') {
    return {
      ...prev,
      top: newTop > 0 ? newTop : 0,
      height: newTop > 0 ? newSize?.height : prev?.height,
    };
  }
};

export const getWidgetNewPositionsAndSizes = ({
  handle = 'se',
  widget,
  widgetsInitialSizes,
  groupInitialSize,
  currentGroupSize,
  clientWidth,
  clientHeight,
}) => {
  const { width: currentGroupWidth, height: currentGroupHeight } = currentGroupSize;
  const widthGroupDelta = currentGroupWidth - groupInitialSize.width;
  const heightGroupDelta = currentGroupHeight - groupInitialSize.height;

  const newWidth =
    clientWidth - (widgetsInitialSizes[widget.id].width + widthGroupDelta) > 0
      ? widgetsInitialSizes[widget.id].width + widthGroupDelta
      : clientWidth - widgetsInitialSizes[widget.id].width + widthGroupDelta;
  const newHeight =
    clientHeight - (widgetsInitialSizes[widget.id].height + heightGroupDelta) > 0
      ? widgetsInitialSizes[widget.id].height + heightGroupDelta
      : clientHeight - widgetsInitialSizes[widget.id].height + heightGroupDelta;

  const newTop = widgetsInitialSizes[widget.id].top + (groupInitialSize.height - currentGroupHeight);
  const newLeft = widgetsInitialSizes[widget.id].left + (groupInitialSize.width - currentGroupWidth);

  if (handle === 's') {
    return {
      ...widget,
      height: newHeight,
    };
  }

  if (handle === 'e') {
    return {
      ...widget,
      width: newWidth,
    };
  }

  if (handle === 'se') {
    return {
      ...widget,
      width: newWidth,
      height: newHeight,
    };
  }

  if (handle === 'sw') {
    return {
      ...widget,
      width: newWidth,
      height: newHeight,
      left: newLeft,
    };
  }

  if (handle === 'w') {
    return {
      ...widget,
      left: newLeft,
      width: newWidth,
    };
  }

  if (handle === 'nw') {
    return {
      ...widget,
      width: newWidth,
      height: newHeight,
      top: newTop,
      left: newLeft,
    };
  }

  if (handle === 'n') {
    return {
      ...widget,
      top: newTop,
      height: newHeight,
    };
  }

  if (handle === 'ne') {
    return {
      ...widget,
      top: newTop,
      width: newWidth,
      height: newHeight,
    };
  }
};
