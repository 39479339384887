import { useCallback } from 'react';
import { useStateContext } from 'store';

import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import {
  ModalOperatorsAdd,
  ModalOperatorsEdit,
  ModalOperatorsChangePassword,
} from 'containers/UI/Modal/Components/Operators';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as PasswordChange } from 'icons/passwordChange.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';

export const useOperators = () => {
  const { openPopup, operators, isOperatorsLoading, totalOperatorsCount, removeOperator } = useStateContext();

  const handleOperatorCreate = useCallback(() => {
    openPopup(() => <ModalOperatorsAdd />, {
      title: 'Создание оператора',
    });
  }, []);

  const handleChangePassword = useCallback(operator => {
    openPopup(() => <ModalOperatorsChangePassword operator={operator} />, {
      title: 'Смена пароля',
    });
  }, []);

  const handleEdit = useCallback(operator => {
    openPopup(() => <ModalOperatorsEdit operatorData={operator} />, {
      title: 'Редактирование оператора',
    });
  }, []);

  const handleDelete = useCallback(operatorId => {
    openPopup(
      () => (
        <ModalDelete
          contentId={operatorId}
          title={'Вы уверены что хотите удалить оператора?'}
          description={'После удаления все данные по этому оператору станут недоступны, удалить?'}
          onDelete={removeOperator}
        />
      ),
      {
        title: 'Удаление оператора',
      },
    );
  }, []);

  const header = [
    { key: 'email', title: 'Email' },
    { key: 'countDevices', title: 'Количество устройств' },
    { key: 'filesAvailability', title: 'Доступ к файлам' },
    { key: 'schedulingsAvailability', title: 'Доступ к расписаниям' },
    { key: 'isBlocked', title: '' },
    {
      key: 'actions',
      title: (
        <div key="operator-head-button" className={tableStyles.rowButtons}>
          <Button key="operator-create" className={tableStyles.headerButton} onClick={handleOperatorCreate}>
            Создать оператора
          </Button>
        </div>
      ),
    },
  ];

  const body = operators?.map(operator => {
    const { id, email, deviceCount, permissions, isBlocked = false } = operator || {};
    const { filesAvailability, schedulingsAvailability } = permissions;

    return {
      email,
      countDevices: deviceCount,
      filesAvailability: filesAvailability ? 'Да' : 'Нет',
      schedulingsAvailability: schedulingsAvailability ? 'Да' : 'Нет',
      isBlocked: isBlocked && 'Заблокирован',
      actions: (
        <div key="operator-item-buttons" className={tableStyles.rowButtons}>
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Изменить'}
            onClick={() => handleEdit(operator)}
          />
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <PasswordChange /> }}
            title={'Изменить пароль'}
            onClick={() => handleChangePassword(operator)}
          />
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить'}
            onClick={() => handleDelete(id)}
          />
        </div>
      ),
    };
  });

  return {
    header,
    body,
    isOperatorsLoading,
    totalOperatorsCount,
  };
};
