import { useApi } from 'services/hooks/useApi';
import { playlistsService } from 'services';
import { useCallback, useEffect, useState } from 'react';
import { responseHandler } from '../helpers/responseHandler';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { URLs } from 'helpers/constants/URLs';

export const usePlaylistsState = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [
    playlists,
    setPlaylists,
    isPlaylistsLoading,
    playlistsError,
    setPlaylistsError,
    totalPlaylistsCount,
    setTotalPlaylistsCount,
  ] = useApi(() => playlistsService.getPlaylists(getQueryParams(+page - 1) || {}));

  const [playlistWasEdited, setPlaylistWasEdited] = useState(false);
  const [playlistScreens, setPlaylistScreens] = useState([]);
  const [groupNameWasChangedInPlaylists, setGroupNameWasChangedInPlaylists] = useState(false);

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.playlists) {
        return (() => {
          playlistsService
            .getPlaylists(getQueryParams(+page - 1) || {})
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response.data?.items) && setPlaylists(response.data.items);
              }
            })
            .catch(setPlaylistsError);
        })();
      }
    }, [pathname, page, totalPlaylistsCount, playlistWasEdited, groupNameWasChangedInPlaylists]);
  };
  paginate();

  const addPlaylist = useCallback((newPlaylist, onModalClose) => {
    return (() => {
      playlistsService
        .addPlaylist(newPlaylist)
        .then(result => {
          if (result.status === 200) {
            setTotalPlaylistsCount(prevState => prevState + 1);
            responseHandler(true, 'Плейлист был успешно создан', onModalClose);
          }
        })
        .catch(e => {
          setPlaylistsError(e);
          responseHandler(false, '', onModalClose, e.response.data.code);
        });
    })();
  }, []);

  const copyPlaylistWithScreens = (newPlaylist, onModalClose) => {
    return (() => {
      playlistsService
        .addPlaylist(newPlaylist)
        .then(response => {
          if (response.status === 200) {
            const newPlaylistId = response?.data?.id;

            playlistsService
              .addScreens(playlistScreens, newPlaylistId)
              .then(response => {
                if (response.status === 204) {
                  setTotalPlaylistsCount(prevState => prevState + 1);
                  responseHandler(true, 'Плейлист был успешно скопирован', onModalClose);
                }
              })
              .catch(error => {
                setPlaylistsError(error);
                responseHandler(false, '', onModalClose, error.response.data.code);
              });
          }
        })
        .catch(e => {
          setPlaylistsError(e);
          responseHandler(false, '', onModalClose, e.response.data.code);
        });
    })();
  };

  const getPlaylistScreens = useCallback(existingPlaylistId => {
    playlistsService
      .getScreens(existingPlaylistId)
      .then(response => {
        if (response.status === 200) {
          response?.data && setPlaylistScreens(response.data);
        }
      })
      .catch(setPlaylistsError);
  }, []);

  const editPlaylist = useCallback((playlistId, newPlaylistData, onModalClose) => {
    if (newPlaylistData.groupId === 'Без группы') {
      newPlaylistData.groupId = null;
    }

    return (() => {
      playlistsService
        .updatePlaylist(playlistId, newPlaylistData)
        .then(result => {
          if (result.status === 204) {
            setPlaylistWasEdited(prevState => !prevState);
            responseHandler(true, 'Плейлист был успешно изменен', onModalClose);
          }
        })
        .catch(e => {
          setPlaylistsError(e);
          responseHandler(false, '', onModalClose, e.response.data.code);
        });
    })();
  }, []);

  const removePlaylist = useCallback((playlistId, onModalClose) => {
    return (() => {
      playlistsService
        .deletePlaylist(playlistId)
        .then(result => {
          if (result.status === 204) {
            setTotalPlaylistsCount(prevState => prevState - 1);
            responseHandler(true, 'Плейлист был успешно удален', onModalClose);
          }
        })
        .catch(e => {
          setPlaylistsError(e);
          responseHandler(false, '', onModalClose, e.response.data.code);
        });
    })();
  }, []);

  return {
    playlists,
    isPlaylistsLoading,
    playlistsError,
    setPlaylistsError,
    addPlaylist,
    editPlaylist,
    removePlaylist,
    totalPlaylistsCount,
    setPlaylists,
    copyPlaylistWithScreens,
    getPlaylistScreens,
    setPlaylistScreens,
    playlistScreens,
    setGroupNameWasChangedInPlaylists,
  };
};
