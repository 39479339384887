import { useStateContext } from 'store';
import Leaflet from 'leaflet';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ButtonGroup } from 'containers/UI/ButtonGroup';
import { usePlaylistsLoad } from 'containers/Hooks/playlists/usePlaylistsLoad';
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import { useEffect, useRef } from 'react';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { DevicesMapMarker, DevicesMapControlButton, DevicesMapSearch } from './Components';
import { useDevicesMap } from './Hooks/useDevicesMap';
import { UserMessageAboutNoPositions } from './Components/UserMessageAboutNoPositions/UserMessageAboutNoPositions';

import 'leaflet/dist/leaflet.css';
import 'css/leaflet.css';
import styles from './DevicesMap.module.css';

Leaflet.Icon.Default.mergeOptions({ shadowUrl: markerShadow });

const DevicesMap = () => {
  const mapRef = useRef();
  const mountRef = useRef(false);
  const { allDevicesForMap: markers, isMinimumOneMarkerWithPosition, setIsMapOpened } = useStateContext();

  const {
    zoom,
    center,
    isFullscreen,
    fullscreenTextButton,
    onCenter,
    onCenterMarkers,
    toggleFullscreen,
    handleOpenAll,
    handleCloseAll,
  } = useDevicesMap(mapRef);

  const { playlistOptions, handlePlaylistsLoad } = usePlaylistsLoad();

  function LocationMarker() {
    const map = useMapEvents({
      locationfound(e) {
        map.panTo(e.latlng, { zoom: 13 });
      },
    });

    mapRef.current = map;

    useEffect(() => {
      if (!markers.length) {
        onCenter();
      } else if (markers.length > 0 && !mountRef.current) {
        onCenterMarkers(markers);
        mountRef.current = true;
      }
    }, []);

    return null;
  }

  const componentDidMount = function () {
    useEffect(() => {
      setIsMapOpened(true);
    }, []);
  };
  componentDidMount();

  const componentWillUnmount = function () {
    useEffect(() => {
      return () => {
        setIsMapOpened(false);
      };
    }, []);
  };
  componentWillUnmount();

  return (
    <div className={styles.devicesWrapper}>
      <ButtonGroup
        className={styles.devicesWrapperTabs}
        isNavigate
        items={[
          { url: '/devices', text: 'Список' },
          { url: '/devices/map', text: 'Карта' },
        ]}
      />

      {!isMinimumOneMarkerWithPosition() && <UserMessageAboutNoPositions />}

      {isMinimumOneMarkerWithPosition() && (
        <div
          className={classnames(styles.deviceMapContainer, { [styles.deviceMapContainer__fullscreen]: isFullscreen })}
        >
          <DevicesMapSearch mapRef={mapRef} />

          <MapContainer className={styles.deviceMapWrapper} center={center} zoom={zoom} minZoom={3} maxZoom={18}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <LocationMarker />

            {markers.length > 0 && (
              <>
                <DevicesMapControlButton text={fullscreenTextButton} onClick={toggleFullscreen} />
                <DevicesMapControlButton text={'Развернуть все'} onClick={handleOpenAll} />
                <DevicesMapControlButton text={'Свернуть все'} onClick={handleCloseAll} />

                {markers.map(marker => (
                  <DevicesMapMarker
                    key={`${marker.id}:${marker.position?.latitude}`}
                    deviceId={marker.id}
                    deviceData={marker}
                    playlistOptions={playlistOptions}
                    handlePlaylistsLoad={handlePlaylistsLoad}
                  />
                ))}
              </>
            )}
          </MapContainer>
        </div>
      )}
    </div>
  );
};

DevicesMap.defaultProps = { markers: [] };
DevicesMap.propTypes = { markers: PropTypes.array };

export default DevicesMap;
