import { toast } from 'react-toastify';

export const showSuccessNotify = (text, options = {}) => {
  toast.success(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    theme: 'dark',
    autoClose: 2000,
    ...options,
  });
};

export const showErrorNotify = (text, options = {}) => {
  toast.error(text, {
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    theme: 'dark',
    autoClose: 2000,
    ...options,
  });
};
