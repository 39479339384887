import { useStateContext } from 'store';
import { NavLink } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { UserRoleEnum } from 'helpers/constants';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import {
  ModalDevicesAdd,
  ModalDevicesEdit,
  ModalDevicesSnapshot,
  ModalDevicesSensors,
} from 'containers/UI/Modal/Components/Devices';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Camera } from 'icons/camera.svg';
import { ReactComponent as Sensors } from 'icons/sensor.svg';
import { ReactComponent as Logs } from 'icons/logs.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as ChartButton } from 'icons/chartButton.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';
import styles from '../Devices.module.css';

export const useDevices = () => {
  const {
    user,
    openPopup,
    devices,
    isDevicesLoading,
    totalDevicesCount,
    allOrganisationsListInDevices,
    setOrganisationFilterTypeForDevices,
    organisationFilterTypeForDevices,
    removeDevice,
  } = useStateContext();

  const isMaster = user.role === UserRoleEnum.MASTER;

  const handleFilterGroup = filterValue => {
    setOrganisationFilterTypeForDevices(filterValue);
  };

  const organisationsOptions = () => {
    const options = allOrganisationsListInDevices?.map(organisation => {
      return { name: organisation.name, value: organisation.id };
    });

    return [{ name: 'Все', value: null }, ...options];
  };

  const handleDeviceAdd = useCallback(() => {
    openPopup(() => <ModalDevicesAdd />, {
      title: 'Создать устройство',
    });
  }, []);

  const handleDeviceEdit = useCallback(deviceData => {
    openPopup(() => <ModalDevicesEdit deviceData={deviceData} isMaster={isMaster} />, {
      title: 'Редактирование устройства',
    });
  }, []);

  const handleDeviceSnapshot = useCallback(deviceData => {
    openPopup(() => <ModalDevicesSnapshot deviceData={deviceData} />, {
      title: 'Снимок',
    });
  }, []);

  const handleDeviceSensors = useCallback(deviceId => {
    openPopup(() => <ModalDevicesSensors deviceId={deviceId} />, {
      title: 'Датчики',
    });
  }, []);

  const handleDeviceDelete = useCallback(id => {
    openPopup(
      () => (
        <ModalDelete
          contentId={id}
          title={'Вы уверены что хотите удалить устройство?'}
          description={'После удаления все данные по этому устройству станут недоступны, удалить?'}
          onDelete={removeDevice}
        />
      ),
      {
        title: 'Удалить устройство',
      },
    );
  }, []);

  const heading = [
    { key: 'name', title: 'Название' },
    { key: 'id', title: 'Идентификатор' },
    { key: 'resolution', title: 'Разрешение' },
    { key: 'state', title: 'Статус' },
    { key: 'comments', title: 'Комментарий' },
    { key: 'contentType', title: 'Тип контента' },
    { key: 'playlistName', title: 'Плейлист/Презентация/Расписание' },
    { key: 'groupName', title: 'Группа плейлиста/презентации/расписания' },
    {
      key: 'actions',
      title: isMaster && (
        <div key="device-head-button" className={tableStyles.rowButtons}>
          <Button key="button-create-device" className={styles.headerButton} onClick={handleDeviceAdd}>
            Создать устройство
          </Button>
        </div>
      ),
    },
  ];

  const body = useMemo(
    () =>
      devices?.map(device => {
        const playlistOrPresentationOrSchedulingName =
          device?.content?.playlist?.name || device?.content?.presentation?.name || device?.content?.scheduling?.name;
        const groupName =
          device?.content?.playlist?.group?.name ||
          device?.content?.presentation?.group?.name ||
          device?.content?.scheduling?.group?.name;

        const { id, name, screenWidth, screenHeight, comment, state } = device;

        return {
          name: (
            <div key="device-item-name" className={styles.deviceItemEllipsis}>
              {name}
            </div>
          ),
          id: <div className={styles.idWrapper}>{id}</div>,
          resolution: `${screenWidth}×${screenHeight}`,
          state,
          comments: (
            <div key="device-item-comment" className={styles.deviceItemEllipsis}>
              {comment}
            </div>
          ),
          contentType: device?.content?.type,
          playlistName: playlistOrPresentationOrSchedulingName,
          groupName,
          actions: (
            <div key="device-item-buttons" className={tableStyles.rowButtons}>
              <Button
                key="device-action-edit"
                variant={ButtonVariantEnum.ICON}
                iconData={{ icon: <Edit /> }}
                title={'Изменить'}
                onClick={() => handleDeviceEdit(device)}
              />
              <Button
                key="device-action-snapshot"
                variant={ButtonVariantEnum.ICON}
                iconData={{ icon: <Camera /> }}
                title={'Снимок'}
                disabled={state !== 'online'}
                onClick={() => handleDeviceSnapshot(device)}
              />
              <Button
                key="device-action-sensors"
                variant={ButtonVariantEnum.ICON}
                iconData={{ icon: <Sensors /> }}
                title={'Датчики'}
                disabled={state !== 'online'}
                onClick={() => handleDeviceSensors(device.id)}
              />
              <NavLink to={'/devices/' + id + '/logs'}>
                <Button
                  key="device-action-logs"
                  variant={ButtonVariantEnum.ICON}
                  iconData={{ icon: <Logs /> }}
                  title={'Логирование'}
                />
              </NavLink>

              <NavLink to={'/devices/' + id + '/chart'}>
                <Button
                  key="device-action-chart"
                  variant={ButtonVariantEnum.ICON}
                  iconData={{ icon: <ChartButton /> }}
                  title={'График по датчикам'}
                />
              </NavLink>

              {isMaster && (
                <Button
                  key="device-action-delete"
                  variant={ButtonVariantEnum.ICON}
                  iconData={{ icon: <Delete /> }}
                  title={'Удалить'}
                  onClick={() => handleDeviceDelete(device.id)}
                />
              )}
            </div>
          ),
        };
      }),
    [devices],
  );

  return {
    heading,
    body,
    handleDeviceAdd,
    isDevicesLoading,
    totalDevicesCount,
    handleFilterGroup,
    organisationsOptions,
    organisationFilterTypeForDevices,
  };
};
