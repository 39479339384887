import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useStateContext } from 'store';

import { ReactComponent as PlusIcon } from 'icons/plus.svg';

import { SelectForm } from 'containers/UI/Select';
import { Input, InputParseErrors } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';

import styles from '../ModalPlaylistsAdd/ModalPlaylists.module.css';

export const ModalPlaylistsEdit = ({ playlist, groupsOptions, handleCreateGroup }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', defaultValues: { ...playlist, groupId: playlist?.group?.id } });

  const { closePopup, editPlaylist } = useStateContext();
  const groupId = watch('groupId');
  const { group } = playlist;

  const save = form => {
    const payload = {
      name: form.name,
      comment: form.comment,
      groupId,
    };
    editPlaylist(playlist.id, payload, closePopup);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(save)}>
      <Input
        label="Название"
        placeholder="Название"
        className={styles.input}
        error={InputParseErrors(errors.name, { minLength: 4, maxLength: 30 })}
        {...register('name', { required: true, minLength: 4, maxLength: 30, setValueAs: v => v.trim() })}
      />

      <Input
        label="Комментарий"
        placeholder="Комментарий"
        className={styles.input}
        error={InputParseErrors(errors.comment, { minLength: 4, maxLength: 100 })}
        {...register('comment', { minLength: 4, maxLength: 100, setValueAs: v => v.trim() })}
      />

      <div className={styles.form_select_action}>
        <SelectForm
          name="groupId"
          control={control}
          placeholder={!group ? 'Без группы' : group.name}
          label={'Группа'}
          options={groupsOptions}
          rules={{ required: false }}
        />

        <Button
          className={styles.form_create_group_btn}
          variant={ButtonVariantEnum.ICON}
          iconData={{ icon: <PlusIcon /> }}
          title={'Создание группы'}
          onClick={handleCreateGroup}
        />
      </div>

      <div className={styles.actions}>
        <Button type="submit" disabled={!isValid}>
          Сохранить
        </Button>
        <Button variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
          Закрыть
        </Button>
      </div>
    </form>
  );
};

ModalPlaylistsEdit.defaultProps = {
  playlist: null,
  groupsOptions: [],
};

ModalPlaylistsEdit.propTypes = {
  playlist: PropTypes.object,
  groupsOptions: PropTypes.array,
  handleCreateGroup: PropTypes.func,
};
