import { useCallback, useEffect, useState } from 'react';
import { useApi } from 'services/hooks/useApi';
import { operatorsService } from 'services/operators.service';
import { responseHandler } from '../helpers/responseHandler';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { URLs } from 'helpers/constants/URLs';

export function useOperatorsState() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();

  const [operators, setOperators, isOperatorsLoading, error, setError, totalOperatorsCount, setTotalOperatorsCount] =
    useApi(() => operatorsService.getAll(getQueryParams(+page - 1) || {}));

  const [fullOperatorData, setFullOperatorData] = useState({});

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.operators) {
        return (() => {
          operatorsService
            .getAll(getQueryParams(+page - 1) || {})
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response.data?.items) && setOperators(response.data.items);
              }
            })
            .catch(setError);
        })();
      }
    }, [pathname, page, totalOperatorsCount]);
  };
  paginate();

  const addOperator = useCallback((newOperator, onModalClose) => {
    return (() => {
      operatorsService
        .createNew(newOperator)
        .then(response => {
          if (response.status === 200) {
            setTotalOperatorsCount(prevState => prevState + 1);
            responseHandler(true, 'Оператор был успешно создан', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Оператор не был создан', onModalClose);
        });
    })();
  }, []);

  const removeOperator = useCallback((operatorId, onModalClose) => {
    return (() => {
      operatorsService
        .deleteById(operatorId)
        .then(response => {
          if (response.status === 204) {
            setOperators(prev => prev.filter(el => el.id !== operatorId));
            setTotalOperatorsCount(prevState => prevState - 1);
            responseHandler(true, 'Оператор был успешно удалён', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Оператор не был удалён', onModalClose);
        });
    })();
  }, []);

  const updateOperator = useCallback((operatorNewData, onModalClose) => {
    return (() => {
      operatorsService
        .put(operatorNewData.id, operatorNewData)
        .then(response => {
          if (response.status === 204) {
            setOperators(prev => [
              ...prev.map(el => {
                if (el.id === operatorNewData.id) return { ...el, ...operatorNewData };
                return el;
              }),
            ]);
            responseHandler(true, 'Оператор был успешно изменён', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Оператор не был изменён', onModalClose);
        });
    })();
  }, []);

  const changeOperatorPassword = useCallback((operatorNewData, onModalClose) => {
    return (() => {
      operatorsService
        .changePassword(operatorNewData.id, operatorNewData.password)
        .then(response => {
          if (response.status === 204) {
            setOperators(prev => [
              ...prev.map(el => {
                if (el.id === operatorNewData.id) return { ...el, ...operatorNewData };
                return el;
              }),
            ]);
            responseHandler(true, 'Пароль был успешно изменён', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Пароль не был изменён', onModalClose);
        });
    })();
  }, []);

  const getFullOperatorDataById = useCallback(operatorId => {
    return (() => {
      operatorsService
        .getById(operatorId)
        .then(response => {
          if (response.status === 200) {
            const formattedData = {
              ...response?.data,
              devices: response?.data.devices.map(device => device.id),
            };
            setFullOperatorData(formattedData);
          }
        })
        .catch(setError);
    })();
  }, []);

  return {
    operators,
    setOperators,

    addOperator,
    updateOperator,
    removeOperator,
    totalOperatorsCount,
    changeOperatorPassword,

    isOperatorsLoading,
    error,
    fullOperatorData,
    setFullOperatorData,
    getFullOperatorDataById,
  };
}
