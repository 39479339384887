export const RESPONSE_CODE_FILE_NOT_FOUND = 'FILE_NOT_FOUND';
export const RESPONSE_CODE_FILE_CANNOT_BE_DELETED = 'FILE_CANNOT_BE_DELETED';
export const NAME_NOT_UNIQUE = 'NAME_NOT_UNIQUE';
export const SCHEDULINGSGROUP_NOT_FOUND = 'SCHEDULINGSGROUP_NOT_FOUND';
export const PRESENTATIONSGROUP_NOT_FOUND = 'PRESENTATIONSGROUP_NOT_FOUND';
export const PRESENTATION_NOT_FOUND = 'PRESENTATION_NOT_FOUND';
export const PLAYLISTSGROUP_NOT_FOUND = 'PLAYLISTSGROUP_NOT_FOUND';
export const PLAYLIST_NOT_FOUND = 'PLAYLIST_NOT_FOUND';
export const USERNAME_OR_PASSWORD_INCORRECT = 'USERNAME_OR_PASSWORD_INCORRECT';
export const USER_BLOCKED = 'USER_BLOCKED';
export const FILE_NOT_FOUND = 'FILE_NOT_FOUND';
export const PLAYLISTSGROUP_CANNOT_BE_DELETED = 'PLAYLISTSGROUP_CANNOT_BE_DELETED';
export const RELEASESCHEDULE_NOT_UNIQUE = 'RELEASESCHEDULE_NOT_UNIQUE';
export const EMAIL_NOT_UNIQUE = 'EMAIL_NOT_UNIQUE';
export const CLIENT_NOT_FOUND = 'CLIENT_NOT_FOUND';
export const LICENSENUMBER_NOT_UNIQUE = 'LICENSENUMBER_NOT_UNIQUE';
export const CLIENTRESOURCE_NOT_FOUND = 'CLIENTRESOURCE_NOT_FOUND';
export const LICENSENUMBER_CANNOT_BE_CHANGED = 'LICENSENUMBER_CANNOT_BE_CHANGED';
export const CLIENTLICENSE_EXPIRED = 'CLIENTLICENSE_EXPIRED';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const CLIENT_CANNOT_BE_DELETED = 'CLIENT_CANNOT_BE_DELETED';
export const DESKTOP_SHOULD_BE_CHOSEN = 'DESKTOP_SHOULD_BE_CHOSEN';
export const CLIENTSGROUP_CANNOT_BE_DELETED = 'CLIENTSGROUP_CANNOT_BE_DELETED';
