export const ButtonVariantEnum = {
  TEXT: 'text',
  ICON: 'icon',
  FLAT: 'flat',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SOLID_ACCENT: 'solid_accent',
  SOLID_BOTTOM: 'solid_bottom',
  SOLID_SECONDARY: 'solid_secondary',
};
