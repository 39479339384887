import PropTypes from 'prop-types';
import classnames from 'classnames';
import Leaflet from 'leaflet';
import { memo, useRef } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { NavLink } from 'react-router-dom';
import * as ReactDOMServer from 'react-dom/server';
import { Select } from 'containers/UI/Select';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ReactComponent as Offline } from 'icons/map/pin-offline.svg';
import { ReactComponent as Online } from 'icons/map/pin-online.svg';
import { ReactComponent as DeviceEditIcon } from 'icons/edit.svg';
import { ReactComponent as Settings } from 'icons/settings.svg';
import { ReactComponent as SnapshotIcon } from 'icons/camera.svg';
import { ReactComponent as LogsIcon } from 'icons/logs.svg';
import { ReactComponent as RefreshIcon } from 'icons/refresh.svg';
import { ReactComponent as PlaylistIcon } from 'icons/playlist.svg';
import { ReactComponent as ChartButton } from 'icons/chartButton.svg';
import { useDevicesMapMarker } from './Hooks/useDevicesMapMarker';

import styles from './DevicesMapMarker.module.css';

export const iconOffline = Leaflet.divIcon({
  className: styles.markerIcon,
  html: ReactDOMServer.renderToString(<Offline />),
  iconSize: [32, 32],
});
export const iconOnline = Leaflet.divIcon({
  className: styles.markerIcon,
  html: ReactDOMServer.renderToString(<Online />),
  iconSize: [32, 32],
});

export const DevicesMapMarker = memo(({ deviceData, playlistOptions, handlePlaylistsLoad, deviceId }) => {
  const {
    name,
    state,
    comment,
    position: { latitude = 0, longitude = 0 },
    config: { externalEquipment },
  } = deviceData;

  const isOnline = state === 'online';

  const markerRef = useRef();

  const {
    loading,
    sensors,
    isMaster,
    isHasSensorError,
    selectedPlaylist,
    setSelectedPlaylist,
    openLogs,
    getSensors,
    getSnapshot,
    handleShowPopup,
  } = useDevicesMapMarker(markerRef, deviceData);

  return (
    <Marker ref={markerRef} position={{ lat: latitude, lng: longitude }} icon={isOnline ? iconOnline : iconOffline}>
      <Popup minWidth={403} autoClose={false} closeOnClick={false} closeOnEscapeKey={false} offset={[0, 0]}>
        <div className={styles.markerContent}>
          <span className={styles.markerDeviceName}>
            <Settings className={styles.markerDeviceHeaderIcon} />
            <span className={styles.markerDeviceNameText}>{name}</span>
          </span>
          <span className={styles.markerDeviceComment}>
            <span className={styles.markerDeviceCommentText}>{comment}</span>
          </span>
          <div className={styles.markerDeviceActions}>
            <Button
              className={styles.markerDeviceActions_button}
              variant={ButtonVariantEnum.ICON}
              iconData={{ icon: <DeviceEditIcon /> }}
              title={'Изменить'}
              onClick={handleShowPopup}
            />
            {isOnline && (
              <Button
                className={styles.markerDeviceActions_button}
                variant={ButtonVariantEnum.ICON}
                iconData={{ icon: <SnapshotIcon /> }}
                title={'Снимок'}
                onClick={getSnapshot}
              />
            )}
            <Button
              className={styles.markerDeviceActions_button}
              variant={ButtonVariantEnum.ICON}
              iconData={{ icon: <LogsIcon /> }}
              title={'Логирование'}
              onClick={openLogs}
            />
            <NavLink to={'/devices/' + deviceId + '/chart'}>
              <Button
                className={styles.markerDeviceActions_button}
                variant={ButtonVariantEnum.ICON}
                iconData={{ icon: <ChartButton /> }}
                title={'График по датчикам'}
              />
            </NavLink>
            {!isMaster && (
              <Select
                className={styles.markerDeviceActions_playlist}
                disabled={loading || !isOnline}
                icon={<PlaylistIcon />}
                selected={selectedPlaylist}
                options={playlistOptions}
                onChange={setSelectedPlaylist}
                isInfinityLoad
                onLoad={handlePlaylistsLoad}
              />
            )}
          </div>

          {isOnline && externalEquipment && (
            <div className={styles.markerDeviceSensors_Wrapper}>
              <div className={styles.markerDeviceSensors}>
                {sensors.length > 0 && (
                  <div
                    className={classnames(styles.markerDeviceSensors_Content, {
                      [styles.markerDeviceSensors_Content__error]: isHasSensorError,
                    })}
                  >
                    {sensors.map(el => (
                      <div
                        key={el.sensor}
                        className={classnames(styles.markerDeviceSensors_Content_Item, {
                          [styles.markerDeviceSensors_Content_Item__error]: isHasSensorError,
                        })}
                      >
                        <label>{el.sensor}</label>
                        <span className={styles.markerDeviceSensors_Content_Separator}>—</span>
                        <span>{el.value}</span>
                      </div>
                    ))}
                  </div>
                )}

                <Button
                  className={styles.markerDeviceSensors_Refresh}
                  variant={ButtonVariantEnum.ICON}
                  iconData={{ icon: <RefreshIcon /> }}
                  title={'Обновить'}
                  onClick={getSensors}
                />
              </div>
            </div>
          )}
        </div>
      </Popup>
    </Marker>
  );
});

DevicesMapMarker.displayName = 'DevicesMapMarker';

DevicesMapMarker.defaultProps = {
  deviceData: {},
  playlistOptions: [],
  handlePlaylistsLoad: () => {},
};

DevicesMapMarker.propTypes = {
  deviceData: PropTypes.object,
  playlistOptions: PropTypes.array,
  handlePlaylistsLoad: PropTypes.func,
  deviceId: PropTypes.number,
};
