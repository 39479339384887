import PropTypes from 'prop-types';
import ReactSlider from 'react-slider';
import { useFormContext, Controller } from 'react-hook-form';

import styles from './Slider.module.css';
import { useState } from 'react';

export const Slider = ({
  className = '',
  min = 0,
  max = 100,
  label = '',
  screenOurId,
  screenIndex,
  selectWidgetIndex,
  key,
}) => {
  const { control } = useFormContext();
  const [value, setValue] = useState(min);

  return (
    <div className={styles['audio-volume-wrapper']}>
      <div className={styles['slider-title']}>
        {label} {value}
      </div>

      <Controller
        name={`screens[${screenIndex}].widgets[${selectWidgetIndex}].audioVolume`}
        key={'widget' + screenOurId + key + selectWidgetIndex}
        control={control}
        render={({ field: { onChange, value } }) => {
          setValue(value);
          return (
            <ReactSlider
              className={[styles['wrapper'], className].join(' ')}
              trackClassName={styles['track']}
              thumbClassName={styles['thumb']}
              value={value}
              onChange={onChange}
              min={min}
              max={max}
            />
          );
        }}
      />
    </div>
  );
};

Slider.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
  label: PropTypes.string,
  screenOurId: PropTypes.number,
  selectWidgetIndex: PropTypes.number,
  key: PropTypes.string,
  screenIndex: PropTypes.number,
};
