import PropTypes from 'prop-types';
import styles from './Loader.module.css';
import classnames from 'classnames';

export const Loader = ({ size = 75, className = '' }) => {
  return (
    <div className={classnames(styles['loader'], { [className]: !!className })} style={{ width: size, height: size }} />
  );
};

Loader.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};
