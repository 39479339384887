import { useEffect, useMemo, useState } from 'react';
import { tableauTemplatesService } from 'services';

export function useScoreboardTemplatesLoad() {
  const [scoreboardTemplates, setScoreboardTemplates] = useState([]);
  const [scoreboardTemplatesCount, setScoreboardTemplatesCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const scoreboardTemplatesOptions = useMemo(
    () =>
      scoreboardTemplates.map(scoreboardTemplate => ({
        ...scoreboardTemplate,
        name: scoreboardTemplate.name,
        value: scoreboardTemplate.id,
      })),
    [scoreboardTemplates],
  );

  useEffect(() => {
    setLoading(true);

    tableauTemplatesService
      .getAll({ skip: 0, take: 10 })
      .then(response => {
        if (response.status === 200) {
          Array.isArray(response.data?.items) && setScoreboardTemplates(response.data.items);
          setScoreboardTemplatesCount(response.data.count);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleScoreboardTemplatesLoad = () => {
    if (scoreboardTemplates.length < scoreboardTemplatesCount) {
      tableauTemplatesService
        .getAll({ skip: scoreboardTemplates.length, take: 10 })
        .then(response => {
          if (response.status === 200) {
            if (Array.isArray(response.data?.items)) {
              setScoreboardTemplates(prev => [...prev, ...response.data.items]);
              setScoreboardTemplatesCount(response.data.count);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return {
    isLoading,
    setLoading,
    scoreboardTemplates,
    scoreboardTemplatesCount,
    scoreboardTemplatesOptions,
    handleScoreboardTemplatesLoad,
  };
}
