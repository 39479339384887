import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useStateContext } from 'store';
import { useDevicesLoad } from 'containers/Hooks/devices/useDevicesLoad';
import { useArbitersLoad } from 'containers/Hooks/arbiters/useArbitersLoad';
import { useScoreboardTemplatesLoad } from 'containers/Hooks/scoreboardTemplates/useScoreboardTemplatesLoad';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { Input, InputParseErrors, InputTypeEnum } from 'containers/UI/Input';
import { DatePicker } from 'containers/UI/DatePicker';
import { SelectForm } from 'containers/UI/Select';

import styles from './ModalSportEvents.module.css';

export const ModalSportEvents = ({ createSportEvent, sportEvent, editSportEvent }) => {
  const { closePopup, getDataForExistingSportEvent } = useStateContext();
  const { devicesOptions, handleDevicesLoad } = useDevicesLoad();
  const { arbitersOptions, handleArbitersLoad } = useArbitersLoad();
  const { scoreboardTemplatesOptions, handleScoreboardTemplatesLoad } = useScoreboardTemplatesLoad();

  const {
    watch,
    reset,
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: sportEvent?.name,
      startDate: sportEvent?.startDate ? new Date(sportEvent?.startDate) : '',
      comment: sportEvent?.comment,
      team1Name: sportEvent?.team1Name,
      team2Name: sportEvent?.team2Name,
      sport: 'generic',
    },
  });
  const sport = watch('sport');

  useEffect(() => {
    if (sportEvent) {
      getDataForExistingSportEvent(sportEvent.id, reset, closePopup);
    }
  }, []);

  const save = formData => {
    const payload = {
      ...formData,
      startDate: formData.startDate.toISOString(),
    };

    if (createSportEvent) {
      createSportEvent(payload, closePopup);
    }

    if (sportEvent) {
      editSportEvent(sportEvent.id, payload, closePopup);
    }
  };

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_fields}>
          <Controller
            key={'ModalSportEvents-radioInput'}
            control={control}
            name={'sport'}
            render={({ field: { onChange, value } }) => (
              <Input
                label={'Вид спорта'}
                value={value}
                type={InputTypeEnum.Radio}
                defaultRadioVariant="generic"
                handleInput={onChange}
                radioVariants={[
                  { name: 'Хоккей', value: 'hockey' },
                  { name: 'Generic', value: 'generic' },
                ]}
              />
            )}
          />
          <Input
            label={'Название'}
            placeholder={'Название мероприятия'}
            type={InputTypeEnum.TEXT}
            error={InputParseErrors(errors.name, { minLength: 4, maxLength: 30 })}
            {...register('name', { required: true, minLength: 4, maxLength: 30 })}
          />

          <Controller
            control={control}
            name={'startDate'}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                classNameWrapper={classNames(styles['params-field'], styles['date-field'])}
                label={'Дата и время начала'}
                dateFormat="dd.MM.yyyy hh:mm:ss"
                selected={value}
                onChange={onChange}
              />
            )}
          />

          <Input
            label={'Комментарий'}
            placeholder={'Комментарий'}
            type={InputTypeEnum.TEXT}
            error={InputParseErrors(errors.comment, { minLength: 4, maxLength: 100 })}
            {...register('comment', { minLength: 4, maxLength: 100 })}
          />

          <Input
            label={'Название первой команды'}
            placeholder={'Название первой команды'}
            type={InputTypeEnum.TEXT}
            error={InputParseErrors(errors.team1Name, { minLength: 3, maxLength: 100 })}
            {...register('team1Name', { required: true, minLength: 3, maxLength: 100 })}
          />

          <Input
            label={'Название второй команды'}
            placeholder={'Название второй команды'}
            type={InputTypeEnum.TEXT}
            error={InputParseErrors(errors.team2Name, { minLength: 3, maxLength: 100 })}
            {...register('team2Name', { required: true, minLength: 3, maxLength: 100 })}
          />

          <SelectForm
            name={'devices'}
            control={control}
            placeholder={'Устройства'}
            label={'Устройства'}
            options={devicesOptions}
            isMulti
            isInfinityLoad
            onLoad={handleDevicesLoad}
            rules={{ required: false }}
          />

          <SelectForm
            name={'arbiters'}
            control={control}
            placeholder={'Судьи, имеющие доступ к мероприятию'}
            label={'Судьи, имеющие доступ к мероприятию'}
            options={arbitersOptions}
            isMulti
            isInfinityLoad
            onLoad={handleArbitersLoad}
            rules={{ required: false }}
          />

          {!sportEvent && (
            <SelectForm
              name={'scoreboardTemplateId'}
              control={control}
              label={'Шаблон спортивного табло'}
              placeholder={'Название шаблона'}
              options={scoreboardTemplatesOptions.filter(i => sport === i.sport)}
              onLoad={handleScoreboardTemplatesLoad}
              isInfinityLoad
              rules={{ required: false }}
              disabled={!sport}
            />
          )}

          <div className={styles.form_actions}>
            <Button className={styles.form_button} type="submit" disabled={!isValid}>
              {createSportEvent ? 'Создать' : 'Сохранить'}
            </Button>
            <Button className={styles.form_button} variant={ButtonVariantEnum.SOLID_ACCENT} onClick={closePopup}>
              Отмена
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

ModalSportEvents.propTypes = {
  createSportEvent: PropTypes.func,
  sportEvent: PropTypes.object,
  editSportEvent: PropTypes.func,
};
