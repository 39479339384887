import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './SelectOption.module.css';

export const SelectOption = props => {
  const { value, name, selected = false, isMulti, onClickOption } = props;

  return (
    <div
      className={classnames(styles.select_option, {
        [styles.select_option_selected]: selected,
        [styles.select_option_multi]: isMulti,
        [styles.select_option_not_found]: name === 'Результатов не найдено',
      })}
      onClick={() => onClickOption(value)}
    >
      {name}
    </div>
  );
};

SelectOption.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  selected: PropTypes.bool,
  isMulti: PropTypes.bool,
  onClickOption: PropTypes.func,
};
