import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { showErrorNotify } from 'helpers/showNotify';
import { DefaultFileTypes, FileExtensions } from 'helpers/constants/fileTypes';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { InputTypeEnum } from './Constants/InputTypeEnum';
import { InputActions } from './Components/InputActions';
import { InputVariantEnum } from './Constants/InputVariantEnum';

import styles from './Input.module.css';

export const Input = forwardRef((props, ref) => {
  const {
    type = InputTypeEnum.TEXT,
    label = '',
    error,
    variant = InputVariantEnum.DEFAULT,
    actions = [],
    children,
    handleInput = () => {},
    handleBlur = () => {},
    handleFocus = () => {},
    className = '',
    classNameError = '',
    onChange = () => {},
    fileTypes = DefaultFileTypes,
    allFileTypesAvailable,
    radioVariants = [],
    defaultRadioVariant,
    ...attrs
  } = props;

  const inputRef = useRef(null);
  useImperativeHandle(ref, () => inputRef.current);

  const [inputType, setInputType] = useState(type);
  const [fileName, setFileName] = useState('');
  const [isDragOvered, setIsDragOvered] = useState(false);
  const [currentRadioVariant, setCurrentRadioVariant] = useState(defaultRadioVariant);

  const fileExtensions = useMemo(() => {
    return allFileTypesAvailable
      ? null
      : fileTypes
          .reduce((acc, value) => {
            if (Object.keys(FileExtensions).find(key => key === value)) {
              acc.push(...FileExtensions[value]);
            }
            return acc;
          }, [])
          .map(ext => `.${ext}`)
          .join(',');
  }, []);

  const onInput = e => {
    handleInput(e.target.value);
    setCurrentRadioVariant(e.target.value);
  };

  const handleChange = e => {
    if (e.target?.files?.length) {
      if (!fileExtensions?.includes(e.target.files[0].type.split('/')[1]) && !allFileTypesAvailable) {
        return showErrorNotify(`Допустимый формат ${fileExtensions}`);
      }

      setFileName(e.target.files[0].name);
      onChange(e.target.files[0]);
    } else {
      onChange(e);
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
    setIsDragOvered(true);
  };

  const handleDragEnd = e => {
    e.preventDefault();
    setIsDragOvered(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    const { files } = e.dataTransfer;

    setFileName(files[0].name);
    onChange(files[0]);
    setIsDragOvered(false);
  };

  return (
    <div className={classnames(styles.input_wrapper, className)}>
      {label && <label className={styles.input_label}>{label}</label>}
      <div className={styles.input_container}>
        {type === InputTypeEnum.Radio &&
          radioVariants.map(variant => {
            return (
              <div key={variant.value} className={variant.value === 'generic' && styles['generic-variant']}>
                <input
                  onChange={onInput}
                  checked={variant.value === currentRadioVariant}
                  type="radio"
                  id={variant.value}
                  name={variant.name}
                  value={variant.value}
                />
                <label htmlFor={variant.value}>{variant.name}</label>
              </div>
            );
          })}

        {type !== InputTypeEnum.Radio && (
          <>
            <input
              ref={inputRef}
              className={classnames(styles.input, styles[`input__${variant}`], {
                [styles.input__with_error]: error,
              })}
              type={inputType}
              onChange={handleChange}
              onInput={onInput}
              onFocus={handleFocus}
              onBlur={handleBlur}
              accept={type === InputTypeEnum.FILE ? fileExtensions : null}
              {...attrs}
            />

            {type === InputTypeEnum.FILE && (
              <div
                className={classnames(styles.input, styles[`input__${variant}`], {
                  [styles.input__with_error]: error,
                  [styles.input__drag_overed]: isDragOvered,
                })}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDragEnter={handleDragOver}
                onDragLeave={handleDragEnd}
                onDrop={handleDrop}
              >
                {fileName || attrs.placeholder}
              </div>
            )}

            <div className={styles.input__icons_wrapper}>
              <InputActions ref={inputRef} inputType={inputType} actions={actions} onChangeInputType={setInputType} />
              {children}
            </div>
          </>
        )}
      </div>

      {error && <div className={classnames(styles.input__error, classNameError)}>{error}</div>}

      <Tooltip id="input-tooltip" />
    </div>
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  className: PropTypes.string,
  classNameError: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  type: PropTypes.oneOf([
    InputTypeEnum.TEXT,
    InputTypeEnum.EMAIL,
    InputTypeEnum.NUMBER,
    InputTypeEnum.PASSWORD,
    InputTypeEnum.TELEPHONE,
    InputTypeEnum.FILE,
    InputTypeEnum.Radio,
  ]),
  radioVariants: PropTypes.array,
  defaultRadioVariant: PropTypes.string,
  variant: PropTypes.oneOf([InputVariantEnum.DEFAULT, InputVariantEnum.TRANSPARENT]),
  actions: PropTypes.array,
  fileTypes: PropTypes.array,
  onChange: PropTypes.func,
  handleInput: PropTypes.func,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  allFileTypesAvailable: PropTypes.bool,
};
