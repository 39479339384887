import { useForm } from 'react-hook-form';
import { useStateContext } from 'store';
import { useEffect } from 'react';
import { emailSchema, passwordSchema } from 'helpers/validation';
import { SelectForm } from 'containers/UI/Select';
import { Input, InputTypeEnum, InputParseErrors, InputActionTypeEnum } from 'containers/UI/Input';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { useDevicesLoad } from 'containers/Hooks/devices/useDevicesLoad';
import { PermissionTitle } from 'helpers/constants/permissionsConstants';
import { Checkbox } from 'containers/UI/Checkbox';

import styles from './ModalOperatorsAdd.module.css';

// todo 1. ['auth/currentUser'].widgets
// todo 2. ['auth/currentUser'].permissions
// todo 3. isEmailTaken logic
const PERMISSIONS_NAMES = ['filesAvailability', 'schedulingsAvailability', 'mobileAppActiveScreenAvailability'];

export const ModalOperatorsAdd = () => {
  const { closePopup, addOperator, getAllowedWidgets, allowedWidgets, setAllowedWidgets } = useStateContext();
  const { isLoading, devicesOptions, handleDevicesLoad } = useDevicesLoad();

  const getWidgets = function () {
    useEffect(() => {
      if (getAllowedWidgets.length === 0) {
        getAllowedWidgets();
      }
    }, []);
  };
  getWidgets();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' });

  const save = form => {
    const formData = {
      email: form.email,
      password: form.password,
      devices: form.devices,
      widgets: form.widgets,
      permissions: {
        filesAvailability: !!form.filesAvailability,
        schedulingsAvailability: !!form.schedulingsAvailability,
        mobileAppActiveScreenAvailability: !!form.mobileAppActiveScreenAvailability,
      },
    };
    addOperator(formData, closePopup);
  };

  const getPasswordSchema = fieldName => ({
    ...passwordSchema,
    validate: {
      ...passwordSchema.validate,
      retryPassword: val => {
        if (watch(fieldName) != val) return 'Ваши пароли не совпадают';
      },
    },
  });

  const componentWillUnmount = function () {
    useEffect(() => {
      return () => {
        setAllowedWidgets([]);
      };
    }, []);
  };
  componentWillUnmount();

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={handleSubmit(save)}>
        <div className={styles.form_fields}>
          <Input
            label={'Email'}
            placeholder={'Email'}
            type={InputTypeEnum.EMAIL}
            error={InputParseErrors(errors.email, { minLength: 5, maxLength: 30 })}
            {...register('email', emailSchema)}
          />

          <Input
            type={InputTypeEnum.PASSWORD}
            label={'Пароль'}
            placeholder={'Пароль'}
            actions={[InputActionTypeEnum.EYE, InputActionTypeEnum.DICE, InputActionTypeEnum.COPY]}
            error={InputParseErrors(errors.password, passwordSchema)}
            {...register('password', passwordSchema)}
          />

          <Input
            type={InputTypeEnum.PASSWORD}
            label={'Пароль повторно'}
            placeholder={'Пароль повторно'}
            actions={[InputActionTypeEnum.EYE]}
            error={InputParseErrors(errors.confirm_password, getPasswordSchema('password'))}
            {...register('confirm_password', getPasswordSchema('password'))}
          />

          <SelectForm
            name="devices"
            control={control}
            placeholder={'Устройство'}
            label={'Доступные устройства'}
            options={devicesOptions}
            searchable
            isMulti
            isInfinityLoad
            onLoad={handleDevicesLoad}
          />

          <SelectForm
            name="widgets"
            control={control}
            placeholder={'Выберите виджеты'}
            label={'Доступные виджеты'}
            options={allowedWidgets}
            searchable
            isMulti
          />

          <div className={styles.form_checkboxes}>
            {PERMISSIONS_NAMES.map(permission => (
              <Checkbox key={permission} control={control} name={permission} label={PermissionTitle[permission]} />
            ))}
          </div>
        </div>

        <div className={styles.form_actions}>
          <Button className={styles.form_button} type="submit" disabled={!isValid || isLoading}>
            Создать
          </Button>
          <Button
            className={styles.form_button}
            variant={ButtonVariantEnum.SOLID_ACCENT}
            disabled={isLoading}
            onClick={closePopup}
          >
            Закрыть
          </Button>
        </div>
      </form>
    </div>
  );
};
