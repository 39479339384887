import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { DndContext, useDroppable } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { useFormContext } from 'react-hook-form';
import isEqual from 'lodash.isequal';
import classnames from 'classnames';
import {
  isCoursorInsideOneOfTheGroup,
  getGroupSides,
  isActiveInputElement,
  SPORT_SCORE_WIDGET_TYPE,
  LOCATION_TABLEAU_TEMPLATES,
  LOCATION_SPORT_EVENTS,
  SPORT_PERIOD_WIDGET_TYPE,
} from 'containers/Desktops/helpers';

import {
  WIDGETS_COMPONENTS,
  WIDGETS_DEFAULT,
  isWidgetInsideSelectedRange,
  getSelectedRangeStyle,
  LOCATION_SCREEN_TEMPLATES,
  RESIZE_DIRECTION_PLUS,
  RESIZE_DIRECTION_MINUS,
} from '../helpers';
import { WidgetTypesC, WidgetTitle } from 'helpers/constants/widgetsConstants';
import { Button } from 'containers/UI/Button';
import { Group } from './Group/Group';
import { SelectedRange } from './SelectedRange/SelectedRange';
import { GuideLines } from './GuideLines/GuideLines';
import { ReactComponent as Arrow } from 'icons/arrows/arrow_left.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Add } from 'icons/add.svg';
import { ReactComponent as Chevron } from 'icons/triangle.svg';
import { ReactComponent as Copy } from 'icons/widgetCopy.svg';
import { ReactComponent as Cut } from 'icons/widgetCut.svg';
import { ReactComponent as Paste } from 'icons/widgetPaste.svg';
import { ReactComponent as IncreaseSize } from 'icons/resize+.svg';
import { ReactComponent as DecreaseSize } from 'icons/resize-.svg';
import { ReactComponent as IncreaseGroupSize } from 'icons/widgets-groups-resize-plus.svg';
import { ReactComponent as DecreaseGroupSize } from 'icons/widgets-group-resize-minus.svg';
import { ReactComponent as WidgetsGroupingButton } from 'icons/widgets-group-button.svg';

import styles from './DesktopEditor.module.css';

export const DesktopEditor = ({ onUpdateLocalStorage = () => {} }) => {
  const [mouseDown, setMouseDown] = useState(false);
  const [selectedRange, setSelectedRange] = useState({});
  const [widgetsSelector, setWidgetsSelector] = useState(false);
  const { setNodeRef } = useDroppable({ id: 'editor-droppable' });
  const {
    watch,
    indexSelect,
    screensUpdate,
    selectWidgetIndex,
    setSelectWidgetIndex,
    clearErrors,
    selectedWidgets,
    setSelectedWidgets,
    previewTemplateMode,
    setValue,
    onResize,
    sizeCoeff,
    location,
    isSizeMessageShown,
    setCurrentChosenGroup,
    currentChosenGroup,
    currentGroupWithIntersection,
    setSizeCoeff,
    deltaTarget,
    setDeltaTarget,
  } = useFormContext();
  const screens = watch('screens') || [];
  const screen = screens[indexSelect];

  const widgets = watch(`screens[${indexSelect}].widgets`) || [];

  const initialWidgetsRef = useRef([]);
  const debounceTimer = useRef(0);
  const isWaitDebounceUpdateCallback = useRef(false);

  const getWidgetsInitialPositions = widgets => {
    return widgets?.reduce((accumulator, widget) => {
      accumulator[widget.id] = { top: widget.top, left: widget.left };
      return accumulator;
    }, {});
  };

  const [scrollValues, setScrollValues] = useState({ scrollLeft: 0, scrollTop: 0 });
  const [widgetGroups, setWidgetGroups] = useState([]);
  const [widgetsInitialPositions, setWidgetsInitialPositions] = useState({});

  useEffect(() => {
    setWidgetsInitialPositions(getWidgetsInitialPositions(widgets));
  }, [selectedWidgets]);

  useEffect(() => {
    if (indexSelect !== -1) {
      initialWidgetsRef.current = widgets;
    }
  }, [indexSelect]);

  useEffect(() => {
    if (currentChosenGroup !== null) {
      setWidgetsInitialPositions(getWidgetsInitialPositions(widgets));
    }
  }, [currentChosenGroup]);

  useEffect(() => {
    if (indexSelect !== -1) {
      const isWidgetsEqual = isEqual(initialWidgetsRef.current, widgets);

      if (!isWidgetsEqual && !isWaitDebounceUpdateCallback.current) {
        onUpdateLocalStorage();
        isWaitDebounceUpdateCallback.current = true;

        debounceTimer.current = window.setTimeout(() => {
          onUpdateLocalStorage();
          isWaitDebounceUpdateCallback.current = false;
        }, 1000);
      }
    }
    setWidgetGroups(getWidgetGroupsForCurrentScreen());
  }, [indexSelect, watch('screens')]);

  const getWidgetGroupsForCurrentScreen = () => {
    const widgets = watch(`screens[${indexSelect}].widgets`) || [];
    const widgetsGroupsIDsWithWidgets = widgets.reduce((accumulator, widget) => {
      if (widget.canvasGroupId && !accumulator[`${widget.canvasGroupId}`]) {
        accumulator[`${widget.canvasGroupId}`] = [];
      }

      widget.canvasGroupId && accumulator[`${widget.canvasGroupId}`].push(widget);

      return accumulator;
    }, {});

    return Object.keys(widgetsGroupsIDsWithWidgets).map(key => {
      const widgets = widgetsGroupsIDsWithWidgets[key];
      const coordinates = getGroupSides(widgets);
      return {
        canvasGroupId: +key,
        widgets,
        coordinates,
      };
    });
  };

  const addWidget = type => {
    screensUpdate(indexSelect, {
      ...screen,
      widgets: [
        ...widgets,
        {
          type,
          ...WIDGETS_DEFAULT[type],
          canvasGroupId: null,
          left: 0,
          top: 0,
          width: 100,
          height: 50,
          id: Math.random(),
        },
      ],
    });
    setSelectWidgetIndex(widgets.length);
  };

  const pushWidgetsToInitialPositions = () => {
    screensUpdate(indexSelect, {
      ...screen,
      widgets: widgets.map(widget => {
        if (widget.canvasGroupId === currentGroupWithIntersection) {
          return {
            ...widget,
            top: widgetsInitialPositions[widget.id].top,
            left: widgetsInitialPositions[widget.id].left,
          };
        }

        return widget;
      }),
    });
  };

  const setWidget = props => {
    const widget = widgets[selectWidgetIndex];
    if (currentGroupWithIntersection !== null && widget?.canvasGroupId === currentGroupWithIntersection) {
      pushWidgetsToInitialPositions();
      return;
    }

    const { delta, active } = props || {};

    if (delta.x === 0 && delta.y === 0) {
      return;
    }

    const isSavedGroup = active.id == currentChosenGroup;
    const isTempGroup = active.id == 'group';
    const newWidgets = widgets.map((w, index) => {
      if (
        index === selectWidgetIndex ||
        (isTempGroup && selectedWidgets[index]) ||
        (isSavedGroup && w.canvasGroupId === currentChosenGroup)
      ) {
        return {
          ...w,
          top: (widgetsInitialPositions[w.id]?.top || w.top || 0) + delta.y / (sizeCoeff / 100),
          left: (widgetsInitialPositions[w.id]?.left || w.left || 0) + delta.x / (sizeCoeff / 100),
        };
      } else return w;
    });
    screensUpdate(indexSelect, { ...screen, widgets: newWidgets });
    setWidgetsInitialPositions(getWidgetsInitialPositions(newWidgets));
  };

  const onDragMove = ({ delta, active }) => {
    setDeltaTarget(delta);
    const newWidgets = widgets.map((widget, index) => {
      if (widget.canvasGroupId === active.id || selectedWidgets[index]) {
        return {
          ...widget,
          top: (widgetsInitialPositions[widget.id]?.top || 0) + delta.y / (sizeCoeff / 100),
          left: (widgetsInitialPositions[widget.id]?.left || 0) + delta.x / (sizeCoeff / 100),
        };
      }
      return widget;
    });

    screensUpdate(indexSelect, {
      ...screen,
      widgets: newWidgets,
    });
  };

  const moveWidgetWithArrows = direction => {
    const bulkSelected = [];
    Object.keys(selectedWidgets).forEach(key => selectedWidgets[key] && bulkSelected.push(key));
    const movedIndexes = isNaN(selectWidgetIndex) ? bulkSelected : [selectWidgetIndex];
    const newWidgets = [...widgets];
    let offset = 0;
    const newSelectedWidgets = {};
    movedIndexes.forEach(i => {
      const start = parseInt(i);
      const destination = parseInt(i) + direction + offset;
      const end = destination === newWidgets.length ? 0 : destination < 0 ? newWidgets.length - 1 : destination;
      newWidgets[end] = newWidgets.splice(start, 1, newWidgets[end])[0];
      if (!isNaN(selectWidgetIndex)) {
        setSelectWidgetIndex(end);
      } else {
        newSelectedWidgets[end] = true;
        offset + direction;
      }
    });
    screensUpdate(indexSelect, { ...screen, widgets: newWidgets });
    if (isNaN(selectWidgetIndex)) setSelectedWidgets(newSelectedWidgets);
  };

  const deleteWidgets = () => {
    const isGroupDeleting = selectWidgetIndex === undefined && currentChosenGroup !== null;
    const isWidgetsDeleting = !isGroupDeleting;
    const newWidgets = [];

    if (isGroupDeleting) {
      widgets.forEach(widget => widget.canvasGroupId !== currentChosenGroup && newWidgets.push(widget));
    }

    if (isWidgetsDeleting) {
      const indexesToRemove = [];
      Object.values(selectedWidgets).forEach((v, i) => v && indexesToRemove.push(i));
      if (!isNaN(selectWidgetIndex)) indexesToRemove.push(selectWidgetIndex);

      widgets.forEach((w, i) => (indexesToRemove.indexOf(i) === -1 || w.canvasGroupId !== null) && newWidgets.push(w));
    }

    screensUpdate(indexSelect, { ...screen, widgets: newWidgets });
    clearErrors(`screens[${indexSelect}].widgets`);
    setSelectWidgetIndex(undefined);
    setSelectedWidgets({});
  };

  const onDragStart = ({ active }) => {
    const activeIndex = active?.data?.current?.index;
    if (!isNaN(activeIndex)) setSelectedWidgets({});
    setSelectWidgetIndex(activeIndex);
  };
  // TODO: is here for app working without crashes. should be deleted in the future ( should be only widgets without filter)
  const filteredWidgets = widgets.filter(widget => !!WIDGETS_COMPONENTS[widget?.type]);

  const { width, height } = screen || {};

  const copyHandler = () => {
    const isWidgetCopying = selectWidgetIndex + 1;
    const isWidgetsGroupCopying = currentChosenGroup !== null && !(selectWidgetIndex + 1);
    const isMultipleWidgetsCopying =
      Object.keys(selectedWidgets).filter(key => selectedWidgets[+key] && key).length >= 2;

    const copiedWidgets = [];
    if (isWidgetCopying || isMultipleWidgetsCopying) {
      Object.values(selectedWidgets).forEach((v, i) => v && copiedWidgets.push(widgets[i]));
      if (!isNaN(selectWidgetIndex)) copiedWidgets.push(widgets[selectWidgetIndex]);
    }

    if (isWidgetsGroupCopying) {
      const { widgets } = widgetGroups.find(widgetGroup => widgetGroup.canvasGroupId === currentChosenGroup);
      copiedWidgets.push(...widgets);
    }

    localStorage.setItem('copiedWidgets', JSON.stringify(copiedWidgets));
  };

  const pasteHandler = () => {
    let copiedWidgets = JSON.parse(localStorage.getItem('copiedWidgets'));
    const isWidgetsGroupCopying = currentChosenGroup !== null && !(selectWidgetIndex + 1);

    if (isWidgetsGroupCopying) {
      copiedWidgets = copiedWidgets.map(widget => ({ ...widget, id: Math.random(), canvasGroupId: Date.now() }));
    }

    screensUpdate(indexSelect, { ...screen, widgets: [...screen.widgets, ...copiedWidgets] });
  };

  const cutHandler = () => {
    copyHandler();
    deleteWidgets();
  };

  const lockUnlockWidgetHandler = () => {
    if (!setSelectWidgetIndex) {
      return;
    }

    const isBlocked = watch(`screens[${indexSelect}].widgets[${selectWidgetIndex}].blockedOnCanvas`);

    setValue(`screens[${indexSelect}].widgets[${selectWidgetIndex}].blockedOnCanvas`, !isBlocked);
  };

  const onScrollHandle = ({ deltaY, shiftKey, currentTarget }) => {
    const { scrollTop, scrollLeft } = currentTarget;

    if (!shiftKey) {
      setScrollValues({ scrollLeft, scrollTop });
      return;
    }

    if (deltaY < 0) {
      onResize(RESIZE_DIRECTION_PLUS);
    } else if (deltaY > 0) {
      onResize(RESIZE_DIRECTION_MINUS);
    }
  };

  const keyDownHandler = ({ keyCode, ctrlKey }) => {
    if (isActiveInputElement()) {
      return false;
    }

    const C = 67;
    const V = 86;
    const X = 88;
    const M = 77;
    const B = 66;
    const L = 76;
    const G = 71;

    if (keyCode === L && ctrlKey) {
      lockUnlockWidgetHandler();
    }

    if (keyCode === C && ctrlKey) {
      copyHandler();
    }

    if (keyCode === V && ctrlKey) {
      pasteHandler();
    }

    if (keyCode === X && ctrlKey) {
      cutHandler();
    }

    if (keyCode === M && ctrlKey) {
      moveWidgetWithArrows(1);
    }

    if (keyCode === B && ctrlKey) {
      moveWidgetWithArrows(-1);
    }

    if (keyCode === G && ctrlKey) {
      onCreateNewGroupOrUngroupSavedGroup();
    }
  };

  const onGroupResize = direction => {
    const newWidgets = widgets.map(widget => {
      if (widget.canvasGroupId === currentChosenGroup) {
        return {
          ...widget,
          width: direction === RESIZE_DIRECTION_PLUS ? widget.width * 1.05 : widget.width / 1.05,
          height: direction === RESIZE_DIRECTION_PLUS ? widget.height * 1.05 : widget.height / 1.05,
        };
      }
      return widget;
    });

    screensUpdate(indexSelect, {
      ...screen,
      widgets: newWidgets,
    });
  };

  const onCreateNewGroupOrUngroupSavedGroup = () => {
    const isNewGroupCreating = Object.values(selectedWidgets).filter(widget => widget).length >= 2;
    const isSavedGroupUngrouping = !isNewGroupCreating && currentChosenGroup !== null;

    if (isNewGroupCreating) {
      const canvasGroupId = Date.now();
      const widgets = screen.widgets.reduce((accumulator, widget, index) => {
        if (
          Object.keys(selectedWidgets).some(widgetIndex => widgetIndex === '' + index && selectedWidgets[widgetIndex])
        ) {
          accumulator.push({ ...widget, canvasGroupId });
        }

        return accumulator;
      }, []);

      const widgetsIDs = {};
      widgets.forEach(widget => (widgetsIDs[`${widget.id}`] = widget.id));

      const coordinates = getGroupSides(widgets);
      const newWidgetGroup = {
        canvasGroupId,
        coordinates,
        widgets,
      };
      setWidgetGroups(prevState => [...prevState, newWidgetGroup]);
      setCurrentChosenGroup(canvasGroupId);
      setSelectedRange({});
      setSelectedWidgets({});
      screensUpdate(indexSelect, {
        ...screen,
        widgets: screen['widgets'].map(widget => (widget.id in widgetsIDs ? { ...widget, canvasGroupId } : widget)),
      });
    }

    if (isSavedGroupUngrouping) {
      screensUpdate(indexSelect, {
        ...screen,
        widgets: screen['widgets'].map(widget =>
          widget.canvasGroupId === currentChosenGroup ? { ...widget, canvasGroupId: null } : widget,
        ),
      });
    }
  };

  useEffect(() => {
    window.removeEventListener('keydown', keyDownHandler);
    window.addEventListener('keydown', keyDownHandler);
  }, [selectWidgetIndex, indexSelect, currentChosenGroup]);

  useEffect(() => {
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
      setSizeCoeff(100);
    };
  }, [selectWidgetIndex, indexSelect, currentChosenGroup]);

  const { offsetLeft, offsetTop } = document.querySelector('#editor-droppable') || {};

  const calculateSelectedRange = ({ pageX, pageY, type, target }) => {
    const { scrollLeft, scrollTop } = scrollValues;
    const x = pageY - offsetTop + scrollTop;
    const y = pageX - offsetLeft + scrollLeft;

    if (type === 'mousedown') {
      setMouseDown(target.id === 'editor-droppable');
      setSelectedRange({ top: x, left: y, x, y });

      const groups = isCoursorInsideOneOfTheGroup(y, x, widgetGroups, sizeCoeff);
      const group = groups[groups.length - 1];
      if (group || widgets[selectWidgetIndex]?.canvasGroupId) {
        const { canvasGroupId } = group || widgets[selectWidgetIndex];
        setCurrentChosenGroup(canvasGroupId);
      } else {
        setCurrentChosenGroup(null);
      }
    }

    if (type === 'mousemove' && mouseDown) {
      setSelectedRange({ ...selectedRange, x, y });
    }

    if (type === 'mouseup' || (type === 'mouseleave' && mouseDown)) {
      setMouseDown(false);
      const rangeStyle = getSelectedRangeStyle(selectedRange, sizeCoeff);
      const newSelectedWidgets = widgets.reduce((acc, cur, index) => {
        if (
          rangeStyle.width === 0 &&
          rangeStyle.height === 0 &&
          selectWidgetIndex === undefined &&
          currentChosenGroup === null &&
          cur.canvasGroupId === null &&
          !cur.blockedOnCanvas
        ) {
          acc[index] = true;
        }

        if (rangeStyle.width !== 0 && rangeStyle.height !== 0 && !cur.blockedOnCanvas) {
          acc[index] = isWidgetInsideSelectedRange(rangeStyle, cur);
        }
        return acc;
      }, {});

      setSelectedWidgets(newSelectedWidgets);
      if (Object.values(newSelectedWidgets).some(v => v)) setSelectWidgetIndex(undefined);
    }

    if (type === 'mouseup') setDeltaTarget(false);
  };
  const disabledActions = isNaN(selectWidgetIndex) && Object.values(selectedWidgets).every(v => !v);
  const widgetsGroupsDisable =
    !currentChosenGroup && Object.values(selectedWidgets).filter(widget => widget).length < 2;

  const renderSavedGroups = () => {
    return widgetGroups.map(widgetGroup => <Group widgetGroup={widgetGroup} key={widgetGroup.canvasGroupId} />);
  };

  const renderNewGroup = () => {
    return Object.values(selectedWidgets).filter(v => v).length > 1 && <Group creatingNewGroupMode={true} />;
  };

  const style =
    (width && height) || location === LOCATION_SCREEN_TEMPLATES
      ? {
          width: `${width}px`,
          minWidth: `${width}px`,
          maxWidth: `${width}px`,
          height: `${height}px`,
          minHeight: `${height}px`,
          maxHeight: `${height}px`,
          transform: `translateZ(0) scale(${sizeCoeff / 100})`,
          transformOrigin: 'left top',
        }
      : {};

  return (
    <article
      className={classnames({
        [styles['wrapper']]: !previewTemplateMode,
        [styles[`preview-${previewTemplateMode}-mode-wrapper`]]: true,
      })}
    >
      {!previewTemplateMode && (
        <header className={styles['header']}>
          <Button
            style={{ transform: 'rotate(-90deg)' }}
            className={styles['action']}
            disabled={disabledActions}
            onClick={() => moveWidgetWithArrows(1)}
            title={'Переместить виджет на передний план(Ctrl + M)'}
          >
            <Arrow />
          </Button>

          <Button
            style={{ transform: 'rotate(90deg)' }}
            className={styles['action']}
            disabled={disabledActions}
            onClick={() => moveWidgetWithArrows(-1)}
            title={'Переместить виджет на задний план(Ctrl + B)'}
          >
            <Arrow />
          </Button>

          <Button
            className={styles['action']}
            disabled={disabledActions && currentChosenGroup === null}
            onClick={copyHandler}
            title={'Скопировать виджет(Ctrl + C)'}
          >
            <Copy />
          </Button>

          <Button
            className={styles['action']}
            disabled={disabledActions && currentChosenGroup === null}
            onClick={cutHandler}
            title={'Вырезать виджет(Ctrl + X)'}
          >
            <Cut />
          </Button>

          <Button
            className={styles['action']}
            disabled={!(indexSelect + 1)}
            onClick={pasteHandler}
            title={'Вставить скопированный виджет(Ctrl + V)'}
          >
            <Paste />
          </Button>

          <Button
            className={styles['action']}
            disabled={!(indexSelect + 1) || sizeCoeff === 1000}
            title={'Увеличить масштаб'}
            onClick={() => onResize(RESIZE_DIRECTION_PLUS)}
          >
            <IncreaseSize />
          </Button>

          <Button
            className={styles['action']}
            disabled={!(indexSelect + 1) || sizeCoeff === 10}
            title={'Уменьшить масштаб'}
            onClick={() => onResize(RESIZE_DIRECTION_MINUS)}
          >
            <DecreaseSize />
          </Button>

          <Button
            className={styles['action']}
            disabled={currentChosenGroup === null}
            onClick={() => onGroupResize(RESIZE_DIRECTION_PLUS)}
            title={'Увеличить размер группы'}
          >
            <IncreaseGroupSize />
          </Button>

          <Button
            className={styles['action']}
            disabled={currentChosenGroup === null}
            onClick={() => onGroupResize(RESIZE_DIRECTION_MINUS)}
            title={'Уменьшить размер группы'}
          >
            <DecreaseGroupSize />
          </Button>

          <Button
            className={styles['action']}
            disabled={widgetsGroupsDisable}
            onClick={onCreateNewGroupOrUngroupSavedGroup}
            title={'Сгруппировать виджеты'}
          >
            <WidgetsGroupingButton />
          </Button>

          <Button
            className={styles['action']}
            disabled={currentChosenGroup === null && disabledActions}
            onClick={deleteWidgets}
            title={'Удалить виджет'}
          >
            <Delete />
          </Button>

          <Button className={styles['add']} disabled={!screen} onClick={() => setWidgetsSelector(!widgetsSelector)}>
            <Add />
            <Chevron className={classnames(styles['chevron'], { [styles['chevron__open']]: widgetsSelector })} />
            {widgetsSelector && (
              <div className={styles['widgetOptions']}>
                {Object.values(WidgetTypesC)
                  .filter(
                    type =>
                      (type !== SPORT_SCORE_WIDGET_TYPE && type !== SPORT_PERIOD_WIDGET_TYPE) ||
                      ((type === SPORT_SCORE_WIDGET_TYPE || type === SPORT_PERIOD_WIDGET_TYPE) &&
                        (location === LOCATION_TABLEAU_TEMPLATES || location === LOCATION_SPORT_EVENTS)),
                  )
                  .map(type => (
                    <div key={type} className={styles['widgetOption']} onClick={() => addWidget(type)}>
                      {WidgetTitle[type]}
                    </div>
                  ))}
              </div>
            )}
          </Button>

          {isSizeMessageShown && <div className={styles['size-message']}>{`${sizeCoeff}%`}</div>}
        </header>
      )}

      <DndContext
        onDragStart={onDragStart}
        onDragEnd={setWidget}
        onDragMove={onDragMove}
        modifiers={[restrictToParentElement]}
      >
        <div className={styles['body-wrapper']} onScroll={onScrollHandle} onWheel={onScrollHandle}>
          <div
            id="editor-droppable"
            style={style}
            className={styles['body']}
            ref={setNodeRef}
            onClick={e => !e.ctrlKey && e.target.id === 'editor-droppable' && setSelectedWidgets({})}
            onMouseDown={calculateSelectedRange}
            onMouseMove={calculateSelectedRange}
            onMouseUp={calculateSelectedRange}
            onMouseLeave={calculateSelectedRange}
            onWheel={onScrollHandle}
          >
            {/*TODO: Should be changed to widgets*/}
            {filteredWidgets?.map((w, index) => {
              const Widget = WIDGETS_COMPONENTS[w.type];
              return <Widget key={screen.id + w.id} {...{ ...w, index }} />;
            })}

            {renderSavedGroups()}

            {renderNewGroup()}

            {mouseDown && <SelectedRange coords={selectedRange} sizeCoeff={sizeCoeff} />}

            {deltaTarget && <GuideLines {...deltaTarget} />}
          </div>
        </div>
      </DndContext>
    </article>
  );
};

DesktopEditor.propTypes = {
  onUpdateLocalStorage: PropTypes.func,
};
