import constate from 'constate';
import { useUserState } from './modules/user.store';
import { usePopupDataState } from './modules/popupData.store';
import { useDevicesState } from './modules/devices.store';
import { useAdminsState } from './modules/admins.store';
import { useOperatorsState } from './modules/operators.store';
import { useFilesState } from './modules/files.store';
import { useOrganisationsState } from './modules/organisations.store';
import { useTerminalReleasesState } from './modules/terminalReleases.store';
import { useTerminalUpdatesState } from './modules/terminalUpdates.store';
import { useSchedulesState } from './modules/shedules.store';
import { usePresentationsState } from './modules/presentations.store';
import { usePlaylistsState } from './modules/paylists.store';
import { useScreenTemplatesState } from './modules/screenTemplates.store';
import { useTableauTemplatesState } from './modules/tableauTemplates.store';
import { useSportEventsState } from './modules/sportEvents.store';
import { useArbitersState } from './modules/arbiters.store';

function useGlobalState() {
  return {
    ...useUserState(),
    ...useAdminsState(),
    ...useFilesState(),
    ...useDevicesState(),
    ...useOperatorsState(),
    ...usePopupDataState(),
    ...useOrganisationsState(),
    ...useTerminalReleasesState(),
    ...useTerminalUpdatesState(),
    ...useSchedulesState(),
    ...usePresentationsState(),
    ...usePlaylistsState(),
    ...useScreenTemplatesState(),
    ...useTableauTemplatesState(),
    ...useSportEventsState(),
    ...useArbitersState(),
  };
}

const [StateProvider, useStateContext] = constate(useGlobalState);

function getGlobalState() {
  return useStateContext();
}

export { StateProvider, useStateContext, getGlobalState };
