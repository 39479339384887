import { Table } from 'containers/UI/Table';
import { useFiles } from './useFiles';
import PropTypes from 'prop-types';

const Files = () => {
  const { header, body, isFilesLoading, totalFilesCount } = useFiles();

  return (
    <Table
      heading={header}
      body={body}
      isLoading={isFilesLoading}
      totalEntitiesCount={totalFilesCount}
      link="id"
      idKey="id"
      emptyText={'Вы еще не добавили ни одного файла'}
    />
  );
};

Files.defaultProps = { files: [] };
Files.propTypes = { files: PropTypes.array, isLoading: PropTypes.bool };

export default Files;
