import { useApi } from 'services/hooks/useApi';
import { terminalReleasesService } from 'services/terminalReleases.service';
import { useCallback, useEffect, useState } from 'react';
import { responseHandler } from '../helpers/responseHandler';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { URLs } from 'helpers/constants/URLs';
import { administratorsService } from 'services/administrators.service';

const MAX_ADMINS_COUNT_PER_REQUEST = 100;

export const useTerminalReleasesState = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { pathname } = useLocation();
  const [fullTerminalReleaseData, setFullTerminalReleaseData] = useState({});
  const [allAdminsList, setAllAdminsList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dynamicSkipValue, setDynamicSkipValue] = useState(0);
  const [dynamicTakeValue, setDynamicTakeValue] = useState(MAX_ADMINS_COUNT_PER_REQUEST);
  const [allAdminsCount, setAllAdminsCount] = useState(0);

  const [
    terminalReleases,
    setTerminalReleases,
    isTerminalReleasesLoading,
    error,
    setError,
    totalTerminalReleasesCount,
    setTotalTerminalReleasesCount,
  ] = useApi(terminalReleasesService.getAll);

  const paginate = function () {
    useEffect(() => {
      if (pathname === URLs.terminalReleases) {
        return (() => {
          terminalReleasesService
            .getAll(getQueryParams(+page - 1) || {})
            .then(response => {
              if (response.status === 200) {
                Array.isArray(response.data?.items) && setTerminalReleases(response.data.items);
              }
            })
            .catch(setError);
        })();
      }
    }, [pathname, page, totalTerminalReleasesCount]);
  };
  paginate();

  const getAllAdmins = function () {
    useEffect(() => {
      if (!isModalOpen || (allAdminsList.length > 0 && allAdminsList.length === allAdminsCount)) {
        return;
      }

      const queryParams = {
        skip: dynamicSkipValue,
        take: dynamicTakeValue,
      };

      administratorsService.getAll(queryParams).then(response => {
        if (response.status === 200) {
          setAllAdminsCount(response?.data?.count);
          setDynamicSkipValue(prevState => prevState + MAX_ADMINS_COUNT_PER_REQUEST);
          setDynamicTakeValue(allAdminsCount - dynamicSkipValue);
          Array.isArray(response?.data.items) &&
            response.data.items.length > 0 &&
            setAllAdminsList(prevState => [...prevState, ...response.data.items]);
        }
      });
    }, [isModalOpen, allAdminsList]);
  };
  getAllAdmins();

  const addTerminalRelease = useCallback(
    (terminalRelease, file, onModalClose) => {
      return (() => {
        terminalReleasesService
          .createNew(terminalRelease)
          .then(response => {
            if (response.status === 200) {
              setTotalTerminalReleasesCount(prevState => prevState + 1);

              responseHandler(true, 'Релиз терминала был успешно создан', onModalClose);

              if (file) {
                uploadFile(file, response?.data?.id, onModalClose);
              }
            }
          })
          .catch(error => {
            setError(error);
            responseHandler(false, 'Релиз терминала не был создан', onModalClose, error.response.data.code);
          });
      })();
    },
    [totalTerminalReleasesCount],
  );

  const removeTerminalRelease = useCallback((terminalReleaseId, onModalClose) => {
    return (() => {
      terminalReleasesService
        .deleteById(terminalReleaseId)
        .then(response => {
          if (response.status === 204) {
            setTerminalReleases(prevState =>
              prevState.filter(terminalRelease => terminalRelease.id !== terminalReleaseId),
            );
            setTotalTerminalReleasesCount(prevState => prevState - 1);
            responseHandler(true, 'Релиз терминала был успешно удален', onModalClose);
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Релиз терминала не был удален', onModalClose);
        });
    })();
  }, []);

  const editTerminalRelease = useCallback((updatedTerminalRelease, file, onModalClose) => {
    const { id, ...payload } = updatedTerminalRelease;

    return (() => {
      terminalReleasesService
        .put(id, payload)
        .then(response => {
          if (response.status === 204) {
            setTerminalReleases(prevState => {
              return [
                ...prevState.filter(terminalRelease => terminalRelease.id !== updatedTerminalRelease.id),
                updatedTerminalRelease,
              ];
            });
            responseHandler(true, 'Релиз терминала был успешно изменён', onModalClose);

            if (file) {
              uploadFile(file, id, onModalClose);
            }
          }
        })
        .catch(error => {
          setError(error);
          responseHandler(false, 'Релиз терминала не был изменён', onModalClose);
        });
    })();
  }, []);

  const uploadFile = (fileData, releaseId, onModalClose) => {
    terminalReleasesService
      .uploadFile(fileData, releaseId)
      .then(response => {
        if (response.status === 204) {
          responseHandler(true, 'Файл был успешно загружен', onModalClose, null);
        }
      })
      .catch(error => {
        setError(error.response.data.code);
        responseHandler(false, 'Файл не был загружен', onModalClose, error.response.data.code);
      });
  };

  const getTerminalReleaseById = useCallback(terminalReleaseId => {
    terminalReleasesService
      .getById(terminalReleaseId)
      .then(response => {
        if (response.status === 200) {
          const dataWithRefactoredAdmins = {
            ...response?.data,
            administrators: response?.data?.administrators.map(admin => {
              return admin.id;
            }),
          };
          setFullTerminalReleaseData(dataWithRefactoredAdmins);
        }
      })
      .catch(setError);
  }, []);

  return {
    terminalReleases,
    totalTerminalReleasesCount,
    isTerminalReleasesLoading,
    error,

    addTerminalRelease,
    removeTerminalRelease,
    editTerminalRelease,
    getTerminalReleaseById,
    fullTerminalReleaseData,
    allAdminsList,
    setIsModalOpen,
    isModalOpen,
    setFullTerminalReleaseData,
  };
};
