import { useStateContext } from 'store';
import { playlistsService } from 'services';
import { NavLink } from 'react-router-dom';
import { showSuccessNotify } from 'helpers/showNotify';
import { useCallback, useEffect, useState } from 'react';

import { useGroups } from 'containers/Groups/hooks/useGroups';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { ModalGroupsManagement } from 'containers/UI/Modal/Components/Groups';
import { ModalSend } from 'containers/UI/Modal/Components/Common/ModalSend';
import { ModalDelete } from 'containers/UI/Modal/Components/Common/ModalDelete';
import { ModalPlaylistsAdd, ModalPlaylistsEdit, ModalPlaylistsCopy } from 'containers/UI/Modal/Components/Playlists';

import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as List } from 'icons/list.svg';
import { ReactComponent as Upload } from 'icons/upload.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ReactComponent as Copy } from 'icons/copy.svg';

import tableStyles from 'containers/UI/Table/Table.module.css';
import styles from '../Playlists.module.css';

export const usePlaylists = () => {
  const { playlists, setPlaylists, openPopup, isPlaylistsLoading, totalPlaylistsCount, removePlaylist } =
    useStateContext();

  const [filterType, setFilterType] = useState(null);

  const {
    groups,
    groupOptions,

    loadGroups,
    handleCreateGroup,
    handleUpdateGroup,
    handleDeleteGroup,
  } = useGroups(playlistsService);

  useEffect(() => {
    loadGroups();
  }, []);

  useEffect(() => {
    setFilterType(null);
  }, [groups]);

  const loadPlaylists = params => {
    playlistsService.getPlaylists(params).then(result => {
      if (result.status === 200) {
        setPlaylists(result.data.items);
      }
    });
  };

  const handleFilterGroup = filterValue => {
    if (filterValue === 'all') {
      loadPlaylists();
    } else {
      loadPlaylists({ groupId: String(filterValue) });
    }
    setFilterType(filterValue);
  };

  const handleSendPlaylist = ({ deviceId, contentId }) => {
    if (Array.isArray(deviceId)) {
      deviceId.forEach(id => {
        playlistsService.sendPlaylist(contentId, id).then(result => {
          if (result.status === 204) {
            showSuccessNotify('Плейлист был успешно отправлен');
          }
        });
      });
    } else {
      playlistsService.sendPlaylist(contentId, deviceId).then(result => {
        if (result.status === 204) {
          showSuccessNotify('Плейлист был успешно отправлен');
        }
      });
    }
  };

  const handleOpenGroupSettings = useCallback(() => {
    openPopup(
      () => (
        <ModalGroupsManagement
          groups={groups}
          handleCreate={handleCreateGroup}
          handleEdit={handleUpdateGroup}
          handleDelete={handleDeleteGroup}
        />
      ),
      {
        title: 'Управление группами',
      },
    );
  }, [groups]);

  const handlePlaylistCreate = useCallback(() => {
    openPopup(
      () => (
        <ModalPlaylistsAdd
          groupsOptions={groupOptions.filter(option => option.value !== 'all')}
          handleCreateGroup={handleCreateGroup}
        />
      ),
      {
        title: 'Создание плейлиста',
      },
    );
  }, [groupOptions]);

  const handlePlaylistCopy = useCallback(playlist => {
    let groupsOptionsWithChoosenGroup;

    if (playlist.group) {
      groupsOptionsWithChoosenGroup = [...groupOptions, { name: playlist.group.name, value: playlist.group.id }];
    } else {
      groupsOptionsWithChoosenGroup = groupOptions;
    }

    openPopup(
      () => (
        <ModalPlaylistsCopy
          groupsOptions={groupsOptionsWithChoosenGroup.filter(option => option.value !== 'all')}
          playlist={playlist}
          handleCreateGroup={handleCreateGroup}
        />
      ),
      { title: 'Копирование плейлиста' },
    );
  }, []);

  const handlePlaylistEdit = useCallback(
    playlist => {
      const correctGroupsOptions = groupOptions.map(groupOption => {
        if (!groupOption.value) {
          groupOption.value = groupOption.name;
        }

        return groupOption;
      });
      openPopup(
        () => (
          <ModalPlaylistsEdit
            playlist={playlist}
            groupsOptions={correctGroupsOptions.filter(option => option.value !== 'all')}
            handleCreateGroup={handleCreateGroup}
          />
        ),
        {
          title: 'Редактирование плейлиста',
        },
      );
    },
    [groupOptions],
  );

  const handlePlaylistDelete = useCallback(playlist => {
    openPopup(
      () => (
        <ModalDelete
          contentId={playlist.id}
          title={`Вы уверены что хотите удалить плейлист ${playlist.name}?`}
          description={playlist.count > 0 ? 'Плейлист используется в одном или нескольких расписаниях' : ''}
          isVisibleIcon={false}
          onDelete={removePlaylist}
        />
      ),
      {
        title: 'Удаление плейлиста',
      },
    );
  }, []);

  const handlePlaylistSend = useCallback(playlist => {
    openPopup(() => <ModalSend contentId={playlist.id} onSend={handleSendPlaylist} />, {
      title: 'Отправка на устройство',
    });
  }, []);

  const header = [
    { key: 'name', title: 'Название' },
    { key: 'count', title: 'Количество рабочих столов' },
    { key: 'comment', title: 'Комментарий' },
    { key: 'group', title: 'Группа' },
    {
      key: 'actions',
      title: (
        <div key="playlist-head-button" className={tableStyles.rowButtons}>
          <Button key="playlist-create" className={tableStyles.headerButton} onClick={() => handlePlaylistCreate()}>
            Создать плейлист
          </Button>
        </div>
      ),
    },
  ];

  const body = playlists?.map(playlist => {
    const { id, name, count, comment, group = {} } = playlist || {};
    return {
      id,
      name,
      count,
      comment,
      group: group?.name || null,
      actions: (
        <div key="playlist-item-buttons" className={tableStyles.rowButtons}>
          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Copy /> }}
            className={styles.copyButton}
            title={'Скопировать'}
            onClick={() => handlePlaylistCopy(playlist)}
          />

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Edit /> }}
            title={'Изменить'}
            onClick={() => handlePlaylistEdit(playlist)}
          />

          <NavLink to={'/playlists/' + id}>
            <Button variant={ButtonVariantEnum.ICON} iconData={{ icon: <List /> }} title={'Список'} />
          </NavLink>

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Upload /> }}
            title={'Отправка на устройство'}
            className={styles.sendButton}
            onClick={() => handlePlaylistSend(playlist)}
          />

          <Button
            variant={ButtonVariantEnum.ICON}
            iconData={{ icon: <Delete /> }}
            title={'Удалить'}
            onClick={() => handlePlaylistDelete(playlist)}
          />
        </div>
      ),
    };
  });

  return {
    header,
    body,

    isPlaylistsLoading,
    filterType,
    groupOptions,
    handleFilterGroup,
    handleOpenGroupSettings,
    totalPlaylistsCount,
  };
};
