export const InputParseErrors = (errorData, options = {}) => {
  if (!errorData) return null;

  const { type } = errorData;
  const { min, max, minLength = 5, maxLength = 100 } = options;

  if (type === 'required') return `Поле является обязательным`;

  if (type === 'min') return `Минимальное значение ${min}`;
  if (type === 'max') return `Максимальное значение ${max}`;
  if (type === 'minLength') return `Поле содержит менее ${minLength} символов`;
  if (type === 'maxLength') return `Максимально допустимая длина ${maxLength}`;

  if (type === 'containsUpperCase') return `Поле должно содержать хотя бы 1 заглавную букву`;
  if (type === 'containsLowerCase') return `Поле должно содержать как минимум 1 строчную букву`;
  if (type === 'withoutSpaces') return `Поле должно быть без пробелов`;
  if (type === 'containsTwoDigits') return `Поле должно содержать не менее 2 цифр`;
  if (type === 'retryPassword') return `Пароли не совпадают`;
  if (type === 'fileExist') return `Данный файл уже загружен`;

  if (type === 'pattern') return errorData?.message || 'Ошибка';
};
