import { containsDigits, containsLowerCase, containsUpperCase, withoutSpaces } from './extraValidation';

const emailSchema = {
  required: true,
  pattern: {
    value: /\S+@\S+\.\S+/,
    message: 'Введите адрес электронной почты',
  },
  // checkEmailTaken: async () => await fetch(), // todo isEmailTaken
};

const passwordSchema = {
  required: true,
  minLength: 8,
  maxLength: 20,
  validate: {
    containsTwoDigits: containsDigits(2),
    containsUpperCase,
    containsLowerCase,
    withoutSpaces,
  },
};

export { emailSchema, passwordSchema };
