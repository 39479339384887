import { createBaseApi, configRequest } from './baseApi';

const baseApi = createBaseApi();

const DEFAULT_PARAMS = {
  skip: 0,
  take: 10,
};

function getPlaylists(params = {}) {
  return baseApi.get('/playlists', {
    ...configRequest,
    params: { ...DEFAULT_PARAMS, ...params },
  });
}

function getPlaylist(playlistId) {
  return baseApi.get(`/playlists/${playlistId}`, configRequest);
}

function addPlaylist(data) {
  return baseApi.post('/playlists', data, configRequest);
}

function updatePlaylist(playlistId, data) {
  return baseApi.put(`/playlists/${playlistId}`, data, configRequest);
}

function deletePlaylist(playlistId) {
  return baseApi.delete(`/playlists/${playlistId}`, configRequest);
}

function sendPlaylist(playlistId, deviceId) {
  return baseApi.post(`/playlists/${playlistId}/devices/${deviceId}`, configRequest);
}

/** groups */
function getGroups() {
  return baseApi.get('/playlists/groups', configRequest);
}

function addGroup(data) {
  return baseApi.post('/playlists/groups', data, configRequest);
}

function updateGroup(groupId, data) {
  return baseApi.put(`/playlists/groups/${groupId}`, data, configRequest);
}

function deleteGroup(groupId) {
  return baseApi.delete(`/playlists/groups/${groupId}`, configRequest);
}
/**
 * screens
 */
function getScreens(playlistId) {
  return baseApi.get(`/playlists/${playlistId}/screens`, configRequest);
}

function addScreens(data, playlistId) {
  return baseApi.post(`/playlists/${playlistId}/screens`, data, configRequest);
}

/**
 *
 * @type {{getPlaylistsGroups: (function(): *), updateGroupPlaylist: (function(*, *): *), deletePlaylist: (function(*): *), addPlaylist: (function(*): *), getPlaylist: (function(*): *), addGroupPlaylist: (function(*): *), deleteGroupPlaylist: (function(*): *), getPlaylists: (function(*=): *), updatePlaylist: (function(*, *): *)}}
 */
export const playlistsService = {
  name: 'playlists',
  getPlaylists,
  getPlaylist,
  addPlaylist,
  updatePlaylist,
  deletePlaylist,
  sendPlaylist,

  getScreens,
  addScreens,

  getGroups,
  addGroup,
  updateGroup,
  deleteGroup,
};
