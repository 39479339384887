import { Table } from 'containers/UI/Table';
import HeaderFilterGroups from 'containers/App/HeaderFilterGroups/HeaderFilterGroups';
import { usePlaylists } from './Hooks/usePlaylists';

const Playlists = () => {
  const {
    header,
    body,
    isPlaylistsLoading,
    filterType,
    groupOptions,
    handleFilterGroup,
    handleOpenGroupSettings,
    totalPlaylistsCount,
  } = usePlaylists();

  return (
    <>
      <HeaderFilterGroups
        filterType={filterType}
        groupOptions={groupOptions}
        handleFilterGroup={handleFilterGroup}
        handleOpenGroupSettings={handleOpenGroupSettings}
      />

      <Table
        isLoading={isPlaylistsLoading}
        heading={header}
        body={body}
        totalEntitiesCount={totalPlaylistsCount}
        link="id"
        idKey="id"
        emptyText={'Вы еще не добавили ни одного плейлиста'}
      />
    </>
  );
};

export default Playlists;
