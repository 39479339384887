export const URLs = {
  administrators: '/administrators',
  devices: '/devices',
  terminalReleases: '/terminalReleases',
  files: '/files',
  operators: '/operators',
  arbiters: '/arbiters',
  playlists: '/playlists',
  presentations: '/presentations',
  schedules: '/schedules',
  terminalUpdates: '/terminalUpdates',
  organisations: '/organisations',
  screenTemplates: '/screenTemplates',
  scoreboardTemplates: '/tableauTemplates',
  sportEvents: '/sportEvents',
};
