import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { screenTemplatesService } from 'services';
import { getQueryParams } from 'helpers/queryParams/queryParams.helper';
import { responseHandler } from 'store/helpers/responseHandler';
import { decodeWidget, encodeWidget, MODAL_DESKTOP_TEMPLATES_PAGE_SIZE, NEW_DESKTOP } from '../../helpers';
import { useStateContext } from 'store';

export const useDesktopsModals = () => {
  const [screenTemplates, setScreenTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalTemplatesCount, setTotalTemplatesCount] = useState(null);
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  const { closePopup } = useStateContext();

  const createNewDesktopFromTemplate = (chosenTemplateId, append, setSelectScreen) => {
    screenTemplatesService.getAllowedScreenTemplatesWidgets(chosenTemplateId).then(response => {
      if (response.status === 200) {
        if (Array.isArray(response?.data?.widgets)) {
          const widgets = response.data.widgets.map(decodeWidget);
          const newDesktop = { ...NEW_DESKTOP, widgets };
          const ourId = Math.random().toString();
          append({ ourId, id: ourId, ...newDesktop });
          setSelectScreen(ourId);
        }
      }
    });
  };

  const saveAsNewTemplate = (data = {}) => {
    const { width, height, widgets, name } = data;
    const payload = { width, height, name };

    screenTemplatesService
      .createNew(payload)
      .then(response => {
        if (response.status === 200) {
          const encodedWidgets = widgets.map(encodeWidget);
          screenTemplatesService
            .addWidgets(response?.data?.id, { widgets: encodedWidgets })
            .then(response => {
              if (response.status === 204) {
                responseHandler(true, 'Шаблон рабочего стола успешно создан');
              }
            })
            .catch(e => responseHandler(false, 'Не удалось создать новый шаблон', closePopup, e.response.data.code));
        }
      })
      .catch(e => responseHandler(false, 'Не удалось создать новый шаблон', closePopup, e.response.data.code));
  };

  const changeExistingTemplate = (data = {}) => {
    const { chosenTemplateId, ...screenData } = data;
    const { widgets } = screenData;

    const encodedWidgets = widgets.map(encodeWidget);
    const payload = { ...screenData, widgets: encodedWidgets };

    screenTemplatesService
      .updateAllowedScreenTemplatesWidgets(chosenTemplateId, payload)
      .then(response => {
        if (response.status === 204) {
          responseHandler(true, 'Шаблон рабочего стола успешно обновлен');
        }
      })
      .catch(e => responseHandler(false, 'Не удалось обновить шаблон', closePopup, e.response.data.code));
  };

  useEffect(() => {
    getTemplatesIds();
  }, [page]);

  const getTemplatesIds = () => {
    setIsLoading(true);
    screenTemplatesService
      .getAll(getQueryParams(+page - 1, null, MODAL_DESKTOP_TEMPLATES_PAGE_SIZE) || {})
      .then(response => {
        if (response.status === 200) {
          setTotalTemplatesCount(response?.data?.count);
          setScreenTemplates(response?.data?.items);
        }
      })
      .catch(e => responseHandler(false, null, () => {}, e.response.data.code))
      .finally(() => setIsLoading(false));
  };

  return {
    screenTemplates,
    isLoading,
    totalTemplatesCount,
    createNewDesktopFromTemplate,
    saveAsNewTemplate,
    changeExistingTemplate,
  };
};
