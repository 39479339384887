import PropTypes from 'prop-types';
import { Button, ButtonVariantEnum } from 'containers/UI/Button';
import { Select, SelectVariantEnum } from 'containers/UI/Select';

import { ReactComponent as FilterIcon } from 'icons/filter.svg';
import { ReactComponent as GroupSettings } from 'icons/group_settings.svg';
import { ReactComponent as ArrowIcon } from 'icons/arrows/arrow_down.svg';

import styles from './HeaderFilterGroups.module.css';

const HeaderFilterGroups = ({ filterType = null, groupOptions = [], handleOpenGroupSettings, handleFilterGroup }) => {
  return (
    <div className={styles.headerButtons}>
      <Button
        variant={ButtonVariantEnum.ICON}
        iconData={{ icon: <GroupSettings /> }}
        title={'Управление группами'}
        onClick={handleOpenGroupSettings}
      />
      <Select
        wrapperClassName={styles.headerFilterWrapper}
        className={styles.headerFilter}
        variant={SelectVariantEnum.NOT_BORDERED}
        icon={<FilterIcon />}
        customArrowIcon={<ArrowIcon />}
        options={groupOptions}
        selected={filterType}
        onChange={handleFilterGroup}
      />
    </div>
  );
};

HeaderFilterGroups.propTypes = {
  filterType: PropTypes.string,
  groupOptions: PropTypes.array,
  handleFilterGroup: PropTypes.func,
  handleOpenGroupSettings: PropTypes.func,
};

export default HeaderFilterGroups;
