export const renderPPTX = (id, pptxFileUrl) => {
  // eslint-disable-next-line no-undef
  $('#' + id).pptxToHtml({
    pptxFileUrl,
    slideMode: false,
    slidesScale: 100,
    keyBoardShortCut: false,
    slideModeConfig: {
      //on slide mode (slideMode: true)
      first: 1,
      nav: true /** true,false : show or not nav buttons*/,
      navTxtColor: 'black' /** color */,
      navNextTxt: '---', //">"
      navPrevTxt: '---', //"<"
      showPlayPauseBtn: false /** true,false */,
      keyBoardShortCut: false /** true,false */,
      showSlideNum: true /** true,false */,
      showTotalSlideNum: false /** true,false */,
      autoSlide: false /** false or seconds (the pause time between slides) , F8 to active(keyBoardShortCut: true) */,
      randomAutoSlide: false /** true,false ,autoSlide:true */,
      loop: false /** true,false */,
      background: 'black' /** false or color*/,
      transition:
        'default' /** transition type: "slid","fade","default","random" , to show transition efects :transitionTime > 0.5 */,
      transitionTime: 1 /** transition time in seconds */,
    },
  });
};
