import { Table } from 'containers/UI/Table';
import HeaderFilterGroups from 'containers/App/HeaderFilterGroups/HeaderFilterGroups';

import { useSchedules } from './Hooks/useSchedules';

const Schedules = () => {
  const {
    header,
    body,
    isSchedulesLoading,
    filterType,
    groupOptions,
    handleFilterGroup,
    handleOpenGroupSettings,
    totalSchedulesCount,
  } = useSchedules();

  return (
    <>
      <HeaderFilterGroups
        filterType={filterType}
        groupOptions={groupOptions}
        handleFilterGroup={handleFilterGroup}
        handleOpenGroupSettings={handleOpenGroupSettings}
      />

      <Table
        isLoading={isSchedulesLoading}
        heading={header}
        body={body}
        totalEntitiesCount={totalSchedulesCount}
        link="id"
        idKey="id"
        emptyText={'Вы еще не добавили ни одного расписания'}
      />
    </>
  );
};

export default Schedules;
