import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { pageSize as defaultPageSize } from 'helpers/constants/queryParams';

import { ReactComponent as GoToLeft } from 'icons/pagination/left.svg';
import { ReactComponent as GoToRight } from 'icons/pagination/right.svg';

import styles from './Paginator.module.css';

export const Paginator = ({ totalEntitiesCount = 0, styleData = {}, pageSize }) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const pagesAmount = Math.ceil(totalEntitiesCount / (pageSize || defaultPageSize));
  const page = +searchParams.get('page') || 1;

  const onNextPage = whereToGo => {
    const toPrevPage = whereToGo === -1;
    const toNextPage = whereToGo === 1;

    if (page > 1 && toPrevPage) {
      setSearchParams({ page: page - 1 });
    }

    if (page !== pagesAmount && toNextPage) {
      setSearchParams({ page: page + 1 });
    }
  };

  const onPageNumberClick = index => {
    setSearchParams({ page: index + 1 });
  };

  const buttons = () => {
    let buttonsArray = [];

    for (let i = 0; i < pagesAmount; i++) {
      const className = classNames({
        [styles.active]: i + 1 === page,
        [styles.number_button]: true,
      });

      buttonsArray.push(
        <a key={i} className={className} onClick={() => onPageNumberClick(i)}>
          {i + 1}
        </a>,
      );
    }

    return buttonsArray;
  };

  return (
    <div
      className={classNames({
        [styles.paginator]: true,
        [styles.__disabled]: pagesAmount === 1,
      })}
      style={styleData}
    >
      <a className={styles.next_button} onClick={() => onNextPage(-1)}>
        <GoToLeft />
      </a>

      {buttons()}

      <a className={styles.next_button} onClick={() => onNextPage(+1)}>
        <GoToRight />
      </a>
    </div>
  );
};

Paginator.propTypes = {
  totalEntitiesCount: PropTypes.number,
  styleData: PropTypes.object,
  pageSize: PropTypes.number,
};
